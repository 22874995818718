import axios from "axios";
import { apiHost } from "../config";

export const updatePlan = async (payload) => {
  const formdata = new FormData();
  formdata.append("plan", payload.plan);

  const response = await axios.post(
    `${apiHost.fetchUrl}/organisation/update-subscription/${payload.id}`,
    formdata,
    {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("id_token") || undefined,
      },
    }
  );
  console.log(response)

  return response;
};
