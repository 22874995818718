import React from "react";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import NumberFormat from "react-number-format";
import SuperFetch from "../../../helpers/superFetch";
import {
  formatTwoDecimals,
  calculateGstHelper,
  renderTaxType,
  calcTwoDecimalPlaces,
  formatTwoDecimalsWithComma,
} from "../../../_methods/commonMethods";
import { store } from "react-notifications-component";
import ProposalActions from "../../../redux/Proposals/actions";
import { apiHost, apiSocketHost } from "../../../config";
import ProposalsHelper from "../../../helpers/proposalHelper";
import HistorySection from "../Invoice/HistorySection";
import DraftComponent from "../../DraftComponent";

class NonEditProposal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      uuid: "",
      showModal: true,
      proposal_date: "",
      proposal_expiry_date: "",
      due_date: "",
      proposal_rows: [],
      total: 0,
      gst: 0,
      net: 0,
      organisation_name: "",
      address: "",
      suburb: "",
      state: "",
      postcode: "",
      email: "",
      id: "",
      proposal_number: "",
      currentLead: {
        first_name: "",
        last_name: "",
      },
      message: "",
      sms: false,
      pdf: false,
      message_err: false,
      email_err: false,
      markAsPaidError: null,
      modal: false,
      email_correct_err: false,
      status: null,
      accepted_date: null,
      attachments: [],
      notes: "",
      signature: null,
      organisation: null,
      abn: null,
      reminder_enabled: 0,
    };
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSendInvoice = this.handleSendInvoice.bind(this);
    this.handleAbnSave = this.handleAbnSave.bind(this);
    this.handleSms = this.handleSms.bind(this);
    this.handlePdf = this.handlePdf.bind(this);
  }

  handleSms() {
    this.setState({ sms: !this.state.sms });
  }
  handlePdf() {
    this.setState({ pdf: !this.state.pdf });
  }

  changeToNumber(item) {
    let number = null;
    if (item === true) number = 1;
    if (item === false) number = 0;
    return number;
  }

  handlePaidDate = (e) => {
    this.setState({
      paid_date: new Date(e),
      markAsPaidError: null,
    });
  };

  validateMarkAsPaid = () => {
    let error = false;
    if (!this.state.paid_date) {
      error = true;
      this.setState({ markAsPaidError: "Paid Date is required" });
    }

    return error;
  };

  nameChangeHandler = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  onMobileChangeHandler = (e) => {
    this.setState({
      mobile: e.target.value,
    });
  };

  handleInputEmail(e) {
    this.setState({
      email: e.target.value,
      email_err: false,
    });
  }

  handleInputMessage(e) {
    this.setState({
      message: e.target.value,
      message_err: false,
    });
  }

  validate() {
    let error = false;
    if (this.state.message === "") this.state.message_err = true;
    if (this.state.email === "") {
      this.state.email_err = true;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
        this.state.email_correct_err = true;
      } else {
        this.state.email_correct_err = false;
        this.setState({
          email_correct_err: false,
        });
      }
    }
    if (
      this.state.message_err ||
      this.state.email_err ||
      this.state.email_correct_err
    )
      error = true;

    return error;
  }

  handleSendInvoice() {
    if (this.validate()) {
      this.setState({
        modal: true,
      });
      return;
    } else {
      let data = {
        name: this.state.name,
        mobile: this.state.mobile,
        email: this.state.email,
        message: this.state.message,
        send_sms: this.changeToNumber(this.state.sms),
        include_pdf: this.changeToNumber(this.state.pdf),
        link: "/proposal/" + this.state.uuid,
      };
      SuperFetch.post(
        "/proposals/send/" + this.state.proposal_number,
        data
      ).then((response) => {
        this.getInvoiceData();
        store.addNotification({
          message: "Proposal sent successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.setState({ message: "" });

        this.setMessageHandler();

        $("#modal-org-proposal").find("button.close").click();
      });
    }
  }

  handleEdit(id, e) {
    this.props.history.push("/proposal/edit/" + id);
  }

  openCopyPage = async () => {
    const { proposal } = this.props;

    await localStorage.setItem(
      "copy_proposals",
      JSON.stringify(proposal.items)
    );

    setTimeout(() => {
      this.props.history.push(`/proposal/add/product?products=copy`);
    }, 100);
  };

  handleShowModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  abnChangeHandler = (e) => {
    this.setState({
      abn: e.target.value,
    });
  };

  dataState(response) {
    let id = this.props.match.params.id;
    this.setState({
      organisation: response.lead.organisation,
      organisation_name: response.lead.company_name,
      address: response.lead.address,
      suburb: response.lead.suburb,
      state: response.lead.state,
      postcode: response.lead.postcode,
      email: response.lead.email,
      id: id,
      uuid: response.uuid,
      business_name: response.lead.organisation.business_name,
      accepted_date: response.accepted_date,
    });
  }

  componentDidMount() {
    const { selectedProposal } = this.props;
    document.title = "Cloud Collect - Preview Proposal";
    let id = this.props.match.params.id;
    SuperFetch.get("/proposals/" + id).then((response) => {
      this.initInvoice(response.data);
      this.dataState(response.data);
      selectedProposal({ payload: response.data });
    });
    this.setMessageHandler();
  }

  getInvoiceData = () => {
    const { selectedProposal } = this.props;
    let id = this.props.match.params.id;
    SuperFetch.get("/proposals/" + id).then((response) => {
      this.initInvoice(response.data);
      this.dataState(response.data);
      selectedProposal({ payload: response.data });
    });
  };

  setMessageHandler = () => {
    SuperFetch.get("/templates").then((response) => {
      this.setState({
        message: response.data.template_data.proposal
          ? response.data.template_data.proposal
          : "<p></p>",
      });
    });
  };

  downloadPdfHandler = () => {
    const { proposal } = this.props;

    window.open(
      apiSocketHost.fetchUrl + "/proposal/pdf/" + `${proposal.uuid}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  initInvoice(data) {
    let rows = [];

    for (let item of data.items) {
      let obj = {
        id: item.id,
        name: item.name,
        description: item.description,
        unit_amount: parseFloat(item.amount),
        quantity: item.quantity,
        tax_rate: item.gst_free,
        amount: 0,
      };
      rows.push(obj);
    }
    // let tmpDate = data.invoice_date.split('-');
    // let date = new Date(tmpDate[2] + '-' + tmpDate[1] + '-' +tmpDate[0]);
    // tmpDate = data.due_date.split('-');
    // let dueDate = new Date(tmpDate[2] + '-' + tmpDate[1] + '-' +tmpDate[0]);
    this.setState({
      status: data.status,
      proposal_date: data.proposal_date,
      proposal_expiry_date: data.proposal_expiry_date,
      due_date: data.due_date,
      proposal_number: data.proposal_reference,
      lead: data.lead_id,
      attachments: data.attachments,
      notes: data.notes,
      name: data.lead.full_name,
      mobile: data.lead.mobile,
      signature: data.lead.organisation.signature,
      reminder_enabled: data.reminder_enabled,
    });
    this.calculatePrices(rows);
    this.getLeads(data.lead_id);
  }

  getLeads(id) {
    SuperFetch.get("/leads").then((response) => {
      let lead;
      for (let item of response.data) {
        if (item.id === id) lead = item;
      }
      if (lead) this.setState({ currentLead: lead });
    });
  }

  calculatePrices(baseTmp) {
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;
    for (let i = 0; i < baseTmp.length; i++) {
      baseTmp[i].amount =
        baseTmp[i].unit_amount * parseFloat(baseTmp[i].quantity).toFixed(2);
      net += baseTmp[i].amount;
      if (parseInt(baseTmp[i].tax_rate) === 0) {
        netWithGST = baseTmp[i].amount;
      }
      // gst += baseTmp[i].gst_free === 1 ? 0 : (net * 10) / 100;
      gst += calculateGstHelper(netWithGST, baseTmp[i].tax_rate);
      total = net + gst;
    }
    this.setState({
      proposal_rows: baseTmp,
      net: net,
      gst: gst,
      total: total,
    });
  }

  openPreviewPage = () => {
    let url = "/proposal/" + this.state.uuid;
    window.open(url);
  };

  getChangeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  editorValue = (value) => {
    this.setState({ message: value, error: false });
  };

  handleAbnSave() {
    let data = {
      abn: this.state.abn,
    };
    SuperFetch.post(
      "/organisation/update/" + this.state.organisation.id,
      data
    ).then((response) => {
      this.getInvoiceData();
      store.addNotification({
        message: "Business ABN updated successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });

      $("#modal-abn").find("button.close").click();
    });
  }

  changeStatusToArchived = () => {
    const { match, history } = this.props;

    let data = { status: "Archived", proposal_id: match.params.id };
    axios
      .post(`${apiHost.fetchUrl}/proposal-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        if (this.state.status == "Draft") {
          store.addNotification({
            message: "Draft proposal removed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          history.push("/proposal");
        } else {
          store.addNotification({
            message: "Proposal archived successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });

          this.getInvoiceData();
        }
      })
      .catch((error) => {});
  };

  changeStatusToExpired = () => {
    const { match, history } = this.props;

    let data = { status: "Expired", proposal_id: match.params.id };
    axios
      .post(`${apiHost.fetchUrl}/proposal-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        store.addNotification({
          message: "Proposal updated successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.getInvoiceData();
      })
      .catch((error) => {});
  };

  changeStatusToUnexpired = () => {
    const { match, history } = this.props;

    let data = { status: "Approved", proposal_id: match.params.id };
    axios
      .post(`${apiHost.fetchUrl}/proposal-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        store.addNotification({
          message: "Proposal updated successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.getInvoiceData();
      })
      .catch((error) => {});
  };

  enableReminder = (e) => {
    this.setState({
      reminder_enabled: e.target.checked ? 1 : 0,
    });
    let data = {
      id: this.state.id,
      reminder_enabled: e.target.checked ? 1 : 0,
    };
    SuperFetch.post("/proposals/updateInput", data).then((response) => {
      // this.addNotification("Reminder updated", "success");
    });
  };

  render() {
    const { proposal } = this.props;
    const { organisation } = this.state;
    return (
      <div className="main-content-inner proposal">
        <div className="row">
          <div className="col-lg-10 mt-3">
            <div className="card">
              {proposal && proposal.status && (
                <div
                  className={`card-header card-${proposal.status.toLowerCase()}`}
                >
                  {proposal.status}
                </div>
              )}
              <div className="card-body">
                <div className="invoice-area">
                  {/* <div className="invoice-head">
                    <div className="row">
                      <div className="iv-left col-6">
                        <h4>Proposal #{this.state.proposal_number}</h4>
                      </div>
                      <div class="iv-right col-6 text-md-right">
                        <h4>{this.state.status}</h4>
                      </div>
                    </div>
                  </div> */}
                  <div className="row ">
                    <div class="col-md-3 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Contact
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Contact</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={
                            this.state.currentLead
                              ? this.state.currentLead.type &&
                                this.state.currentLead.type ===
                                  "Business/Organisation"
                                ? this.state.currentLead.company_name
                                : this.state.currentLead.full_name
                              : ""
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Reference
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Reference</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.state.proposal_number}
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Date
                      </label>
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Date</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.getChangeDate(
                            this.state.proposal_date
                          )}
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Expires
                      </label>
                      <div class="input-group mb-2 date-e">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Expires</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.getChangeDate(
                            this.state.proposal_expiry_date
                          )}
                          disabled
                        />
                      </div>
                    </div>

                    {/* <div class="col-md-2 col-sm-12">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Due
                      </label>
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Due</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="inlineFormInputGroup"
                          placeholder=""
                          defaultValue={this.getChangeDate(this.state.due_date)}
                          disabled
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-6">
                      <div className="invoice-address">
                        <p>
                          {this.state.currentLead
                            ? this.state.currentLead.full_name
                            : ""}
                        </p>
                        <p class="text-capitalize">{this.state.address}</p>
                        <p class="text-capitalize">
                          {this.state.suburb} {this.state.state}{" "}
                          {this.state.postcode}
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="col-md-6 text-md-right" id="form-dates">
                      <p>
                        Proposal Date :{" "}
                        {this.getChangeDate(this.state.proposal_date)}
                      </p>
                      <p>
                        Proposal Expiry Date :{" "}
                        {this.getChangeDate(this.state.proposal_expiry_date)}
                      </p>
                      {this.state.status === "Accepted" && (
                        <p>
                          Proposal Accepted Date :{" "}
                          {this.getChangeDate(this.state.accepted_date)}
                        </p>
                      )}
                      {this.state.status === "Declined" && (
                        <p>
                          Proposal Declined Date :{" "}
                          {this.getChangeDate(this.state.accepted_date)}
                        </p>
                      )}
                    </div> */}
                  </div>
                  <br />
                  <div class="row" id="hide-mobile">
                    <div class="col-12">
                      <div id="table" className="table-editable">
                        <table className="table table-bordered table-responsive-md text-center">
                          <thead>
                            <tr>
                              <th className="text-left">Product ID</th>
                              <th className="text-left">Description</th>
                              <th>Units</th>
                              <th>Unit Price</th>
                              <th>Tax Rate</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.proposal_rows.map((item) => {
                              return (
                                <tr key={item.id} className="invoice-body">
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "left" }}
                                    width="125"
                                  >
                                    {item.name}
                                  </td>
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "left" }}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></div>
                                  </td>
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "right" }}
                                    width="125"
                                  >
                                    {item.quantity}
                                  </td>
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "right" }}
                                    width="125"
                                  >
                                    {item.unit_amount < 0 && "("}
                                    <NumberFormat
                                      value={formatTwoDecimals(
                                        Math.abs(item.unit_amount)
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => (
                                        <span>{value}</span>
                                      )}
                                    />
                                    {item.unit_amount < 0 && ")"}
                                  </td>
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "center" }}
                                    width="125"
                                  >
                                    {renderTaxType(item.tax_rate)}
                                  </td>
                                  <td
                                    className="pt-3-half p-10"
                                    contenteditable="false"
                                    style={{ textAlign: "right" }}
                                    width="125"
                                  >
                                    {item.amount < 0 && "("}
                                    <NumberFormat
                                      value={parseFloat(
                                        Math.abs(item.amount)
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => (
                                        <span>{value}</span>
                                      )}
                                    />
                                    {item.amount < 0 && ")"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="4"></td>
                              <td>Sub-Total :</td>
                              <td class="text-right">
                                <NumberFormat
                                  value={formatTwoDecimals(this.state.net)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>Total GST :</td>
                              <td class="text-right">
                                <NumberFormat
                                  value={formatTwoDecimals(this.state.gst)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => <span>{value}</span>}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td>
                                <h5>Total: </h5>
                              </td>
                              <td class="text-right">
                                <h5>
                                  <NumberFormat
                                    value={formatTwoDecimals(this.state.total)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </h5>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <br />
                    </div>
                  </div>

                  <div class="row" id="hide-mobile">
                    <div class="col-md-6 mt-1">
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Notes</div>
                          </div>
                            <DraftComponent
                              editorClass={"notes-editor"}
                              toolbarHidden={true}
                              width={"80%"}
                              initialValue={
                                this.state.notes ? this.state.notes : ''
                              }
                              getValue={(value) =>
                                this.setState({ notes: value })
                              }
                            />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 mt-1">
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        {/* <div class="input-group mb-2">
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="customFile"
                            disabled
                          />
                          <label class="custom-file-label" for="customFile">
                            Attach file
                          </label>
                        </div>
                      </div> */}
                        {this.state.attachments.map((file2) => (
                          <div className="row">
                            <div title={file2.file_name} className="col-md-12">
                              <a
                                target="blank"
                                href={`${apiSocketHost.fetchUrl}/storage/${file2.id}/${file2.file_name}`}
                              >
                                {file2.file_name.length > 30
                                  ? file2.file_name.substr(0, 30) + "..."
                                  : file2.file_name}{" "}
                              </a>
                            </div>

                            {/* <i
                            style={{ cursor: "pointer" }}
                            onClick={() => this.fileDelteHandler(file2)}
                            class="fas fa-trash"
                          ></i> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="customCheck1"
                          name="enable_reminder"
                          onChange={(e) => {
                            this.enableReminder(e);
                          }}
                          checked={
                            this.state.reminder_enabled === 1 ? true : false
                          }
                          className="form-check-input"
                          value={this.state.reminder_enabled}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="customCheck1"
                        >
                          Enable reminders per Debtor Control Setting
                        </label>
                      </div>
                    </div>
                  </div>

                  <div id="hide-mobile">
                    {proposal.activity_logs &&
                      proposal.activity_logs.length > 0 && (
                        <HistorySection logs={proposal.activity_logs} />
                      )}
                  </div>

                  <div className="row" id="hide-desktop">
                    <p style={{ fontSize: 16 }}>Invoice Items</p>
                    <table className="table">
                      <tbody>
                        {this.state.proposal_rows.map((element, index) => {
                          return element.name || element.description ? (
                            <tr key={index}>
                              <td
                                style={{ whiteSpace: "pre-wrap" }}
                                id="myBtn-1"
                              >
                                {element.description} <br />
                                {element.quantity}X{" "}
                                {element.unit_amount &&
                                  "$" +
                                    formatTwoDecimalsWithComma(
                                      parseFloat(element.unit_amount)
                                    )}{" "}
                                (
                                {parseInt(element.tax_rate) === 1
                                  ? "GST Free"
                                  : "GST"}
                                )
                              </td>
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div class="row" id="hide-desktop">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td>Sub-Total</td>
                          <td style={{ textAlign: "right" }}>
                            <NumberFormat
                              value={formatTwoDecimals(this.state.net)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total GST</td>
                          <td style={{ textAlign: "right" }}>
                            <NumberFormat
                              value={formatTwoDecimals(this.state.gst)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td style={{ textAlign: "right" }}>
                            <NumberFormat
                              value={formatTwoDecimals(this.state.total)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" id="hide-desktop">
                    <p>
                      <small>Attached Files:</small>
                    </p>
                  </div>
                  <div class="row" id="hide-desktop">
                    {this.state.attachments.map((file2) => (
                      <p>
                        <small>
                          -
                          <a
                            target="blank"
                            href={`${apiSocketHost.fetchUrl}/storage/${file2.id}/${file2.file_name}`}
                          >
                            {file2.file_name.length > 30
                              ? file2.file_name.substr(0, 30) + "..."
                              : file2.file_name}{" "}
                          </a>
                        </small>
                      </p>
                    ))}
                  </div>
                  {/* <div className="invoice-buttons text-center proposal_button invoice-buttons-invoice">
                  {this.state.status === "Accepted" ||
                  this.state.status === "Declined" ||
                  this.state.status === "Expired" ||
                  this.state.status === "Archived" ? null : (
                    <button
                      onClick={this.handleEdit.bind(this, this.state.id)}
                      type="button"
                      className="btn btn-secondary btn-non-edit-invoice"
                      disabled={
                        this.state.status === "Accepted" ||
                        this.state.status === "Declined" ||
                        this.state.status === "Expired" ||
                        this.state.status === "Archived"
                          ? true
                          : false
                      }
                    >
                      Edit
                    </button>
                  )}

                  <button
                    onClick={this.openPreviewPage}
                    type="button"
                    className="btn btn-secondary text-white btn-non-edit-invoice"
                  >
                    View
                  </button>
                  {this.state.status !== "Declined" && (
                    <a
                      href="#modal-org-proposal"
                      data-toggle="modal"
                      data-target="#modal-org-proposal"
                      className="btn btn-secondary  btn-non-edit-invoice"
                    >
                      Send
                    </a>
                  )}
                </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="invoice-buttons text-center">
                  {proposal.status === "Archived" ||
                  proposal.status === "Paid" ? null : (
                    <button
                      type="button"
                      className="btn btn-success"
                      href="#modal-org-proposal"
                      data-toggle="modal"
                      data-target={
                        organisation && !organisation.abn
                          ? "#modal-abn"
                          : "#modal-org-proposal"
                      }
                      style={{ width: "150px" }}
                    >
                      Send
                    </button>
                  )}
                  {proposal.status === "Archived" ||
                  proposal.status === "Paid" ? null : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.handleEdit.bind(this, this.state.id)}
                      disabled={
                        this.state.status === "Accepted" ||
                        this.state.status === "Declined" ||
                        this.state.status === "Expired" ||
                        this.state.status === "Archived"
                          ? true
                          : false
                      }
                    >
                      Edit
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary new-proposal-button"
                    style={{ width: "150px" }}
                    onClick={this.openCopyPage}
                  >
                    Copy
                  </button>
                  {organisation && !organisation.abn ? (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      href="#modal-org-proposal"
                      data-toggle="modal"
                      data-target="#modal-abn"
                    >
                      View
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.openPreviewPage}
                    >
                      View
                    </button>
                  )}

                  {organisation && !organisation.abn ? (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      href="#modal-org-proposal"
                      data-toggle="modal"
                      data-target="#modal-abn"
                    >
                      Download PDF
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.downloadPdfHandler}
                    >
                      Download PDF
                    </button>
                  )}

                  {proposal.status === "Overdue" ||
                  proposal.status === "Approved" ||
                  proposal.status === "Draft" ? (
                    <button
                      type="button"
                      className="btn btn-danger new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.changeStatusToArchived}
                    >
                      Void
                    </button>
                  ) : null}

                  {proposal.status !== "Expired" ? (
                    <button
                      type="button"
                      className="btn btn-danger new-proposal-button"
                      style={{ width: "150px", color: "#fff" }}
                      onClick={this.changeStatusToExpired}
                    >
                      Mark As Expired
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success new-proposal-button"
                      style={{ width: "150px", color: "#fff" }}
                      onClick={this.changeStatusToUnexpired}
                    >
                      Unmark As Expired
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-abn">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enter Business ABN</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container abn">
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          To send invoices and proposals, the Business Abn is
                          required
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{ width: "100px" }}
                            >
                              Business ABN
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.abnChangeHandler}
                            id="inlineFormInputGroup"
                            placeholder=""
                            value={this.state.abn}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="button"
                  onClick={this.handleAbnSave.bind(this)}
                  className="btn btn-success"
                  style={{ width: "150px" }}
                >
                  Save
                </button>
                <button
                  onClick={this.handleShowModal}
                  type="button"
                  data-dismiss="modal"
                  style={{ width: "150px" }}
                  className="btn btn-secondary ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade show" id="modal-org-proposal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send Proposal</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="">
                    <div className="row">
                      <div className="col-md-10">
                        <label className="sr-only" for="inlineFormInputGroup">
                          Contact
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Name</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.nameChangeHandler}
                            id="inlineFormInputGroup"
                            placeholder=""
                            value={this.state.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <label className="sr-only" for="inlineFormInputGroup">
                          Contact
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Email</div>
                          </div>
                          <input
                            onChange={this.handleInputEmail.bind(this)}
                            className={"form-control"}
                            defaultValue={this.state.email}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    {this.state.email_correct_err ? (
                      <div className="correct_email_err">
                        <span className="invoice-error text-danger">
                          Enter correnct email
                        </span>
                      </div>
                    ) : null}
                    <div className="form-group">
                      <div className="form-group">
                        <div>Hi {this.state.name},</div>
                        <br />
                        {this.state.message && (
                          <DraftComponent
                            initialValue={
                              this.state.message && this.state.message
                            }
                            getValue={this.editorValue}
                          />
                        )}

                        {/* <textarea
                          onChange={this.handleInputMessage.bind(this)}
                          className={"form-control"}
                          value={this.state.message ? this.state.message : ""}
                          rows="10"
                        ></textarea> */}
                        <br />
                        {this.state.signature ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                this.state.signature &&
                                this.state.signature.replace(/\\n/g, "<br />"),
                            }}
                          />
                        ) : (
                          <div>
                            Kind Regards
                            <br />
                            <br /> [organisation_name] <br />
                            <br />
                            [email] <br />
                            <br />
                            [phone]
                          </div>
                        )}
                      </div>
                    </div>
                    {this.state.organisation &&
                      this.state.organisation.stripe_customer_id && (
                        <>
                          <div className="row">
                            <div className="col-md-10">
                              <label
                                className="sr-only"
                                for="inlineFormInputGroup"
                              >
                                Contact
                              </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">Mobile</div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="inlineFormInputGroup"
                                  onChange={this.onMobileChangeHandler}
                                  placeholder=""
                                  value={this.state.mobile}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="custom-control custom-checkbox">
                            <input
                              onChange={this.handleSms.bind(this)}
                              name="sms"
                              type="checkbox"
                              class="form-check-input"
                              id="customCheck1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              Send proposal to mobile
                            </label>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>

              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="button"
                  onClick={this.handleSendInvoice.bind(this)}
                  className="btn btn-success"
                  style={{ width: "150px" }}
                >
                  Send
                </button>
                <button
                  onClick={this.handleShowModal}
                  type="button"
                  data-dismiss="modal"
                  style={{ width: "150px" }}
                  className="btn btn-secondary ml-2"
                >
                  Cancel
                </button>
              </div>

              {/* <div
                className="modal-footer"
                style={{
                  borderTop: "1px #e9ecef",
                  padding: "2rem",
                  margin: "auto", <div>Hi {this.state.name},</div>
                      <br />
                }}
              >
                <button
                  onClick={this.handleSendInvoice.bind(this)}
                  type="button"
                  className="btn btn-secondary"
                >
                  Send
                </button>
                <button
                  onClick={this.handleShowModal}
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { selectedProposal } = ProposalActions;

const mapDispatchToProps = { selectedProposal };

const mapStateToProps = (state) => {
  return {
    proposal: state.Proposals.proposal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonEditProposal);
