import React from "react";
import { reduxForm, Field, Form } from "redux-form";
import { renderField } from "../../uielements/FormFields";
import { required, email } from "redux-form-validators";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <p class="onboard-heading">Invite New User</p>
        <hr />
        <Form onSubmit={this.props.handleSubmit}>
          <div class="col-md-12 mt-3">
            <label class="sr-only" for="inlineFormInputGroup"></label>
            <div class="input-group mb-2">
              <Field
                name="first_name"
                label="First Name"
                component={renderField}
                type="text"
                id="first_name"
                validate={[required()]}
              />
            </div>
          </div>
          <div class="col-md-12 ">
            <label class="sr-only" for="inlineFormInputGroup"></label>
            <div class="input-group mb-2">
              <Field
                label="Last Name"
                component={renderField}
                id="last_name"
                name="last_name"
                validate={[required()]}
              />
            </div>
          </div>
          <div class="col-md-12 ">
            <label class="sr-only" for="inlineFormInputGroup"></label>

            <Field
              label="Email"
              component={renderField}
              id="email"
              name="email"
              validate={[required(), email()]}
            />
          </div>

          <hr />
          <div class="invoice-buttons text-center">
            <button type="submit" class="btn btn-success">
              Send Invite
            </button>
          </div>
        </Form>
      </>
    );
  }
}

UserForm = reduxForm({
  form: "UserForm",
  enableReinitialize: false,
})(UserForm);

export default UserForm;
