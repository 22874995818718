import SuperFetch from "./superFetch";
import { store } from "react-notifications-component";

class InvoiceHelper {
  static fetchCustomerInvoice = async () => {
    return await SuperFetch.get("/customer/invoices")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  static fetchAllInvoice = async () => {
    return await SuperFetch.get("/invoices")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  static fetchInvoiceSendBulk = async (sendData, postData) => {
    return await SuperFetch.post(
      "/invoices/send-mulitple/" + sendData,
      postData
    )
      .then((response) => {
        store.addNotification({
          message: response.success,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export default InvoiceHelper;
