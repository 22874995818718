import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({ idToken: null, user: null, error: null });
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CURRENT_USER_SUCCESS:
      return state
        .set('user', action.payload)
    case actions.LOGIN_SUCCESS:
      return state
        .set('idToken', action.payload.token)
        .set('user', action.payload.user)
    case actions.FORGOT_PASSWORD_ERROR:
      return state
        .set('error', action.payload.error)
    case actions.REFRESH_TOKEN_SUCCESS:
      return state
        .set('idToken', action.payload.access_token)
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
