import React from "react";
import SuperFetch from "../../../helpers/superFetch";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import contactActions from "../../../redux/contacts/actions";
import NotesSection from "./NotesSection";
import ButtonSection from "./ButtonsSection";
import InvoiceActions from "../../../redux/invoice/action";
import ProposalActions from "../../../redux/Proposals/actions";
import ContactForm from "./ContactForm";

const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

const STATES = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

class NewLeadEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      organisation: "",
      email: "",
      mobile: "",
      address: "",
      suburb: "",
      state: "",
      post_code: "",
      invite: false,
      tags: [],
      value: "",
      create: false,
      selectedState: "",
      lead: null,
      showModal: false,
    };
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    // this.handelUpdate = this.handelUpdate.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
  }

  selectedState = (e) => {
    this.setState({ selectedState: e.target.value });
  };

  handleRemoveTag(id, e) {
    let tagsArray = this.state.tags;
    tagsArray.filter((tag, index) => index !== id);
    for (let i in tagsArray) {
      if (i === id) {
        tagsArray.splice(i, 1);
      }
    }
    this.setState({ tags: tagsArray });
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  handleKeyDown(e) {
    const key = e.keyCode;
    if (key === BACKSPACE_KEY && !this.state.value) {
      this.editPrevTag();
    }
  }

  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();

    tag = tag.replace(/,/g, "");

    if (!tag) {
      return;
    }

    this.setState({
      tags: [...tags, tag],
      value: "",
    });
  }

  editPrevTag() {
    let { tags } = this.state;

    const tag = tags.pop();

    this.setState({ tags, value: tag });
  }

  componentDidMount = () => {
    const { fetchSingleContact } = this.props;
    document.title = "Cloud Collect - Edit Contact";
    let id = this.props.match.params.id;
    fetchSingleContact({ id });
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.contact != this.props.contact) {
      return true;
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { contact } = this.props;
    if (snapshot !== null) {
      this.setState({
        lead: contact,
      });

      if (contact) {
        this.getResponseData(contact);
      }
    }
  }

  getResponseData(response) {
    this.setState({
      product_id: response.product_id,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email,
      mobile: response.mobile,
      address: response.address,
      suburb: response.suburb,
      post_code: response.postcode,
      selectedState: response.state,
      organisation: response.company_name,
      tags: response.tags ? response.tags : [],
    });
  }

  handleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "") {
      this.setState({
        [e.target.name + "_err"]: "",
      });
    }
  }

  handleCheck() {
    this.setState({ invite: !this.state.invite });
  }

  createLeadData() {
    let leadData = {
      product_id: this.state.product_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      mobile: this.state.mobile,
      company_name: this.state.organisation,
      address: this.state.address,
      suburb: this.state.suburb,
      state: this.state.selectedState,
      postcode: this.state.post_code,
      // send_invite: this.state.invite,
      _method: "PUT",
      tag: this.state.tag,
    };
    return leadData;
  }

  addNotification() {
    return store.addNotification({
      message: "Lead updated successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  handleCreateInvoice() {
    if (this.validate()) return;
    let id = this.props.match.params.id;
    SuperFetch.post("/leads/" + id, this.reformTagsArray()).then((response) => {
      this.addNotification();
      this.props.history.push("/invoice/add/" + response.data.id);
    });
  }

  submitNotes = (values) => {
    const id = this.props.match.params.id;
    const { createNote } = this.props;
    values.lead_id = id;
    createNote({ values });
  };

  updateHandler = (values) => {
    const { match } = this.props;

    SuperFetch.post("/leads/" + match.params.id, {
      ...values,
      _method: "PUT",
    }).then((response) => {
      localStorage.removeItem("newLead");
      this.addNotification();
      this.props.history.push("/contacts");
    });
  };

  render() {
    const { tags, value, lead } = this.state;
    const { history, fetchSingleContact, invoices, proposals, match, contact } =
      this.props;

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="card">
              <div className="card-body">
                <ContactForm
                  states={STATES}
                  typeOptions={["Business/Organisation", "Individual"]}
                  onSubmit={this.updateHandler}
                  initialValues={
                    contact && {
                      type: contact.type,
                      company_name: contact.company_name,
                      first_name: contact.first_name,
                      last_name: contact.last_name,
                      email: contact.email,
                      mobile: contact.mobile,
                      address: contact.address,
                      suburb: contact.suburb,
                      state: contact.state,
                      postcode: contact.postcode,
                    }
                  }
                  {...this.props}
                />
              </div>
            </div>
          </div>
          <NotesSection
            {...this.props}
            history={history}
            lead={this.state.lead}
            submitNotes={this.submitNotes}
            fetchSingleContact={fetchSingleContact}
            invoices={invoices.filter(
              (invoice) =>
                parseInt(invoice.lead.id) === parseInt(match.params.id)
            )}
            proposals={proposals.filter(
              (proposal) =>
                parseInt(proposal.lead.id) === parseInt(match.params.id)
            )}
          />
          <ButtonSection
            {...this.props}
            removeContact={this.removeContact}
            history={history}
            invoices={invoices.filter(
              (invoice) =>
                parseInt(invoice.lead.id) === parseInt(match.params.id)
            )}
            proposals={proposals.filter(
              (proposal) =>
                parseInt(proposal.lead.id) === parseInt(match.params.id)
            )}
          />
        </div>
      </div>
    );
  }
}

const { fetchCurrentInvoices } = InvoiceActions;
const { fetchCurrentProposals } = ProposalActions;

const { selectedContact, fetchSingleContact, createNote } = contactActions;

const mapDispatchToProps = {
  selectedContact,
  fetchSingleContact,
  createNote,
  fetchCurrentInvoices,
  fetchCurrentProposals,
};

const mapStateToProps = (state) => {
  return {
    contact: state.Contacts.contact,
    invoices: state.Invoices.list,
    proposals: state.Proposals.list,
    contactForm: state.form.ContactForm
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLeadEdit);
