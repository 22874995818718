import React from "react";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="main-content-inner">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="card-1">
                <div className="card-body-1">
                  <div className="card">
                    <div className="card-body">
                      <h3>Admin Dashboard</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
