import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { setLoader } from "../../redux/Loader/Actions";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cardName: "",
    isLoading: false,
    name_err: "",
    checkbox_err: false,
    card_err: false,
    isPreview:
      this.props.location.pathname.indexOf("preview/") !== -1 ? true : false,
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState({ isLoading: nextProps.isLoading });
  }

  test() {}

  handleSubmit = async (ev) => {
    let token;
    if (this.state.cardName) {
      this.setState({ name_err: false });
      this.props.setLoader(true);
      token = await this.props.stripe.createToken({
        name: this.state.cardName,
      });
      token = token.token;
      this.props.handleSubmit(token);
    } else {
      this.setState({ name_err: true });
      toast.error("Please Enter card name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.props.handleSubmit(this.state.cardName);
      return null;
    }
    if (!token) {
      this.setState({ card_err: true });
      toast.error("Please Enter card details", {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.props.handleSubmit(token);
    }
  };

  checkBox = () => {
    // this.props.checkBox();
  };

  handleCardName = (ev) => {
    this.setState({ cardName: ev.target.value });
  };

  render() {
    const {
      paymentPlan,
      organisation,
      firstPayment,
      selectedPlan,
      flow,
      btnclass,
      type,
    } = this.props;

    return (
      <>
        {/*<form>*/}
        <div className="checkout">
          <div className="filter-block">
            <input
              className={
                "form-control " +
                (this.state.name_err ? "border border-danger" : "")
              }
              onChange={this.handleCardName}
              type="text"
              placeholder="Card Name"
              name="cardname"
            />
          </div>
          <div
            className={
              "filter-block stripe_marginBottom" +
              (this.props.error !== "" ? "border border-danger" : "")
            }
            style={{
              border: "1px solid #ccc",
              padding: 12,
              borderRadius: 5,
              marginTop: 10,
            }}
          >
            <CardElement hidePostalCode={true} />
          </div>

          {this.state.isPreview ? (
            <>
              {(type === "later" || type === "plan") && (
                <>
                  {flow.retry_payment === 1 && flow.retry_attempt > 0 && flow.retry_attempt_fee > 0 ? (
                    <p class="">
                      Please ensure you have sufficient funds, as a fee of $
                      {flow.retry_attempt_fee} will be charged for failed
                      payment.
                    </p>
                  ) : (
                    <p>
                      Please ensure you have sufficient funds on the selected
                      date
                    </p>
                  )}
                  <br />
                </>
              )}

              <div className="invoice-buttons text-center">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  // data-dismiss="modal"
                  className="btn btn-secondary close_modal"
                >
                  Submit
                </button>
              </div>
              <p className="text-tcs">
                By clicking 'Submit' you agree to our{" "}
                <a target="blank" href="https://cloudcollect.io/terms/">
                  terms and conditions
                </a>
                .
              </p>
            </>
          ) : (
            <>
              {organisation &&
                paymentPlan &&
                flow &&
                selectedPlan &&
                firstPayment &&
                flow[selectedPlan] !== "Auto Accept" && (
                  <>
                    <p class="mb-2">
                      {organisation.business_name} has until {firstPayment} to
                      approve this payment plan request.
                    </p>
                    <br />
                  </>
                )}

              {(type === "later" || type === "plan") && flow && (
                <>
                  {flow.retry_payment === 1 && flow.retry_attempt > 0 && flow.retry_attempt_fee > 0 ? (
                    <p class="">
                      Please ensure you have sufficient funds, as a fee of $
                      {flow.retry_attempt_fee} will be charged for failed
                      payment.
                    </p>
                  ) : (
                    <p>
                      Please ensure you have sufficient funds on the selected
                      date
                    </p>
                  )}
                  <br />
                </>
              )}
              <p>
                By clicking 'Submit' below you agree to our{" "}
                <a target="blank" href="https://cloudcollect.io/terms/">
                  terms and conditions
                </a>
                .
              </p>
              <br />

              <div className="invoice-buttons text-center">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className={`btn customer-registr-btn ${
                    btnclass ? btnclass : "btn-secondary"
                  }`}
                  disabled={this.props.load ? true : false}
                >
                  {this.props.load ? " Loading..." : "Submit"}
                </button>
              </div>
            </>
          )}

          {/*</div>*/}
        </div>
        {/*</form>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoader: (data) => dispatch(setLoader(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectStripe(CheckoutForm))
);
