import SuperFetch from "./superFetch";
import { apiHost } from "../config";
import axios from "axios";
import { configMultipart } from "./utility";

class OrganisationHelper {
  fetchOrganisation = async (primary_user_id) => {
    return await SuperFetch.get("/fetch-organisation/" + primary_user_id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  fetchSingleOrganisation = async (id) => {
    return await SuperFetch.get("/organisation/show/" + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateOrganisation = async (organisationInfo) => {
    let formData = new FormData();
    formData.append("_method", "put");
    for (let name in organisationInfo) {
      if (organisationInfo[name] == null) {
        // formData.append(name, "");
      } else {
        formData.append(name, organisationInfo[name]);
      }
    }
    if (
      organisationInfo.asic_document &&
      organisationInfo.asic_document.length
    ) {
      formData.append("asic_document", organisationInfo.asic_document[0]);
    }

    let response = await fetch(
      `${apiHost.fetchUrl}/organisation/${organisationInfo.id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      }
    );
    let result = await response.json();
    if (!response.ok) {
      if (response.status == 401) {
        if (window.location.pathname !== "/login") {
          // localStorage.removeItem('id_token');
          // localStorage.removeItem('cloud_collect_userInfo');
          // window.location.href = "/login"
          throw result;
        } else {
          throw result;
        }
      } else if (response.status === 422) {
        Object.keys(result.errors).map((key) => {
          result.errors[key] = result.errors[key][0];
        });
        throw result;
      } else {
        throw result;
      }
    }
    return result;
  };

  createCreditCard = async (creditCardData) => {
    return await SuperFetch.post(
      "/organisation/" + creditCardData.organisation_id + "/create-creditcard",
      creditCardData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  deleteCreditCard = async (creditCardData) => {
    return await SuperFetch.delete(
      "/organisation/" + creditCardData.organisation_id + "/delete-creditcard",
      creditCardData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateCreditCard = async (creditCardData) => {
    return await SuperFetch.put(
      "/organisation/" + creditCardData.organisation_id + "/update-creditcard",
      creditCardData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  updateDirector = async (directorInfo) => {
    if (directorInfo && (!directorInfo.id || directorInfo.id == "")) {
      let formData = new FormData();
      if (directorInfo.id_document)
        formData.append("id_document", directorInfo.id_document[0]);
      if (directorInfo.asic_document)
        formData.append("asic_document", directorInfo.asic_document[0]);
      formData.append("id", directorInfo.id);
      formData.append("first_name", directorInfo.first_name);
      formData.append("last_name", directorInfo.last_name);
      formData.append("city", directorInfo.city);
      formData.append("state", directorInfo.state);
      formData.append("line1", directorInfo.line1);
      formData.append("post_code", directorInfo.post_code);
      formData.append("dob", directorInfo.dob);
      formData.append("email", directorInfo.email);
      formData.append("gender", directorInfo.gender);
      formData.append("id_number", directorInfo.id_number);
      formData.append("phone", directorInfo.phone);
      formData.append("title", directorInfo.title);
      formData.append("entity_name", directorInfo.entity_name);
      formData.append("organisation_id", directorInfo.organisation_id);
      if (directorInfo.payment_gateway_status)
      formData.append("payment_gateway_status", directorInfo.payment_gateway_status);
      let response = await fetch(`${apiHost.fetchUrl}/director`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      });

      let result = await response.json();
      if (!response.ok) {
        if (response.status == 401) {
          if (window.location.pathname !== "/login") {
            // localStorage.removeItem('id_token');
            // localStorage.removeItem('cloud_collect_userInfo');
            // window.location.href = "/login"
            throw result;
          } else {
            throw result;
          }
        } else if (response.status === 422) {
          Object.keys(result.errors).map((key) => {
            result.errors[key] = result.errors[key][0];
          });
          throw result;
        } else {
          throw result;
        }
      }
      return result;
    } else {
      let formData = new FormData();
      if (directorInfo.id_document)
        formData.append("id_document", directorInfo.id_document[0]);
      if (directorInfo.asic_document)
        formData.append("asic_document", directorInfo.asic_document[0]);
      formData.append("id", directorInfo.id);
      formData.append("first_name", directorInfo.first_name);
      if (directorInfo.payment_gateway_status)
      formData.append("payment_gateway_status", directorInfo.payment_gateway_status);
      formData.append("last_name", directorInfo.last_name);
      formData.append("city", directorInfo.city);
      formData.append("state", directorInfo.state);
      formData.append("line1", directorInfo.line1);
      formData.append("post_code", directorInfo.post_code);
      formData.append("dob", directorInfo.dob);
      formData.append("email", directorInfo.email);
      formData.append("gender", directorInfo.gender);
      formData.append("id_number", directorInfo.id_number);
      formData.append("phone", directorInfo.phone);
      formData.append("title", directorInfo.title);
      formData.append("organisation_id", directorInfo.organisation_id);
      formData.append("entity_name", directorInfo.entity_name);
      formData.append("_method", "PUT");
      let response = await fetch(
        `${apiHost.fetchUrl}/director/${directorInfo.id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        }
      );

      let result = await response.json();
      if (!response.ok) {
        if (response.status == 401) {
          if (window.location.pathname !== "/login") {
            // localStorage.removeItem('id_token');
            // localStorage.removeItem('cloud_collect_userInfo');
            // window.location.href = "/login"
            throw result;
          } else {
            throw result;
          }
        } else if (response.status === 422) {
          Object.keys(result.errors).map((key) => {
            result.errors[key] = result.errors[key][0];
          });
          throw result;
        } else {
          throw result;
        }
      }
      return result;
    }
  };

  updateFieldOrganisation = async (organisationInfo) => {
    let formData = new FormData();
    for (let name in organisationInfo) {
      formData.append(name, organisationInfo[name]);
    }

    let response = await fetch(
      `${apiHost.fetchUrl}/organisation/update/${organisationInfo.id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      }
    );
    let result = await response.json();
    if (!response.ok) {
      if (response.status == 401) {
        if (window.location.pathname !== "/login") {
          // localStorage.removeItem('id_token');
          // localStorage.removeItem('cloud_collect_userInfo');
          // window.location.href = "/login"
          throw result;
        } else {
          throw result;
        }
      } else if (response.status === 422) {
        Object.keys(result.errors).map((key) => {
          result.errors[key] = result.errors[key][0];
        });
        throw result;
      } else {
        throw result;
      }
    }
    return result;
  };
}

export default new OrganisationHelper();
