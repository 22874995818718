import React from 'react';
import { List } from 'react-content-loader';
import CloudCollectWrapper from './cloudCollectWrapper';

export default function FlowLoader(props) {
    return (
        <CloudCollectWrapper>
            <List width="300" />
            <List width="300" />
            <List width="300" />
        </CloudCollectWrapper>
    );
} 