import { all, takeEvery, put, fork } from "redux-saga/effects";
import actions from "./action";

export function* getSelectedProduct() {
  yield takeEvery("FETCH_SELECTED_PRODUCT", function* ({ payload }) {
    yield put({
      type: actions.SET_PRODUCT,
      payload: payload.payload,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(getSelectedProduct)]);
}
