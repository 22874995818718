import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import alertActions from "../alert/actions";
import UserHelper from "../../helpers/userHelper";
import { createNotification } from "../../components/uielements/notification";

export function* fetchUsers() {
  yield takeEvery(actions.FETCH_ALL_USERS, function* ({ payload }) {
    try {
      const result = yield call(UserHelper.fetchUsers, { payload });
      if (result && result.data) {
        yield put({
          type: actions.FETCH_ALL_USERS_SUCCESS,
          payload: result,
        });
      }
    } catch (error) {}
  });
}
export function* deleteUser() {
  yield takeEvery(actions.DELETE_USER, function* ({ payload }) {
    try {
      const result = yield call(UserHelper.deleteUser, { payload });
      if (result.success) {
        createNotification("success", "User Deleted Successfully");
        yield put({
          type: actions.FETCH_ALL_USERS,
          payload,
        });
      }
    } catch (error) {
      createNotification("danger", "Server error");
    }
  });
}
export function* deleteConnectAccount() {
  yield takeEvery(actions.DELETE_CONNECT_ACCOUNT, function* ({ payload }) {
    try {
      const result = yield call(UserHelper.deleteConnectAccount, { payload });
      if (result.success) {
        createNotification("success", "Connect Account Deleted Successfully");
        yield put({
          type: actions.FETCH_ALL_USERS,
          payload: { role: "Business" },
        });
      }
    } catch (error) {
      createNotification("danger", "Server error");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchUsers)]);
  yield all([fork(deleteUser)]);
  yield all([fork(deleteConnectAccount)]);
}
