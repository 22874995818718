import React, { Component } from "react";
import { connect } from "react-redux";
import TermsActions from "../../redux/terms/actions";

class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { fetchPrevTerms } = this.props;

    fetchPrevTerms();
  };

  render() {
    const { terms } = this.props;
    return (
      <div className="container">
        <div className="terms_and_conditions">
          <div className="card">
            <div className="card-body">
              <h3 style={{ textAlign: "center" }}>Terms & Conditions</h3>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: terms.term_conditions,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchTerms } = TermsActions;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrevTerms: () => fetchTerms({ dispatch }),
  };
};

const mapStateToProps = (state) => {
  return {
    terms: state.Terms.terms,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
