import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser } from "../../../../helpers/utility";
import Form from "./form";
import organisationAction from "../../../../redux/organisation/actions";

class InvoiceSettings extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { userInfo } = this.props;
    this.props.fetchSingleOrganisation({
      id: userInfo.default_organisation_id,
    });
  }

  handleSubmit = (values) => {
    const { userInfo } = this.props;
    this.props.updateFieldOrganisation({
      ...values,
      id: userInfo.default_organisation_id,
    });
  };

  render() {
    return (
      <div className="col-lg-4 mt-3">
        <div className="card">
          <div className="card-body">
            <p className="onboard-heading">Invoice Settings</p>
            <hr />
            <div className="col-md-12 mt-3">
              <Form
                onSubmit={this.handleSubmit}
                initialValues={{
                  due_date_threshold:
                    this.props?.organisationData?.due_date_threshold,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchSingleOrganisation, updateFieldOrganisation } = organisationAction;

const mapDispatchToProps = {
  fetchSingleOrganisation,
  updateFieldOrganisation,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
    userInfo: getUser(),
    loading: state.Organisation.get("loading"),
    organisationData: state.Organisation.get("organisationData"),
    organisationLoading: state.Organisation.get("organisationLoading"),
    errors: state.Alert.error ? state.Alert.error.errors : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSettings);
