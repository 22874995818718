import React from "react";
import SubscriptionActions from "../../../redux/subscription/actions";
import { connect } from "react-redux";

class SubscriptionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionId: null,
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - Subscriptions";
    this.props.fetchSubscriptions();
  }

  cancelSubscription = (subscriptionId) => {
    this.setState({
      subscriptionId,
    });
  };

  handleCancel = () => {
    const { subscriptionId } = this.state;
    this.props.cancelSubscription(subscriptionId);
  };

  render() {
    const { subscriptionsAll } = this.props;
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <br />
                <div className="single-table">
                  <div className="table-responsive">
                    <table
                      id="table-customer-invoices"
                      className="table table-hover progress-table text-center"
                    >
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Organisation Name</th>
                          <th>Amount</th>
                          <th>Frequency</th>
                          <th>Next Due</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptionsAll.length ? 
                          subscriptionsAll.map((subscription, index) => {
                            return (
                              <tr key={index}>
                                <td>{subscription.product.name}</td>
                                <td>
                                  {
                                    subscription.product.organisation
                                      .business_name
                                  }
                                </td>
                                <td>{subscription.amount}</td>
                                <td>{subscription.frequency}</td>
                                <td>{subscription.next_due}</td>
                                <td>
                                  <a
                                    className="btn btn-outline-danger btn-xs"
                                    href="javascript:void(0)"
                                    data-toggle="modal"
                                    data-target="#cancelSubscription"
                                    onClick={() =>
                                      this.cancelSubscription(subscription.id)
                                    }
                                  >
                                    Cancel
                                  </a>
                                </td>
                              </tr>
                            );
                          }) : 
                          <tr>
                              <td colSpan="5">No records found!</td>
                          </tr>
                          }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="cancelSubscription">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Are you sure, you want to Cancel?
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleCancel}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchSubscriptions, cancelSubscription } = SubscriptionActions;

const mapDispatchToProps = (dispatch) => ({
  fetchSubscriptions: () => fetchSubscriptions({ dispatch }),
  cancelSubscription: (subscriptionId) =>
    cancelSubscription({ dispatch, subscriptionId }),
});

const mapStateToProps = (state) => {
  return {
    subscriptionsAll: state.Subscriptions.subscriptionsAll,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
