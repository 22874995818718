const actions = {
  FETCH_TERMS: "FETCH_TERMS",
  SUBMIT_TERMS: "SUBMIT_TERMS",
  SET_DATA: "SET_DATA",

  fetchTerms: ({ dispatch }) =>
    dispatch({
      type: actions.FETCH_TERMS,
    }),

  createTerms: (payload) => {
    return {
      type: actions.SUBMIT_TERMS,
      payload,
    };
  },
};

export default actions;
