const actions = {
  FETCH_ALL_USERS: "FETCH_ALL_USERS",
  FETCH_ALL_USERS_ERROR: "FETCH_ALL_USERS_ERROR",
  FETCH_ALL_USERS_SUCCESS: "FETCH_ALL_USERS_SUCCESS",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_CONNECT_ACCOUNT: "DELETE_CONNECT_ACCOUNT",
  DELETE_CONNECT_ACCOUNT_SUCCESS: "DELETE_CONNECT_ACCOUNT_SUCCESS",
  DELETE_CONNECT_ACCOUNT_ERROR: "DELETE_CONNECT_ACCOUNT_ERROR",

  //   fetchUserSuccess(payload) {
  //     return { type: actions.FETCH_ALL_USERS_SUCCESS, payload };
  //   },
  fetchUsers: ({ dispatch, payload }) =>
    dispatch({
      type: actions.FETCH_ALL_USERS,
      payload,
    }),
  deleteUser: ({ dispatch, payload }) =>
    dispatch({
      type: actions.DELETE_USER,
      payload,
    }),
  deleteConnectAccount: ({ dispatch, payload }) =>
    dispatch({
      type: actions.DELETE_CONNECT_ACCOUNT,
      payload,
    }),
};
export default actions;
