import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderCheckBox } from "../../uielements/FormFields";
import contact from "../Contacts/contact";

const SettingsForm = ({ handleSubmit }) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              name="reminder_enabled"
              label="Enable reminders for Invoices & Proposals"
              component={renderCheckBox}
              // validate={[required()]}
            />
          </div>
        </div>
        <hr />
        <div class="invoice-buttons text-center">
          <button type="submit" class="btn btn-success">
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "SettingsForm",
  // validate,
  enableReinitialize: true,
})(SettingsForm);
