import SuperFetch from './superFetch';

class AuthHelper {

  login = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'please fill in the input' };
    }
    return await SuperFetch.post('/login', userInfo).then(response => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };

  fetchCurrentUser = async () => {
    return await SuperFetch.get('/user').then(response => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };

  register = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'please fill in the input' };
    }
    return await SuperFetch.post('/register', userInfo).then(response => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };

  forgotPassword = userInfo => {
    if (!userInfo.email) {
      return { error: 'please fill in the input' };
    }
    return SuperFetch.post('/forgot-password', userInfo).then(response => {
      return { result: response };
    }).catch((error) => {
      throw error;
    });
  };

  resetPassword = async userInfo => {
    if (!userInfo.email) {
      return { error: 'please fill in the input' };
    }
    return await SuperFetch.post('/reset-password', userInfo).then(response => {
      return response;
    }).catch((error) => {
      throw error;
    });
  };

  refreshToken = async () => {
    return await SuperFetch.get('refreshToken').then(response => response)
      .catch(error => ({ error: JSON.stringify(error) }));
  };



}
export default new AuthHelper();
