import React, { Component } from "react";
import SuperFetch from '../../../helpers/superFetch';

class Lead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leads: [],
          isLoaded: false,
          deleteInfo: '',
        };
      }

      
    componentDidMount() {
        document.title = "Cloud Collect - Leads"
        let userInfo = JSON.parse(localStorage.getItem("cloud_collect_userInfo"));
        SuperFetch.get('/organisation/leads/'+userInfo.organisation_id).then(response => {
            this.setState({
            leads: response.data
            });
        }).catch((error) => {
            throw error;
        });
    }


 

  render() {
    const { leads } = this.state;

    const leadsList = leads.map(item => (
        <tr key={item.id}>
          <td>{item.first_name}</td>
          <td>{item.last_name}</td>
          <td>{item.email}</td>
          <td>{item.mobile}</td>
          <td>{(item.product_name) ? item.product_name :  '-'}</td>
        </tr>
      ))

    return (
        <div className='main-content-inner'>
        <div className='row'>
          <div className='col-12 mt-3'>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 mt-3'>
            <div className='card' style={{ margin: '0 5% 0 5%' }}>
              <div className='card-body'>
                    <div className='single-table'>
                      <div className='table-responsive'>
                        <table
                          id='table-main'
                          className='table table-hover progress-table text-center'
                        >
                          <thead className='text-capitalize'>
                            <tr>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Product Name</th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              leadsList.length ? leadsList : <tr><td colSpan="8">No data found.</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export default (Lead);
