import React, { Component } from "react";

export default class Integrations extends Component {
  componentDidMount(){
      document.title = "Cloud Collect - Integration";
  }
  render() {
    return (
      <div className='main-content-inner'>
        <div className='row'>
          <div className='col-12 mt-3'>
            <div className='card' style={{ margin: "0 5% 0 5%" }}>
              <div className='card-body'>
                <p>Integrations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
