import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import AdminSidebar from "../components/Sidebar/admin";
import Header from "../components/Header";
import Models from "../components/Common/Models";
import AppRoutes from "../routes";
import AdminRoutes from "../adminRoutes";
import "../styles/main.scss";
import url from "../url";
import { getUser, getToken } from "../helpers/utility";
import authActions from "../redux/auth/actions";
import notificationActions from "../redux/notification/actions";
import Echo from "laravel-echo";
import { apiSocketHost } from "../config";
import { valid } from "semver";

var validate = require("uuid-validate");
const io = require("socket.io-client");

window.io = io;

class App extends Component {
  componentDidMount() {
    const { userInfo, userToken, dipatch } = this.props;
    if (userInfo) {
      this.props.fetchCurrentUser();
    }

    // let backendUrl = 'https://admin.cloudcollect.io';
    let backendUrl = apiSocketHost.fetchUrl;
    if (userToken && userInfo) {
      if (typeof io !== undefined) {
        let echo = new Echo({
          broadcaster: "socket.io",
          host: `${backendUrl}:6001`,
          auth: {
            headers: {
              Authorization: "Bearer " + userToken,
            },
          },
        });
        window.Echo = echo;
        window.Echo.private(`newNotification.${userInfo.id}`).notification(
          ({ notifications }) => {
            this.props.setNotification(notifications);
          }
        );
      }
    }
  }

  componentWillMount() {
    const { userInfo } = this.props;
    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/login"
    ) {
      if (userInfo) {
        this.props.history.push("/invoices");
      } else {
        this.props.history.push(url.account.login);
      }
    }
  }

  validateUUID = (location) => {
    const uuid = location.pathname.split("/")[
      this.props.location.pathname.split("/").length - 1
    ];

    const isPublicPage =
      location.pathname.indexOf("invoice") !== -1 ||
      location.pathname.indexOf("proposal") !== -1
        ? true
        : false;

    return isPublicPage && validate(uuid);
  };

  render() {
    let role = "";
    const { userInfo, userToken } = this.props;
    let isPublic =
      this.props.location.pathname === "/forgot-password" ||
      this.props.location.pathname === "/password-reset";
    let otherPage =
      this.props.location.pathname !== "/my-profile" &&
      this.props.location.pathname !== "/my-invoice"
        ? true
        : false;

    let isDashboard =
      this.props.location.pathname === "/invoices" ? true : false;
    let isLogin = this.props.location.pathname === "/login" ? true : false;
    let isPreviewPage =
      this.validateUUID(this.props.location) ||
      this.props.location.pathname.indexOf("register") !== -1 ||
      this.props.location.pathname.indexOf("thankyou") !== -1 ||
      this.props.location.pathname.indexOf("terms-and-conditions") !== -1
        ? true
        : false;
    let isBusiness =
      (userInfo &&
        userInfo.role &&
        userInfo.role[0].title === "Business" &&
        this.props.location.pathname === "/my-profile") ||
      (userInfo &&
        userInfo.role &&
        userInfo.role[0].title === "Business" &&
        this.props.location.pathname === "/my-invoice")
        ? true
        : false;

    if (isDashboard && userInfo) {
      if (
        userInfo.role &&
        userInfo.role.some((role) => role.title === "Admin")
      ) {
        this.props.history.push("/admin/businesses");
      }
    }
    if (
      isDashboard &&
      userInfo &&
      userInfo.role &&
      userInfo.role[0].title === "Customer"
    ) {
      this.props.history.push(url.customer.myProfile.myProfile);
    }
    if (
      otherPage &&
      userInfo &&
      userInfo.role &&
      userInfo.role.length &&
      userInfo.role[0].title === "Customer"
    ) {
      if (
        this.props.location.pathname.indexOf("register") === -1 &&
        this.props.location.pathname === "my-subscriptions"
      ) {
        this.props.history.push(url.customer.myProfile.myProfile);
      }
    }
    if (isBusiness) {
      this.props.history.push(url.index);
    }
    let isAdmin =
      userInfo && userInfo.role && userInfo.role[0].title === "Admin";
    if (!isLogin && !userInfo && !isPreviewPage && !isPublic) {
      this.props.history.push(url.account.login);
    }
    return (
      <div>
        {userInfo ? (
          isPreviewPage ? (
            <AppRoutes {...this.props} />
          ) : isAdmin ? (
            <div>
              <div className="page-container">
                <AdminSidebar {...this.props} />
                <div className="main-content" style={{ minHeight: "247px" }}>
                  <Header {...this.props} />
                  <AdminRoutes {...this.props} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="page-container">
                <Sidebar {...this.props} />
                <div className="main-content" style={{ minHeight: "247px" }}>
                  <Header {...this.props} />
                  <AppRoutes {...this.props} />
                </div>
              </div>
              <Models {...this.props} />
            </div>
          )
        ) : (
          <AppRoutes {...this.props} />
        )}
      </div>
    );
  }
}

const { fetchCurrentUser } = authActions;
const { setNotification } = notificationActions;

const mapDispatchToProps = { fetchCurrentUser, setNotification };

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
    userToken: getToken(),
    currentUser: state.Auth.get("user"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
