import React, { Fragment } from "react";
import { reduxForm, Field, Form } from "redux-form";
import { required } from "redux-form-validators";
import { submit } from "redux-form";
import SuperFetch from "../../helpers/superFetch";
import { store } from "react-notifications-component";

const renderSelect = ({
  input,
  label,
  type,
  readOnly,
  id,
  options,
  meta: { touched, error, warning },
}) => (
  <>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <select
        {...input}
        className="form-control"
        style={{ height: "50px" }}
        disabled={readOnly}
        // id="inlineFormInputGroup"
      >
        {options &&
          options.map((option, index) => (
            <option key={id + index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {touched &&
        ((error && <span className="error">This Field {error}</span>) ||
          (warning && <span className="warning">{warning}</span>))}
    </div>
  </>
);

class ChangeOrganisationForm extends React.Component {
  constructor(props) {
    super(props);

    this.modalClose = React.createRef();
  }

  componentDidMount() {
    this.props.fetchBusinessOrganisations();
  }

  handleFormSubmit(values) {}

  submitForm = () => {
    const { dispatch } = this.props;
    dispatch(submit("ChangeOrganisationForm"));
  };

  onChange = (e) => {
    if (e.target.value) {
      SuperFetch.put("/change-default-organisation", {
        organisation: e.target.value,
      })
        .then((response) => {
          store.addNotification({
            message: "Organisation changed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        })
        .catch((error) => {});
    }
  };

  render() {
    const organisations = this.props.myBusinessOrganisations.map((org) => {
      return {
        label: org.business_name,
        value: org.id,
      };
    });

    return (
      <Fragment>
        <Form onSubmit={this.props.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title">Select Organisation</h5>
          </div>

          <div class="col-md-12 mt-3">
            <label class="sr-only" for="inlineFormInputGroup"></label>
            <div class="input-group mb-2">
              <Field
                label="Organisations"
                id="Organisations"
                name="organisation"
                component={renderSelect}
                options={organisations}
                validate={[required()]}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div
            className="modal-footer"
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "5px",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              style={{ width: "150px", color: "#fff" }}
              data-dismiss="modal"
              ref={this.modalClose}
            >
              Close
            </button>
          </div>
        </Form>
      </Fragment>
    );
  }
}

ChangeOrganisationForm = reduxForm({
  form: "ChangeOrganisationForm",
  enableReinitialize: true,
})(ChangeOrganisationForm);

export default ChangeOrganisationForm;
