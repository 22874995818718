import React, { Component } from "react";
import LoginForm from "./form";
import authAction from "../../../redux/auth/actions";
import { connect } from "react-redux";
import { getUser } from "../../../helpers/utility";
import Loader from "../../uielements/loader";
import $ from "jquery";

class Login extends Component {
  couter = 0;
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Login";
    const { userInfo, history } = this.props;
    if (userInfo) {
      history.push("/invoices");
    }
  }

  submit = (values) => {
    // print the form values to the console
    const { login, history } = this.props;

    login({ history, userInfo: values });
  };

  handleShowPassword(e) {
    // this.counter +=1;
    if ($('input[name="password"]').attr("type") === "password") {
      $('input[name="password"]').attr("type", "text");
    } else {
      $('input[name="password"]').attr("type", "password");
    }
  }

  render() {
    return (
      <div
        className="login-area"
        style={{ width: "100%", height: "100%", margin: "0,auto", top: 0 }}
      >
        <div className="container">
          <div className="login-box">
            <LoginForm
              onSubmit={this.submit}
              showPassword={this.handleShowPassword}
            />
          </div>
        </div>
      </div>
    );
  }
}

const { login } = authAction;
const mapDispatchToProps = { login };

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
