import React from "react";
import url from "../../../url";
import { getToken, getUser } from "../../../helpers/utility";
import authActions from "../../../redux/auth/actions";
import { connect } from "react-redux";

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - My Profile";
    this.props.fetchCurrentUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userInfo !== this.props.userInfo) {
      this.setState({
        userInfo: this.props.userInfo,
      });
    }
  }

  render() {
    return (
      <>
        <div className="main-content">
          <div className="main-content-inner">
            <div className="row">
              <div className="col-12 mt-3">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="col-sm-11">
                        <h5>Contact Details</h5>
                      </div>
                      <div className="col-sm-1">
                        <a href="">
                          <i className="fa fa-cog fa-lg"></i>
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="first_name"
                          defaultValue={
                            this.state.userInfo &&
                            this.state.userInfo.first_name
                          }
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="col-form-label">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="last_name"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.last_name
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-4">
                        <label className="col-form-label">Contact Number</label>
                        <input
                          className="form-control"
                          type="text"
                          name="mobile"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.mobile
                          }
                        />
                      </div>
                      <div className="col-sm-8">
                        <label className="col-form-label">Contact E-mail</label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.email
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Address</label>
                      <input
                        className="form-control"
                        type="text"
                        name="address"
                        defaultValue={
                          this.state.userInfo && this.state.userInfo.address
                        }
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <label className="col-form-label">City/Suburb</label>
                        <input
                          className="form-control"
                          type="text"
                          name="suburb"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.suburb
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label className="col-form-label">State</label>
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.state
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label className="col-form-label">Postcode</label>
                        <input
                          className="form-control"
                          type="text"
                          name="postcode"
                          defaultValue={
                            this.state.userInfo && this.state.userInfo.postcode
                          }
                        />
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label">
                        Same as Postal Address
                      </label>
                    </div>
                    <div className="form-group text-center">
                      <button className="btn btn-secondary ">
                        Save changes
                      </button>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const { fetchCurrentUser } = authActions;

const mapDispatchToProps = { fetchCurrentUser };

const mapStateToProps = (state) => {
  return {
    userToken: getToken(),
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
