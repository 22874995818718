import actionTypes from "./actions";

const initialState = {
  data: [],
  proposal: {},
  list: [],
};

const Proposals = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    case actionTypes.SET_PROPOSAL:
      return {
        ...state,
        proposal: payload,
      };

    case actionTypes.SET_PROPOSALS:
      return {
        ...state,
        list: payload,
      };

    default:
      return state;
  }
};
export default Proposals;
