import React, { Component } from "react";
import { store } from "react-notifications-component";
import SuperFetch from "../../../helpers/superFetch";
import FlowLoader from "../../uielements/FlowLoader";
import { Modal, Button } from "react-bootstrap";
import "./flow.scss";
import { getUser } from "../../../helpers/utility";
import { connect } from "react-redux";

class Flow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: { terms_conditions: "" },
      errors: {},
      proposal_reminder_notice_days: 1,
      isLoading: true,
      registration_form: 0,
      terms_conditions: 0,
      welcome_email: 0,
      welcome_sms: 0,
      days_notice: 0,
      notify_user: 0,
      payment_reminder_email: 0,
      payment_reminder_sms: 0,
      attach_invoice: 0,
      // days_notice: 0,
      successful_payment_receipt: 0,
      failed_payment_email: 0,
      failed_payment_sms: 0,
      retry_payment_in_days: 1,
      retry_attempt: 1,
      retry_attempt_fee: 0,
      offer_payment_plan: 0,
      auto_accept_payment_plan: 0,
      maximum_terms: "1 week",
      administration_fee: 0,
      setup_fees: 0,
      send_sms_messages: 0,
      showTermsConditions: false,
      organisation: null,
      late_payment_reminder_in_days: 0,
      late_payment_email: 0,
      late_payment_sms: 0,
      resend_late_fees_reminder: 0,
      proposal_expiry_email: 0,
      proposal_expiry_sms: 0,
      retry_payment: 0,
      payment_plan_enabled: 0,
      first_payment_within: 0,
      two_weekly_payments: "Disabled",
      three_weekly_payments: "Disabled",
      four_weekly_payments: "Disabled",
      two_fortnightly_payments: "Disabled",
      three_fortnightly_payments: "Disabled",
      four_fortnightly_payments: "Disabled",
      two_monthly_payments: "Disabled",
      three_monthly_payments: "Disabled",
      four_monthly_payments: "Disabled",
      custom_payment_plan: "Disabled",
      payment_plan_frequency: [],
      upfront_enabled: 0,
      failed_payment_reminder_in_days: 0,
      minimum_amount: 0,
      scheduled_payment_sms: 0,
      scheduled_payment_email: 0,
      scheduled_days_notice: 1,
    };
  }

  validate = () => {
    let isError = false;

    const errors = {
      name_err: "",
    };

    // if (this.state.attach_invoice === 0) {
    //     isError = true;
    //     errors.attach_invoice_err = "Please select attach invoice.";
    // }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  handleUserInput(e) {
    this.setState({
      [e.target.name]: this.state[e.target.name] == 1 ? 0 : 1,
      [e.target.name + "_err"]: "",
    });
  }

  handleUserInput2(e) {
    console.log([e.target.name] == "four_monthly_payments");
    if (
      [e.target.name] == "maximum_terms" ||
      [e.target.name] == "custom_payment_plan" ||
      [e.target.name] == "four_monthly_payments" ||
      [e.target.name] == "three_monthly_payments" ||
      [e.target.name] == "two_monthly_payments" ||
      [e.target.name] == "four_fortnightly_payments" ||
      [e.target.name] == "three_fortnightly_payments" ||
      [e.target.name] == "two_fortnightly_payments" ||
      [e.target.name] == "two_weekly_payments" ||
      [e.target.name] == "three_weekly_payments" ||
      [e.target.name] == "four_weekly_payments"
    ) {
      console.log(e.target.name);

      this.setState(
        {
          [e.target.name]: e.target.value,
          [e.target.name + "_err"]: "",
        },
        () => {
          console.log(this.state);
        }
      );
    } else {
      this.setState({
        [e.target.name]: Number(e.target.value),
        [e.target.name + "_err"]: "",
      });
    }
  }

  handleUserInput3 = (e) => {
    this.setState({ proposal_reminder_notice_days: e.target.value });
  };

  handleKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  getTemplatesData = () => {
    SuperFetch.get("/templates")
      .then((response) => {
        this.setState({
          values: {
            terms_conditions: response.data.template_data.terms_conditions
              ? response.data.template_data.terms_conditions
              : "",
          },
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  componentDidMount() {
    document.title = "Cloud Collect - Debtor Control";
    this.getTemplatesData();
    SuperFetch.get("/flows/")
      .then((response) => {
        if (response.data != null) {
          this.setState({
            organisation: response.data.organisation,
            proposal_reminder_notice_days:
              response.data.proposal_reminder_notice_days,
            registration_form: response.data.registration_form,
            terms_conditions: response.data.terms_conditions,
            welcome_email: response.data.welcome_email,
            welcome_sms: response.data.welcome_sms,
            payment_reminder_email: response.data.payment_reminder_email,
            notify_user: response.data.notify_user,
            payment_reminder_sms: response.data.payment_reminder_sms,
            attach_invoice: response.data.attach_invoice,
            days_notice: response.data.days_notice,
            successful_payment_receipt:
              response.data.successful_payment_receipt,
            failed_payment_email: response.data.failed_payment_email,
            failed_payment_sms: response.data.failed_payment_sms,
            retry_attempt: response.data.retry_attempt,
            retry_attempt_fee: response.data.retry_attempt_fee,
            offer_payment_plan: response.data.offer_payment_plan,
            auto_accept_payment_plan: response.data.auto_accept_payment_plan,
            maximum_terms: response.data.maximum_terms
              ? response.data.maximum_terms
              : "1 week",
            administration_fee: response.data.administration_fee,
            setup_fees: response.data.setup_fees,
            send_sms_messages: response.data.send_sms_messages,
            proposal_expiry_email: response.data.proposal_expiry_email,
            proposal_expiry_sms: response.data.proposal_expiry_sms,
            late_payment_sms: response.data.late_payment_sms,
            late_payment_email: response.data.late_payment_email,
            late_payment_reminder_in_days:
              response.data.late_payment_reminder_in_days,
            resend_late_fees_reminder: response.data.resend_late_fees_reminder
              ? response.data.resend_late_fees_reminder
              : 0,
            retry_payment: response.data.retry_payment,
            failed_payment_reminder_in_days:
              response.data.failed_payment_reminder_in_days,
            retry_payment_in_days: response.data.retry_payment_in_days,
            first_payment_within: response.data.first_payment_within,

            two_weekly_payments: response.data.two_weekly_payments
              ? response.data.two_weekly_payments
              : "Disabled",
            three_weekly_payments: response.data.three_weekly_payments
              ? response.data.three_weekly_payments
              : "Disabled",
            four_weekly_payments: response.data.four_weekly_payments
              ? response.data.four_weekly_payments
              : "Disabled",
            two_fortnightly_payments: response.data.two_fortnightly_payments
              ? response.data.two_fortnightly_payments
              : "Disabled",
            three_fortnightly_payments: response.data.three_fortnightly_payments
              ? response.data.three_fortnightly_payments
              : "Disabled",
            four_fortnightly_payments: response.data.four_fortnightly_payments
              ? response.data.four_fortnightly_payments
              : "Disabled",
            two_monthly_payments: response.data.two_monthly_payments
              ? response.data.two_monthly_payments
              : "Disabled",
            three_monthly_payments: response.data.three_monthly_payments
              ? response.data.three_monthly_payments
              : "Disabled",
            four_monthly_payments: response.data.four_monthly_payments
              ? response.data.four_monthly_payments
              : "Disabled",
            custom_payment_plan: response.data.custom_payment_plan
              ? response.data.custom_payment_plan
              : "Disabled",
            payment_plan_frequency: response.data.payment_plan_frequency
              ? response.data.payment_plan_frequency
              : [],
            upfront_enabled: response.data.upfront_enabled,
            minimum_amount: response.data.minimum_amount,
            scheduled_payment_email: response.data.scheduled_payment_email,
            scheduled_payment_sms: response.data.scheduled_payment_sms,
            scheduled_days_notice: response.data.scheduled_days_notice,
            btn_name: "Update",
          });
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  postData = (data) => {
    SuperFetch.post("/flows/", data)
      .then((response) => {
        this.setState({ isLoading: false, btn_name: "Update" });
        store.addNotification({
          message: "Updated",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  handleOnBoardingSubmit = (e) => {
    e.preventDefault();

    if (!this.validate()) {
      this.setState({ isLoading: true });
      const data = {
        registration_form: this.state.registration_form,
        terms_conditions: this.state.terms_conditions,
        welcome_email: this.state.welcome_email,
        welcome_sms: this.state.welcome_sms,
        notify_user: this.state.notify_user,
      };

      this.postData(data);
    }
  };

  handlePaymentProcessingSubmit = (e) => {
    e.preventDefault();

    if (!this.validate()) {
      this.setState({ isLoading: true });
      const data = {
        proposal_expiry_email: this.state.proposal_expiry_email,
        proposal_expiry_sms: this.state.proposal_expiry_sms,
        payment_reminder_email: this.state.payment_reminder_email,
        payment_reminder_sms: this.state.payment_reminder_sms,
        attach_invoice: this.state.attach_invoice,
        days_notice: this.state.days_notice,
        successful_payment_receipt: this.state.successful_payment_receipt,
        scheduled_payment_email: this.state.scheduled_payment_email,
        scheduled_payment_sms: this.state.scheduled_payment_sms,
        scheduled_days_notice: this.state.scheduled_days_notice,

        proposal_reminder_notice_days:
          this.state.proposal_reminder_notice_days &&
          this.state.proposal_reminder_notice_days,
      };

      this.postData(data);
    }
  };

  handleDebtorSubmit = (e) => {
    e.preventDefault();
    if (!this.validate()) {
      this.setState({ isLoading: true });
      const data = {
        failed_payment_email: this.state.failed_payment_email,
        failed_payment_sms: this.state.failed_payment_sms,
        retry_attempt: this.state.retry_attempt,
        retry_attempt_fee: this.state.retry_attempt_fee,
        offer_payment_plan: this.state.offer_payment_plan,
        auto_accept_payment_plan: this.state.auto_accept_payment_plan,
        maximum_terms: this.state.maximum_terms,
        retry_attempt: this.state.retry_attempt,
        administration_fee: this.state.administration_fee,
        setup_fees: this.state.setup_fees,
        minimum_terms: this.state.minimum_terms,
        late_payment_email: this.state.late_payment_email,
        late_payment_sms: this.state.late_payment_sms,
        late_payment_reminder_in_days: this.state.late_payment_reminder_in_days,
        resend_late_fees_reminder: this.state.resend_late_fees_reminder,
        failed_payment_reminder_in_days:
          this.state.failed_payment_reminder_in_days,
        retry_payment: this.state.retry_payment,
        retry_payment_in_days: this.state.retry_payment_in_days,
        first_payment_within: this.state.first_payment_within,
        two_weekly_payments: this.state.two_weekly_payments,
        three_weekly_payments: this.state.three_weekly_payments,
        four_weekly_payments: this.state.four_weekly_payments,
        two_fortnightly_payments: this.state.two_fortnightly_payments,
        three_fortnightly_payments: this.state.three_fortnightly_payments,
        four_fortnightly_payments: this.state.four_fortnightly_payments,
        two_monthly_payments: this.state.two_monthly_payments,
        three_monthly_payments: this.state.three_monthly_payments,
        four_monthly_payments: this.state.four_monthly_payments,
        custom_payment_plan: this.state.custom_payment_plan,
        payment_plan_frequency: this.state.payment_plan_frequency,
        upfront_enabled: this.state.upfront_enabled,
        minimum_amount: this.state.minimum_amount,
      };

      this.postData(data);
    }
  };

  handleOtherSubmit = (e) => {
    e.preventDefault();

    if (!this.validate()) {
      this.setState({ isLoading: true });
      const data = {
        send_sms_messages: this.state.send_sms_messages,
      };

      this.postData(data);
    }
  };

  handleTermsChangeChange = (e) => {
    this.setState({
      values: {
        terms_conditions: e.target.value,
      },
    });
  };

  showTermsConditionsModal = () => {
    this.setState({ showTermsConditions: true });
  };

  handleClose = () => {
    this.setState({ showTermsConditions: false });
  };

  termsAndConditionsSubmit = () => {
    SuperFetch.post("/flows/terms", this.state.values)
      .then((response) => {
        this.setState({ showTermsConditions: false });
        this.getTemplatesData();
        store.addNotification({
          message: "Updated",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch((error) => {
        this.setState({ showTermsConditions: false });
        store.addNotification({
          message:
            "Your request can not be completed right now. Please try again later.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  render() {
    const { userInfo } = this.props;
    if (
      userInfo.organisation &&
      userInfo.organisation.subscription &&
      userInfo.organisation.subscription.plan_id == 1
    ) {
      return <></>;
    }
    return (
      <div className="main-content-inner">
        <Modal show={this.state.showTermsConditions} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="form-non-edit-label">Terms and Conditions</label>
            <div className="form-group">
              <textarea
                rows="5"
                name="terms_conditions"
                value={
                  this.state.values.terms_conditions
                    ? this.state.values.terms_conditions
                    : ""
                }
                onChange={this.handleTermsChangeChange}
                className="modal-message"
              ></textarea>
            </div>
            <button
              onClick={this.termsAndConditionsSubmit}
              type="button"
              className="btn btn-secondary"
            >
              Update
            </button>
            <button
              onClick={this.handleClose}
              type="button"
              data-dismiss="modal"
              className="btn btn-secondary"
            >
              Cancel
            </button>
          </Modal.Body>
        </Modal>
        <div className="sales-report-area mt-3 mb-5">
          <div className="row">
            {/* <div className="col-md-4">
              <form onSubmit={(e) => this.handleOnBoardingSubmit(e)}>
                <div className="card">
                  <div className="card-body"> */}
            {/* {this.state.isLoading == true ? (
                      <FlowLoader />
                    ) : (
                      <div>
                        <h5 className="card-title">Customer On-Boarding</h5>
                        <p className="text-muted font-14 mb-4">
                          Cloud Collect will invite the customer to either join
                          the network or login.
                        </p>
                        <hr /> */}

            {/* <div className="row">
                          <div className="col-md-2">
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="terms_conditions"
                                onChange={(e) => this.handleUserInput(e)}
                                defaultChecked={
                                  this.state.terms_conditions == 1
                                    ? true
                                    : false
                                }
                                className="custom-control-input"
                                value={this.state.terms_conditions}
                              />
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="col-md-10">
                            <button
                              type="button"
                              className="btn btn-outline-secondary mb-3"
                              style={{ width: "100%" }}
                              onClick={this.showTermsConditionsModal}
                            >
                              Terms & Conditions
                            </button>
                          </div>
                        </div> */}
            {/* 
                        <div className="row">
                          <div className="col-md-2">
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="notify_user"
                                onChange={(e) => this.handleUserInput(e)}
                                defaultChecked={
                                  this.state.notify_user == 1 ? true : false
                                }
                                className="custom-control-input"
                                value={this.state.notify_user}
                              />

                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="col-md-10">
                            <button
                              type="button"
                              className="btn btn-outline-secondary mb-3"
                              style={{ width: "100%" }}
                            >
                              Notify User
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}
            {/* <button className="btn btn-secondary mt-3">
                      {this.state.btn_name}
                    </button>
                  </div>
                </div>
              </form> */}
            {/* <form onSubmit={(e) => this.handleOtherSubmit(e)}>
                <div className="card mt-4">
                  <div className="card-body">
                    {this.state.isLoading == true ? (
                      <FlowLoader />
                    ) : (
                      <div>
                        <h5 className="card-title">SMS Settings</h5>
                        <div className="row">
                          <div className="col-md-2">
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="send_sms_messages"
                                onChange={(e) => this.handleUserInput(e)}
                                defaultChecked={
                                  this.state.send_sms_messages == 1
                                    ? true
                                    : false
                                }
                                className="custom-control-input"
                                value={this.state.send_sms_messages}
                              />
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div className="col-md-10">
                            <button
                              type="button"
                              className="btn btn-outline-secondary mb-3"
                              style={{ width: "100%" }}
                            >
                              Send Sms Messages
                            </button>
                          </div>
                        </div>
                        <button className="btn btn-secondary mt-3">
                          {this.state.btn_name}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form> */}
            {/* </div> */}

            <div className="col-md-4">
              <form onSubmit={(e) => this.handlePaymentProcessingSubmit(e)}>
                <div className="card">
                  <div className="card-body">
                    {this.state.isLoading == true ? (
                      <FlowLoader />
                    ) : (
                      <div>
                        <p className="onboard-heading">
                          Payment and Proposal Reminders
                        </p>
                        <hr />

                        <p className="text-muted font-14 mb-4">
                          Set pre-emptive reminders to send before due dates.
                        </p>
                        <h6>Payment Reminder (Invoice Due Date)</h6>
                        <label className="form-check-label" htmlfor="gridCheck">
                          Send reminder via:
                        </label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="customCheck1"
                            name="payment_reminder_email"
                            onChange={(e) => {
                              this.handleUserInput(e);
                            }}
                            defaultChecked={
                              this.state.payment_reminder_email === 1
                                ? true
                                : false
                            }
                            className="form-check-input"
                            value={this.state.payment_reminder_email}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheck1"
                          >
                            E-mail
                          </label>
                        </div>
                        {this.state.organisation &&
                          this.state.organisation.stripe_customer_id && (
                            <div className="form-check ">
                              <input
                                type="checkbox"
                                id="customCheck2"
                                name="payment_reminder_sms"
                                onChange={(e) => {
                                  this.handleUserInput(e);
                                }}
                                className="form-check-input"
                                defaultChecked={
                                  this.state.payment_reminder_sms === 1
                                    ? true
                                    : false
                                }
                                value={this.state.payment_reminder_sms}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheck2"
                              >
                                SMS
                              </label>
                            </div>
                          )}

                        {(this.state.payment_reminder_email === 1 ||
                          this.state.payment_reminder_sms === 1) && (
                          <div className="row">
                            <div className="col-md-8">
                              <label>
                                When reminder sends (before due date)?
                              </label>
                            </div>
                            <div className="col-md-4">
                              <select
                                name="days_notice"
                                onChange={(e) => this.handleUserInput2(e)}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                }}
                                defaultValue={this.state.days_notice}
                              >
                                <option value="1">1 Day</option>
                                <option value="2">2 Days</option>
                                <option value="3">3 Days</option>
                                <option value="4">4 Days</option>
                                <option value="5">5 Days</option>
                                <option value="6">6 Days</option>
                                <option value="7">7 Days</option>
                              </select>
                            </div>
                          </div>
                        )}
                        <br />

                        <h6>Payment Reminder (Pay Later & Payment Plan)</h6>
                        <label className="form-check-label" htmlfor="gridCheck">
                          Send reminder via:
                        </label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="customCheck1"
                            name="scheduled_payment_email"
                            onChange={(e) => {
                              this.handleUserInput(e);
                            }}
                            defaultChecked={
                              this.state.scheduled_payment_email === 1
                                ? true
                                : false
                            }
                            className="form-check-input"
                            value={this.state.scheduled_payment_email}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheck1"
                          >
                            E-mail
                          </label>
                        </div>
                        {this.state.organisation &&
                          this.state.organisation.stripe_customer_id && (
                            <div className="form-check ">
                              <input
                                type="checkbox"
                                id="customCheck2"
                                name="scheduled_payment_sms"
                                onChange={(e) => {
                                  this.handleUserInput(e);
                                }}
                                className="form-check-input"
                                defaultChecked={
                                  this.state.scheduled_payment_sms === 1
                                    ? true
                                    : false
                                }
                                value={this.state.scheduled_payment_sms}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheck2"
                              >
                                SMS
                              </label>
                            </div>
                          )}

                        {/* <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="customCheck3"
                            name="attach_invoice"
                            value={this.state.attach_invoice}
                            onChange={(e) => this.handleUserInput(e)}
                            className="custom-control-input"
                            defaultChecked={
                              this.state.attach_invoice == 1 ? true : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck3"
                          >
                            Attach Invoice To Payment Reminder
                          </label>
                        </div> */}

                        {/*{this.state.attach_invoice_err !== '' ? <span*/}
                        {/*className="m-form__help text-danger">{this.state.attach_invoice_err}</span> : null}*/}

                        {(this.state.scheduled_payment_email === 1 ||
                          this.state.scheduled_payment_sms === 1) && (
                          <div className="row">
                            <div className="col-md-8">
                              <label>
                                When reminder sends (before due date)?
                              </label>
                            </div>
                            <div className="col-md-4">
                              <select
                                name="scheduled_days_notice"
                                onChange={(e) => this.handleUserInput2(e)}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                }}
                                defaultValue={this.state.scheduled_days_notice}
                              >
                                <option value="1">1 Day</option>
                                <option value="2">2 Days</option>
                                <option value="3">3 Days</option>
                                <option value="4">4 Days</option>
                                <option value="5">5 Days</option>
                                <option value="6">6 Days</option>
                                <option value="7">7 Days</option>
                              </select>
                            </div>
                          </div>
                        )}
                        <br />

                        <h6>Proposal Expiry Reminder</h6>
                        <label className="form-check-label" htmlfor="gridCheck">
                          Send reminder via:
                        </label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            id="customCheck1"
                            name="proposal_expiry_email"
                            onChange={(e) => {
                              this.handleUserInput(e);
                            }}
                            defaultChecked={
                              this.state.proposal_expiry_email === 1
                                ? true
                                : false
                            }
                            className="form-check-input"
                            value={this.state.proposal_expiry_email}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheck1"
                          >
                            E-mail
                          </label>
                        </div>
                        {this.state.organisation &&
                          this.state.organisation.stripe_customer_id && (
                            <div className="form-check ">
                              <input
                                type="checkbox"
                                id="customCheck2"
                                name="proposal_expiry_sms"
                                onChange={(e) => {
                                  this.handleUserInput(e);
                                }}
                                className="form-check-input"
                                defaultChecked={
                                  this.state.proposal_expiry_sms === 1
                                    ? true
                                    : false
                                }
                                value={this.state.proposal_expiry_sms}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customCheck2"
                              >
                                SMS
                              </label>
                            </div>
                          )}
                        <br />

                        {(this.state.proposal_expiry_email === 1 ||
                          this.state.proposal_expiry_sms === 1) && (
                          <div className="row">
                            <div className="col-md-8">
                              <label>
                                When reminder sends (before expiry date)?
                              </label>
                            </div>
                            <div className="col-md-4">
                              <select
                                name="proposal_reminder_notice_days"
                                onChange={(e) => this.handleUserInput3(e)}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                }}
                                defaultValue={
                                  this.state.proposal_reminder_notice_days
                                }
                              >
                                <option value="1">1 Day</option>
                                <option value="2">2 Days</option>
                                <option value="3">3 Days</option>
                                <option value="4">4 Days</option>
                                <option value="5">5 Days</option>
                                <option value="6">6 Days</option>
                                <option value="7">7 Days</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <hr />
                    <div className="invoice-buttons text-center">
                      <button type="submit" className="btn btn-success">
                        {this.state.btn_name}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <form onSubmit={(e) => this.handleDebtorSubmit(e)}>
                <div className="card">
                  <div className="card-body">
                    {this.state.isLoading == true ? (
                      <FlowLoader />
                    ) : (
                      <>
                        <p className="onboard-heading">
                          Late and Failed Payment Reminders
                        </p>
                        <hr />
                        <div className="col-md-12 mt-3">
                          <p className="text-muted font-14 mb-4">
                            Set reminders to send after due dates.
                          </p>
                          <h6>Late Payment Reminder</h6>
                          <label
                            className="form-check-label"
                            htmlfor="gridCheck"
                          >
                            Send reminder via:
                          </label>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="customCheck1"
                              name="late_payment_email"
                              onChange={(e) => {
                                this.handleUserInput(e);
                              }}
                              defaultChecked={
                                this.state.late_payment_email === 1
                                  ? true
                                  : false
                              }
                              className="form-check-input"
                              value={this.state.late_payment_email}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              E-mail
                            </label>
                          </div>
                          {this.state.organisation &&
                            this.state.organisation.stripe_customer_id && (
                              <div className="form-check ">
                                <input
                                  type="checkbox"
                                  id="customCheck2"
                                  name="late_payment_sms"
                                  onChange={(e) => {
                                    this.handleUserInput(e);
                                  }}
                                  className="form-check-input"
                                  defaultChecked={
                                    this.state.late_payment_sms === 1
                                      ? true
                                      : false
                                  }
                                  value={this.state.late_payment_sms}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck2"
                                >
                                  SMS
                                </label>
                              </div>
                            )}
                          <br />

                          {(this.state.late_payment_email === 1 ||
                            this.state.late_payment_sms === 1) && (
                            <>
                              {" "}
                              <div className="row">
                                <div className="col-md-8">
                                  <label>
                                    When reminder sends (after due date)?
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <select
                                    name="late_payment_reminder_in_days"
                                    onChange={(e) => this.handleUserInput2(e)}
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                    }}
                                    defaultValue={
                                      this.state.late_payment_reminder_in_days
                                    }
                                  >
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                    <option value="4">4 Days</option>
                                    <option value="5">5 Days</option>
                                    <option value="6">6 Days</option>
                                    <option value="7">7 Days</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8">
                                  <label>
                                    Re-send late payment reminder weekly:
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <select
                                    name="resend_late_fees_reminder"
                                    onChange={(e) => this.handleUserInput2(e)}
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                    }}
                                    defaultValue={
                                      this.state.resend_late_fees_reminder
                                    }
                                  >
                                    <option value="1">Enable</option>
                                    <option value="0">Disabled</option>
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                          <h6>Failed Payment Reminder</h6>
                          <label
                            className="form-check-label"
                            htmlfor="gridCheck"
                          >
                            Send reminder via:
                          </label>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              id="customCheck1"
                              name="failed_payment_email"
                              onChange={(e) => {
                                this.handleUserInput(e);
                              }}
                              defaultChecked={
                                this.state.failed_payment_email === 1
                                  ? true
                                  : false
                              }
                              className="form-check-input"
                              value={this.state.failed_payment_email}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customCheck1"
                            >
                              E-mail
                            </label>
                          </div>
                          {this.state.organisation &&
                            this.state.organisation.stripe_customer_id && (
                              <div className="form-check ">
                                <input
                                  type="checkbox"
                                  id="customCheck2"
                                  name="failed_payment_sms"
                                  onChange={(e) => {
                                    this.handleUserInput(e);
                                  }}
                                  className="form-check-input"
                                  defaultChecked={
                                    this.state.failed_payment_sms === 1
                                      ? true
                                      : false
                                  }
                                  value={this.state.failed_payment_sms}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck2"
                                >
                                  SMS
                                </label>
                              </div>
                            )}
                          <br />

                          <div className="row">
                            <div className="col-md-12">
                              <label>
                                Notification sent immediately after failed
                                payment attempt.
                              </label>
                            </div>
                          </div>
                          <h6 class="mt-2">Retry payment</h6>
                          <div className="row">
                            <div className="col-md-8">
                              <label>Rety Payment:</label>
                            </div>
                            <div className="col-md-4">
                              <select
                                name="retry_payment"
                                onChange={(e) => this.handleUserInput2(e)}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                }}
                                defaultValue={this.state.retry_payment}
                              >
                                <option value="1">Enable</option>
                                <option value="0">Disabled</option>
                              </select>
                            </div>
                          </div>

                          {this.state.retry_payment === 1 && (
                            <>
                              <div className="row">
                                <div className="col-md-8">
                                  <label>
                                    Retry payment in (after reminder):
                                  </label>
                                </div>
                                <div className="col-md-4">
                                  <select
                                    name="retry_payment_in_days"
                                    onChange={(e) => this.handleUserInput2(e)}
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                    }}
                                    defaultValue={
                                      this.state.retry_payment_in_days
                                    }
                                  >
                                    <option value="1">1 Day</option>
                                    <option value="2">2 Days</option>
                                    <option value="3">3 Days</option>
                                    <option value="4">4 Days</option>
                                    <option value="5">5 Days</option>
                                    <option value="6">6 Days</option>
                                    <option value="7">7 Days</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-8">
                                  <label>Retry attempts:</label>
                                </div>
                                <div className="col-md-4">
                                  <select
                                    name="retry_attempt"
                                    onChange={(e) => this.handleUserInput2(e)}
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                    }}
                                    defaultValue={this.state.retry_attempt}
                                  >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                  </select>
                                </div>
                              </div>

                              {/* <div className="row">
                                <div className="col-md-8">
                                  <label>Retry fee:</label>
                                </div>
                                <div className="col-md-4">
                                  <select
                                    name="retry_attempt_fee"
                                    onChange={(e) => {
                                      this.handleUserInput2(e);
                                    }}
                                    style={{
                                      width: "100%",
                                      height: "25px",
                                      borderRadius: "5px",
                                      marginBottom: "10px",
                                    }}
                                    defaultValue={this.state.retry_attempt_fee}
                                  >
                                    <option value="0">$0</option>
                                    <option value="5">$5</option>
                                    <option value="10">$10</option>
                                    <option value="15">$15</option>
                                    <option value="20">$20</option>
                                  </select>
                                </div>
                              </div> */}
                            </>
                          )}

                          <hr />
                          <div className="invoice-buttons text-center">
                            <button type="submit" className="btn btn-success">
                              {this.state.btn_name}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <form onSubmit={(e) => this.handleDebtorSubmit(e)}>
                <div className="card">
                  <div className="card-body">
                    {this.state.isLoading == true ? (
                      <FlowLoader />
                    ) : (
                      <>
                        <p className="onboard-heading">Payment Plans</p>
                        <hr />
                        <div className="col-md-12 mt-3">
                          <p className="text-muted font-14 mb-4">
                            Set which payment plans to make available.
                          </p>
                          <div className="row">
                            <div className="col-md-8">
                              <label>Payment Plans:</label>
                            </div>
                            <div className="col-md-4">
                              <select
                                name="offer_payment_plan"
                                onChange={(e) => this.handleUserInput2(e)}
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                }}
                                defaultValue={this.state.offer_payment_plan}
                              >
                                <option value="1">Enable</option>
                                <option value="0">Disabled</option>
                              </select>
                            </div>
                          </div>

                          {this.state.offer_payment_plan == 1 && (
                            <div className="row">
                              <div className="col-md-8">
                                <label>First payment within:</label>
                              </div>
                              <div className="col-md-4">
                                <select
                                  name="first_payment_within"
                                  onChange={(e) => this.handleUserInput2(e)}
                                  style={{
                                    width: "100%",
                                    height: "25px",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                  }}
                                  defaultValue={this.state.first_payment_within}
                                >
                                  <option value="7">7 Days</option>
                                  <option value="14">14 Days</option>
                                  <option value="30">30 Days</option>
                                </select>
                              </div>
                            </div>
                          )}

                          {this.state.offer_payment_plan == 1 && (
                            <div className="row">
                              <div className="col-md-8">
                                <label>Minimum Amount:</label>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="number"
                                  name="minimum_amount"
                                  onChange={(e) => this.handleUserInput2(e)}
                                  class="form-control"
                                  defaultValue={this.state.minimum_amount}
                                />
                              </div>
                            </div>
                          )}

                          <br />

                          {this.state.offer_payment_plan == 1 &&
                            this.state.first_payment_within >= 1 && (
                              <>
                                {" "}
                                <h6>Weekly Payment Plans</h6>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>2 weekly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="two_weekly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.two_weekly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>3 weekly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="three_weekly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.three_weekly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>4 weekly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="four_weekly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.four_weekly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <br />
                                <h6>Fortnightly Payment Plans</h6>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>2 fortnightly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="two_fortnightly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.two_fortnightly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>3 fortnightly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="three_fortnightly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.three_fortnightly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>4 fortnightly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="four_fortnightly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.four_fortnightly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <br />
                                <h6>Monthly Payment Plans</h6>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>2 monthly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="two_monthly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.two_monthly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>3 monthly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="three_monthly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.three_monthly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>4 monthly payments</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="four_monthly_payments"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.four_monthly_payments
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <br />
                                <h6>Custom Payment Plans</h6>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>Custom payment plan</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="custom_payment_plan"
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(e) => this.handleUserInput2(e)}
                                      defaultValue={
                                        this.state.custom_payment_plan
                                      }
                                    >
                                      <option value="Enable">
                                        Enable Request
                                      </option>
                                      <option value="Auto Accept">
                                        Auto Accept
                                      </option>
                                      <option value="Disabled">Disabled</option>
                                    </select>
                                  </div>
                                </div>
                                <label
                                  className="form-check-label"
                                  htmlfor="gridCheck"
                                >
                                  Frequency:
                                </label>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    defaultChecked={this.state.payment_plan_frequency.some(
                                      (f) => f === "Weekly"
                                    )}
                                    name="payment_plan_frequency"
                                    value={"Weekly"}
                                    onChange={(e) => {
                                      let payment_plan_frequency = [
                                        ...this.state.payment_plan_frequency,
                                      ];
                                      if (e.target.checked) {
                                        payment_plan_frequency.push("Weekly");
                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      } else {
                                        payment_plan_frequency.pop("Weekly");

                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlfor="gridCheck"
                                  >
                                    Weekly
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    defaultChecked={this.state.payment_plan_frequency.some(
                                      (f) => f === "Forthrightly"
                                    )}
                                    name="payment_plan_frequency"
                                    value={"Forthrightly"}
                                    onChange={(e) => {
                                      let payment_plan_frequency = [
                                        ...this.state.payment_plan_frequency,
                                      ];
                                      if (e.target.checked) {
                                        payment_plan_frequency.push(
                                          "Forthrightly"
                                        );
                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      } else {
                                        payment_plan_frequency.pop(
                                          "Forthrightly"
                                        );

                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlfor="gridCheck"
                                  >
                                    Forthrightly
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    defaultChecked={this.state.payment_plan_frequency.some(
                                      (f) => f === "Monthly"
                                    )}
                                    name="payment_plan_frequency"
                                    value={"Monthly"}
                                    onChange={(e) => {
                                      let payment_plan_frequency = [
                                        ...this.state.payment_plan_frequency,
                                      ];
                                      if (e.target.checked) {
                                        payment_plan_frequency.push("Monthly");
                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      } else {
                                        payment_plan_frequency.pop("Monthly");

                                        this.setState({
                                          payment_plan_frequency,
                                        });
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlfor="gridCheck"
                                  >
                                    Monthly
                                  </label>
                                </div>
                                <br />
                                {/* <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="customCheck8"
                              name="auto_accept_payment_plan"
                              onChange={(e) => this.handleUserInput(e)}
                              className="custom-control-input"
                              value={this.state.auto_accept_payment_plan}
                              defaultChecked={
                                this.state.auto_accept_payment_plan === 1
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck8"
                            >
                              Auto-accept payment plan request
                            </label>
                          </div> */}
                                {/* {this.state.auto_accept_payment_plan_err !== '' ? <span className="m-form__help text-danger">{this.state.auto_accept_payment_plan_err}</span> : null} */}
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>Upfront Payment:</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="upfront_enabled"
                                      onChange={(e) => this.handleUserInput2(e)}
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      defaultValue={this.state.upfront_enabled}
                                    >
                                      <option value="0">Disabled</option>
                                      <option value="1">Enable</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <label>Maximum Length:</label>
                                  </div>
                                  <div className="col-md-4">
                                    <select
                                      name="maximum_terms"
                                      onChange={(e) => this.handleUserInput2(e)}
                                      style={{
                                        width: "100%",
                                        height: "25px",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                      }}
                                      defaultValue={this.state.maximum_terms}
                                    >
                                      <option value=""></option>
                                      <option value="1 week">1 week</option>
                                      <option value="2 week">2 weeks</option>
                                      <option value="3 week">3 weeks</option>
                                      <option value="4 week">4 weeks</option>
                                      <option value="2 months">2 months</option>
                                      <option value="3 months">3 months</option>
                                      <option value="4 months">4 months</option>
                                      <option value="5 months">5 months</option>
                                      <option value="6 months">6 months</option>
                                      <option value="7 months">7 months</option>
                                      <option value="8 months">8 months</option>
                                      <option value="9 months">9 months</option>
                                      <option value="10 months">
                                        10 months
                                      </option>
                                      <option value="11 months">
                                        11 months
                                      </option>
                                      <option value="1 year">1 year</option>
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                          <hr />
                          <div className="invoice-buttons text-center">
                            <button type="submit" className="btn btn-success">
                              {this.state.btn_name}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
    var style = {};
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flow);
