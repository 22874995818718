import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";

import FlowHelper from "../../helpers/flowHelper";

export function* fetchFlowSettings() {
  yield takeEvery("FETCH_FLOW", function* () {
    try {
      const result = yield call(FlowHelper.fetchFlowSettings);
      yield put({
        type: actions.SET_FLOW,
        payload: result.data,
      });
    } catch (error) {
      //   yield put({
      //     type: alertActions.ADD_ERROR,
      //     payload: error,
      //   });
    }
  });
}

export function* updateFlowSettings() {
  yield takeEvery("UPDATE_NOTIFICATION_SETTINGS", function* ({ payload }) {
    try {
      const result = yield call(
        FlowHelper.updateNotificationsSettings,
        payload.payload
      );
      if (result) {
        yield put({
          type: actions.FETCH_FLOW,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FLOW,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchFlowSettings)]);
  yield all([fork(updateFlowSettings)]);
}
