import React, { Component } from "react";
import { reduxForm, Form } from "redux-form";
import DraftComponent from "../../../DraftComponent";

class TermsForm extends Component {
  render() {
    const { editorValue } = this.props;

    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <Form onSubmit={this.props.handleSubmit}>
            <div className="form-group">
              <DraftComponent
                initialValue={
                  this.props.initialValues &&
                  this.props.initialValues.term_conditions
                }
                getValue={editorValue}
              />
            </div>

            <div className="form-buttons-group">
              <button
                type="submit"
                className="btn btn-secondary"
                style={{ width: "100px" }}
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "TermsForm",
  enableReinitialize: false,
})(TermsForm);
