import SuperFetch from "./superFetch";

class BusinessHelper {
  fetchBusinessUsers = async () => {
    return await SuperFetch.get("/business-users")
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };

  fetchBusinessOrganisations = async () => {
    return await SuperFetch.get("/business-organisations")
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };
  myInvites = async () => {
    return await SuperFetch.get("/business-users/my-invites")
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };

  inviteBusinessUser = async payload => {
    return await SuperFetch.post("/business-users", payload)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };

  changeDefaultOrganisation = async payload => {
    return await SuperFetch.put("/change-default-organisation", payload)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };

  acceptRejectBusinessInvite = async payload => {
    
    return await SuperFetch.put("/business-users/accept-reject/"+payload.id, payload)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };

  deleteBusinessUser = async id => {
    return await SuperFetch.delete("/business-users/" + id)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
      });
  };
}
export default new BusinessHelper();
