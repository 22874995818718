import React from "react";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import SuperFetch from "../../../helpers/superFetch";
import ContactForm from "./ContactForm";

const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

const STATES = ["", "ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

class NewLead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      first_name: "",
      last_name: "",
      organisation: "",
      email: "",
      mobile: "",
      tag: "",
      address: "",
      suburb: "",
      state: "",
      post_code: "",
      invite: false,
      tags: [],
      value: "",
      created: true,
      selectedState: "",
    };
    this.handleCreateInvoice = this.handleCreateInvoice.bind(this);
    this.handelCreate = this.handelCreate.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
  }
  componentDidMount() {
    document.title = "Cloud Collect - Create Lead";
  }

  handleRemoveTag(id, e) {
    let tagsArray = this.state.tags;
    tagsArray.filter((tag, index) => index !== id);
    for (let i in tagsArray) {
      if (i === id) {
        tagsArray.splice(i, 1);
      }
    }
    this.setState({ tags: tagsArray });
  }

  handleChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  selectedState = (e) => {
    this.setState({ selectedState: e.target.value });
  };

  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  handleKeyDown(e) {
    const key = e.keyCode;
    if (key === BACKSPACE_KEY && !this.state.value) {
      this.editPrevTag();
    }
  }

  addTag() {
    const { tags, value } = this.state;
    let tag = value.trim();

    tag = tag.replace(/,/g, "");

    if (!tag) {
      return;
    }

    this.setState({
      tags: [...tags, tag],
      value: "",
    });
  }

  editPrevTag() {
    let { tags } = this.state;

    const tag = tags.pop();

    this.setState({ tags, value: tag });
  }

  handleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value !== "") {
      this.setState({
        [e.target.name + "_err"]: "",
      });
    }
  }

  handleCheck() {
    this.setState({ invite: !this.state.invite });
  }

  validate = () => {
    let isError = false;
    const errors = {
      type_err: "",
      first_name_err: "",
      last_name_err: "",
      organisation_err: "",
      email_err: "",
      mobile_err: "",
      address_err: "",
      suburb_err: "",
      state_err: "",
      post_code_err: "",
      tag_err: "",
    };
    if (this.state.type === "") {
      isError = true;
      errors.type_err = "Please select type.";
    }
    if (this.state.first_name === "") {
      isError = true;
      errors.first_name_err = "Please enter first name.";
    }
    if (this.state.last_name === "") {
      isError = true;
      errors.last_name_err = "Please enter last name.";
    }
    // if (this.state.organisation === "") {
    //   isError = true;
    //   errors.organisation_err = "Please enter organisation name.";
    // }
    if (this.state.email === "") {
      isError = true;
      errors.email_err = "Please enter email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      isError = true;
      errors.email_err = "Please enter tag.";
    }

    // if (this.state.mobile === '') {
    //     isError = true;
    //     errors.mobile_err = "Please enter mobile.";
    // } else if (!this.state.mobile.match(/^\d{10}$/) || this.state.mobile.length < 10) {
    //     isError = true;
    //     errors.mobile_err = "Please enter only numbers.";
    // }
    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  reformTagsArray() {
    let newLeadData = this.createLeadData();
    if (this.state.tags.length !== 0) {
      let tagsArray = this.state.tags;
      for (let i in tagsArray) {
        newLeadData["tags"] = tagsArray;
      }
    }
    return newLeadData;
  }

  createLeadData() {
    let leadData = {
      // product_id: 1,
      type: this.state.type,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      mobile: this.state.mobile,
      company_name: this.state.organisation,
      address: this.state.address,
      suburb: this.state.suburb,
      state: this.state.selectedState,
      postcode: this.state.post_code,
      send_invite: this.state.invite ? 1 : 0,
    };
    return leadData;
  }

  addNotification() {
    return store.addNotification({
      message: "Lead added successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  handelCreate(e) {
    this.reformTagsArray();
    if (this.validate()) return;
    this.setState({ created: false });
    if (this.state.created) {
      SuperFetch.post("/leads", this.reformTagsArray()).then((response) => {
        localStorage.removeItem("newLead");
        this.addNotification();
        this.props.history.push("/contacts");
      });
    }
  }

  handleCreateInvoice() {
    if (this.validate()) return;
    this.setState({ created: false });
    if (this.state.created) {
      SuperFetch.post("/leads", this.reformTagsArray()).then((response) => {
        this.addNotification();
        this.props.history.push("/invoice/add/" + response.data.id);
      });
    }
  }

  submitHandler = (values) => {
    SuperFetch.post("/leads", values).then((response) => {
      localStorage.removeItem("newLead");
      this.addNotification();
      this.props.history.push("/contacts");
    });
  };

  render() {
    const { tags, value } = this.state;
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="card">
              <div className="card-body">
                <ContactForm
                  states={STATES}
                  onSubmit={this.submitHandler}
                  typeOptions={["Business/Organisation", "Individual"]}
                  {...this.props}
                />
                {/* <p class="onboard-heading">Details</p> */}
                {/* <hr /> */}
                {/* <form> */}
                {/* <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Type
                        </div>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        id="inlineFormInputGroup"
                        placeholder="Lead"
                        readonly
                        disabled
                      />
                    </div>
                  </div> */}

                {/* <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Organisation
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type=""
                        className="form-control"
                        name="organisation"
                        defaultValue={this.state.organisation}
                      />
                      {this.state.organisation_err !== "" ? (
                        <span
                          className="m-form__help w-100 d-inline-block
                          text-danger"
                        >
                          {this.state.organisation_err}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          First Name
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type=""
                        className="form-control"
                        name="first_name"
                        id="inlineFormInputGroup"
                        placeholder=""
                        defaultValue={this.state.first_name}
                      />
                      {this.state.first_name_err ? (
                        <span className="m-form__help w-100 d-inline-block text-danger">
                          {this.state.first_name_err}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Last Name
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type=""
                        className="form-control"
                        name="last_name"
                        defaultValue={this.state.last_name}
                        id="inlineFormInputGroup"
                        placeholder=""
                      />
                      {this.state.last_name_err ? (
                        <span className="m-form__help w-100 d-inline-block text-danger">
                          {this.state.last_name_err}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Email
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type=""
                        className="form-control"
                        name="email"
                        defaultValue={this.state.email}
                        id="inlineFormInputGroup"
                        placeholder=""
                      />
                      {this.state.email_err ? (
                        <span className="m-form__help w-100 d-inline-block text-danger">
                          {this.state.email_err}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Mobile
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type="number"
                        className="form-control"
                        name="mobile"
                        defaultValue={this.state.mobile}
                        id="inlineFormInputGroup"
                        placeholder=""
                      /> */}
                {/*{this.state.mobile_err !== '' ? <span*/}
                {/*className="m-form__help w-100 d-inline-block text-danger">{this.state.mobile_err}</span> : null}*/}
                {/* </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Address
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type="text"
                        className="form-control"
                        name="address"
                        defaultValue={this.state.address}
                        id="inlineFormInputGroup"
                        placeholder=""
                      />
                      {this.state.address_err ? (
                        <span className="m-form__help w-100 d-inline-block text-danger">
                          {this.state.address_err}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup"></label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Surbub
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type="text"
                        className="form-control"
                        name="suburb"
                        defaultValue={this.state.suburb}
                        id="inlineFormInputGroup"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="col-md-7 mt-3">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          State
                        </div>
                      </div>
                      <select
                        className="form-control"
                        // id="exampleFormControlSelect1"
                        value={this.state.selectedState}
                        onChange={(e) => this.selectedState(e)}
                        id="inlineFormInputGroup"
                        style={{ height: 45 }}
                      >
                        {STATES.map((state) => {
                          return <option value={state}>{state}</option>;
                        })}
                      </select>
                    </div>
                  </div>

                  <div class="col-md-7  mt-3">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Postcode
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        type="number"
                        className="form-control"
                        id="inlineFormInputGroup"
                        name="post_code"
                        defaultValue={this.state.post_code}
                      />
                    </div>
                  </div> */}
                {/* <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      Tag
                    </label>
                    <div className="col-sm-9">
                      <div className="tags">
                        <div className="parent_div">
                          {tags.map((tag, i) => (
                            <div key={tag + i} className="tag mb-3">
                              <a onClick={this.handleRemoveTag.bind(this, i)}>
                                x
                              </a>
                              {tag}
                            </div>
                          ))}
                          <input
                            type="text"
                            placeholder="Add tag..."
                            value={value}
                            onChange={this.handleChange}
                            ref="tag"
                            className=" mb-3 form-control"
                            onKeyUp={this.handleKeyUp}
                            onKeyDown={this.handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                {/* <div className="form-group row">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      Invite
                    </label>
                    <div className="col-sm-3 custom-control custom-checkbox ml-2">
                      <input
                        type="checkbox"
                        onChange={this.handleCheck.bind(this)}
                        className="custom-control-input"
                        id="element1"
                      />
                      <label
                        className="custom-control-label custom-control-invoice"
                        htmlFor="element1"
                      ></label>
                    </div>
                  </div> */}
                {/* </form> */}

                {/* <hr /> */}
                {/* <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    onClick={this.handelCreate.bind(this)}
                    class="btn btn-success"
                  >
                    Create
                  </button> */}
                {/* <button
                    type="button"
                    onClick={this.handleCreateInvoice.bind(this)}
                    class="btn btn-secondary ml-2"
                    id="button-auto"
                  >
                    Create + Send Checkout Link
                  </button> */}
                {/* </div> */}

                {/* <hr />
                <div className="invoice-buttons text-center">
                  <button
                    onClick={this.handelCreate.bind(this)}
                    type="button"
                    className="btn btn-secondary "
                    id="button-float"
                  >
                    Create
                  </button>
                  <button
                    onClick={this.handleCreateInvoice.bind(this)}
                    type="button"
                    className="btn btn-secondary"
                    id="button-float"
                  >
                    Create + Invoice
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => {
  return {
    contactForm: state.form.ContactForm
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLead);
