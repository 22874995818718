const actions = {
  FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
  FETCH_SUBSCRIPTIONS_SUCCESS: "FETCH_SUBSCRIPTIONS_SUCCESS",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  fetchSubscriptions: ({ dispatch }) =>
    dispatch({
      type: actions.FETCH_SUBSCRIPTIONS,
    }),
  cancelSubscription: ({ dispatch, subscriptionId }) =>
    dispatch({
      type: actions.CANCEL_SUBSCRIPTION,
      subscriptionId
    }),
};
export default actions;
