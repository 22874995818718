import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import url from "../../../url";
import Alert from "../../uielements/alert";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email  is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <div>
    <input
      {...input}
      className="form-control"
      placeholder={label}
      type={type}
    />
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

let ForgotpasswordForm = (props) => {
  const { handleSubmit, pristine, reset, submitting, error } = props;
  return (
    <form onSubmit={handleSubmit} className="login-form">
      <div className="login-form-head" style={{ backgroundColor: "#292b2c" }}>
        <h2 className="logo" style={{ color: "white" }}>
          Cloud Collect
        </h2>
        <h2 className="logo" style={{ color: "white", fontSize: "20px" }}>
          Recover Password
        </h2>
      </div>
      <div className="login-form-body">
        <div className="row">
          <Alert />
        </div>
        <div className="form-group">
          {/* <label for='exampleInputEmail1'>Email</label> */}
          <Field
            label="Email"
            component={renderField}
            type="email"
            name="email"
            id="exampleInputEmail1"
          />
          {error && <span class="error">{error}</span>}
        </div>
        <small id="emailHelp" class="form-text text-muted">
          You will receive an email with recovery instructions.
        </small>
        <br />
        <div className="submit-btn-area">
          <button id="form_submit" type="submit">
            Recover Password
          </button>
        </div>
        <br />
        <div className="submit-btn-area">
          <Link to={url.account.login}>
            <button type="button">Return To Login</button>
          </Link>
        </div>
        <p class="text-center">
          <a href="https://www.cloudcollect.io">Return to Cloud Collect Home</a>
        </p>
      </div>
    </form>
  );
};

ForgotpasswordForm = reduxForm({
  // a unique name for the form
  form: "forgotPassword",
  validate,
})(ForgotpasswordForm);

export default ForgotpasswordForm;
