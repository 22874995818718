import React, { Component } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import SuperFetch from "../../helpers/superFetch";
import { store } from "react-notifications-component";
import authAction from "../../redux/auth/actions";
import { getUser } from "../../helpers/utility";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import FlowLoader from "../uielements/FlowLoader";
import $ from "jquery";
import Login from "./Login";
import Button from "react-bootstrap/Button";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      postcode: "",
      stat: "ACT",
      suburb: "",
      product_id: "",
      organisation_id: "",
      lead_id: "",
      radio: "",
      checkbox: false,
      first_name_err: "",
      last_name_err: "",
      email_err: "",
      mobile_err: "",
      address_err: "",
      suburb_err: "",
      errors: {},
      products: [],
      stripe_token: "",
      termData: "",
      isLoading: false,
      show_card: false,
      empty_organisation_message: "",
      stripe_account: "",
      terms_conditions: null,
      err_message: "",
      organisation: null,
      user_id: null,
      show: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.checkBox = this.checkBox.bind(this);
    this.handleShowCard = this.handleShowCard.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Registration";
    // this.getFlow();
    this.getDataByBusinessName();
    this.getDataByCode();

    const { userInfo } = this.props;
    let info = userInfo
      ? userInfo
      : localStorage.getItem("cloud_collect_userInfo");
    info = typeof info === "string" ? JSON.parse(info) : info;
    if (info) {
      this.setState({
        first_name: info.first_name,
        last_name: info.last_name,
        email: info.email,
        mobile: info.mobile,
        address: info.address,
        suburb: info.suburb,
        state: info.state,
        postcode: info.postcode,
        user_id: info.id,
      });
    }
  }

  handleShowCard() {
    this.setState({ show_card: !this.state.show_card });
    $(".show_card_button").css("display", "none");
  }

  async validate() {
    let is_Error = false;
    const errors = {};
    if (this.state.first_name === "" || this.state.first_name == undefined) {
      is_Error = true;
      errors.first_name = "First name is required";
    }
    if (this.state.last_name === "" || this.state.last_name == undefined) {
      is_Error = true;
      errors.last_name = "Last name is required";
    }
    if (
      (this.state.email && this.state.email.length === 0) ||
      this.state.email == undefined
    ) {
      is_Error = true;
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      is_Error = true;
      errors.email = "Invalid email address";
    }
    if (this.state.mobile === "" || this.state.mobile == undefined) {
      is_Error = true;
      errors.mobile = "Mobile is required";
    } else if (
      (this.state.mobile !== "" && this.state.mobile.length < 10) ||
      (this.state.mobile !== "" && this.state.mobile.length > 13)
    ) {
      is_Error = true;
      errors.mobile = "Mobile is required";
    }

    if (this.state.email && this.state.email !== "") {
      const response = await SuperFetch.post("/email-check/", {
        email: this.state.email,
      });
      if (response) {
        if (
          response.data &&
          response.data.role &&
          response.data.role.some((r) => r.title === "Business")
        ) {
          is_Error = true;
          errors.email =
            "A business account exist with this email address. Use different email address to continue";
        }
      }
    }

    if (this.state.product_id === "") {
      is_Error = true;
      errors.product = "Product is required";
    }

    this.setState({ errors: errors });
    return is_Error;
  }

  handleOptionChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.validate();
      }
    );
  }

  checkBox() {
    this.setState({ checkbox: !this.state.checkBox });
  }

  getDataByBusinessName() {
    if (this.props.match.params.name) {
      let businessName = this.props.match.params.name;
      SuperFetch.get("/organisation/" + businessName).then((response) => {
        if (response.data.length !== 0) {
          if (response.data.user.organisation === null) {
            this.setState({
              empty_organisation_message:
                "Please complete your organisation profile to activate the register page",
            });
          } else {
            this.setState({
              products: response.data.active_products,
              organisation_id: response.data.id,
              stripe_account: response.data.stripe_account_id,
              organisation: response.data,
            });
            this.getTermsAndConditions();
          }
        } else {
          this.setState({
            empty_organisation_message:
              "Please complete your organisation profile to activate the register page",
          });
        }
      });
    }
    return;
  }

  getDataByCode() {
    if (this.props.match.params.code) {
      let code = this.props.match.params.code;
      SuperFetch.get("/leads/code/" + code).then((response) => {
        this.setState({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          mobile: response.data.mobile,
          lead_id: response.data.id,
          business_name: response.data.company_name,
          address: response.data.address,
          suburb: response.data.suburb,
          state: response.data.state,
          postcode: response.data.postcode,
        });
      });
    }
    return;
  }

  getTermsAndConditions() {
    if (!this.state.organisation_id) return;
    SuperFetch.get(`/organisation/${this.state.organisation_id}/terms`)
      .then((response) => {
        this.setState({
          termData: response.data,
          terms_conditions: response.data,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  Product() {
    // let label = '';
    // this.state.products.map((product, i) => {
    //     label += `<label key=${product.id}>
    //         <input
    //             type="radio"
    //             className="product-item"
    //             // checked={this.state.checked == product.id? true: false}
    //             key=${product.id}
    //             value=${product.name} />
    //             ${product.name}<br/>
    //     </label>`
    // });
    // return label;
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.userInfo != this.props.userInfo) {
      return true;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userInfo } = this.props;
    if (userInfo && snapshot) {
      if (!this.props.match.params.code) {
        this.setState({
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          mobile: userInfo.mobile,
          address: userInfo.address,
          suburb: userInfo.suburb,
          state: userInfo.state,
          postcode: userInfo.postcode,
          user_id: userInfo.id,
          show: false,
        });
      } else {
        this.setState({
          user_id: userInfo.id,
          show: false,
        });
      }

      if (userInfo.role.some((r) => r.title === "Business")) {
        this.setState({
          empty_organisation_message:
            "A business account cannot register for product",
        });
      }
    }
  }

  handleSubmit(token) {
    const { history } = this.props;
    if (Object.keys(this.state.errors).length > 0) {
      return;
    } else {
      if (token) {
        this.setState({ stripe_token: token, isLoading: true });
        const data = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          mobile: this.state.mobile,
          product_id: this.state.product_id,
          organisation_id: this.state.organisation_id,
          lead_id: this.state.lead_id,
          stripe_token: { id: token.id },
          address: this.state.address,
          suburb: this.state.suburb,
          postcode: this.state.postcode,
          state: this.state.stat,
        };

        if (this.state.user_id) {
          data.user_id = this.state.user_id;
        }
        SuperFetch.post("/customer/registration", data)
          .then((response) => {
            this.setState({ isLoading: false });
            window.location.href = "/thankyou";
          })
          .catch((error) => {
            if (error && !error.message) {
              this.setState({ err_message: error.error });
            }
            if (error && error.message && error.errors.email) {
              let tmp = this.state.errors;
              tmp.email = error.errors.email;
              this.setState({ errors: tmp });
            }
            throw error;
          });
      }
    }
  }

  showLoginModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const { userInfo } = this.props;
    return (
      <div>
        <div id="preloader">
          <div className="loader"></div>
        </div>
        <div className="">
          <div className="main-content">
            <div className="">
              <div className="sales-report-area mt-2 mb-5">
                <div className="row">
                  {this.state.empty_organisation_message === "" ? (
                    <div className="col-md-4" id="onboard-card">
                      <div
                        className="card"
                        style={{
                          width: "100%",
                          display: "block",
                          margin: "auto auto",
                        }}
                      >
                        <div className="card-body">
                          <div>
                            <h3 style={{ textAlign: "center" }}>
                              {this.state.organisation
                                ? this.state.organisation.business_name
                                : ""}
                            </h3>
                            <h3 style={{ textAlign: "center" }}>
                              Customer Sign-Up
                            </h3>
                          </div>
                          <p style={{ textAlign: "center" }}>
                            Powered by Cloud Collect
                          </p>
                          <hr />
                          {!userInfo && (
                            <>
                              <p>
                                Existing Cloud Collect user?{" "}
                                <Button
                                  style={{
                                    height: 0,
                                    width: 0,
                                    fontSize: "16px",
                                  }}
                                  variant="link"
                                  onClick={this.showLoginModal}
                                >
                                  Login
                                </Button>
                                .
                              </p>
                              <hr />
                            </>
                          )}
                          <p className="onboard-heading">Customer Details</p>
                          <form onSubmit={this.handleSubmit}>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                First Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.first_name}
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                />
                                {this.state.errors.first_name !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.first_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                Last Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.last_name}
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                />
                                {this.state.errors.last_name !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.last_name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="email"
                                className="col-sm-3 col-form-label"
                              >
                                Email
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.email}
                                  type="text"
                                  className="form-control"
                                  name="email"
                                />
                                {this.state.errors.email !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.email}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                Mobile
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.mobile}
                                  type="text"
                                  className="form-control"
                                  name="mobile"
                                />
                                {this.state.errors.mobile !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.mobile}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                Address
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.address}
                                  type="text"
                                  className="form-control"
                                  name="address"
                                />
                                {this.state.errors.address !== "" ? (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.address}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                Suburb
                              </label>
                              <div className="col-sm-9">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.suburb}
                                  type="text"
                                  className="form-control"
                                  name="suburb"
                                />
                                {this.state.errors.suburb !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.suburb}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                State
                              </label>
                              <div className="col-sm-3">
                                <select
                                  onChange={this.handleOptionChange}
                                  value={this.state.stat}
                                  name="stat"
                                  className="form-control"
                                >
                                  <option value="ACT">ACT</option>
                                  <option value="NSW">NSW</option>
                                  <option value="NT">NT</option>
                                  <option value="QLD">QLD</option>
                                  <option value="SA">SA</option>
                                  <option value="TAS">TAS</option>
                                  <option value="VIC">VIC</option>
                                  <option value="WA">WA</option>
                                  <option value="Other">Other</option>
                                </select>
                              </div>
                              <label
                                htmlFor=""
                                className="col-sm-3 col-form-label"
                              >
                                Post-Code
                              </label>
                              <div className="col-sm-3">
                                <input
                                  onInput={this.handleOptionChange}
                                  defaultValue={this.state.postcode}
                                  type="text"
                                  className="form-control"
                                  name="postcode"
                                />
                                {this.state.errors.postcode !== "" && (
                                  <span className="m-form__help w-100  text-danger">
                                    {this.state.errors.postcode}
                                  </span>
                                )}
                              </div>
                            </div>
                            <hr />
                            <p className="onboard-heading">Product Selection</p>
                            {this.state.products.map((product, i) => {
                              let frequency = "";
                              if (product.type === "Recurring")
                                frequency = product.frequency;
                              return (
                                <div key={i}>
                                  <input
                                    onChange={this.handleOptionChange}
                                    checked={
                                      this.state.product_id == product.id
                                        ? true
                                        : false
                                    }
                                    type="radio"
                                    name="product_id"
                                    className="product-item"
                                    key={i}
                                    value={product.id}
                                  />{" "}
                                  <span className="pr-3">
                                    &nbsp;{product.name}
                                  </span>
                                  <span className="pr-1">
                                    &nbsp; &nbsp;${product.amount}
                                  </span>
                                  {frequency !== "" && (
                                    <span className="pr-3">
                                      (Recurring -&nbsp;{frequency})
                                    </span>
                                  )}
                                  <br />
                                </div>
                              );
                            })}
                            {this.state.errors.product !== "" && (
                              <span className="m-form__help w-100  text-danger">
                                {this.state.errors.product}
                              </span>
                            )}
                            <hr />
                            <div
                              id={this.state.stripe_account}
                              className="content-wrapper "
                            >
                              {this.state.stripe_account !== null ? (
                                <StripeProvider
                                  apiKey={
                                    process.env
                                      .REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                                    "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
                                  }
                                >
                                  <Elements>
                                    <CheckoutForm
                                      termMessage={this.state.terms_conditions}
                                      handleSubmit={this.handleSubmit}
                                      isLoading={this.state.isLoading}
                                      error={this.state.err_message}
                                    />
                                  </Elements>
                                </StripeProvider>
                              ) : (
                                <p className="text-center">
                                  Please create stripe account.
                                </p>
                              )}
                            </div>
                          </form>
                          {/*{(this.state.err_message !== "") &&*/}
                          {/*<p className="error_message">{this.state.err_message}</p>}*/}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-4" id="onboard-card">
                      <p>{this.state.empty_organisation_message}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal fade" id="modal-login">
          <div className="modal-dialog modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <p className="onboard-heading">Login to Cloud Collect</p>
                <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body"> */}
        <Login
          {...this.props}
          show={this.state.show}
          hideModal={this.hideModal}
        />
        {/* </div>
              <hr />
            </div>
          </div>
        </div> */}
        <div className="modal fade" id="modal-tcs">
          <div className="modal-dialog modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <p className="onboard-heading">Terms & Conditions</p>
                <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.termData}</p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { login } = authAction;
const mapDispatchToProps = { login };

const mapStateToProps = (state) => {
  return {
    userInfo: state.Auth.get("user"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
