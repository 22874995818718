import actionTypes from "./actions";


const initialState = {
  businessUsers: [],
  myBusinessInvites: [],
  myBusinessOrganisations: [],
  loading: false,
  singleBusinessUser: {
    
  }
};

const businessUser = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_ALL_BUSINESS_USERS_SUCCESS:
      return {
        ...state,
        businessUsers: payload
      };
    case actionTypes.FETCH_MY_BUSINESS_INVITES_SUCCESS:
      return {
        ...state,
        myBusinessInvites: payload
      };
    case actionTypes.FETCH_ALL_BUSINESS_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        myBusinessOrganisations: payload
      };

    case actionTypes.FETCH_SINGLE_BUSINESS_USER_SUCCESS:
      return {
        ...state,
        singleBusinessUser: payload
      };

    case actionTypes.CREATE_BUSINESS_USER_SUCCESS:
      return {
        ...state,
        businessUsers: state.businessUsers.push(payload)
      };

    default:
      return state;
  }
};

export default businessUser;
