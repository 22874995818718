import React, { Component } from "react";
import SuperFetch from "../../../helpers/superFetch";
import { store } from "react-notifications-component";
import { getUser } from "../../../helpers/utility";

class AddProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      isLoading: false,
      name: "",
      amount: "",
      description: "",
      type: "Single",
      checkbox: true,
      frequency: "Weekly",
      name_err: "",
      amount_err: "",
      duration: "",
      payment_in: "",
      upfront_payment: false,
      gst_free: false,
    };
  }

  handleUserInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkBox(e) {
    this.setState({
      checkbox: !this.state.checkbox,
    });
  }

  checkHidden() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleSubmit = async (e) => {
    const { hideModal, addedProduct } = this.props;
    if (this.state.type === "Single") {
      this.setState({ frequency: "" });
    }
    e.preventDefault();
    let valid = await this.validate();

    if (!valid) {
      this.setState({ isLoading: true });
      SuperFetch.post("/products", this.singleOrRecuring())
        .then((response) => {
          addedProduct(response.data);
          this.setState({ isLoading: false });
          hideModal();
          store.addNotification({
            message: "Product added successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          //   SuperFetch.get("/products").then((response) => {
          //     this.props.history.push({
          //       state: { detail: response.data },
          //     });
          //   });
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  validate = async () => {
    let isError = false;
    const errors = {
      name_err: "",
      amount_err: "",
      description_err: "",
    };

    if (this.state.name === "") {
      isError = true;
      errors.name_err = "Please enter Product ID";
    } else {
      const res = await SuperFetch.get(
        `/products/validate?name=${this.state.name}`
      );
      if (res && !res.valid) {
        isError = true;
        errors.name_err = "Product ID already taken.";
      } else {
        errors.name_err = "";
      }
    }

    if (this.state.amount === "") {
      isError = true;
      errors.amount_err = "Please enter amount.";
    }
    if (this.state.description === "") {
      isError = true;
      errors.description_err = "Please enter description.";
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  checkGstFree(e) {
    this.setState({ gst_free: e.target.value });
  }

  upfrontPaymentValue() {
    let upfront_payment = null;
    if (this.state.checkbox === true) upfront_payment = 1;
    if (this.state.checkbox === false) upfront_payment = 0;
    return upfront_payment;
  }

  hiddenValue() {
    let hidden = null;
    if (this.state.hidden === true) hidden = 1;
    if (this.state.hidden === false) hidden = 0;
    return hidden;
  }

  gstFreeValue() {
    let gstFree = null;
    if (this.state.gst_free === "true") gstFree = 1;
    if (this.state.gst_free === "false") gstFree = 0;
    return gstFree;
  }

  singleOrRecuring() {
    const userinfo = getUser();
    const data = {
      name: this.state.name,
      amount: this.state.amount,
      type: this.state.type,
      description: this.state.description,
      upfront_payment: this.upfrontPaymentValue(),
      duration: this.state.duration,
      payment_in: this.state.payment_in,
      userId: userinfo.id,
      organisation_id: userinfo.organisation.id,
      hidden: this.hiddenValue(),
      gst_free: this.gstFreeValue(this.state.gst_free),
    };
    if (this.state.type !== "Single") data.frequency = this.state.frequency;
    return data;
  }

  render() {
    const { hideModal } = this.props;

    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div class="col-md-8 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Product ID</div>
            </div>
            <input
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => this.handleUserInput(e)}
              id="inlineFormInputGroup"
            />
            {this.state.name_err ? (
              <span className="m-form__help w-100 d-inline-block text-danger">
                {this.state.name_err}
              </span>
            ) : null}
          </div>
        </div>

        <div class="col-md-8 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Unit Price</div>
            </div>
            <input
              type="number"
              step="0.01"
              name="amount"
              className="form-control"
              id="inlineFormInputGroup"
              onChange={(e) => this.handleUserInput(e)}
            />
            {this.state.amount_err ? (
              <span className="m-form__help w-100 d-inline-block text-danger">
                {this.state.amount_err}
              </span>
            ) : null}
          </div>
          <span>Input GST-Exclusive Unit Price</span>
        </div>

        <div class="col-md-8 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">GST Code</div>
            </div>
            <select
              style={{ height: "45px" }}
              class="form-control"
              id="exampleFormControlSelect1"
              onChange={this.checkGstFree.bind(this)}
              name="gst_free"
            >
              <option value={false}>GST</option>
              <option value={true}>GST-Free</option>
            </select>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="exampleFormControlTextarea1"></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">Description</div>
              </div>
              <textarea
                onChange={(e) => this.handleUserInput(e)}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="8"
                name="description"
              ></textarea>
              {this.state.description_err ? (
                <span className="m-form__help w-100 d-inline-block text-danger">
                  {this.state.description_err}
                </span>
              ) : null}
            </div>
          </div>
        </div>

        {/* <div className="form-group">
          <label>Type</label>
          <select
            style={{ height: "44.44px" }}
            className="form-control"
            name="type"
            onChange={(e) => this.handleOptionChange(e)}
          >
            <option value="Single">Regular</option>
            <option value="Recurring">Recurring</option>
          </select>
        </div> */}
        {/* {this.state.type === "Single" ? (
          <div className="form-group">
            <div style={{ marginLeft: "0" }} className="row">
              <label className="mb-3 w-100 d-inline-block">
                Upfront Payment:
              </label>
              <div className="form-check col-4">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.checkBox.bind(this)}
                    className="form-check-input"
                    name="optradio"
                    value="Yes"
                    checked={this.state.checkbox}
                  />
                  Yes
                </label>
              </div>
              <div className="form-check col-4">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.checkBox.bind(this)}
                    className="form-check-input"
                    name="optradio"
                    value="No"
                  />
                  No
                </label>
              </div>
            </div> */}
        {/* {!this.state.checkbox ? (
              <div className="mt-2 form-group">
                <label>Payment In:</label>
                <input
                  type="number"
                  name="payment_in"
                  className="form-control"
                  placeholder="Payment In"
                  onChange={(e) => this.handleOptionChange(e)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            <div className="form-group">
              <label>Frequency:</label>
              <select
                style={{ height: "45.44px" }}
                className="form-control"
                name="frequency"
                onChange={(e) => this.handleOptionChange(e)}
              >
                <option value="Weekly">Weekly</option>
                <option value="Forthrightly">Forthrightly</option>
                <option value="Monthly">Monthly</option> */}
        {/*<option value="Quarterly">Quarterly</option>*/}
        {/*<option value="Annually">Annually</option>*/}
        {/* </select>
            </div>
            <div className="form-group">
              <label>Duration:</label>
              <input
                type="number"
                name="duration"
                onChange={(e) => this.handleOptionChange(e)}
                className="form-control"
              />
            </div>
          </>
        )} */}
        {/* <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              name="hidden"
              value={this.state.hidden}
              onChange={this.checkHidden.bind(this)}
              className="custom-control-input"
              id="customCheck2"
            />
            <label
              className="custom-control-label custom-control-invoice"
              htmlFor="customCheck2"
            >
              Public Product
            </label>
          </div>
        </div> */}
        <hr />
        <div class="invoice-buttons text-center">
          <button type="submit" class="btn btn-success">
            Save
          </button>
          <button
            type="button"
            onClick={hideModal}
            class="btn btn-secondary ml-2"
          >
            Cancel
          </button>
        </div>
        {/* <div className="form-group" style={{ textAlign: "center" }}>
          <button className="btn btn-primary">Submit</button>
        </div> */}
      </form>
    );
  }
}

export default AddProductModal;
