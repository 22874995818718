import React, { Component } from "react";
import { required } from "redux-form-validators";
import { reduxForm, Field, Form } from "redux-form";

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

class InvoiceSetting extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              name="due_date_threshold"
              label="Default Due Date"
              component={renderField}
              type="text"
              id="due_date_threshold"
              validate={[required()]}
            />
            <p>Number of days after invoice date</p>
          </div>
        </div>
        <hr />
        <div class="invoice-buttons text-center">
          <button type="submit" class="btn btn-success">
            Update
          </button>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "InvoiceSettingForm",
  enableReinitialize: true,
})(InvoiceSetting);
