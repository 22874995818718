import React, { Component } from "react";
import { Link } from "react-router-dom";
import url from "../../url";
import "./reports.scss";

class ReportsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    document.title = "Cloud Collect - Reports";
  };

  render() {
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-lg-8 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card" id="card-inner-reports">
                      <div className="card-body">
                        <h5 className="card-title">Debtors</h5>
                        <p className="card-text">
                          Displays what customers owe at a specified date.
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <Link to={url.reports.debtor.debtorSummary}>
                          <li className="list-group-item">
                            View Summary Report
                          </li>
                        </Link>
                        <Link to={url.reports.debtor.debtor}>
                          <li className="list-group-item">
                            View Detailed Report
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="card" id="card-inner-reports">
                      <div className="card-body">
                        <h5 className="card-title">Income</h5>
                        <p className="card-text">
                          Displays invoices for each customer within a date
                          range.
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <Link to={url.reports.income.incomeSummary}>
                          <li className="list-group-item">
                            View Summary Report
                          </li>
                        </Link>
                        <Link to={url.reports.income.income}>
                          <li className="list-group-item">
                            View Detailed Report
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="card" id="card-inner-reports">
                      <div className="card-body">
                        <h5 className="card-title">Payments</h5>
                        <p className="card-text">
                          Displays customer payments and net payments received
                          within a date range.
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <Link to={url.reports.payment.paymentSummary}>
                          <li className="list-group-item">
                            View Summary Report
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsHome;
