import React, { Component } from "react";
import { getUser } from "../../../helpers/utility";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import SuperFetch from "../../../helpers/superFetch";
import $ from "jquery";
import DataTable from "react-data-table-component";
import NumberFormat from "react-number-format";
import { apiHost } from "../../../config";
import ConfirmComponent from "../../ConfirmComponent/ConfirmComponent";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoaded: false,
      deleteInfo: "",
      all_checked: false,
      arr: [],
      checkedProducts: [],
      deleteType: "single",
      arrayIds: "",
      showModal: false,
    };
    this.handleRemove = this.handleRemove.bind(this);
  }

  confirmDelete = (e) => {
    this.setState({
      isLoaded: false,
    });
    SuperFetch.delete("/products/" + this.state.arrayIds)
      .then((response) => {
        this.setState({
          products: this.state.products.filter(
            (products) => products.id !== this.state.deleteInfo
          ),
          isLoaded: true,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  deleteId = (id) => {
    this.setState({
      deleteInfo: id,
    });
  };

  componentDidMount() {
    document.title = "Cloud Collect - Products";
    this.actionFetch();
  }
  actionFetch() {
    SuperFetch.get("/products")
      .then((response) => {
        this.setState({
          isLoaded: true,
          products: response.data,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  handleChecked(e) {
    let id = e.target.value;
    let tmp;
    if (!this.state.checkedProducts.includes(id)) {
      tmp = this.state.checkedProducts;
      tmp.push(id);
    } else {
      tmp = [];
      for (let item of this.state.checkedProducts) {
        if (item !== id) tmp.push(item);
      }
    }
    this.setState({
      checkedProducts: tmp,
    });
  }

  handleAllCheck(e) {
    let tmp;
    if (e) {
      this.setState({
        all_checked: !this.state.all_checked,
      });
      if (!this.state.all_checked) {
        $(".custom-control-input").prop("checked", "true");
        this.state.products.map((item) => {
          if (!this.state.checkedProducts.includes(item.id)) {
            tmp = this.state.checkedProducts;
            tmp.push(item.id.toString());
          } else {
            tmp = [];
            for (let id of this.state.checkedProducts) {
              if (id !== item.id.toString()) tmp.push(id);
            }
          }
          this.setState({
            checkedProducts: tmp,
            deleteType: "bulk",
          });
        });
      } else {
        tmp = [];
        this.setState({ checkedProducts: tmp });
        $(".custom-control-input").prop("checked", "");
      }
    }
  }

  handleBulk(e) {
    let stringIds = this.state.checkedProducts.join(",");
    let ids = {
      ids: stringIds,
    };
    this.setState({
      // deleteType: 'bulk',
      arrayIds: ids,
    });
  }

  handleBulkDelete(e) {
    SuperFetch.post(`/invoices/delete-bulk`, this.state.arrayIds).then(
      (response) => {
        this.actionFetch();
        this.setState({
          checkedProducts: [],
        });
      }
    );
  }

  handleRemove(e) {
    let stringIds = this.state.checkedProducts.join(",");

    let formData = new FormData();
    formData.append("ids", stringIds);

    fetch(`${apiHost.fetchUrl}/products/delete-bulk`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + localStorage.getItem("id_token") || undefined,
      },
    }).then((response) => {
      this.setState({ showModal: false });
      this.actionFetch();
      this.setState({ checkedProducts: [] });
    });

    // Axios.post("/products/delete-bulk", formData).then((response) => {
    //   this.actionFetch();
    //   this.setState({ checkedProducts: [] });
    // });
  }

  handleEdit(id, e) {
    if (id) this.props.history.push("/products/edit/" + id);
  }

  handleRowClick = (product) => {
    this.props.history.push("/products/edit/" + product.id);
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    let ids = state.selectedRows.map((row) => row.id);
    this.setState({ checkedProducts: ids });
  };

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <div>{value}</div>}
      />
    );
  }

  render() {
    const { products, showModal } = this.state;

    const columns = [
      {
        name: "Product ID",
        selector: "name",
        cell: (row) => (
          <a
            href="javascript:void(0);"
            className="text-capitalize"
            onClick={() => this.handleRowClick(row)}
          >
            {row.name}
          </a>
        ),
        sortable: true,
      },
      {
        name: "Unit Price",
        selector: "amount",
        cell: (row) => (
          <div>{row.amount && this.amountToFixed(row.amount)}</div>
        ),
        sortable: true,
      },
      {
        name: "GST",
        selector: "gst",
        cell: (row) => {
          return (
            <div>{row.gst_free && row.gst_free === 1 ? "GST-Free" : "GST"}</div>
          );
        },
        sortable: true,
      },
      {
        name: "Description",
        selector: "description",
        sortable: true,
      },

      // {
      //   name: "Charge Type",
      //   selector: "type",
      //   cell: (row) => (
      //     <div>
      //       {row.type === "Recurring"
      //         ? `${row.type} (${row.frequency})`
      //         : `${row.type}`}
      //     </div>
      //   ),
      //   sortable: true,
      // },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    };

    return (
      <div className="main-content-inner">
        <ConfirmComponent
          title="Delete"
          body="Are you sure you want to delete these products?"
          buttonText="Delete"
          show={showModal}
          handleClose={() => this.setState({ showModal: false })}
          confirmHandler={this.handleRemove}
        />
        <div className="row">
          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9 col-sm-12 c-buttons">
                    <NavLink to={{ pathname: `/products/add` }}>
                      <button
                        id="button-float-left"
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: 15 }}
                      >
                        Add Product
                      </button>
                    </NavLink>
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="button-float-left"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        href="javascript:void(0);"
                        onClick={() => this.setState({ showModal: true })}
                      >
                        Remove
                      </a>
                    </div>
                  </div>
                </div>
                <br />
                {/* {this.state.checkedProducts.length ? (
                  <div className="position-relative">
                    <div
                      data-id=""
                      onClick={this.handleBulk.bind(this)}
                      data-toggle="modal"
                      data-target="#myModal"
                      className="row invoice-list-delete"
                    >
                      <i className="fa fa-trash"></i>
                      <span className="trash_name">archive</span>
                    </div>
                  </div>
                ) : null} */}
                <div className="single-table">
                  <div className="">
                    <DataTable
                      noHeader
                      overflowY
                      highlightOnHover
                      responsive={true}
                      title=""
                      columns={columns}
                      data={products || []}
                      defaultSortField="Reference"
                      onRowClicked={this.handleRowClick}
                      selectableRows
                      Clicked
                      onSelectedRowsChange={this.handleChange}
                      customStyles={customStyles}
                      pointerOnHover={true}
                      pagination={true}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Are you sure want Archive?</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleRemove.bind(this)}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps)(Products);
