import React, { Component } from "react";

import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

class DateDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { year: null, month: null, day: null };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.initialValues &&
      this.props.initialValues !== nextProps.initialValues
    ) {
      this.setState({
        day: parseInt(nextProps.initialValues.split("-")[0]),
        month: parseInt(nextProps.initialValues.split("-")[1]) - 1,
        year: parseInt(nextProps.initialValues.split("-")[2]),
      });
    }
  };

  render() {
    let { readOnly, selectedDate, initialValues } = this.props;
    let { year, month, day } = this.state;

    if (this.props.initialValues) {
      day = day || parseInt(this.props.initialValues.split("-")[0]);
      month = month || parseInt(this.props.initialValues.split("-")[1]) - 1;
      year = year || parseInt(this.props.initialValues.split("-")[2]);
    }

    if (year && month && day) {
      selectedDate(
        ("0" + day).slice(-2) +
          "-" +
          ("0" + (parseInt(month) + 1)).slice(-2) +
          "-" +
          year
      );
    }

    return (
      <div className="row">
        <YearPicker
          defaultValue={"Year"}
          // default is 1900
          start={1950}
          // default is current year
          end={2020}
          // default is ASCENDING
          reverse
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={year}
          // mandatory
          onChange={(year) => {
            this.setState({ year });
          }}
          id={"year"}
          name={"year"}
          classes={"form-control year-select"}
          optionClasses={"option classes"}
        />
        <MonthPicker
          defaultValue={"Month"}
          // to get months as numbers

          // default is full name
          short
          // default is Titlecase
          caps
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // mandatory
          year={year}
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={month}
          // mandatory
          onChange={(month) => {
            this.setState({ month });
          }}
          id={"month"}
          name={"month"}
          classes={"form-control month-select"}
          optionClasses={"option classes"}
        />
        <DayPicker
          defaultValue={"Day"}
          // mandatory
          year={year}
          // mandatory
          month={month}
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // default is false
          required={true}
          // default is false
          disabled={readOnly}
          // mandatory
          value={day}
          // mandatory
          onChange={(day) => {
            this.setState({ day });
          }}
          id={"day"}
          name={"day"}
          classes={"form-control day-select "}
          optionClasses={"option classes"}
        />
      </div>
    );
  }
}

export default DateDropdown;
