import React from "react";
import Autosuggest from "react-autosuggest";

export class Autosuggestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      suggestionData: [],
      keys: this.props.keys,
    };

    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
  }

  componentDidMount(){
    this.setState({ value: this.props.nameValue });
  }

  componentDidUpdate() {
    console.log(this.props.nameValue)
    if (
      (this.state.suggestionData.length === 0 &&
        this.props.suggestionData.length > 0) ||
      (this.state.suggestionData.length > 0 &&
        this.state.suggestionData !== this.props.suggestionData)
    ) {
      this.setState({
        suggestionData: this.props.suggestionData,
      });
    }
    if (this.props.nameValue && this.state.value !== this.props.nameValue)
      this.setState({ value: this.props.nameValue });
  }

  dataFilter(item, inputLength, inputValue) {
    let match = false;
    for (let key of this.state.keys) {
      if (item[key]) {
        // if((item[key].toLowerCase().slice(0, inputLength) === inputValue) && (item[key].indexOf('Add') === -1)) match = true;
        if (item[key].indexOf("Add") !== -1) match = true;
        if (item[key].toLowerCase().slice(0, inputLength) === inputValue)
          match = true;
      }
    }
    return match;
  }

  clicked = () => {
    this.props.addNewProduct();
  };

  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (this.state.keys.length > 1) {
      return inputLength === 0
        ? [{ first_name: "+Add", last_name: "New", name: "+Add New", data_id: "add-new" }]
        : this.state.suggestionData.filter((item) =>
            this.dataFilter(item, inputLength, inputValue)
          );
    }
    if (this.props.addContact) {
      return inputLength === 0
        ? [{ first_name: "+Add", last_name: "New", name: "+Add New", data_id: "add-new" }]
        : this.state.suggestionData.filter((item) =>
            this.dataFilter(item, inputLength, inputValue)
          );
    }
    if (this.props.addProduct) {
      return inputLength === 0
        ? [
            { name: "+Add New", data_id: "add-new" },
            ...this.props.suggestionData,
          ]
        : this.state.suggestionData.filter((item) =>
            this.dataFilter(item, inputLength, inputValue)
          );
    } else {
      return inputLength === 0
        ? [...this.props.suggestionData]
        : this.state.suggestionData.filter((item) =>
            this.dataFilter(item, inputLength, inputValue)
          );
    }
    // return inputLength === 0
    //   ? this.state.suggestionData.filter((item) =>
    //       this.dataFilter(item, inputLength, inputValue)
    //     )
    //   : this.state.suggestionData.filter((item) =>
    //       this.dataFilter(item, inputLength, inputValue)
    //     );
  }

  getSuggestionValue(suggestion) {
    this.props.selectValue(suggestion);
    return this.generateText(suggestion);
  }

  generateText(suggestion) {
    let res = "";
    for (let item of this.state.keys) {
      if (item !== this.state.keys[this.state.keys.length - 1])
        res += suggestion[item] + " ";
      else res += suggestion[item];
    }
    return res;
  }

  renderSuggestion = (suggestion) => {
    if (this.props.addProduct) {
      if (!suggestion.id) {
        return (
          <div onClick={this.clicked}>{this.generateText(suggestion)}</div>
        );
      } else {
        return <div>{this.generateText(suggestion)}</div>;
      }
    } else {
      return <div>{this.generateText(suggestion)}</div>;
    }
  };

  onChange = (event, { newValue }) => {
    this.props.keyupValue(newValue);
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "",
      value,
      onChange: this.onChange,
      onFocus: this.onFocus,
      style: this.props.style,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        shouldRenderSuggestions={this.renderSuggestion}
      />
    );
  }
}
