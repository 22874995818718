import actions from "./actions";

const initState = { users: [], error: null };
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
      };
    default:
      return state;
  }
}
