import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { store, history } from "./redux/store";
import App from "./container";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// @import "bootstrap/dist/css/bootstrap.min.css";

const MainApp = () => (
  <Provider store={store}>
    <div>
      <Router history={history}>
        <ReactNotification />
        <Route path={"/"} component={App} />
      </Router>
    </div>
  </Provider>
);

export default MainApp;
