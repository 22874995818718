import React, { Component } from "react";
import { connect } from 'react-redux';
import ForgotPasswordForm from './form'
import authAction from '../../../redux/auth/actions';
class ForgotPassword extends Component {

  submit = async (values, dispatch) => {
    // print the form values to the console
    const { forgotPassword, history } = this.props;

    await forgotPassword({ history, userInfo: values })
  };
  componentDidMount(){
      document.title = "Cloud Collect - Forgot Password";
  }
  render() {
    return (
      <div className='login-area'>
        <div className='container'>
          <div className='login-box ptb--100'>
            <ForgotPasswordForm onSubmit={this.submit} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}


const { forgotPassword } = authAction
const mapDispatchToProps = { forgotPassword };


const mapStateToProps = (state) => {
  return {
    error: state.Auth.get('error')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
