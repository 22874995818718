import Auth from "./auth/reducer";
import Alert from "./alert/reducer";
import Organisation from "./organisation/reducer";
import BusinessUser from "./businessUser/reducer";
import Notifications from "./notification/reducer";
import Invoices from "./invoice/reducer";
import Subscriptions from "./subscription/reducer";
import Users from "./user/reducer";
import Terms from "./terms/reducer";
import Contacts from "./contacts/reducer";
import Flow from "./flow/reducer";
import Proposals from "./Proposals/reducer";
import Product from "./Products/reducer";

export default {
  Auth,
  Alert,
  Organisation,
  BusinessUser,
  Notifications,
  Invoices,
  Proposals,
  Subscriptions,
  Users,
  Terms,
  Contacts,
  Flow,
  Product,
};
