import SuperFetch from "./superFetch";

class SubscriptionHelper {
  fetchSubscriptions = async () => {
    return await SuperFetch.get("/subscriptions")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  cancelSubscription = async (subscriptionId) => {
    return await SuperFetch.post(
      "/subscriptions/" + subscriptionId + "/change-status",
      {
        status: "Cancelled",
      }
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new SubscriptionHelper();
