const actions = {
  GET_SELECTED_CONTACT: "GET_SELECTED_CONTACT",
  GET_SINGLE_CONTACT: "GET_SINGLE_CONTACT",
  FETCH_SINGLE_CONTACT_SUCCESS: "FETCH_SINGLE_CONTACT_SUCCESS",
  CREATE_NOTE: "CREATE_NOTE",
  SET_STATE: "SET_STATE",

  selectedContact: ( payload ) => {
    return {
      type: actions.GET_SELECTED_CONTACT,
      payload,
    };
  },
  fetchSingleContact: ( payload ) => {
    return {
      type: actions.GET_SINGLE_CONTACT,
      payload,
    };
  },
  createNote: ( payload ) => {
    return {
      type: actions.CREATE_NOTE,
      payload,
    };
  },
};
export default actions;
