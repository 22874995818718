import React from "react";
import url from "./url";
import Customers from "./components/Customers";
import { Route, Redirect, withRouter } from "react-router-dom";

import Debtor from "./components/Reports/Debtor";
import Performance from "./components/Reports/Performance";
import Earnings from "./components/Reports/Earnings";

import Files from "./components/Utilities/Files";
import Flow from "./components/Utilities/Flow";
import Lead from "./components/Utilities/Leads";
import Integrations from "./components/Utilities/Integrations";
import Organisation from "./components/Utilities/Organisation";
import Products from "./components/Utilities/Products/index";
import ProductsAdd from "./components/Utilities/Products/add";
import ProductsEdit from "./components/Utilities/Products/edit";
import Templates from "./components/Utilities/Templates";
import Login from "./components/Account/Login";
import ForgotPassWord from "./components/Account/Password/ForgotPassword";
import Register from "./components/Account/Register/index.js";
import ResetPassword from "./components/Account/ResetPassword/index.js";
import Customer from "./components/Customer/registration";
import Dashboard from "./components/Dashboard/index";
import MyProfile from "./components/Customer/MyProfile/index";
import CustomerInvoice from "./components/Customer/Invoice/index";
import CustomerSubscription from "./components/Customer/Subscriptions/index";
import CustomerThankyou from "./components/Customer/thankyou";
import Contacts from "./components/Utilities/Contacts/contact";
import invoiceNew from "./components/Utilities/Invoice/invoiceNew";
import NewLead from "./components/Utilities/Leads/newLead";
import Proposals from "./components/Utilities/Proposals/index";
import InvoiceList from "./components/Utilities/Invoice/list";
import AddInvoice from "./components/Utilities/Invoice/add";
import EditInvoice from "./components/Utilities/Invoice/edit";
import NonEditInvoice from "./components/Utilities/Invoice/non-edit";
import AddProposal from "./components/Utilities/Proposals/add";
import EditProposal from "./components/Utilities/Proposals/edit";
import NonEditProposal from "./components/Utilities/Proposals/non-edit";
import PreviewInvoice from "./components/Utilities/Invoice/preview";
import PreviewProposal from "./components/Utilities/Proposals/preview";
import NewLeadEdit from "./components/Utilities/Leads/edit";
import UserList from "./components/Utilities/User/list";
import DeptorsSummary from "./components/Reports/Debtor/debtorsSummary";
import ReportIncome from "./components/Reports/Income/reportIncome";
import IncomeSummary from "./components/Reports/Income/incomeSummary";
import ReoprtPayment from "./components/Reports/Payment/reportPayment";
import PaymentSummary from "./components/Reports/Payment/paymentSummary";
import Dashboard1 from "./components/Dashboard1/index";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import ReportsHome from "./components/Reports";
import SettingsPage from "./components/Utilities/Settings";
import NotificationsPage from "./components/Utilities/Settings/Notifications/index";
import FinancePage from "./components/Utilities/Organisation/FinancePage";
import InvoiceSettings from "./components/Utilities/Organisation/InvoiceSettings";
import AccountDetails from "./components/Utilities/Settings/AccountDetails/index";

var validate = require("uuid-validate");

const AppRoutes = ({ location, match }) => {
  let userInfo = localStorage.getItem("cloud_collect_userInfo");
  let pageUrl = window.location.href;
  let tmp = pageUrl.split("/");
  let endpoint = tmp[tmp.length - 1];
  let userInfoJson = JSON.parse(userInfo);

  let role = "Customer";
  if (userInfo && userInfoJson.role) {
    if (userInfoJson.role[0].title === "Business") {
      role = "Business";
    }
  }

  return (
    <React.Fragment>
      {userInfo && role === "Business" && (
        <React.Fragment>
          {/*<Route exact path={url.customers.index} component={Customers} />*/}
          <Route exact path={url.reports.debtor.debtor} component={Debtor} />
          <Route exact path={url.reports.home} component={ReportsHome} />
          <Route
            exact
            path={url.reports.debtor.debtorSummary}
            component={DeptorsSummary}
          />
          <Route
            exact
            path={url.reports.income.income}
            component={ReportIncome}
          />
          <Route
            exact
            path={url.reports.income.incomeSummary}
            component={IncomeSummary}
          />
          <Route
            exact
            path={url.reports.payment.payment}
            component={ReoprtPayment}
          />
          <Route
            exact
            path={url.reports.payment.paymentSummary}
            component={PaymentSummary}
          />
          <Route exact path={url.reports.earnings} component={Earnings} />
          <Route exact path={url.reports.performance} component={Performance} />
          <Route exact path={url.utilities.files} component={Files} />
          <Route exact path={url.utilities.flow} component={Flow} />
          {/* <Route exact path={url.index} component={Dashboard} />
          <Route exact path={url.customers.index} component={Dashboard1} /> */}

          {/*<Route exact path={url.myProfile.myProfile} component={MyProfile} />*/}
          <Route
            exact
            path={url.utilities.integration}
            component={Integrations}
          />
          <Route
            exact
            path={url.utilities.organisation}
            component={Organisation}
          />
          <Route exact path={url.utilities.contact} component={Contacts} />
          {/*<Route exact path={url.utilities.invoice} component={Invoice} />*/}
          <Route exact path={url.utilities.invoiceNew} component={invoiceNew} />
          <Route
            exact
            path={url.utilities.invoice.list}
            component={InvoiceList}
          />
          <Route
            exact
            path={url.utilities.invoice.add}
            component={AddInvoice}
          />
          <Route
            exact
            path={url.utilities.invoice.add + "/:id"}
            component={AddInvoice}
          />
          <Route
            exact
            path={url.utilities.invoice.add + "/product/:products"}
            component={AddInvoice}
          />
          <Route
            exact
            path={url.utilities.invoice.edit}
            component={EditInvoice}
          />
          <Route
            exact
            path={url.utilities.invoice.nonEdit}
            component={NonEditInvoice}
          />
          {/*<Route exact path={url.utilities.invoice.preview} component={PreviewInvoice} />*/}
          <Route
            exact
            path={url.utilities.proposal.index}
            component={Proposals}
          />
          <Route
            exact
            path={url.utilities.proposal.add}
            component={AddProposal}
          />
          <Route
            exact
            path={url.utilities.proposal.add + "/product/:products"}
            component={AddProposal}
          />
          <Route
            exact
            path={url.utilities.proposal.add + "/:id"}
            component={AddProposal}
          />

          <Route
            exact
            path={url.utilities.proposal.edit}
            component={EditProposal}
          />
          <Route
            exact
            path={url.utilities.proposal.nonEdit}
            component={NonEditProposal}
          />
          <Route exact path={url.utilities.settings} component={SettingsPage} />
          <Route
            exact
            path={url.utilities.notifications}
            component={NotificationsPage}
          />
          <Route exact path={url.utilities.finance} component={FinancePage} />
          <Route exact path={url.utilities.invoice_settings} component={InvoiceSettings} />
          <Route exact path={url.utilities.accountDetails} component={AccountDetails} />
          {/*<Route exact path={url.utilities.proposal.preview} component={PreviewProposal} />*/}
          <Route
            exact
            path={url.utilities.products.index}
            component={Products}
          />
          <Route
            exact
            path={url.utilities.products.add}
            component={ProductsAdd}
          />
          <Route
            exact
            path={url.utilities.products.edit}
            component={ProductsEdit}
          />
          <Route exact path={url.utilities.templates} component={Templates} />
          <Route exact path={url.utilities.lead} component={Lead} />
          <Route exact path={url.utilities.editLead} component={NewLeadEdit} />
          <Route exact path={url.utilities.newLead} component={NewLead} />
          {/* <Route
            exact
            path={url.utilities.proposal.preview}
            component={PreviewProposal}
          />
          <Route
            exact
            path={url.utilities.invoice.preview}
            component={PreviewInvoice}
          /> */}

          {/* invited business users */}
          <Route exact path={url.utilities.users.list} component={UserList} />
        </React.Fragment>
      )}
      <Route exact path={url.customer.registration} component={Customer} />
      <Route
        exact
        path={url.customer.registration + "/:code"}
        component={Customer}
      />

      <Route exact path={url.customer.thankyou} component={CustomerThankyou} />
      {userInfo && role === "Customer" && (
        <React.Fragment>
          <Route
            exact
            path={url.customer.myProfile.myProfile}
            component={MyProfile}
          />
          <Route
            exact
            path={url.customer.myProfile.myInvoice}
            component={CustomerInvoice}
          />
          <Route
            exact
            path={url.customer.mySubscriptions}
            component={CustomerSubscription}
          />
        </React.Fragment>
      )}

      {validate(
        location.pathname.split("/")[location.pathname.split("/").length - 1]
      ) ? (
        <>
          <Route
            exact
            path={url.utilities.proposal.preview}
            component={PreviewProposal}
          />
          <Route
            exact
            path={url.utilities.invoice.preview}
            component={PreviewInvoice}
          />
        </>
      ) : null}

      {location.pathname.includes("/terms-and-conditions") ? (
        <>
          <Route
            exact
            path={url.termsConditions.display}
            component={TermsConditions}
          />
        </>
      ) : null}

      {!userInfo && (
        <React.Fragment>
          <Route path={url.account.register} component={Register} />
          <Route exact path={url.account.login} component={Login} />
          <Route
            exact
            path={url.account.forgotPassword}
            component={ForgotPassWord}
          />

          <Route
            exact
            path={url.account.resetPassword}
            component={ResetPassword}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(AppRoutes);
