import React, { useState } from "react";
import moment from "moment";

const formatLogs = (logs) => {
  const formattedLogs = logs.map((log) => {
    return {
      ...log,
      created_at: moment(log.created_at).format("DD/MM/YYYY"),
    };
  });

  return formattedLogs;
};

const HistorySection = ({ logs }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="row">
      <div className="col-lg-6 mt-3">
        <p>
          History -{" "}
          {show ? (
            <span style={{ cursor: "pointer" }} onClick={() => setShow(false)}>
              Hide
            </span>
          ) : (
            <span style={{ cursor: "pointer" }} onClick={() => setShow(true)}>
              Show
            </span>
          )}
        </p>
        {logs &&
          show &&
          formatLogs(logs).map((log) => (
            <p className="text-history">
              {log.created_at} - {log.description}
            </p>
          ))}
      </div>
    </div>
  );
};

export default HistorySection;
