import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { CardElement, injectStripe } from "react-stripe-elements";
import url from "../../../url";
import Alert from "../../uielements/alert";
import { renderCheckBox } from "../../uielements/FormFields";
import { plans } from "../../../_methods/commonMethods";
import { connect } from "react-redux";

const shouldError = (values) => {
  if (
    values.nextProps &&
    values.nextProps.serverErrors !== values.props.serverErrors
  ) {
    return true;
  }
  return false;
};

const validate = (values, props) => {
  const errors = {};
  // if (!values.first_name) {
  //   errors.first_name = "First name is required";
  // } else if (values.first_name.length > 150) {
  //   errors.first_name = "Must be 150 characters or less";
  // }
  // if (!values.last_name) {
  //   errors.last_name = "Last name is required";
  // } else if (values.last_name.length > 150) {
  //   errors.last_name = "Must be 150 characters or less";
  // }
  if (!values.business_name) {
    errors.business_name = "Business name is required";
  } else if (values.business_name.length > 150) {
    errors.business_name = "Must be 150 characters or less";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password should be min 6 character";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.termsAndConditions) {
    errors.termsAndConditions = "Please accept our terms and conditions.";
  }

  if (values.plan === "Standard" && !values.card_holder_name) {
    errors.card_holder_name = "Customer Name is required.";
  }

  if (!values.plan) {
    errors.plan = "Please select a plan.";
  }

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  id,
  meta: { touched, error, warning },
}) => (
  <>
    <input
      {...input}
      className="form-control"
      id={id}
      placeholder={label}
      type={type}
    />
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </>
);

const renderRadios = (props) => {
  return (
    <>
      {props.plans.map((plan) => (
        <div key={plan.value}>
          <div className="form-check">
            <input
              {...props.input}
              value={plan.value}
              className="form-check-input"
              id={plan.label}
              type={"radio"}
              checked={plan.checked}
              // checked={props.input.value === "Freemium" ? true : false}
            />
            <label htmlFor={plan.label}>{plan.label}</label>
          </div>
        </div>
      ))}
      {props.meta.touched &&
        ((props.meta.error && (
          <span className="error">{props.meta.error}</span>
        )) ||
          (props.meta.warning && (
            <span className="warning">{props.meta.warning}</span>
          )))}
      <br />
    </>
  );
};

let RegisterForm = (props) => {
  const {
    handleSubmit,
    pristine,
    history,
    register,
    showPassword,
    form,
  } = props;

  const [stripeCardObject, setStripeCardObject] = useState({});

  const onSubmit = async (values) => {
    if (values.plan === "Standard") {
      const { token } = await props.stripe.createToken({
        name: values.card_holder_name,
      });

      if (token) {
        register({ history, userInfo: { ...values, stripe_token: token.id } });
      }
    } else {
      register({ history, userInfo: values });
    }
  };

  return (
    <form
      onSubmit={handleSubmit((values) => onSubmit(values))}
      className="login-form"
    >
      <div className="login-form-head">
        <h2 className="login-heading-1">Cloud Collect</h2>
        <h2 className="login-heading-2">Register</h2>
      </div>
      <div className="login-form-body">
        <div className="form-group checks">
          <h5 className="text-center">Start your 30 day free trial</h5>
          <br />

          <p className="text-center">
            <i className="fa fa-check"></i> Send unlimited invoices & proposals
          </p>

          <p className="text-center">
            <i className="fa fa-check"></i> No credit card required & cancel
            anytime
          </p>
        </div>
        <div className="row">
          <Alert />
        </div>
        {/* <div className="form-group">
          <Field
            label="First Name"
            name="first_name"
            component={renderField}
            type="text"
            id="input-userfirstname"
          />
        </div>
        <div className="form-group">
          <Field
            label="Last Name"
            name="last_name"
            component={renderField}
            type="text"
            id="input-userlastname"
          />
        </div> */}
        <div className="form-group">
          <Field
            label="Email Address"
            name="email"
            component={renderField}
            type="email"
            id="input-useremail"
          />
        </div>
        <div className="form-group" style={{ position: "relative" }}>
          <Field
            label="Password"
            name="password"
            component={renderField}
            type="password"
            id="input-password"
          />
          <i
            onClick={showPassword}
            className="fa fa-eye password-show"
            // onclick='showPassword()'
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <div className="form-group">
          <Field
            label="Business/Sole Trader Name"
            name="business_name"
            component={renderField}
            type="text"
            id="input-businessname"
          />
        </div>
        {/* <div className="form-group">
          <Field
            label="Business ABN (Optional)"
            name="abn"
            component={renderField}
            type="text"
            id="input-businessname"
          />
        </div> */}

        {/* <p className="onboard-heading">Subscription</p>
        <fieldset className="form-group">
          <Field
            name="plan"
            component={renderRadios}
            type="radio"
            plans={plans}
          />
        </fieldset> */}

        {/* {form &&
          form.register &&
          form.register.values &&
          form.register.values.plan === "Standard" && (
            <>
              <div className="form-group">
                <Field
                  label="Card Holder Name"
                  name="card_holder_name"
                  component={renderField}
                  type="text"
                  // id="input-useremail"
                />
              </div>
              <div class="form-group">
                <label for="example-text-input" class="col-form-label">
                  Enter Credit Card Details
                </label>
                <CardElement
                  hidePostalCode={true}
                  onChange={(obj) => {
                    setStripeCardObject(obj);
                  }}
                />
              </div>
            </>
          )} */}
        <div className="" style={{ marginLeft: "22px" }}>
          <Field
            label={
              <p>
                I agree to Cloud Collect's{" "}
                <a href="https://cloudcollect.io/terms" target="_blank">
                  Terms of Use.
                </a>
              </p>
            }
            name="termsAndConditions"
            component={renderCheckBox}
            type="checkbox"
            requiredError="Accept our terms and conditions"
            id="termsAndConditions"
          />
        </div>
        <div className="submit-btn-area">
          <button id="form_submit" type="submit">
            Get Started
          </button>
        </div>
        <br />
        <p class="text-center">
          <Link to={url.account.login}>Already have an account? Log in.</Link>
        </p>
        {/* <div className="submit-btn-area">
          <Link to={url.account.login}>
            <button type="button">Return to login</button>
          </Link>
        </div> */}
        <br />
        <p class="text-center">
          {/* <a href="https://www.cloudcollect.io">Return to Cloud Collect Home</a> */}
        </p>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

RegisterForm = reduxForm({
  // a unique name for the form
  form: "register",
  validate,
  // shouldError
})(injectStripe(connect(mapStateToProps)(RegisterForm)));

export default RegisterForm;
