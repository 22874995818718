import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderCheckBox } from "../../../uielements/FormFields";

class NotificationsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, initialValues } = this.props;

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-lg-3 mt-3">
            <div className="card">
              <div className="card-body">
                <p>
                  Select which events send a notification to your business email
                  address.
                </p>

                <div className="col-md-12 mt-3">
                  <form onSubmit={handleSubmit}>
                    <fieldset className="form-group">
                      <div className="form-check">
                        <Field
                          name="invoice_payment_notification"
                          label="Invoice Payment"
                          component={renderCheckBox}
                          id="invoice_payment_notification"
                          checked={
                            initialValues &&
                            initialValues.invoice_payment_notification
                          }
                        />
                      </div>
                      <br />
                      <div className="form-check">
                        <Field
                          name="proposal_accepted_notification"
                          label="Proposal Accepted"
                          component={renderCheckBox}
                          id="proposal_accepted_notification"
                          checked={
                            initialValues &&
                            initialValues.proposal_accepted_notification
                          }
                        />
                      </div>
                      <br />
                      <div className="form-check">
                        <Field
                          name="plan_cancelled_notification"
                          label="Payment Plan Cancelled"
                          component={renderCheckBox}
                          id="plan_cancelled_notification"
                          checked={
                            initialValues &&
                            initialValues.plan_cancelled_notification
                          }
                        />
                      </div>
                      <br />
                      {/*<div className="form-check">*/}
                      {/*  <Field*/}
                      {/*    name="portal_submission_notification"*/}
                      {/*    label="Checkout Purchase"*/}
                      {/*    component={renderCheckBox}*/}
                      {/*    id="portal_submission_notification"*/}
                      {/*    checked={*/}
                      {/*      initialValues &&*/}
                      {/*      initialValues.portal_submission_notification*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "NotificationsForm",
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    props.submit();
  },
})(NotificationsForm);
