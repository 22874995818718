import React from "react";
import SuperFetch from "../../../helpers/superFetch";
import DatePicker from "react-datepicker";
import $ from "jquery";
import DataTable from "react-data-table-component";
import moment from "moment";
import { store } from "react-notifications-component";
import NumberFormat from "react-number-format";
import { apiSocketHost } from "../../../config";
import { withRouter } from "react-router";
import organisationAction from "../../../redux/organisation/actions";
import { getUser } from "../../../helpers/utility";
import { connect } from "react-redux";

class Proposal extends React.Component {
  listRow = {
    reference: "",
    name: "",
    product: "",
    amount: "",
    paid_date: "",
    duo_date: "",
    status: "",
    created_at: "",
    checkedProposals: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      allData: [],
      proposal_date: "",
      proposal_due_date: "",
      arr: [],
      delete_id: "",
      checkedProposals: [],
      deleteType: "single",
      arrayIds: "",
      send_message: "",
      all_checked: false,
      selectedRows: [],
      filter: "active",
      keyword: "",
      // filter: "all",
    };
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeleteId = this.handleDeleteId.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleBulk = this.handleBulk.bind(this);
    this.handleBulkDelete = this.handleBulkDelete.bind(this);
    this.handleAllCheck = this.handleAllCheck.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Proposals";
    this.actionFetch();
    const { currentUser } = this.props;
    this.props.fetchSingleOrganisation({
      id: currentUser.default_organisation_id,
    });
  }

  handleChecked(e) {
    let id = e.target.value;
    let tmp;
    if (!this.state.checkedProposals.includes(id)) {
      tmp = this.state.checkedProposals;
      tmp.push(id);
    } else {
      tmp = [];
      for (let item of this.state.checkedProposals) {
        if (item !== id) tmp.push(item);
      }
    }
    this.setState({
      checkedProposals: tmp,
    });
  }

  handleBulk(e) {
    let stringIds = this.state.checkedProposals.join(",");
    let ids = {
      ids: stringIds,
    };
    this.setState({
      deleteType: "bulk",
      arrayIds: ids,
    });
  }

  handleBulkDelete(e) {
    SuperFetch.post("/proposals/archive-bulk", this.state.arrayIds).then(
      (response) => {
        store.addNotification({
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        this.actionFetch();
        this.setState({
          checkedProposals: [],
        });
      }
    );
  }

  handleAllCheck(e) {
    let tmp;
    if (e) {
      this.setState({
        all_checked: !this.state.all_checked,
      });
      if (!this.state.all_checked) {
        $(".custom-control-input").prop("checked", "true");
        this.state.arr.map((item) => {
          if (!this.state.checkedProposals.includes(item.id)) {
            tmp = this.state.checkedProposals;
            tmp.push(item.id.toString());
          } else {
            tmp = [];
            for (let id of this.state.checkedProposals) {
              if (id !== item.id.toString()) tmp.push(id);
            }
          }
          this.setState({
            checkedProposals: tmp,
          });
        });
      } else {
        tmp = [];
        this.setState({ checkedProposals: tmp });
        $(".custom-control-input").prop("checked", "");
      }
    }
  }

  handleSelect(e) {
    this.setState({ filter: e.target.value });
    if (e.target.value === "all") {
      this.setState({ allData: this.state.arr });
    }
    if (e.target.value === "archived") {
      SuperFetch.get("/proposals?status=Archived").then((response) => {
        this.setState({
          allData: response.data,
        });
      });
    }
    if (e.target.value) {
      let updatedList = this.state.arr;
      updatedList = updatedList.filter(function (item) {
        return (
          item.status.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });
      this.setState({ allData: updatedList });
      if (e.target.value === "active")
        this.setState({
          allData: this.state.arr.filter((i) => i.status !== "Accepted"),
        });
      if (e.target.value === "all")
        this.setState({
          allData: this.state.arr,
        });
    }
  }

  handleFilter(e) {
    this.setState(
      {
        keyword: e.target.value,
      },
      () => {
        this.handleFilterDate();
      }
    );
  }

  handleDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState(
      {
        proposal_date: date,
      },
      () => {
        this.handleFilterDate();
      }
    );
  }

  handleDueDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState(
      {
        proposal_due_date: date,
      },
      () => {
        this.handleFilterDate();
      }
    );
  }

  async handleFilterDate() {
    let allData = JSON.parse(JSON.stringify(this.state.arr));
    let data = allData;
    let filter = this.state.filter;

    if (filter === "Archived") {
      data = await SuperFetch.get("/proposals?status=Archived");
    }
    if (this.state.proposal_date) {
      data = allData.filter((item) => {
        let filterDate = this.state.proposal_date;
        filterDate.setHours(0, 0, 0, 0);
        if (item.proposal_date !== null) {
          let proposalDate = item.proposal_date.split("-");
          proposalDate =
            proposalDate[2] + "-" + proposalDate[1] + "-" + proposalDate[0];
          proposalDate = new Date(proposalDate);
          proposalDate.setHours(0, 0, 0, 0);
          return proposalDate.getTime() >= filterDate.getTime();
        }
      });
    }
    if (this.state.proposal_date && this.state.proposal_due_date) {
      data = allData.filter((item) => {
        let filterDate = this.state.proposal_date;
        let filterDateTo = this.state.proposal_due_date;
        if (item.proposal_date !== null) {
          let proposalDate = item.proposal_date.split("-");
          proposalDate =
            proposalDate[2] + "-" + proposalDate[1] + "-" + proposalDate[0];
          proposalDate = new Date(proposalDate);
          proposalDate.setHours(0, 0, 0, 0);
          return (
            proposalDate.getTime() >= filterDate.getTime() &&
            proposalDate.getTime() <= filterDateTo.getTime()
          );
        }
      });
    }

    let keyword = this.state.keyword;

    if (keyword && keyword !== "")
      data = data.filter(function (item) {
        return (
          item.lead.full_name.toLowerCase().search(keyword.toLowerCase()) !==
            -1 ||
          (item.proposal_reference &&
            item.proposal_reference.toString().includes(keyword.toString())) ||
          item.total.toString().search(keyword) !== -1
        );
      });

    if (filter && filter !== "") {
      if (filter !== "all")
        data = data.filter(function (item) {
          return item.status.toLowerCase().search(filter.toLowerCase()) !== -1;
        });
    }
    this.setState({ allData: data });
  }

  toSimpleDate(data) {
    let tmp = data.split(" ")[0];
    tmp = tmp.split("-");
    return tmp[2] + "-" + tmp[1] + "-" + tmp[0];
  }

  invoiceAction(proposal, e) {
    let status = proposal.status;
    if (status === "Draft")
      this.props.history.push("/proposal/edit/" + proposal.id);
    if (status === "Approved" || status === "Accepted" || status === "Declined")
      this.props.history.push("/proposal/non-edit/" + proposal.id);
  }

  handleDeleteId(id, e) {
    this.setState({
      delete_id: id,
      deleteType: "single",
    });
  }
  handleRemove(e) {
    if (this.state.deleteType === "single") {
      SuperFetch.delete("/proposals/" + this.state.delete_id).then(
        (response) => {
          if (response) this.actionFetch();
        }
      );
    } else this.handleBulkDelete();
  }

  formattedDate = (date) => {
    if (!date) {
      return;
    }
    const time = date.split("-");

    return moment(`${time[1] + "/" + time[0] + "/" + time[2]}`).unix();
  };

  getMakeData(data) {
    let array = [];
    let obj = {};
    data.map((element) => {
      obj = {
        id: element.id,
        reference: element.proposal_reference,
        name: element.lead.full_name,
        amount: element.total,
        proposal_date: element.proposal_date,
        paid_date: element.proposal_date,
        duo_date: element.proposal_expiry_date,
        expiry_date: element.proposal_expiry_date,
        url: element.url,
        status:
          // moment(new Date()).unix() >
          // moment(this.formattedDate(element.proposal_expiry_date)).unix()
          //   ? "Expired"
          //   :
          element.status,
        created_at: this.toSimpleDate(element.created_at),
      };
      array.push(obj);
    });
    return array;
  }

  actionFetch() {
    SuperFetch.get("/proposals").then((response) => {
      this.setState({
        arr: response.data,
        allData: response.data.filter((i) => i.status !== "Accepted"),
      });
    });
  }
  changeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  handleRowClick = (proposal) => {
    this.props.history.push("/proposal/non-edit/" + proposal.id);
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    let ids = state.selectedRows.map((row) => row.id);
    this.setState({
      checkedProposals: ids,
      selectedRows: state.selectedRows,
    });
  };

  handleSend = (email_only) => {
    let sendData = this.state.checkedProposals;
    let postData = {
      message: this.state.send_message,
      include_pdf: "1",
      send_sms: "1",
    };

    if (email_only) {
      postData.send_sms = 0;
    }

    if (sendData.length === 0) return;
    else
      SuperFetch.post("/proposals/send-multiple/" + sendData, postData)
        .then((response) => {
          store.addNotification({
            message: response.success,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          return response;
        })
        .catch((error) => {
          store.addNotification({
            message:
              "Proposal can not be sent right now. Please try after some time.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
  };

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <div>{value}</div>}
      />
    );
  }

  handleDownloadPdf = () => {
    const { selectedRows } = this.state;

    let uuids = "";
    for (let row of selectedRows) {
      if (uuids.length === 0) {
        uuids = row.uuid;
      } else {
        uuids += "," + row.uuid;
      }
    }

    if (uuids) {
      window.open(
        apiSocketHost.fetchUrl + "/proposal/multiple-pdf/" + uuids,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  handleCopy = async () => {
    const { selectedRows } = this.state;

    const selected = selectedRows.reduce(
      (obj, item) => Object.assign(obj, { ...item }),
      {}
    );

    await localStorage.setItem(
      "copy_proposals",
      JSON.stringify(selected.items)
    );

    setTimeout(() => {
      this.props.history.push(`/proposal/add/product?products=copy`);
    }, 100);
  };

  render() {
    const { checkedProposals } = this.state;

    const columns = [
      {
        name: "Reference",
        selector: "proposal_reference",
        cell: (row) => <div> {row.proposal_reference}</div>,
        sortable: true,
      },
      {
        name: "Name",
        selector: "lead.full_name",
        cell: (row) => (
          <div>
            {row.lead
              ? row.lead.type && row.lead.type === "Business/Organisation"
                ? row.lead.company_name
                : row.lead.full_name
              : ""}
          </div>
        ),
        sortable: true,
      },
      {
        name: "Amount",
        selector: "total",
        cell: (row) => <div>{this.amountToFixed(row.total)}</div>,
        sortable: true,
      },
      {
        name: "Proposal Date",
        selector: "proposal_date_formatted",
        cell: (row) => <div>{row.proposal_date}</div>,
        sortable: true,
      },
      {
        name: "Expiry Date",
        selector: "proposal_expiry_date_formatted",
        cell: (row) => <div>{row.proposal_expiry_date}</div>,
        sortable: true,
      },

      {
        name: "Status",
        selector: "status",
        sortable: true,
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    };

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9 col-sm-12 c-buttons">
                    <div className="input-group">
                      <button
                        id="button-float-left"
                        style={{ marginRight: 15 }}
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.props.history.push("/proposal/add")}
                      >
                        New Proposal
                      </button>
                      <div className="">
                        <button
                          className="btn btn-secondary "
                          type="button"
                          id="button-float-left"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            onClick={() => this.handleSend(true)}
                            className="dropdown-item"
                            href="javascript:void(0);"
                          >
                            Send Email
                          </a>
                          {this.props.organisationData &&
                            this.props.organisationData.stripe_customer_id && (
                              <a
                                onClick={() => this.handleSend(false)}
                                className="dropdown-item"
                                href="javascript:void(0);"
                              >
                                Send Email and SMS
                              </a>
                            )}
                          <a
                            className="dropdown-item"
                            onClick={this.handleBulk.bind(this)}
                            data-toggle="modal"
                            data-target="#myModal"
                            href="#"
                          >
                            Remove/Void
                          </a>
                          <a
                            className="dropdown-item"
                            onClick={() => this.handleDownloadPdf()}
                            href="javascript:void(0);"
                          >
                            Download PDF
                          </a>
                          {checkedProposals.length === 1 && (
                            <>
                              <a
                                className="dropdown-item"
                                onClick={this.handleCopy}
                                href="javascript:void(0);"
                              >
                                Copy
                              </a>
                            </>
                          )}
                          {/* <a
                            className="dropdown-item"
                            href="#modal-org-invoice"
                            data-toggle="modal"
                            data-target="#modal-org-invoice"
                          >
                            Import
                          </a>
                          <a
                            className="dropdown-item"
                            href={this.state.file_url}
                          >
                            Export
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row list-fields">
                  <div className="col-sm-12 col-md-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Username
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text filter-span">Search</div>
                      </div>
                      <input
                        onChange={this.handleFilter.bind(this)}
                        className="form-control"
                        type="text"
                        placeholder="Name, Reference or Amount"
                        aria-label="Search"
                      />{" "}
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text filter-span">Status</div>
                      </div>
                      <select
                        onChange={this.handleSelect.bind(this)}
                        className="form-control"
                        id="exampleFormControlSelect1"
                        style={{ height: "44px" }}
                        value={this.state.filter}
                      >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="draft">Draft</option>
                        <option value="approved">Approved</option>

                        <option value="Accepted">Accepted</option>
                        <option value="Pending Acceptance">
                          Pending Acceptance
                        </option>
                        <option value="Overdue">Expired</option>
                        <option value="Archived">Archived</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-2" id="hide-content">
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div className="input-group-text filter-span date-span">
                          Date From
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input"
                        selected={this.state.proposal_date}
                        onChange={this.handleDate}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-2 margin_top_date"
                    id="hide-content"
                  >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div className="input-group-text filter-span date-span">
                          Date To
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input"
                        selected={this.state.proposal_due_date}
                        onChange={this.handleDueDate}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                </div>
                <br />
                {/* {this.state.checkedProposals.length ? (
                  <div className="position-relative">
                    <div
                      data-id=""
                      onClick={this.handleBulk.bind(this)}
                      data-toggle="modal"
                      data-target="#myModal"
                      className="row invoice-list-delete"
                    >
                      <i className="fa fa-trash"></i>
                      <span className="trash_name">archive</span>
                    </div>
                  </div>
                ) : null} */}
                <div className="single-table" id="table-contacts">
                  <div className="">
                    <DataTable
                      noHeader
                      overflowY
                      highlightOnHover
                      responsive={true}
                      title=""
                      columns={columns}
                      data={this.state.allData || []}
                      defaultSortField="Reference"
                      onRowClicked={this.handleRowClick}
                      selectableRows
                      Clicked
                      onSelectedRowsChange={this.handleChange}
                      customStyles={customStyles}
                      pointerOnHover={true}
                      pagination={true}
                      paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Are you sure want Archive?</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleRemove.bind(this)}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchSingleOrganisation } = organisationAction;

const mapDispatchToProps = { fetchSingleOrganisation };

const mapStateToProps = (state) => {
  return {
    currentUser: getUser(),
    organisationData: state.Organisation.get("organisationData"),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Proposal));
