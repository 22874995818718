const url = {
  index: "/dashboard",
  termsConditions: { display: "/terms-and-conditions" },
  customers: {
    index: "/dashboard",
  },
  customer: {
    registration: "/:name/register",
    thankyou: "/thankyou",
    myProfile: {
      myProfile: "/my-profile",
      myInvoice: "/my-invoice",
    },
    mySubscriptions: "/my-subscriptions",
  },
  admin: {
    dashboard: "/admin/dashboard",
    businesses: "/admin/businesses",
    customers: "/admin/customers",
    termsconditions: "/admin/terms&conditions",
  },
  dashboard: {
    index: "/dashboard",
  },

  reports: {
    home: "/reports",
    debtor: {
      debtor: "/debtor-reports",
      debtorSummary: "/debtor-summary",
    },
    income: {
      income: "/income-report",
      incomeSummary: "/income-summary",
    },
    payment: {
      payment: "/payment-report",
      paymentSummary: "/payment-summary",
    },
    earnings: "/earning-reports",
    performance: "/performance-reports",
  },
  utilities: {
    templates: "/templates",
    accountDetails: "/account-details",
    products: {
      index: "/products",
      add: "/products/add",
      edit: "/products/edit/:id",
    },

    // customer: {
    //     registration: '/registration/:name',
    // },
    finance: "/finance",
    notifications: "/notifications",
    settings: "/settings",
    files: "/files",
    flow: "/flow",
    integration: "/integration",
    organisation: "/organisation",
    lead: "/lead",
    newLead: "/newLead",
    editLead: "/lead/edit/:id",
    contact: "/contacts",
    invoice: "/invoice",
    invoiceNew: "/create-invoice",
    users: {
      list: "/users",
    },
    invoice: {
      list: "/invoices",
      add: "/invoice/add",
      edit: "/invoice/edit/:id",
      nonEdit: "/invoice/non-edit/:id",
      preview: "/invoice/:id",
    },
    proposal: {
      index: "/proposal",
      add: "/proposal/add",
      edit: "/proposal/edit/:id",
      nonEdit: "/proposal/non-edit/:id",
      preview: "/proposal/:id",
    },
    invoice_settings: "/invoice-settings",
  },
  support: {
    center: "/support-center",
    contact: "/contact",
    supportOnThisPage: "/support-page",
  },
  account: {
    changeOrganisation: "/change-organisation",
    login: "/login",
    forgotPassword: "/forgot-password",
    register: "/register",
    resetPassword: "/password-reset",
  },
};

export default url;
