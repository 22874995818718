export function clearToken() {
  localStorage.removeItem("id_token");
}
export function setToken(token) {
  localStorage.setItem("id_token", token);
}

export function getToken() {
  try {
    const idToken = localStorage.getItem("id_token");
    return idToken;
  } catch (err) {
    clearToken();
  }
}

export function setUser(user) {
  localStorage.setItem("cloud_collect_userInfo", JSON.stringify(user));
}

export function clearUser() {
  localStorage.removeItem("id_token");
  localStorage.removeItem("cloud_collect_userInfo");
}

export function getUser() {
  try {
    return JSON.parse(localStorage.getItem("cloud_collect_userInfo"));
  } catch (err) {
    clearUser();
  }
}

const configMultipart = () => ({
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("id_token"),
  },
});

export { configMultipart };
