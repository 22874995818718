import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";

const validate = (values) => {
  const errors = {};

  if (values.billing_account_bsb && values.billing_account_bsb.length != 6) {
    errors.billing_account_bsb = "Deposit account bsb should be 6 digits";
  }
  if (
    values.billing_account_number &&
    values.billing_account_number.length < 6
  ) {
    errors.billing_account_number =
      "Deposit account number should be min 6 digits";
  }

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        readOnly={readOnly}
        id="inlineFormInputGroup"
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  type,
  readOnly,
  id,
  options,
  meta: { touched, error, warning },
}) => (
  <div>
    <label htmlFor={id} className="col-form-label">
      {label}
    </label>
    <select
      {...input}
      className="form-control"
      style={{ height: "50px" }}
      disabled={readOnly}
    >
      <option value=""></option>
      {options &&
        options.map((option, index) => (
          <option key={id + index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

let BillingForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    financialAccountEdit,
    handleEditClick,
    handleCancelCLicked,
    handleSaveCLicked,
    currentUser,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      {/*<p>Billing Account</p>*/}

      <div class="col-md-12 mt-3">
        <Field
          label="Account Name"
          type="text"
          id="billing_account_name"
          name="billing_account_name"
          // readOnly={!financialAccountEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="BSB"
          type="number"
          id="billing_account_bsb"
          name="billing_account_bsb"
          // readOnly={!financialAccountEdit}
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Account Number"
          type="number"
          id="billing_account_number"
          name="billing_account_number"
          component={renderField}
        />
      </div>
      {/* <div class="col-md-12 mt-3">
        <p>Credit card for Cloud Collect Subscription and/or SMS usage:</p>
      </div>
      <div class="col-md-12 mt-3">
        <Field
          label="Cardholder Name"
          type="text"
          id="card_holder_name"
          name="card_holder_name"
          component={renderField}
          // readOnly={!creditFormEdit}
        />
      </div> */}
      {/* <div class="col-md-12 mt-3">
        <Field
          label="Expiry Month"
          id="expiry_month"
          name="expiry_month"
          component={renderSelect}
          options={months}
          // readOnly={!creditFormEdit}
        />
      </div> */}
      {/* <div class="col-md-12 mt-3">
        <Field
          label="Expiry Year"
          id="expiry_year"
          name="expiry_year"
          component={renderSelect}
          options={years}
          // readOnly={!creditFormEdit}
        />
      </div> */}

      <hr />
      <div class="invoice-buttons text-center">
        <button type="submit" class="btn btn-success">
          Update
        </button>
      </div>
    </form>
  );
};

BillingForm = reduxForm({
  // a unique name for the form
  form: "billing",
  validate,
  enableReinitialize: true,
  // initialsValues: this.props.initialValues
})(BillingForm);

export default BillingForm;
