import React, { Component } from "react";
import { store } from "react-notifications-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import SuperFetch from "../../../helpers/superFetch";
import ConfirmComponent from "../../ConfirmComponent/ConfirmComponent";
import NotesForm from "./NotesForm";

class NotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showModal: false,
      selected: null,
      notes: null,
      error: false,
      initialValue: "<p></p>",
    };
  }

  editorValue = (value) => {
    this.setState({ notes: value, error: false });
  };

  submitNotes = () => {
    const { submitNotes } = this.props;
    const { notes, initialValue } = this.state;

    if (!notes || notes.trim() === initialValue) {
      return this.setState({ error: true });
    }

    submitNotes({ note: notes });
  };

  deleteNotesHandler = (id) => {
    const { match, fetchSingleContact } = this.props;

    SuperFetch.delete("/lead_notes/" + id, {
      _method: "DELETE",
    }).then((response) => {
      fetchSingleContact({ id: match.params.id });
      this.setState({ showModal: false });
      store.addNotification({
        message: "Note deleted successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    });
  };

  render() {
    let { lead } = this.props;
    const { show, selected, showModal, error } = this.state;
    let notes = lead && lead.notes;

    return (
      <>
        <br />
        <ConfirmComponent
          title="Delete"
          body="Are you sure you want to delete this note?"
          buttonText="Delete"
          show={showModal}
          handleClose={() => this.setState({ showModal: false })}
          confirmHandler={() =>
            this.deleteNotesHandler(selected && selected.id)
          }
        />
        {notes && notes.length > 0 && (
          <PerfectScrollbar style={{ height: "200px" }}>
            <table className="table">
              <thead>
                <tr>
                  <th width="120px" scope="col">Date</th>
                  <th scope="col">Note</th>
                  <th width="30px" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {notes.map((note) => {
                  return (
                    <>
                      <tr>
                        <td>{note.created}</td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{ __html: note.note }}
                          />
                        </td>
                        <td
                          onClick={() =>
                            this.setState({ selected: note, showModal: true })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          X
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </PerfectScrollbar>
        )}
        <NotesForm
          {...this.state}
          notesError={error}
          onSubmit={this.submitNotes}
          {...this.props}
          initialValues={{
            note: "",
          }}
          editorValue={this.editorValue}
        />
      </>
    );
  }
}

export default NotesTable;
