import actions from "./actions";

const initialState = {
  subscriptionsAll: [],
  loading: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        loading: false,
      };
    case actions.FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionsAll: action.payload.data,
      };
    default:
      return state;
  }
}
