import React from "react";
import UserForm from "./add";
import businessActions from "../../../redux/businessUser/actions";
import { connect } from "react-redux";

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delete_id: null,
      deleteType: "single",
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - Users";
    this.props.fetchBusinessUsers();
  }

  handleSubmit = (values) => {
    const { history } = this.props;
    this.props.inviteBusinessUser(history, values);
  };

  handleDeleteId = (id) => {
    this.setState({
      delete_id: id,
      deleteType: "single",
    });
  };

  handleRemove = () => {
    if (this.state.deleteType === "single") {
      const { history } = this.props;

      this.props.deleteBusinessUser(history, this.state.delete_id);
    }
  };

  handleUserInput(e) {}

  handleBulkDelete(e) {}

  handleChecked(e) {}

  render() {
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-lg-4 mt-3">
            <div className="card">
              <div className="card-body">
                <UserForm onSubmit={this.handleSubmit} />
              </div>
            </div>
          </div>
          <div className="col-lg-8 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="single-table">
                  <div className="table-responsive">
                    <table
                      id="table-main"
                      className="table table-hover progress-table text-center"
                    >
                      <thead>
                        <tr>
                          {/* <th className="text-left">All</th> */}
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.businessUsers.length ? (
                          this.props.businessUsers.map((element) => {
                            return (
                              <tr key={element.id}>
                                {/* <td></td> */}
                                <td>{element.first_name}</td>
                                <td>{element.last_name}</td>
                                <td>{element.email}</td>
                                <td>{element.status}</td>
                                <td>
                                  <button
                                    name="singleId"
                                    onClick={() =>
                                      this.handleDeleteId(element.id)
                                    }
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#007bff",
                                      border: "#fff",
                                      width: 0,
                                    }}
                                    // className="m-2"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colspan="5" className="text-center">
                              No record found!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <div className="modal fade" id="myModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Are you sure want delete?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => this.handleRemove()}
                              data-dismiss="modal"
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              data-dismiss="modal"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const {
  fetchBusinessUsers,
  inviteBusinessUser,
  deleteBusinessUser,
} = businessActions;

const mapDispatchToProps = (dispatch) => ({
  fetchBusinessUsers: () => fetchBusinessUsers({ dispatch }),
  inviteBusinessUser: (history, payload) =>
    inviteBusinessUser({ dispatch, history, payload }),
  deleteBusinessUser: (history, id) =>
    deleteBusinessUser({ dispatch, history, id }),
});

const mapStateToProps = (state) => {
  return {
    loading: state.BusinessUser.loading,
    businessUsers: state.BusinessUser.businessUsers,
    singleBusinessUser: state.BusinessUser.singleBusinessUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
