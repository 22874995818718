import { apiHost } from "../config";
import { store } from "../redux/store";
import actions from "../redux/auth/actions";
import { clearUser } from "./utility";
// import notification from '../components/notification';

export const customHeader = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + localStorage.getItem("id_token") || undefined,
});

const base = async (method, url, data = {}) => {
  let body = undefined;
  if (method !== "get") {
    body = JSON.stringify(data);
  }

  let response = await fetch(`${apiHost.fetchUrl}${url}`, {
    method,
    headers: customHeader(),
    body: body,
  });

  let result = await response.json();
  if (!response.ok) {
    if (response.status == 401) {
      if (window.location.pathname !== "/login") {
        // localStorage.removeItem('id_token');
        // localStorage.removeItem('cloud_collect_userInfo');
        // window.location.href = "/login"
        throw result;
      } else {
        throw result;
      }
    } else if (response.status === 422) {
      if (result.errors && Object.keys(result.errors).length) {
        Object.keys(result.errors).map((key) => {
          result.errors[key] = result.errors[key][0];
        });
      }
      throw result;
    } else {
      throw result;
    }
  }
  return result;
};
const SuperFetch = {};
["get", "post", "put", "delete"].forEach((method) => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
