import React from "react";
import url from "./url";
import { Route } from "react-router-dom";
import Debtor from "./components/Reports/Debtor";
import AdminDashboard from "./components/Admin/Dashboard";
import BusinessUsers from "./components/Admin/Users/Business";
import CustomerUsers from "./components/Admin/Users/Customers";
import Terms from "./components/Admin/Terms/index";

const AdminRoutes = (props) => {
  return (
    <React.Fragment>
      <Route exact path={url.admin.dashboard} component={AdminDashboard} />
      <Route exact path={url.admin.businesses} component={BusinessUsers} />
      <Route exact path={url.admin.termsconditions} component={Terms} />
      <Route exact path={url.admin.customers} component={CustomerUsers} />
    </React.Fragment>
  );
};

export default AdminRoutes;
