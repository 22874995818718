import React, { Component } from "react";
import { connect } from 'react-redux';
import ResetPasswordForm from './form'
import authAction from '../../../redux/auth/actions';
import queryString from 'query-string'

class ResetPassword extends Component {

  submit = values => {
    // print the form values to the console
    const { resetPassword, history } = this.props;

    const queryStrings = queryString.parse(history.location.search)
    values.token = queryStrings.token
    resetPassword({ history, userInfo: values })
  };
  componentDidMount(){
      document.title = "Cloud Collect - Reset Password";
  }
  render() {
    return (
      <div className='login-area'>
        <div className='container'>
          <div className='login-box ptb--100'>
            <ResetPasswordForm onSubmit={this.submit} {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}


const { resetPassword } = authAction
const mapDispatchToProps = { resetPassword };


const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
