import React, { Component } from "react";
import { Link } from "react-router-dom";
import url from "../../url";
import { connect } from "react-redux";

class AdminSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "",
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  handleLogout = () => {
    localStorage.removeItem("cloud_collect_userInfo");
    localStorage.removeItem("id_token");
    window.location.href = url.index;
  };

  render() {
    return (
      <>
        <div className="sidebar-menu">
          <div className="sidebar-header">
            <div className="logo">
              <h2>Cloud Collect</h2>
            </div>
          </div>
          <div>
            <div className="row">
              <div id="tool-tray">
                <ul className="notification-area pull-right">
                  <li id="full-view">
                    <i className="ti-fullscreen"></i>
                  </li>
                  <li id="full-view-exit">
                    <i className="ti-zoom-out"></i>
                  </li>
                  <li className="customer-new-btn">
                    {/* <i className="fa fa-plus"></i> */}
                  </li>
                  <li className="settings-btn"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="main-menu"
            style={{ margin: "-20px 20px 0px 20px", width: "90%" }}
          >
            <div
              className="slimScrollDiv"
              style={{
                position: "relative",
                overflow: "hidden",
                width: "auto",
                height: "693.587px",
              }}
            >
              <div
                className="menu-inner"
                style={{ width: "auto", height: "693.587px" }}
              >
                <nav>
                  <ul className="metismenu" id="menu">
                    {/* <li>
                      <Link to={url.admin.dashboard}>
                        <i className="ti-dashboard"></i>
                        <span>Dashboard</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link to={url.admin.businesses}>
                        <i className="fa fa-building-o"></i>
                        <span>Businesses</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={url.admin.customers}>
                        <i className="fa fa-users"></i>
                        <span>Customers</span>
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link to={url.admin.termsconditions}>*/}
                    {/*    <i className="fa fa-users"></i>*/}
                    {/*    <span>Terms & Conditions</span>*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link
                        to={""}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleLogout();
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div
                className="slimScrollBar"
                style={{
                  background: "rgb(0, 0, 0)",
                  width: "7px",
                  position: "absolute",
                  top: "0px",
                  opacity: "0.4",
                  display: "none",
                  borderRadius: "7px",
                  zIndex: "99",
                  right: "1px",
                  height: "694px",
                }}
              ></div>
              <div
                className="slimScrollRail"
                style={{
                  width: "7px",
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  display: "none",
                  borderRadius: "7px",
                  background: "rgb(51, 51, 51)",
                  opacity: "0.2",
                  zIndex: "90",
                  right: "1px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
// const { fetchNotification } =  notificationAction;
// const mapDispatchToProps = dispatch => ({
//     fetchNotification: (payload, history) => fetchNotification({dispatch, payload, history}),
// });
const mapStateToProps = (state) => {
  return {
    allNotifications: state.Notifications,
  };
};
export default connect(mapStateToProps)(AdminSidebar);
