import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({ error: null, success: null });
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.ADD_ERROR:
      return state
        .set('error', action.payload)
    case actions.ADD_SUCCESS:
      return state
        .set('success', action.payload)
    case actions.REMOVE_ALERT:
      return state
        .set('error', null)
        .set('success', null)
    default:
      return state;
  }
}
