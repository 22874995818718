import SuperFetch from "./superFetch";

class FlowHelper {
  fetchFlowSettings = async (id) => {
    return await SuperFetch.get("/flows")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateNotificationsSettings = async (payload) => {
    return await SuperFetch.post("/flows", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new FlowHelper();
