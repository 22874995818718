import React, { Component } from "react";
import { store } from "react-notifications-component";
import SuperFetch from "../../../helpers/superFetch";
import ConfirmComponent from "../../ConfirmComponent/ConfirmComponent";

class ButtonSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  removeContact = () => {
    const { history, match } = this.props;

    SuperFetch.delete("/leads/" + match.params.id).then((response) => {
      this.setState({ showModal: false });
      store.addNotification({
        message: "Lead deleted successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      history.push("/contacts");
    });
  };

  render() {
    const { history, invoices, proposals, match } = this.props;
    const { showModal } = this.state;

    return (
      <div className="col-lg-2 mt-3">
        <ConfirmComponent
          title="Delete"
          body="Are you sure you want to delete this contact?"
          buttonText="Delete"
          show={showModal}
          handleClose={() => this.setState({ showModal: false })}
          confirmHandler={this.removeContact}
        />
        <div className="card">
          <div className="card-body">
            <div className="invoice-buttons text-center">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ width: "150px" }}
                onClick={() => history.push(`/invoice/add/${match.params.id}`)}
              >
                New Invoice
              </button>
              <button
                type="button"
                className="btn btn-secondary new-proposal-button"
                style={{ width: "150px" }}
                onClick={() => history.push(`/proposal/add/${match.params.id}`)}
              >
                New Proposal
              </button>
              {/* <button
                type="button"
                className="btn btn-secondary new-proposal-button"
                style={{ width: "150px" }}
              >
                Send Checkout Link
              </button> */}
              {invoices &&
              invoices.length === 0 &&
              proposals &&
              proposals.length === 0 ? (
                <button
                  type="button"
                  className="btn btn-danger new-proposal-button"
                  style={{ width: "150px" }}
                  onClick={() => this.setState({ showModal: true })}
                >
                  Remove
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ButtonSection;
