import SuperFetch from "./superFetch";

class NotificationHelper {
    fetchNotifications = async () => {
        return await SuperFetch.get("/user/notifications")
            .then(response => {
                return response;

            })
            .catch(error => {
                throw error;
            })
    };

    readNotification = async () => {
        return await SuperFetch.post("/user/notifications/markall")
            .then(response => {
                return response;
            })
            .catch(error => {
                throw error;
            })
    };

    readSingleNotification = async (payload) => {
        return await SuperFetch.post('/user/notifications/makeone', payload)
            .then((response) => {
                return response
            })
            .catch(error => {
                throw error;
            })
    };

}

export default new NotificationHelper();