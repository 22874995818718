import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import ContactHelper from "../../helpers/contactHelper";
import {reset} from 'redux-form';

export function* getSelectedContact() {
  yield takeEvery("GET_SELECTED_CONTACT", function* ({ payload }) {
    yield put({
      type: actions.SET_STATE,
      payload,
    });
  });
}
export function* getSingleContact() {
  yield takeEvery("GET_SINGLE_CONTACT", function* ({payload}) {
    const { id } = payload;
    const result = yield call(
      ContactHelper.fetchSingleContact,
      id
    );
   
    if (result.user) {
      yield put({
        type: actions.FETCH_SINGLE_CONTACT_SUCCESS,
        payload: result.user,
      });
    }
  });
}
export function* createNote() {
  yield takeEvery("CREATE_NOTE", function* ({payload}) {
    const {values} = payload
    const result = yield call(
      ContactHelper.createNote,
      values
    );
    if (result){
      yield put(reset('notesForm'));
      yield put({
        type: actions.GET_SINGLE_CONTACT,
        payload: {id: values.lead_id}
      });
    }
  
  });
}

export default function* rootSaga() {
  yield all([fork(getSelectedContact)]);
  yield all([fork(getSingleContact)]);
  yield all([fork(createNote)]);
}
