import React, { Component } from "react";
import { connect } from "react-redux";
import organisationAction from "../../../redux/organisation/actions";
import ColumnLoader from "../../uielements/columnLoader";
import { getUser } from "../../../helpers/utility";
import OrganisationForm from "./organisationForm";
import BillingForm from "./billingForm";
// import DepositForm from "./depositForm"
import DirectorForm from "./directorForm";
import Alert from "../../uielements/alert";
import CreditCardForm from "./creditCardForm";
import { Elements, StripeProvider } from "react-stripe-elements";
import EditCreditCardForm from "./EditCreditCardForm";
import InvoiceDetailsSection from "./InvoiceDetailsSection/InvoiceDetailsSection";
import { apiHost } from "../../../config";

class Organisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoading: false,
      logo: null,
      organisationDetailsEdit: false,
      financialAccountEdit: false,
      depositAccountEdit: false,
      userDetailsEdit: false,
      creditFormEdit: false,
      editCreditFormEdit: false,
      organisationData: {
        id: "",
        business_name: "",
        abn: "",
        contact_email: "",
        contact_number: "",
        address: "",
        suburb: "",
        postcode: "",
        state: "",
        industry: "",
        mobile: "",
        relationship: "",
        logo: "",
        asic_document: "",
      },
      financialData: {
        id: "",
        billing_account_name: "",
        billing_account_bsb: "",
        billing_account_number: "",
      },
      depositData: {
        id: "",
        deposit_account_name: "",
        deposit_account_bsb: "",
        deposit_account_number: "",
      },
      directorData: {
        id: "",
        first_name: "",
        last_name: "",
        city: "",
        state: "",
        line1: "",
        post_code: "",
        dob: "",
        email: "",
        gender: "",
        id_number: "",
        phone: "",
        title: "",
        id_document: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Organisation";
    const { userInfo } = this.props;
    this.props.fetchSingleOrganisation({
      id: userInfo.default_organisation_id,
    });
  }

  createFile = async (url, name, type) => {
    // TODO, CORS workaround, Access-Control-Allow-Origin: *
    this.setState({
      imageLoading: true
    })
    let response = await fetch(`${apiHost.fetchUrl}/get-images?url=${url}`, {
      mode: "cors",
    });
    this.setState({
      imageLoading: false
    })
    if (response.ok) {
      let data = await response.blob();
      return new File([data], name, { type });
    }
    return null;
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.organisationData !== this.props.organisationData) {
      return true;
    }
    return null;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organisationData !== this.props.organisationData) {
      let logo = null;

      if (this.props.organisationData.logo) {
        logo = await this.createFile(
          `storage/${this.props.organisationData.logo.id}/${this.props.organisationData.logo.file_name}`,
          this.props.organisationData.logo.file_name,
          null
        );
      }

      const {
        id,
        business_name,
        abn,
        contact_email,
        contact_number,
        address,
        suburb,
        postcode,
        state,
        industry,
        mobile,
        relationship,
        billing_account_name,
        billing_account_bsb,
        billing_account_number,
        deposit_account_name,
        deposit_account_bsb,
        deposit_account_number,
        director,
        asic_document,
      } = this.props.organisationData;

      let directorData;
      if (director) {
        directorData = {
          id: director.id,
          first_name: director.first_name,
          last_name: director.last_name,
          city: director.city,
          state: director.state,
          line1: director.line1,
          post_code: director.post_code,
          dob: director.dob,
          email: director.email,
          gender: director.gender,
          id_number: director.id_number,
          phone: director.phone,
          title: director.title,
          id_document: director.id_document,
        };
      } else {
        directorData = state ? state.directorData : {};
      }

      this.setState({
        directorData,
        organisationData: {
          id,
          business_name,
          abn,
          contact_email,
          contact_number,
          address,
          suburb,
          postcode,
          state,
          industry,
          mobile,
          relationship,
          logo,
          asic_document,
        },

        financialData: {
          id,
          billing_account_name,
          billing_account_bsb,
          billing_account_number,
        },
        depositData: {
          id,
          deposit_account_name,
          deposit_account_bsb,
          deposit_account_number,
        },
      });
    }
  }

  handleEditClick = (type) => {
    this.handleEnableDisableFields(type);
  };

  handleSaveCLicked = (type) => {
    this.handleEnableDisableFields(type);
  };

  handleCancelCLicked = (type) => {
    this.handleEnableDisableFields(type);
  };

  handleEnableDisableFields = (type) => {
    let {
      organisationDetailsEdit,
      financialAccountEdit,
      depositAccountEdit,
      userDetailsEdit,
      directorDetailsEdit,
      creditFormEdit,
    } = this.state;
    if (type === "organisation") {
      organisationDetailsEdit = !organisationDetailsEdit;
    } else if (type === "fiancial") {
      financialAccountEdit = !financialAccountEdit;
    } else if (type === "director") {
      directorDetailsEdit = !directorDetailsEdit;
    } else if (type === "depositAccount") {
      depositAccountEdit = !depositAccountEdit;
    } else if (type === "userdetails") {
      userDetailsEdit = !userDetailsEdit;
    } else if (type === "creditCard") {
      creditFormEdit = !creditFormEdit;
    }

    this.setState({
      organisationDetailsEdit,
      directorDetailsEdit,
      financialAccountEdit,
      depositAccountEdit,
      userDetailsEdit,
      creditFormEdit,
    });
  };

  submitOrganisation = (values) => {
    // print the form values to the console
    const { updateOrganisation, updateBilling, updateDeposit } = this.props;

    if (values.hasOwnProperty("business_name")) {
      this.handleSaveCLicked("organisation");
      updateOrganisation({ organisationInfo: values });
    }
    if (values.hasOwnProperty("billing_account_name")) {
      this.handleSaveCLicked("fiancial");
      updateBilling({ organisationInfo: values });
    }
    if (values.hasOwnProperty("deposit_account_name")) {
      this.handleSaveCLicked("depositAccount");
      updateDeposit({ organisationInfo: values });
    }
  };

  submitDirector = (values) => {
   
    const { updateDirector, organisationData } = this.props;
    let directorInfo = {
      ...values,
      organisation_id: organisationData.id,
    };
    updateDirector({ directorInfo });
    this.handleSaveCLicked("director");
  };

  handleDob = (value) => {
    let { day, month, year } = value;

    this.setState({ dob: value });
  };
  handleChange() {
    return 1111111;
  }

  submitCreditCard(values) {
  }

  updateCreditCard = (values) => {
    const { updateCreditCard, organisationData } = this.props;
    values.organisation_id = organisationData.id;
    updateCreditCard(values);
    this.setState({
      creditFormEdit: !this.state.creditFormEdit,
    });
  };

  removeCreditCard = () => {
    const { deleteCreditCard, organisationData } = this.props;
    deleteCreditCard({ organisation_id: organisationData.id });
  };

  render() {
    const {
      depositLoading,
      billingLoading,
      organisationLoading,
      directorLoading,
      creditCardLoading,
      errors,
      organisationData,
    } = this.props;

    const { imageLoading } = this.state

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-lg-4 mt-3">
            <div className="card">
              <div className="card-body">
                {organisationLoading || imageLoading? (
                  <ColumnLoader />
                ) : (
                  <OrganisationForm
                    {...this.state}
                    handleEditClick={this.handleEditClick}
                    handleCancelCLicked={this.handleCancelCLicked}
                    handleSaveCLicked={this.handleSaveCLicked}
                    onSubmit={this.submitOrganisation}
                    initialValues={this.state.organisationData}
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </div>

          <InvoiceDetailsSection />

          {/* <div className="col-4">
            <div className="card">
              <div className="card-body">
                {directorLoading ? (
                  <ColumnLoader />
                ) : (
                  <DirectorForm
                    {...this.state}
                    handleEditClick={this.handleEditClick}
                    handleCancelCLicked={this.handleCancelCLicked}
                    handleSaveCLicked={this.handleSaveCLicked}
                    onSubmit={this.submitDirector}
                    organisationData={organisationData}
                    initialValues={this.state.directorData}
                    selectedDate={this.handleDob}
                    {...this.props}
                  />
                )}
              </div>
            </div>
          </div> */}

          {/* <div className="col-4">
            <div className="card">
              <div className="card-body">
                {depositLoading || billingLoading ? (
                  <ColumnLoader />
                ) : (
                  <div>
                    <h4 className="header-title">Bank Account</h4>

                    <BillingForm
                      {...this.state}
                      handleEditClick={this.handleEditClick}
                      handleCancelCLicked={this.handleCancelCLicked}
                      handleSaveCLicked={this.handleSaveCLicked}
                      onSubmit={this.submitOrganisation}
                      handleChange={this.handleChange()}
                      initialValues={this.state.financialData}
                      {...this.props}
                    />
                  </div>
                )}

                {organisationData &&
                  (creditCardLoading ? (
                    <ColumnLoader />
                  ) : (
                    <>
                      <p>Cloud Collect Charges</p>
                      <p>
                        <small>
                          Enter a credit or debit card for SMS usage.
                        </small>
                      </p>

                      {organisationData && organisationData.credit_card ? (
                        <p className="mt-4">
                          {organisationData.credit_card.brand} Card Ending with
                          ({organisationData.credit_card.last_four}){" "}
                          <EditCreditCardForm
                            {...this.state}
                            handleEditClick={this.handleEditClick}
                            handleCancelCLicked={this.handleCancelCLicked}
                            handleSaveCLicked={this.handleSaveCLicked}
                            onSubmit={this.updateCreditCard}
                            handleChange={this.handleChange()}
                            removeCreditCard={this.removeCreditCard}
                            initialValues={organisationData.credit_card}
                            {...this.props}
                          />
                        </p>
                      ) : (
                        <>
                          <StripeProvider
                            apiKey={
                              process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
                              "pk_test_CcAeSDptJfxfcBCTtZvs460I00FazYhJ2Y"
                            }
                          >
                            <Elements>
                              <CreditCardForm
                                {...this.state}
                                handleEditClick={this.handleEditClick}
                                handleCancelCLicked={this.handleCancelCLicked}
                                handleSaveCLicked={this.handleSaveCLicked}
                                onSubmit={this.submitCreditCard}
                                handleChange={this.handleChange()}
                                {...this.props}
                              />
                            </Elements>
                          </StripeProvider>
                        </>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const {
  fetchSingleOrganisation,
  updateOrganisation,
  updateDirector,
  updateDeposit,
  updateBilling,
  createCreditCard,
  deleteCreditCard,
  updateCreditCard,
} = organisationAction;
const mapDispatchToProps = {
  fetchSingleOrganisation,
  updateOrganisation,
  updateDirector,
  updateDeposit,
  updateBilling,
  createCreditCard,
  deleteCreditCard,
  updateCreditCard,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
    userInfo: getUser(),
    loading: state.Organisation.get("loading"),
    organisationData: state.Organisation.get("organisationData"),
    organisationLoading: state.Organisation.get("organisationLoading"),
    directorLoading: state.Organisation.get("directorLoading"),
    depositLoading: state.Organisation.get("depositLoading"),
    billingLoading: state.Organisation.get("billingLoading"),
    creditCardLoading: state.Organisation.get("creditCardLoading"),
    errors: state.Alert.error ? state.Alert.error.errors : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organisation);
