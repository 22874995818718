import React from "react";
import { NavLink, withRouter } from "react-router-dom";

class OutstandingInvoice extends React.Component {
  dateToExpire(date) {
    let one_day = 1000 * 60 * 60 * 24;
    let expire_date = new Date(this.changeDate(date)).getTime();
    let today = new Date().getTime();
    let math_abs = Math.abs(expire_date - today);
    let math_round = Math.round(math_abs / one_day);
    return math_round;
  }

  changeDate(data) {
    let date = data ? data.split("-") : "";
    return this.addZero(date[1]) + "-" + this.addZero(date[0]) + "-" + date[2];
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  render() {
    const { amountToFixed } = this.props;
    return (
      <div className="col-md-6 col-12 mt-3">
        <div className="card">
          <div className="card-body">
            <h5>Outstanding Invoices</h5>
            <br />
            <h6 className="text-center">Top 5 - Total Amount</h6>
            <table className="table-dash">
              <tbody>
                <tr>
                  <th>Invoice</th>
                  <th>Days Overdue</th>
                  <th>Amount</th>
                </tr>
                {this.props.totalAmount &&
                  this.props.totalAmount.map((item) => {
                    return (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.history.push(
                            `/invoice/non-edit/${item.id}`
                          )
                        }
                      >
                        <td>
                          INV-{item.invoice_number} -{" "}
                          {item.lead && item.lead.first_name}{" "}
                          {item.lead && item.lead.last_name}
                        </td>
                        <td className="text-center">
                          {this.dateToExpire(item.due_date)} Days
                        </td>
                        <td className="table-number">
                          {amountToFixed(item.total)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <br />
            <h6 className="text-center">Top 5 - Days Overdue</h6>
            <table className="table-dash">
              <tbody>
                <tr>
                  <th>Invoice</th>
                  <th>Days Overdue</th>
                  <th>Amount</th>
                </tr>
                {this.props.daysOverdue &&
                  this.props.daysOverdue.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        onClick={() =>
                          this.props.history.push(
                            `/invoice/non-edit/${item.id}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <td>
                          INV-{item.invoice_number} -{" "}
                          {item.lead && item.lead.first_name}{" "}
                          {item.lead && item.lead.last_name}
                        </td>
                        <td className="text-center">
                          {this.dateToExpire(item.due_date)} Days
                        </td>
                        <td className="table-number">
                          {amountToFixed(item.total)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <br />
            <div className="button-center">
              <NavLink
                to={{ pathname: `/invoices` }}
                className="btn btn-secondary"
              >
                See Invoices
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OutstandingInvoice);
