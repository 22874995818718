const actions = {
  ADD_ERROR: 'ADD_ERROR',
  REMOVE_ALERT: 'REMOVE_ALERT',
  ADD_SUCCESS: 'ADD_SUCCESS',
  addError: payload => ({
    type: actions.ADD_ERROR,
    payload
  }),
  addSuccess: payload => ({
    type: actions.ADD_SUCCESS,
    payload
  }),
  removeAlert: payload => ({
    type: actions.REMOVE_ALERT,
    payload
  }),
};
export default actions;
 