import React, { Component } from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { apiHost, apiSocketHost } from "../../../config";

class Debtor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debtor_date: "",
      data: [],
      allData: [],
      downloading: false,
    };
    this.handleDate = this.handleDate.bind(this);
  }

  handleDate(e) {
    var date = null;
    if (e){
      date = e
    }
    this.setState(
      {
        debtor_date: date,
      },
      () => {
        this.getDebtorWithDate();
      }
    );
  }
  getDebtorWithDate() {
    let date = this.state.debtor_date;
    SuperFetch.get(
      "/reports/debtor-detailed?start_date=" +
        this.getDate(date) +
        "&end_date=" +
        this.getDate(date)
    ).then((response) => {
      if (response.data) {
        this.setState({ data: response.data, allData: response.data });
      }
    });
  }

  componentDidMount() {
    document.title = "Cloud Collect - Debtors Detail";
    this.getDeptorData();
  }

  getDeptorData() {
    SuperFetch.get("/reports/debtor-detailed").then((response) => {
      if (response && response.data)
        this.setState({ data: response.data, allData: response.data });
    });
  }

  getDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + this.addZero(month) + "-" + this.addZero(date);
  }

  changeDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return this.addZero(date) + "/" + this.addZero(month) + "/" + year;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  getTotal() {
    let total = 0;
    if (this.state.allData.length !== 0) {
      let debtorData = this.state.data;
      debtorData.map((item) => {
        total = total += parseFloat(item.balance);
        return total;
      });
    } else {
      total = 0.0;
    }
    return total;
  }
  getChangeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  downloadReport = () => {
    this.setState({
      downloading: true,
    });
    let query = "?download=true&";
    if (this.state.debtor_date != "") {
      query += `&start_date=${this.getDate(
        this.state.debtor_date
      )}&end_date=${this.getDate(this.state.debtor_date)}`;
    }
    SuperFetch.get(`/reports/debtor-detailed${query}`)
      .then((response) => {
        this.setState({
          downloading: false,
        });
        if (response && response.data && response.data.url) {
          window.open(
            apiSocketHost.fetchUrl + `/download-pdf/?url=${response.data.url}&name=debtor-report.pdf`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch(() => {
        this.setState({
          downloading: false,
        });
      });
  };

  render() {
    const total = this.getTotal();
    return (
      <div className="main-content">
        <div className="main-content-inner">
          <div className="row">
            <div className="col-md-8 col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <p>
                    This report details all outstanding invoices at the following date.
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-md-4 col-sm-12 mt-3">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Date
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text" id="input-label-large">
                            Date:
                          </div>
                        </div>
                        <DatePicker
                          className="form-control date-picker-input-reports"
                          selected={this.state.debtor_date}
                          onChange={this.handleDate.bind(this)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-12 mt-3">
                      <div className="invoice-buttons text-right">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px" }}
                          onClick={this.downloadReport}
                          disabled={this.state.downloading}
                        >
                          {this.state.downloading ? (
                            <>
                              <i class="fa fa-spinner fa-spin"></i>{" "}
                              Downloading...
                            </>
                          ) : (
                            <>Download PDF</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 mt-3">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-center">
                    {this.props.currentUser &&
                      this.props.currentUser.organisation &&
                      this.props.currentUser.organisation.business_name}
                  </h2>
                  <h4 className="text-center">Debtors Detailed</h4>
                  <p className="text-center">
                    {this.state.debtor_date ? "As at" : ""} &nbsp;&nbsp;&nbsp;
                    {this.state.debtor_date &&
                      this.changeDate(this.state.debtor_date)}
                  </p>
                  <br />
                  <br />
                  <div id="row-report-sum">
                    {this.state.allData &&
                      this.state.allData.map((item, key) => {
                        return (
                          <div key={key} className="row" id="row-report">
                            <div className="col-4 mt-3">
                              <p>
                                {item.lead.first_name} {item.lead.last_name}
                              </p>
                            </div>
                            <div className="col-2 mt-3 pl-0 pr-0">
                              <p>#{item.invoice_number}</p>
                            </div>
                            <div className="col-2 mt-3">
                              <p>{this.getChangeDate(item.invoice_date)}</p>
                            </div>
                            <div className="col-2 mt-3 pl-0 pr-0" id="text-center">
                              <p>{item.status}</p>
                            </div>
                            <div className="col-2 mt-3" id="text-number">
                              <NumberFormat
                                value={parseFloat(item.balance).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => <p>{value}</p>}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="row" id="row-report">
                    <div className="col-6 mt-3">
                      <p>TOTAL</p>
                    </div>
                    <div className="col-6 mt-3" id="text-number">
                      <NumberFormat
                        value={parseFloat(total).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <p>{value}</p>}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
  };
};

export default connect(mapStateToProps)(Debtor);
