import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class ConfirmPopup extends Component {
  render() {
    const {
      show,
      handleClose,
      handleOk,
      acceptButtonClass,
      buttonText,
      confirmText,
    } = this.props;
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={acceptButtonClass}
            // style={{ margin: "10px" }}
            onClick={handleOk}
          >
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmPopup;
