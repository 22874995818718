import React from 'react';
import DatePicker from 'react-datepicker';
import customerInvoices from "../../../redux/invoice/action";
import {connect} from "react-redux";


class InvoiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: '',
            to_date: '',
            allData: [],
            arr: [],
        }
    }

    componentDidMount() {
        document.title = "Cloud Collect - Invoice";
        this.props.fetchCustomerInvoice();
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.invoiceList !== this.props.invoiceList) {
            this.setState({
                allData: this.props.invoiceList.data,
                arr: this.props.invoiceList.data,
            })
        }

    }

    handleFromDate(e) {
        this.setState({
            from_date: new Date(e)
        }, () => {
            this.handleFilterDate();
        });
    }

    handleToDate(e) {
        this.setState({
            to_date: new Date(e)
        }, () => {
            this.handleFilterDate();
        });
    }

    handleFilter(e) {
        if (e.target.value === '') {
            this.setState({allData: this.state.arr})
        }
        else {
            let updatedList = this.state.arr;
            updatedList = updatedList.filter(function (item) {
                return ((item.lead.organisation.business_name.toLowerCase().search(e.target.value.toLowerCase()) !== -1) ||
                    (item.invoice_number.toString().search(e.target.value) !== -1));
            });
            this.setState({allData: updatedList});
        }
    }

    handleSelectFilter(e) {
        if (e.target.value === 'all') {
            this.setState({allData: this.state.arr})
        }
        if (e.target.value) {
            let updatedList = this.state.arr;
            updatedList = updatedList.filter(function (item) {
                return item.status.toLowerCase().search(
                    e.target.value.toLowerCase()) !== -1;
            });
            this.setState({allData: updatedList});
            if (e.target.value === 'all') this.setState({allData: this.state.arr})
        }

    }

    handleFilterDate() {
        let data = JSON.parse(JSON.stringify(this.state.arr));
        if (this.state.from_date) {
            data = data.filter((item) => {
                let filterDate = this.state.from_date;
                if (item.invoice_date) {
                    let invoiceDate = item.invoice_date.split('-');
                    invoiceDate = invoiceDate[2] + '-' + invoiceDate[1] + '-' + invoiceDate[0];
                    invoiceDate = new Date(invoiceDate);
                    return invoiceDate.getTime() >= filterDate.getTime();
                }
            });
        }
        if (this.state.to_date) {
            data = data.filter((item) => {
                let filterDate = this.state.to_date;
                if (item.duo_date) {
                    let invoiceDate = item.duo_date.split('-');
                    invoiceDate = invoiceDate[2] + '-' + invoiceDate[1] + '-' + invoiceDate[0];
                    invoiceDate = new Date(invoiceDate);
                    return invoiceDate.getTime() <= filterDate.getTime();
                }
            });
        }
        this.setState({allData: data});
    }
    changeDate(data){
        if(data){
            let date = data.split('-');
            return date[0] + '/' + date[1] + '/' + date[2];
        }
    }

    render() {
        return (
            <div className="main-content-inner">
                <div className="row">
                    <div className="col-12 mt-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <input
                                            onChange={this.handleFilter.bind(this)}
                                            className="form-control"
                                            type="text"
                                            placeholder="Filter/Search"
                                            aria-label="Search"
                                        />
                                    </div>
                                    <div className="col" id="hide-content">
                                        <div className="form-group">
                                            <select
                                                onChange={this.handleSelectFilter.bind(this)}
                                                className="form-control"
                                                id="exampleFormControlSelect1"
                                                style={{"height": "44px"}}>
                                                <option value="all">All</option>
                                                <option value="awaiting">Awaiting Payment</option>
                                                <option value="overdue">Overdue</option>
                                                <option value="paid">Paid</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 date-c invoice_list">
                                        <input className="form-control" type="text"
                                               aria-label="Search"/>
                                        <DatePicker placeholderText="Date From:"
                                                    selected={this.state.from_date}
                                                    onChange={this.handleFromDate.bind(this)}
                                                    dateFormat={"dd/MM/yyyy"}
                                        />
                                    </div>
                                    <div className="col-md-3 date-c invoice_list">
                                        <input className="form-control" type="text"
                                               aria-label="Search"/>
                                        <DatePicker placeholderText="Date To:"
                                                    selected={this.state.to_date}
                                                    onChange={this.handleToDate.bind(this)}
                                                    dateFormat={"dd/MM/yyyy"}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div className="single-table">
                                    <div className="table-responsive">
                                        <table id="table-customer-invoices"
                                               className="table table-hover progress-table text-center">
                                            <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Reference</th>
                                                <th>Amount</th>
                                                <th>Invoice Date</th>
                                                <th>Due Date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(this.state.allData && this.state.allData !== '') && this.state.allData.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.lead.organisation.business_name}</td>
                                                        <td><a>{item.invoice_number}</a></td>
                                                        <td>${item.amount}</td>
                                                        <td>{this.changeDate(item.invoice_date)}</td>
                                                        <td>{this.changeDate(item.due_date)}</td>
                                                        <td>{item.status}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const {fetchCustomerInvoice} = customerInvoices;

const mapDispatchToProps = dispatch => ({
    fetchCustomerInvoice: () => fetchCustomerInvoice({dispatch}),
});

const mapStateToProps = state => {
    return {
        invoiceList: state.Invoices
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)