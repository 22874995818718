import React from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import { store } from "react-notifications-component";
import TextareaAutosize from "react-textarea-autosize";
import { Autosuggestion } from "../../../tools/Autosuggestion";
import AddInvoiceContact from "../Modals/addInvoiceContact";
import $ from "jquery";
import {
  formatTwoDecimals,
  calculateGstHelper,
  calcTwoDecimalPlaces,
  formatTwoDecimalsWithComma,
} from "../../../_methods/commonMethods";
import { connect } from "react-redux";
import { getUser } from "../../../helpers/utility";
import OrganisationAction from "../../../redux/organisation/actions";
import { NavLink } from "react-router-dom";
import NumberFormat from "react-number-format";
import Modal from "react-bootstrap/Modal";
import AddProductModal from "../Invoice/AddProductModal";
import axios from "axios";
import queryString from "query-string";
import { apiHost } from "../../../config";
import moment from "moment";
import AddInvoiceItemModal from "../Invoice/AddInvoiceItemModal";
import DraftComponent from "../../DraftComponent";

var days = 7; // Days you want to add
var today = new Date();
const date = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
const due_date = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
class AddProposal extends React.Component {
  newRow = {
    id: "",
    name: "",
    description: "",
    unit_amount: "",
    quantity: 1,
    amount: 0,
    tax_rate: 0,
    errors: {
      // name: false,
      unit_amount: false,
      quantity: false,
    },
  };
  errors = {
    // name: "Incorrect product name",
    unit_amount: "Incorrect unit price.",
    quantity: "Incorrect product quantity",
    customer: "Please select customer",
    proposal_length: "At least one element needed",
    description: "Description is required.",
    proposal_reference: null,
  };
  cloneNewRow = Object.assign({}, this.newRow);
  tmpSelection = "";

  constructor(props) {
    super(props);
    this.state = {
      copied_proposal_id: null,
      showAddProduct: false,
      proposal_rows: [this.cloneNewRow],
      total: 0,
      gst: 0,
      net: 0,
      invoice_number: "",
      leads: [{ name: "+ Add New", data_id: "add-new" }],
      currentLead: {
        name: "",
      },
      first_name: "",
      last_name: "",
      email: "",
      lead: 0,
      organisation_id: "",
      suggestionKeys: ["name"],
      products: [{ name: "+Add New", data_id: "add-new" }],
      productKeys: ["name"],
      errors: {
        customer: false,
        invoice_length: false,
      },
      proposal_date: new Date(),
      proposal_due_date: due_date,
      proposal_expiry_date: null,
      due_date: due_date,
      disabled: false,
      stripe_account_id: "",
      notes_files: [],
      notes: null,
      due_date_error: false,
      selectedProduct: {
        description: "",
        tax_rate: 0,
        name: "",
        quantity: 1,
        unit_amount: "",
        save: false,
      },
      selectedIndex: null,
      editing: false,
      invoicesProducts: [],
    };
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleAddProposal = this.handleAddProposal.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.selectLead = this.selectLead.bind(this);
    this.getNewLeads = this.getNewLeads.bind(this);
    this.leadKeyup = this.leadKeyup.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Create Proposal";
    const { userInfo, location } = this.props;
    if (queryString.parse(location.search).products) {
      if (queryString.parse(location.search).products === "copy") {
        let copiedProducts = JSON.parse(localStorage.getItem("copy_proposals"));
        copiedProducts.map((product) => {
          this.setState({
            copied_proposal_id: product.proposal_id,
          });
        });
        this.calculatePrices(
          copiedProducts.map((product) => ({
            ...product,
            unit_amount: product.amount,
            tax_rate: product.gst_free,
            errors: {},
          }))
        );
      } else {
        let chosenProducts = queryString
          .parse(location.search)
          .products.split(",");
        if (chosenProducts) {
          this.setState({ chosenProducts });
        }
      }
    }
    this.getUser();
    this.getInvoiceNumber();
    this.getLeads();
    this.getProducts();

    if ("id" in this.props.match.params) {
      let leadId = this.props.match.params.id;
      if (leadId == "product") {
        leadId = 0;
      }
      this.setState({ lead: leadId }, () => this.getLeads(true));
    }
  }

  onFileChangeHandler = (e) => {
    var files = Array.from(e.target.files);

    this.setState((prevState) => ({
      notes_files:
        prevState.notes_files.length < 10
          ? prevState.notes_files.concat(files)
          : prevState.notes_files,
    }));
  };

  onNotesChangeHandler = (e) => {
    this.setState({ notes: e.target.value });
  };

  getUser() {
    const { userInfo } = this.props;
    SuperFetch.get(
      "/organisation/show/" + userInfo.default_organisation_id
    ).then((response) => {
      if (response.data.stripe_account_id !== null)
        this.setState({
          stripe_account_id:
            this.props.userInfo.primary_organisation.stripe_account_id,
        });

      if (
        response.data.template &&
        response.data.template.template_data &&
        response.data.template.template_data.invoice_notes
      ) {
        this.setState({
          notes: response.data.template.template_data.invoice_notes,
        });
      }
    });
  }

  getInvoiceNumber() {
    SuperFetch.get("/proposals/next-number").then((response) => {
      this.setState({
        invoice_number: response.next_proposal_number,
      });
    });
  }

  getLeads(existingLead = false, leadId = null) {
    SuperFetch.get("/leads").then((response) => {
      let tmp = [{ name: "+ Add New", data_id: "add-new" }];
      for (let item of response.data) tmp.push(item);
      this.setState({
        leads: tmp,
      });
      if (existingLead) {
        let lead;
        if (!leadId) leadId = this.state.lead;
        for (let item of response.data) {
          if (item.id === parseInt(this.state.lead)) lead = item;
        }
        if (lead) {
          this.setState({
            currentLead: lead,
            lead: lead.id,
          });
        }
      }
    });
  }

  getProducts() {
    const { userInfo } = this.props;
    SuperFetch.get(
      "/organisation/" + userInfo.organisation.id + "/products"
    ).then((response) => {
      this.setState({ products: response.data });
    });
  }

  getNewLeads(lead) {
    if (lead.id !== "") {
      this.setState({
        currentLead: lead,
        lead: lead.id,
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
      });
      this.getLeads(true, lead.id);
    }
  }

  setProductName(index, e) {
    let data = {
      target: {
        value: e,
        name: "name",
      },
    };
    this.handleInput(index, data);
  }

  selectProduct(index, e) {
    this.tmpSelection = e.name;
    let tmp = Object.assign({}, this.state.proposal_rows[index]);
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    tmp.id = e.id;
    tmp.name = e.name;
    tmp.description = e.description;
    tmp.unit_amount = formatTwoDecimals(e.price);
    tmp.gst_free = e.gst_free;
    tmp.tax_rate = e.gst_free;
    baseTmp[index] = tmp;
    this.calculatePrices(baseTmp);
  }

  handleAddProposal() {
    let cloneNewRow = Object.assign({}, this.newRow);
    this.setState({
      proposal_rows: this.state.proposal_rows.concat(cloneNewRow),
    });
  }

  handleRemoveRow(key, e) {
    key = parseInt(key);
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;
    let tmp = [];

    for (let i = 0; i < this.state.proposal_rows.length; i++) {
      if (i !== key) tmp.push(this.state.proposal_rows[i]);
    }

    for (let element of tmp) {
      net += element.amount;
      if (parseInt(element.tax_rate) === 0) {
        netWithGST += element.amount;
      }
      gst += calculateGstHelper(netWithGST, element.tax_rate);
      total = net + gst;
    }

    this.setState({
      proposal_rows: tmp,
      invoicesProducts: tmp,
      net: net,
      gst: gst,
      total: total,
    });
    this.closeAddInvoiceHandler();
  }

  handleInput(index, e) {
    if (
      e.target.name === "name" &&
      e.target.value === this.tmpSelection &&
      e.target.value
    )
      return;
    let clone_proposal_rows = this.state.proposal_rows;
    clone_proposal_rows.map((element, i) => {
      if (i === index) {
        if (e.target.name === "name") {
          element.name = e.target.value;
          // element.errors.name = false;
        }
        if (e.target.name === "description") {
          element.description = e.target.value;
        }
        if (e.target.name === "unit_amount") {
          element.unit_amount = e.target.value;
          element.errors.unit_amount = false;
        }
        if (e.target.name === "quantity") {
          element.quantity = e.target.value;
        }
        if (e.target.name === "gst_free") {
          element.tax_rate = e.target.value;
        }
      }
    });
    this.calculatePrices(clone_proposal_rows);
  }

  handleDescription(value, index) {
    let clone_proposal_rows = JSON.parse(
      JSON.stringify(this.state.proposal_rows)
    );
    clone_proposal_rows.map((element, i) => {
      if (i === index) {
        element.description = value;
        element.errors.description = false;
      }
    });
    this.calculatePrices(clone_proposal_rows);

  }

  calculatePrices(baseTmp) {
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;
    for (let i = 0; i < baseTmp.length; i++) {
      // baseTmp[i].errors.name = baseTmp[i].name ? false : true;
      if (baseTmp[i].errors) {
        baseTmp[i].errors.description = baseTmp[i].description ? false : true;
        baseTmp[i].errors.quantity = false;
        baseTmp[i].errors.unit_amount = baseTmp[i].unit_amount ? false : true;
      }
      if (baseTmp[i].save) {
        baseTmp[i].save = baseTmp[i].save;
      }
      if (baseTmp[i].unit_amount) {
        baseTmp[i].amount =
          baseTmp[i].unit_amount * parseFloat(baseTmp[i].quantity);
        net += baseTmp[i].amount;
        if (parseInt(baseTmp[i].tax_rate) === 0) {
          netWithGST = baseTmp[i].amount;
        }
        // gst += baseTmp[i].gst_free === 1 ? 0 : (net * 10) / 100;
        gst += calculateGstHelper(netWithGST, baseTmp[i].tax_rate);
        total = net + gst;
      }
    }
    this.setState({
      proposal_rows: baseTmp,
      net: net,
      gst: gst,
      total: total,
    });
  }

  validation() {
    let valid = true;
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    let customerError =
      this.state.lead === 0 || !this.state.lead ? true : false;
    let proposalLengthError =
      this.state.proposal_rows.length === 0 ? true : false;
    let totalError = this.state.total < 0 ? true : false;
    if (totalError) valid = false;
    for (let item of baseTmp) {
      // item.errors.name = false;
      if (item.errors) {
        item.errors.unit_amount = false;
        item.errors.quantity = false;
        if (item.errors.unit_amount || item.errors.quantity) valid = false;
      }
      // if (!item.name || item.name.length > 255) item.errors.name = true;
      if (!item.unit_amount) item.errors.unit_amount = true;
      if (!item.quantity) item.errors.quantity = true;
      if (!item.description) item.errors.description = true;
    }

    this.setState({
      proposal_rows: baseTmp,
      errors: {
        customer: customerError,
        proposal_length: proposalLengthError,
        totalError: totalError,
        totalDescription: "Total cannot be negative",
      },
    });
    return valid;
  }

  handleStartDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({
      proposal_date: date,
    });
  }

  handleExpiryDate = (e) => {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({
      proposal_expiry_date: date,
    });
  };

  handleDueDate = (e) => {
    // const expiryDate = moment(
    //   this.changeDate(new Date(this.state.proposal_expiry_date)),
    //   "DD-MM-YYYY"
    // ).format("YYYY-MM-DD");
    // const dueDate = moment(this.changeDate(new Date(e)), "DD-MM-YYYY").format(
    //   "YYYY-MM-DD"
    // );
    // if (moment(dueDate).isBefore(expiryDate)) {
    //   this.setState({ due_date: new Date(e), due_date_error: true });
    // } else {
    //   this.setState({
    //     due_date: new Date(e),
    //     due_date_error: false,
    //   });
    // }
  };

  modifyDataForStoring() {
    let arr = [];
    for (let element of this.state.proposal_rows) {
      arr.push({
        id: element.id,
        name: element.name,
        description: element.description,
        quantity: element.quantity,
        amount: element.unit_amount,
        gst_free: element.tax_rate,
        save: element.save ? element.save : false,
      });
    }
    return arr;
  }

  changeDate(data) {
    let date = data.getDate();
    let month = data.getMonth() + 1;
    let year = data.getFullYear();
    return this.addZero(date) + "-" + this.addZero(month) + "-" + year;
    // let newDate = data.split('.');
    // newDate = newDate[0] + "-" + newDate[1] + "-" + newDate[2];
    // if(newDate.indexOf('/') !== -1) {
    //     newDate = newDate.split('-')[0];
    //     newDate = newDate.split('/').join('-');
    // }
    // let tmp = newDate.split('-');
    // newDate = this.addZero(tmp[0]) + '-' + this.addZero(tmp[1]) + '-' + tmp[2];
    // return newDate;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  selectLead(e) {
    if (e.data_id === "add-new") {
      $(".contact_modal").click();
      this.setState({
        lead: 0,
        organisation_id: 0,
      });
      return;
    }
    let tmp = this.state.errors;
    tmp.customer = false;
    this.setState({
      lead: e.id,
      name: e.name,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      organisation_id: e.organisation_id,
      errors: tmp,
    });
  }

  leadKeyup(e) {
    this.setState({ currentLead: "" });
    return;
  }

  handleSave = (e) => {
    let status = e.target.name;

    if (!this.validation()) return;
    // if (this.state.due_date_error) return;

    if (this.state.proposal_rows.length === 0) return;
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      proposal_date: this.changeDate(this.state.proposal_date),
      proposal_expiry_date: this.state.proposal_expiry_date
        ? this.changeDate(new Date(this.state.proposal_expiry_date))
        : null,
      proposal_reference: this.state.invoice_number,
      status: e.target.name,
      lead_id: this.state.lead,
      products: this.modifyDataForStoring(),
      attachments: this.state.notes_files,
      notes: this.state.notes,
      due_date: this.changeDate(this.state.due_date),
    };

    let formdata = new FormData();

    for (let key in data) {
      if (data[key] && key !== "attachments" && key !== "products") {
        formdata.append(key, data[key]);
      }
    }
    if (data.products) {
      for (let index in data.products) {
        for (let key in data.products[index]) {
          if (key !== "id") {
            formdata.append(
              `products[${index}][${key}]`,
              data.products[index][key]
            );
          }
        }
      }
    }
    if (data.attachments) {
      for (let key in data.attachments) {
        formdata.append(`attachments[${key}]`, data.attachments[key]);
      }
    }

    const { userInfo, location } = this.props;
    if (queryString.parse(location.search).products === "copy") {
      formdata.append(`copied`, 1);
      formdata.append(`copied_proposal_id`, this.state.copied_proposal_id);
    }

    if (!this.state.disabled)
      axios
        .post(`${apiHost.fetchUrl}/proposals`, formdata, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        })
        .then((response) => {
          store.addNotification({
            message: "Proposal added successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          if (status === "Draft") {
            this.props.history.push("/proposal");
          } else {
            this.props.history.push(
              "/proposal/non-edit/" + response.data.data.id
            );
          }
        })
        .catch((error) => {
          if (error.response.data && error.response.data.error) {
            return store.addNotification({
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
          if (error.proposal_reference) {
            this.setState({
              errors: {
                ...this.state.errors,
                proposal_reference: error.proposal_reference,
              },
            });
          }
        });
    // this.setState({ disabled: true });
  };

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    const characterNumber = Number(characterCode);
    if (
      (characterNumber >= 0 && characterNumber <= 9) ||
      characterCode === "." ||
      characterCode === "-"
    ) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  hideModal = () => {
    this.setState({ showAddProduct: false, showButton: false });
  };

  addedProduct = (product) => {
    this.selectProductInvoice(product);

    let net = parseFloat(this.state.net)
      ? parseFloat(this.state.net) + parseFloat(product.price)
      : parseFloat(product.price);
    let gst = (net * 10) / 100;
    let total = parseFloat(net) + parseFloat(gst);

    let proposals = this.state.proposal_rows.map((proposal) => {
      if (proposal.name === "+Add New") {
        return {
          id: product.id,
          name: product.name,
          unit_amount: product.price,
          tax_rate: product.gst_free,
          gst_free: product.gst_free,
          amount: product.price,
          quantity: 1,
          description: product.description,
          errors: {
            name: false,
            unit_amount: false,
            quantity: false,
          },
        };
      } else {
        return proposal;
      }
    });

    this.setState((prevState) => {
      return {
        net,
        gst,
        total,
        proposal_rows: proposals,
      };
    });
  };

  addNewProduct = () => {
    this.setState({ showAddProduct: true, showButton: false });
  };

  onChangeProposalNumber = (e) => {
    let proposal_reference = e.target.value;
    this.setState({ invoice_number: proposal_reference });
    axios
      .post(
        `${apiHost.fetchUrl}/proposals/validate/reference`,
        { proposal_reference },
        {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        }
      )
      .then((response) => {
        this.setState({
          errors: {
            ...this.state.errors,
            proposal_reference: null,
          },
        });
      })
      .catch((error) => {
        this.setState({
          errors: {
            ...this.state.errors,
            proposal_reference: "Proposal reference in use",
          },
        });
      });
  };

  onQuntityChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["quantity"] = e.target.value;
    this.setState({ selectProduct: product });
    this.amountCalculateHandler();
  };

  gstChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["tax_rate"] = e.target.value;
    // product["gst_free"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  amountCalculateHandler = () => {
    const amount =
      parseFloat(this.state.selectedProduct.quantity) *
      parseFloat(this.state.selectedProduct.unit_amount);

    let product = this.state.selectedProduct;
    product["amount"] = amount;
    this.setState({ selectProduct: product });
  };

  unitPriceHandler = (e) => {
    let product = this.state.selectedProduct;
    product["unit_amount"] = e.target.value;
    this.setState({ selectProduct: product });
    this.amountCalculateHandler();
  };

  closeAddInvoiceHandler = () => {
    this.setState({
      showAddInvoice: false,
      selectedProduct: {
        description: "",
        tax_rate: 0,
        name: "",
        quantity: 1,
        unit_amount: "",
      },
    });
  };

  selectProductInvoice = (e) => {
    let tmp = {};
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    tmp.name = e.name;
    this.setState({ name: e.name });
    tmp.description = e.description;
    tmp.unit_amount = formatTwoDecimals(e.price);
    tmp.id = e.id;
    tmp.quantity = this.state.selectedProduct.quantity;
    tmp.tax_rate = e.gst_free;
    tmp.amount = parseFloat(e.price) * this.state.selectedProduct.quantity;

    baseTmp.push(tmp);
    this.setState({ selectedProduct: tmp });
  };

  onAddItemHandler = () => {
    const { selectedIndex, editing, selectedProduct } = this.state;

    let products = this.state.invoicesProducts;
    if (editing) {
      products = Object.assign([], products, {
        [selectedIndex]: selectedProduct,
      });
    } else {
      if (selectedProduct.description) {
        products.push(selectedProduct);
        if (selectedProduct.save) {
          this.createProduct(selectedProduct);
        }
      }
    }

    this.setState({ invoicesProducts: products, selectedIndex: null });

    console.log("prodcuts", products);
    this.calculatePrices(products);
    this.closeAddInvoiceHandler();
  };

  createProduct = (selectedProduct) => {
    selectedProduct.type = "Single";
    SuperFetch.post("/products", selectedProduct)
      .then((response) => {})
      .catch((error) => {
        throw error;
      });
  };

  setProductInvoiceName = (e) => {
    let product = this.state.selectedProduct;
    product["name"] = e;
    this.setState({ selectProduct: product });
  };

  onNameChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["name"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  saveProductHandler = (e) => {
    let product = this.state.selectedProduct;
    product["save"] = e.target.checked;
    this.setState({ selectProduct: product });
  };

  onDescriptionChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["description"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  goBack = () => {
    const { history } = this.props;
    history.push("/proposal");
  };

  render() {
    const { due_date_error } = this.state;
    return (
      <div className="main-content-inner">
        <Modal
          show={this.state.showAddProduct}
          onHide={() => this.setState({ showAddProduct: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              New Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddProductModal
              hideModal={this.hideModal}
              addedProduct={this.addedProduct}
            />
          </Modal.Body>
        </Modal>
        <AddInvoiceItemModal
          title={
            this.state.editing ? "Edit Proposal Item" : "Add Proposal Item"
          }
          selectedIndex={this.state.selectedIndex}
          showAddInvoice={this.state.showAddInvoice}
          selectedProduct={this.state.selectedProduct}
          onQuntityChangeHandler={this.onQuntityChangeHandler}
          unitPriceHandler={this.unitPriceHandler}
          gstChangeHandler={this.gstChangeHandler}
          onAddItemHandler={this.onAddItemHandler}
          closeAddInvoiceHandler={this.closeAddInvoiceHandler}
          onNameChangeHandler={this.onNameChangeHandler}
          saveProductHandler={this.saveProductHandler}
          onDescriptionChangeHandler={this.onDescriptionChangeHandler}
          products={this.state.products}
          productKeys={this.state.productKeys}
          addProduct
          addNewProduct={this.addNewProduct}
          selectValue={this.selectProductInvoice}
          keyupValue={this.setProductInvoiceName}
          handleRemoveRow={this.handleRemoveRow}
        />

        <div className="row">
          {/* {this.state.stripe_account_id !== "" ? ( */}
          <>
            <div className="col-lg-10 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="invoice-area">
                    <div className="row">
                      <div className="col-md-3 col-sm-12 position-relative lead-autosuggest  c-contact">
                        <button
                          className="contact_modal d-none"
                          href="#modal-org-invoice"
                          data-toggle="modal"
                          data-target="#modal-org-invoice"
                        ></button>
                        <label class="sr-only" for="inlineFormInputGroup">
                          Contact
                        </label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Contact</div>
                          </div>
                          <Autosuggestion
                            addContact
                            suggestionData={this.state.leads}
                            keys={this.state.suggestionKeys}
                            selectValue={this.selectLead}
                            keyupValue={this.leadKeyup}
                            nameValue={
                              this.state.currentLead.name
                                ? this.state.currentLead.name
                                : ""
                            }
                            placeholder={""}
                          />
                        </div>
                        {this.state.errors.customer ? (
                          <span className="invoice-error text-danger">
                            {this.errors.customer}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <label className="sr-only" for="inlineFormInputGroup">
                          Reference
                        </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Reference</div>
                          </div>
                          <input
                            className="form-control"
                            id="inlineFormInputGroup"
                            type="text"
                            onChange={this.onChangeProposalNumber}
                            value={this.state.invoice_number}
                            aria-label="Search"
                          />
                          {this.state.errors &&
                          this.state.errors.proposal_reference ? (
                            <span className="invoice-error text-danger">
                              {this.state.errors.proposal_reference}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-12">
                        <div class="input-group mb-2 date">
                          <div class="input-group-prepend">
                            <div className="input-group-text filter-span date-span">
                              Date
                            </div>
                          </div>
                          <DatePicker
                            className="form-control date-picker-input"
                            selected={this.state.proposal_date}
                            onChange={this.handleStartDate}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </div>
                      </div>
                      <div
                        class="col-md-2 col-sm-12 margin_top_date"
                        // id="hide-content"
                      >
                        <div class="input-group mb-2 date-e">
                          <div class="input-group-prepend">
                            <div className="input-group-text filter-span date-span">
                              Expires
                            </div>
                          </div>
                          <DatePicker
                            className="form-control date-picker-input"
                            name="expiry_date"
                            selected={this.state.proposal_expiry_date}
                            onChange={this.handleExpiryDate}
                            dateFormat={"dd/MM/yyyy"}
                          />
                        </div>
                      </div>
                      {/* <div class="col-md-2 col-sm-12 margin_top_date">
                        <div class="input-group mb-2 date">
                          <div class="input-group-prepend">
                            <div className="input-group-text filter-span date-span">
                              Due
                            </div>
                          </div>
                          <DatePicker
                            className="form-control date-picker-input "
                            name="duo_date"
                            selected={this.state.due_date}
                            onChange={this.handleDueDate}
                            dateFormat={"dd/MM/yyyy"}
                          />
                          {due_date_error && (
                            <div style={{ color: "red", marginTop: "-35px" }}>
                              Due date must be after Expiry date.
                            </div>
                          )}
                        </div>
                      </div> */}
                    </div>

                    <div class="row" id="hide-mobile">
                      <div class="col-12">
                        <div id="table" className="table-editable">
                          <table className="table table-bordered table-responsive-md text-center">
                            <thead>
                              <tr>
                                <th className="text-center">Product ID</th>
                                <th className="text-center">Description</th>
                                <th className="text-center">Units</th>
                                <th className="text-center">Unit Price</th>
                                <th className="text-center">Tax Rate</th>
                                <th className="text-right">Total Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.proposal_rows.map(
                                (element, index) => {
                                  return (
                                    <tr key={index} className="invoice-body">
                                      <td
                                        className="pt-3-half table-add invoice-col"
                                        width="125"
                                      >
                                        <Autosuggestion
                                          suggestionData={[
                                            // { name: "+Add New", data_id: "add-new" },
                                            ...this.state.products,
                                          ]}
                                          keys={this.state.productKeys}
                                          selectValue={this.selectProduct.bind(
                                            this,
                                            index
                                          )}
                                          nameValue={
                                            this.state.proposal_rows[index].name
                                          }
                                          keyupValue={this.setProductName.bind(
                                            this,
                                            index
                                          )}
                                          placeholder={""}
                                          addProduct
                                          addNewProduct={this.addNewProduct}
                                        />
                                        {/* {element.errors.name ? (
                                    <span className="invoice-error text-danger">
                                      {this.errors.name}
                                    </span>
                                  ) : null} */}
                                      </td>
                                      <td className="pt-3-half table-add invoice-col">
                                        <DraftComponent
                                          background={"#fff"}
                                          toolbarHidden={true}
                                          editorClass={"description-editor"}
                                          width={"100%"}
                                          initialValue={
                                            element && element.description !== undefined
                                              ? element.description
                                              : ""
                                          }
                                          getValue={(value) =>
                                            this.handleDescription(
                                              value,
                                              index
                                            )
                                          }
                                        />
                                        {/* <TextareaAutosize
                                          minRow={1}
                                          name="description"
                                          onChange={this.handleInput.bind(
                                            this,
                                            index
                                          )}
                                          className="form-control input_border_none"
                                          value={element.description}
                                        /> */}
                                        {element.errors &&
                                        element.errors.description ? (
                                          <span className="invoice-error text-danger">
                                            {this.errors.description}
                                          </span>
                                        ) : null}
                                      </td>

                                      <td
                                        className="pt-3-half table-add invoice-col"
                                        width="125"
                                      >
                                        <input
                                          type="text"
                                          name="quantity"
                                          onChange={this.handleInput.bind(
                                            this,
                                            index
                                          )}
                                          className="form-control input_border_none text-right"
                                          value={
                                            element.unit_amount > 0
                                              ? element.quantity
                                              : "1"
                                          }
                                          onKeyPress={this.handleKeypress.bind(
                                            this
                                          )}
                                        />
                                        {element.errors &&
                                        element.errors.quantity ? (
                                          <span className="invoice-error text-danger">
                                            {this.errors.quantity}
                                          </span>
                                        ) : null}
                                      </td>
                                      <td
                                        className="pt-3-half table-add invoice-col"
                                        width="125"
                                      >
                                        <input
                                          type="text"
                                          name="unit_amount"
                                          min="0"
                                          step="0.01"
                                          onChange={this.handleInput.bind(
                                            this,
                                            index
                                          )}
                                          className="form-control input_border_none text-right"
                                          // value={element.unit_amount && formatTwoDecimals(element.unit_amount)}
                                          value={
                                            element.unit_amount &&
                                            calcTwoDecimalPlaces(
                                              element.unit_amount
                                            )
                                          }
                                          onKeyPress={this.handleKeypress.bind(
                                            this
                                          )}
                                        />
                                        {element.errors &&
                                        element.errors.unit_amount ? (
                                          <span className="invoice-error text-danger">
                                            {this.errors.unit_amount}
                                          </span>
                                        ) : null}
                                      </td>
                                      <td
                                        className="pt-3-half table-add invoice-col"
                                        width="125"
                                        style={{ padding: 0 }}
                                      >
                                        <select
                                          style={{
                                            height: "44.44px",
                                            border: 0,
                                          }}
                                          className="form-control"
                                          name="gst_free"
                                          // value={element.tax_rate}
                                          onChange={this.handleInput.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          <option
                                            value="0"
                                            selected={
                                              element.gst_free === 0
                                                ? true
                                                : false
                                            }
                                          >
                                            GST
                                          </option>
                                          <option
                                            value="1"
                                            selected={
                                              element.gst_free === 1
                                                ? true
                                                : false
                                            }
                                          >
                                            GST Free
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        className="pt-3-half table-add invoice-col text-right p-10"
                                        width="125"
                                      >
                                        <NumberFormat
                                          value={parseFloat(
                                            element.amount
                                          ).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          renderText={(value) => (
                                            <span>{value}</span>
                                          )}
                                        />
                                      </td>
                                      {this.state.proposal_rows.length > 1 && (
                                        <td width="125">
                                          <span className="table-add invoice-col">
                                            <a
                                              onClick={this.handleRemoveRow.bind(
                                                this,
                                                index
                                              )}
                                              className="text-danger"
                                            >
                                              <i
                                                className="fa fa-times"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </span>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan="6">
                                  <span className="table-add">
                                    <a
                                      onClick={this.handleAddProposal.bind(
                                        this
                                      )}
                                      className="text-success"
                                    >
                                      <i
                                        className="fa fa-plus fa-2x"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4"></td>
                                <td>Sub-Total:</td>
                                <td className="text-right">
                                  <NumberFormat
                                    value={parseFloat(this.state.net).toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4"></td>
                                <td>Total GST:</td>
                                <td className="text-right">
                                  <NumberFormat
                                    value={formatTwoDecimals(this.state.gst)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4"></td>
                                <td>Total:</td>
                                <td className="text-right invoice-col">
                                  <NumberFormat
                                    value={parseFloat(this.state.total).toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    renderText={(value) => <span>{value}</span>}
                                  />
                                  <span className="invoice-error text-danger text-right">
                                    {this.state.errors.totalError
                                      ? this.state.errors.totalDescription
                                      : ""}
                                  </span>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="row" id="hide-mobile">
                      <div className="col-md-6 mt-1">
                        <div className="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="input-group-text">Notes</div>
                            </div>
                            {/* <textarea
                              className="form-control"
                              onChange={this.onNotesChangeHandler}
                              value={this.state.notes}
                              id="exampleFormControlTextarea1"
                              rows="8"
                            ></textarea> */}

                              <DraftComponent
                                toolbarHidden={true}
                                width={"80%"}
                                initialValue={
                                  this.state.notes && this.state.notes
                                }
                                editorClass={"notes-editor"}
                                getValue={(value) =>
                                  this.setState({ notes: value })
                                }
                              />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-1">
                        <div className="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <div className="input-group mb-2">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.onFileChangeHandler}
                                id="customFile"
                                // multiple
                              />
                              <label
                                className="custom-file-label"
                                for="customFile"
                              >
                                Attach file
                              </label>
                            </div>
                          </div>
                        </div>
                        {this.state.notes_files.map((file2) => (
                          <div className="row">
                            <div className="col-md-10">{file2.name} </div>

                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.setState({
                                  notes_files: this.state.notes_files.filter(
                                    (file1) => file1.name !== file2.name
                                  ),
                                });
                              }}
                              class="fas fa-trash"
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="row" id="hide-desktop">
                      {this.state.proposal_rows.length &&
                      this.state.proposal_rows[0].name ? (
                        <p style={{ fontSize: 16 }}>Proposal Items</p>
                      ) : (
                        ""
                      )}

                      <table className="table">
                        <tbody>
                          {this.state.proposal_rows.map((element, index) => {
                            return element.description ? (
                              <tr key={index}>
                                <td
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    width: "95%",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      selectedProduct: element,
                                      selectedIndex: index,
                                      showAddInvoice: true,
                                      editing: true,
                                    })
                                  }
                                  id="myBtn-1"
                                >
                                  {element.description} <br />
                                  {element.quantity}X{" "}
                                  {element.unit_amount &&
                                    "$" +
                                      formatTwoDecimalsWithComma(
                                        parseFloat(element.unit_amount)
                                      )}{" "}
                                  (
                                  {parseInt(element.tax_rate) === 1
                                    ? "GST Free"
                                    : "GST"}
                                  )
                                </td>
                              </tr>
                            ) : null;
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div class="row" id="hide-desktop">
                      <div class="col-12">
                        <button
                          type="button"
                          class="btn btn-success"
                          style={{ width: "100%" }}
                          onClick={() =>
                            this.setState({ showAddInvoice: true })
                          }
                        >
                          + Add Item
                        </button>
                      </div>
                    </div>

                    <div class="row" id="hide-desktop">
                      <table class="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Sub-Total</td>
                            <td style={{ textAlign: "right" }}>
                              <NumberFormat
                                value={formatTwoDecimals(this.state.net)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total GST</td>
                            <td style={{ textAlign: "right" }}>
                              <NumberFormat
                                value={formatTwoDecimals(this.state.gst)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => <span>{value}</span>}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td
                              style={{ textAlign: "right" }}
                              className="invoice-col"
                            >
                              <NumberFormat
                                value={formatTwoDecimals(this.state.total)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => <span>{value}</span>}
                              />
                              <span className="invoice-error text-danger text-right">
                                {this.state.errors.totalError
                                  ? this.state.errors.totalDescription
                                  : ""}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row" id="hide-desktop">
                      <div className="col-md-3 mt-1">
                        <div className="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <div className="input-group mb-2">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                onChange={this.onFileChangeHandler}
                                id="customFile"
                              />
                              <label
                                className="custom-file-label"
                                for="customFile"
                              >
                                Attach file
                              </label>
                            </div>
                          </div>
                        </div>

                        {this.state.notes_files.map((file2) => (
                          <div className="row">
                            <div className="col-md-10">
                              {file2.name.length > 30
                                ? file2.name.substr(0, 30) + "..."
                                : file2.name}{" "}
                              <i
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.setState({
                                    notes_files: this.state.notes_files.filter(
                                      (file1) => file1.name !== file2.name
                                    ),
                                  })
                                }
                                class="fas fa-trash"
                              ></i>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="invoice-buttons text-center pt-2 pb-2">
                  {this.state.errors.proposal_length ? (
                    <span className="invoice-error text-danger">
                      {this.errors.proposal_length}
                    </span>
                  ) : null}
                </div>
                {/* <div className="invoice-buttons text-center"> */}
                {/* <button
                    name="Preview"
                    type="button"
                    className="btn btn-secondary"
                    style={{ width: "150px", marginRight: "10px" }}
                  >
                    Preview
                  </button> */}
                {/* <button
                      name="Draft"
                      onClick={this.handleSave.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      style={{ width: "150px", marginRight: "10px" }}
                    >
                      Save As Draft
                    </button>
                    <button
                      name="Approved"
                      onClick={this.handleSave.bind(this)}
                      type="button"
                      className="btn btn-secondary"
                      style={{ width: "150px" }}
                    >
                      Approve
                    </button>
                  </div> */}
              </div>
            </div>
            <div className="col-lg-2 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="invoice-buttons text-center">
                    <button
                      name="Approved"
                      type="button"
                      className="btn btn-success"
                      style={{ width: "150px" }}
                      onClick={this.handleSave.bind(this)}
                    >
                      Approve
                    </button>
                    <button
                      name="Draft"
                      type="button"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.handleSave.bind(this)}
                    >
                      Save As Draft
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.goBack}
                    >
                      Void
                    </button>
                    {/* <!-- <button type="button" class="btn btn-secondary" style="width: 150px">Edit</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">Copy</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">View</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">Print PDF</button>
										<button type="button" class="btn btn-secondary" style="width: 150px">Mark As Paid</button> --> */}
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* // ) : ( //{" "}
          <p>
            // Please complete your //{" "}
            <NavLink to={{ pathname: `/organisation` }}>organisation</NavLink>{" "}
            // profile to create proposals. //{" "}
          </p>
          // )} */}
          <AddInvoiceContact getLeads={this.getNewLeads} />
        </div>
      </div>
    );
  }
}
const { fetchSingleOrganisation } = OrganisationAction;

const mapDispatchToProps = { fetchSingleOrganisation };

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
    organisationData: state.Organisation.get("organisationData"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProposal);
