import React, { Component } from "react";

export default class Files extends Component {
  componentDidMount(){
      document.title = "Cloud Collect - Files";
  }
  render() {
    return (
      <div class='main-content-inner'>
        <div class='row'>
          <div class='col-12 mt-3'>
            <div class='card' style={{margin: '0 5% 0 5%'}}>
              <div class='card-body'>
                <div class='single-table'>
                  <div class='table-responsive'>
                    <table
                      id='table-main'
                      class='table table-hover progress-table text-center'
                    >
                      <thead class='text-capitalize'>
                        <tr>
                          <th onclick='sortTable(0)'>Select</th>
                          <th onclick='sortTable(1)'>File Name</th>
                          <th onclick='sortTable(2)'>File Category</th>
                          <th onclick='sortTable(3)'>File Type</th>
                          <th onclick='sortTable(4)'>Upload Date</th>
                          <th onclick='sortTable(5)'>Upload User</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type='checkbox' />
                          </td>
                          <td>Questionare</td>
                          <td>Form</td>
                          <td>PDF</td>
                          <td>01/01/2019</td>
                          <td>Natalia Romanova</td>
                        </tr>
                        <tr>
                          <td>
                            <input type='checkbox' />
                          </td>
                          <td>Waiver</td>
                          <td>Form</td>
                          <td>PDF</td>
                          <td>01/01/2019</td>
                          <td>Natalia Romanova</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
