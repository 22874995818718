import { stat } from "fs-extra";
import actionTypes from "./action";

const initialState = {
  data: [],
  invoice: {},
  list: [],
};

const Invoices = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        data: payload,
      };

    case actionTypes.SET_CONTACT:
      return {
        ...state,
        invoice: payload,
      };

    case actionTypes.SET_LIST:
      return {
        ...state,
        list: payload,
      };

    default:
      return state;
  }
};
export default Invoices;
