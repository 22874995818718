import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import alertActions from "../alert/actions";
import SubscriptionHelper from "../../helpers/subscriptionHelper";
import { createNotification } from "../../components/uielements/notification";

export function* fetchSubscriptions() {
  yield takeEvery("FETCH_SUBSCRIPTIONS", function* ({}) {
    try {
      const result = yield call(SubscriptionHelper.fetchSubscriptions);
      if (result.data) {
        yield put({
          type: actions.FETCH_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LOADER,
        payload: error,
      });

      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}
export function* cancelSubscription() {
  yield takeEvery("CANCEL_SUBSCRIPTION", function* ({ subscriptionId }) {
    const result = yield call(
      SubscriptionHelper.cancelSubscription,
      subscriptionId
    );
    if (result.data) {
      yield put({
        type: actions.FETCH_SUBSCRIPTIONS_SUCCESS,
        payload: result,
      });
      createNotification("success", "Subscription Cancelled Successfully");
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchSubscriptions)]);
  yield all([fork(cancelSubscription)]);
}
