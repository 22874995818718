import React from "react";
import { reduxForm } from "redux-form";
import DraftComponent from "../../DraftComponent";

const validate = (values) => {
  const errors = {};

  if (values.note && values.note == "") {
    errors.note = "Note is required";
  }

  return errors;
};

let NotesForm = (props) => {
  const { handleSubmit, editorValue, initialValues, notesError } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-12" style={{marginTop: 111}}>
        <div className="form-group">
          <DraftComponent
            initialValue={initialValues && initialValues.notes}
            getValue={editorValue}
            editorClass="draft-notes"
          />
          {notesError && <div style={{ color: "red" }}>Required</div>}
        </div>
      </div>
      <div className="invoice-buttons text-center">
        <button type="submit" className="btn btn-secondary">
          Save Note
        </button>
      </div>
    </form>
  );
};

NotesForm = reduxForm({
  form: "notesForm",
  validate,
  enableReinitialize: true,
})(NotesForm);

export default NotesForm;
