import React, { Component } from "react";
import SuperFetch from "../../helpers/superFetch";
import { store } from "react-notifications-component";
import { NavLink } from "react-router-dom";
import businessActions from "../../redux/businessUser/actions";
import notificationAction from "../../redux/notification/actions";
import { connect } from "react-redux";
import ChangeOrganisationForm from "./ChangeOrganisationForm";
import { getToken, getUser } from "../../helpers/utility";
import { apiSocketHost } from "../../config";
import Echo from "laravel-echo/dist/echo";

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      products: [],
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      product_id: "",
      notification: [],
    };
  }

  componentDidMount() {
    this.props.fetchNotification();

    // this.getProducts();
  }

  // getProducts = () => {
  //     SuperFetch.get("/products")
  //         .then(response => {
  //
  //             this.setState({
  //                 products: response.data
  //             });
  //         })
  //         .catch(error => {
  //             throw error;
  //         });
  // };

  // handleUserInput = e => {
  //     this.setState({
  //         [e.target.name]: e.target.value
  //     });
  // };

  handleChangeOrganisation = (values) => {
    const { history } = this.props;
    this.props.changeDefaultOrganisation(values, history);
  };

  getTime(data) {
    let Data = new Date(data);
    let hour = Data.getHours();
    let minute = Data.getMinutes();
    let date = Data.getDate();
    let month = Data.getMonth() + 1;
    let year = Data.getFullYear();
    // let day = Data.split(" ") ? Data.split(" ") : '';
    // let today = new Date().toJSON().split("T");
    // if(day[0] === today[0]) return hour+":"+minute;
    return (
      this.addZero(hour) +
      ":" +
      this.addZero(minute) +
      " " +
      this.addZero(date) +
      "/" +
      this.addZero(month) +
      "/" +
      year
    );
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  // validate = () => {
  //     let isError = false;
  //
  //     const errors = {
  //         first_name_err: "",
  //         last_name_err: "",
  //         mobile_err: "",
  //         email_err: "",
  //         product_err: ""
  //     };
  //     if (this.state.first_name === "") {
  //         isError = true;
  //         errors.first_name_err = "Please enter first name.";
  //     }
  //
  //     if (this.state.last_name === "") {
  //         isError = true;
  //         errors.last_name_err = "Please enter last name.";
  //     }
  //
  //     if (this.state.mobile === "") {
  //         isError = true;
  //         errors.mobile_err = "Please enter mobile number.";
  //     } else if (
  //         !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
  //             this.state.mobile
  //         )
  //     ) {
  //         isError = true;
  //         errors.mobile_err = "Invalid mobile number";
  //     }
  //
  //     if (this.state.email === "") {
  //         isError = true;
  //         errors.email_err = "Please enter email address.";
  //     } else if (
  //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
  //     ) {
  //         isError = true;
  //         errors.email_err = "Invalid email address";
  //     }
  //
  //     this.setState({
  //         ...this.state,
  //         ...errors
  //     });
  //
  //     return isError;
  // };

  // handleSubmit = e => {
  //     e.preventDefault();
  //     if (!this.validate()) {
  //         this.setState({isLoading: true});
  //
  //         let data = {
  //             first_name: this.state.first_name,
  //             last_name: this.state.last_name,
  //             email: this.state.email,
  //             mobile: this.state.mobile
  //         };
  //         if (this.state.product_id) {
  //             data.product_id = this.state.product_id;
  //         }
  //
  //         SuperFetch.post("/leads", data)
  //             .then(response => {
  //                 this.setState({isLoading: false});
  //                 const {history} = this.props;
  //                 store.addNotification({
  //                     message: "Lead added successfully.",
  //                     type: "success",
  //                     insert: "top",
  //                     container: "top-right",
  //                     animationIn: ["animated", "fadeIn"],
  //                     animationOut: ["animated", "fadeOut"],
  //                     dismiss: {
  //                         duration: 5000,
  //                         onScreen: true
  //                     }
  //                 });
  //                 window.location.href = "/lead";
  //                 // if(history && history.length)
  //                 // history.push('/lead');
  //             })
  //             .catch(error => {
  //                 throw error;
  //             });
  //     }
  // };
  handleCloseNotification(e) {
    if (e) {
      // this.props.readNotifications();
    }
  }

  handleMarkOne(id, e) {
    let data = {
      id: id,
    };
    this.props.readSingleNotification(data);
  }

  render() {
    // const {products} = this.state;
    // let productList;
    // if (this.props.location.state !== undefined) {
    //     productList = this.props.location.state.detail.map(item => (
    //         <option value={item.id} key={item.id}>
    //             {item.name}
    //         </option>
    //     ));
    // } else {
    //     productList = products.map(item => (
    //         <option value={item.id} key={item.id}>
    //             {item.name}
    //         </option>
    //     ));
    // }

    const { currentUser } = this.props;
    let business_name;
    if (currentUser && currentUser.organisation) {
      business_name = currentUser.organisation.business_name;
    }

    return (
      <div>
        <div className="offset-area">
          <div
            onClick={this.handleCloseNotification.bind(this)}
            className="offset-close"
          >
            <i className="ti-close"></i>
          </div>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <h5>Notifications</h5>
          </div>
          <div
            className="offset-content tab-content"
            style={{ paddingTop: "25px" }}
          >
            <div id="activity" className="tab-pane fade in show active">
              <div
                className="slimScrollDiv"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "auto",
                  height: "calc(100vh - 114px)",
                }}
              >
                <div
                  className="recent-activity"
                  style={{
                    overflow: "hidden",
                    width: "auto",
                    height: "calc(100vh - 114px)",
                  }}
                >
                  {this.props.allNotifications.allNotifications &&
                    this.props.allNotifications.allNotifications.unread &&
                    this.props.allNotifications.allNotifications.unread.map(
                      (item) => {
                        let time = this.getTime(item.created_at);
                        let first_name = "";
                        let last_name = "";
                        let type = "";
                        let link = null;
                        let refrence_number = "";
                        if (item.data.type === "proposal_accepted") {
                          type = "Proposal accepted";
                          refrence_number =
                            item.data.proposal.proposal_reference;
                          first_name = item.data.proposal.lead.first_name;
                          last_name = item.data.proposal.lead.last_name;
                          link = "/proposal/" + item.data.proposal.uuid;
                        }
                        if (item.data.type === "invoice_paid") {
                          type = " Paid Invoice";
                          refrence_number = item.data.invoice.invoice_number;
                          first_name = item.data.invoice.lead.first_name;
                          last_name = item.data.invoice.lead.last_name;
                          link = "/invoice/" + item.data.invoice.uuid;
                        }
                        if (item.data.type === "payment_missed") {
                          type = "Missed Payment";
                          refrence_number = item.data.invoice.invoice_number;
                          first_name = item.data.invoice.lead.first_name;
                          last_name = item.data.invoice.lead.last_name;
                          link = "/invoice/" + item.data.invoice.uuid;
                        }
                        if (item.data.type === "new_customer") {
                          type = "New Customer Registered";
                          first_name = item.data.customer.first_name;
                          last_name = item.data.customer.last_name;
                        }
                        if (item.data.type === "paylater_selected") {
                          type = "Pay Later Selected";
                          refrence_number = item.data.invoice.invoice_number;
                          first_name = item.data.invoice.lead.first_name;
                          last_name = item.data.invoice.lead.last_name;
                          link = "/invoice/" + item.data.invoice.uuid;
                        }
                        if (item.data.type === "payment_plan_accepted") {
                          type = "Pay Plan Accepted";
                          refrence_number = item.data.invoice.invoice_number;
                          first_name = item.data.invoice.lead.first_name;
                          last_name = item.data.invoice.lead.last_name;
                          link = "/invoice/" + item.data.invoice.uuid;
                        }
                        return (
                          <div
                            key={item.id}
                            className=" d-flex  timeline-task tm-title_width"
                          >
                            <div className="icon bg2 ">
                              <i
                                className={
                                  item.data.type === "payment_missed"
                                    ? "fa fa-exclamation-triangle"
                                    : "fa fa-plus"
                                }
                              ></i>
                            </div>
                            <div className="tm-title ">
                              <h4 className="">
                                {link ? (
                                  <a href={link} target="_blank">
                                    {type}: {first_name} {last_name}{" "}
                                    {`#${refrence_number}`}
                                  </a>
                                ) : (
                                  <>
                                    <a href="javascript:void(0);">
                                      {type}: {first_name} {last_name}
                                    </a>
                                  </>
                                )}
                              </h4>
                              <span className="time">
                                <i className="ti-time"></i>
                                {time}
                              </span>
                            </div>
                            <div className="ml-3 mt-2">
                              <span className="table-add invoice-col">
                                <a
                                  onClick={this.handleMarkOne.bind(
                                    this,
                                    item.id
                                  )}
                                  className="text-danger"
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  {/*<div className="timeline-task">*/}
                  {/*<div className="icon bg2">*/}
                  {/*<i className="fa fa-plus"></i>*/}
                  {/*</div>*/}
                  {/*<div className="tm-title">*/}
                  {/*<h4>New Customer: Rashan Gary</h4>*/}
                  {/*<span className="time">*/}
                  {/*<i className="ti-time"></i>7 Minutes Ago*/}
                  {/*</span>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className="timeline-task">*/}
                  {/*<div className="icon bg2">*/}
                  {/*<i className="fa fa-exclamation-triangle"></i>*/}
                  {/*</div>*/}
                  {/*<div className="tm-title">*/}
                  {/*<h4>Missed Payment: Kevin King</h4>*/}
                  {/*<span className="time">*/}
                  {/*<i className="ti-time"></i>09:20 Am*/}
                  {/*</span>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className="timeline-task">*/}
                  {/*<div className="icon bg3">*/}
                  {/*<i className="fa fa-bomb"></i>*/}
                  {/*</div>*/}
                  {/*<div className="tm-title">*/}
                  {/*<h4>Cancellation: Darnell Savage Jr.</h4>*/}
                  {/*<span className="time">*/}
                  {/*<i className="ti-time"></i>09:35*/}
                  {/*</span>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                </div>
                <div
                  className="slimScrollBar"
                  style={{
                    background: "rgb(0, 0, 0)",
                    width: "7px",
                    position: "absolute",
                    top: "0px",
                    opacity: "0.4",
                    display: "none",
                    borderRadius: "7px",
                    zIndex: "99",
                    right: "1px",
                    height: "737px",
                  }}
                ></div>
                <div
                  className="slimScrollRail"
                  style={{
                    width: "7px",
                    height: "100%",
                    position: "absolute",
                    top: "0px",
                    display: "none",
                    borderRadius: "7px",
                    background: "rgb(51, 51, 51)",
                    opacity: "0.2",
                    zIndex: "90",
                    right: "1px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="messages-area">*/}
        {/*<div className="messages-close">*/}
        {/*<i className="ti-close"></i>*/}
        {/*</div>*/}
        {/*<div style={{textAlign: "center", marginTop: "15px"}}>*/}
        {/*<h5>Messages</h5>*/}
        {/*</div>*/}
        {/*<ul className="nav offset-menu-tab" style={{marginBottom: "-25px"}}>*/}
        {/*<li>*/}
        {/*<a className="active" data-toggle="tab" href="#inbox">*/}
        {/*Inbox*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<a data-toggle="tab" href="#messages-new">*/}
        {/*New Message*/}
        {/*</a>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*<div className="messages-content tab-content">*/}
        {/*<div id="inbox" className="tab-pane fade in show active">*/}
        {/*<div id="message-search">*/}
        {/*<form className="form-group">*/}
        {/*<input*/}
        {/*type="email"*/}
        {/*className="form-control"*/}
        {/*id=""*/}
        {/*placeholder="Search"*/}
        {/*/>*/}
        {/*<br/>*/}
        {/*</form>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*className="slimScrollDiv"*/}
        {/*style={{*/}
        {/*position: "relative",*/}
        {/*overflow: "hidden",*/}
        {/*width: "auto",*/}
        {/*height: "calc(100vh - 114px)"*/}
        {/*}}*/}
        {/*>*/}
        {/*<div*/}
        {/*className="recent-activity"*/}
        {/*style={{*/}
        {/*overflow: "hidden",*/}
        {/*width: "auto",*/}
        {/*height: "calc(100vh - 114px)"*/}
        {/*}}*/}
        {/*>*/}
        {/*<div className="timeline-task">*/}
        {/*<div className="icon bg1">*/}
        {/*<i className="fa fa-envelope"></i>*/}
        {/*</div>*/}
        {/*<div className="tm-title">*/}
        {/*<h4>Nick Bosa</h4>*/}
        {/*<span className="time">*/}
        {/*<i className="ti-time"></i>09:35am 24/08/2019*/}
        {/*</span>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className="timeline-task">*/}
        {/*<div className="icon bg1">*/}
        {/*<i className="fa fa-envelope"></i>*/}
        {/*</div>*/}
        {/*<div className="tm-title">*/}
        {/*<h4>Shaun Wade</h4>*/}
        {/*<span className="time">*/}
        {/*<i className="ti-time"></i>08:42am 24/08/2019*/}
        {/*</span>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div className="timeline-task">*/}
        {/*<div className="icon bg1">*/}
        {/*<i className="fa fa-envelope"></i>*/}
        {/*</div>*/}
        {/*<div className="tm-title">*/}
        {/*<h4>Victor Benjamin</h4>*/}
        {/*<span className="time">*/}
        {/*<i className="ti-time"></i>05:22pm 23/08/2019*/}
        {/*</span>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*className="slimScrollBar"*/}
        {/*style={{*/}
        {/*background: "rgb(0, 0, 0)",*/}
        {/*width: "7px",*/}
        {/*position: "absolute",*/}
        {/*top: "0px",*/}
        {/*opacity: "0.4",*/}
        {/*display: "none",*/}
        {/*borderRadius: "7px",*/}
        {/*zIndex: "99",*/}
        {/*right: "1px",*/}
        {/*height: "737px"*/}
        {/*}}*/}
        {/*></div>*/}
        {/*<div*/}
        {/*className="slimScrollRail"*/}
        {/*style={{*/}
        {/*width: "7px",*/}
        {/*height: "100%",*/}
        {/*position: "absolute",*/}
        {/*top: "0px",*/}
        {/*display: "none",*/}
        {/*borderRadius: "7px",*/}
        {/*background: "rgb(51, 51, 51)",*/}
        {/*opacity: "0.2",*/}
        {/*zIndex: "90",*/}
        {/*right: "1px"*/}
        {/*}}*/}
        {/*></div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*<div id="messages-new" className="tab-pane fade">*/}
        {/*<div className="messages-settings">*/}
        {/*<form className="form-group">*/}
        {/*<input*/}
        {/*type="email"*/}
        {/*className="form-control"*/}
        {/*id=""*/}
        {/*placeholder="Recepeint"*/}
        {/*/>*/}
        {/*<br/>*/}
        {/*<textarea*/}
        {/*className="form-control"*/}
        {/*id=""*/}
        {/*rows="10"*/}
        {/*placeholder="Message"*/}
        {/*></textarea>*/}
        {/*<br/>*/}
        {/*<button*/}
        {/*type="button"*/}
        {/*className="btn btn-primary"*/}
        {/*style={{width: "100%"}}*/}
        {/*>*/}
        {/*Send*/}
        {/*</button>*/}
        {/*</form>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}

        <div className="customer-new-area">
          <div className="customer-new-close">
            <i className="ti-close"></i>
          </div>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            {/* <h5>Add</h5> */}
          </div>
          <div className="customer-new-content tab-content">
            <div
              id="customer-create"
              className="tab-pane fade in show active btn_non_edit"
            >
              <form className="form-group" id="slider-new">
                <NavLink to={{ pathname: `/invoice/add` }}>
                  <button
                    type="button"
                    className="btn-non-edit-invoice btn btn-secondary"
                  >
                    New Invoice
                  </button>
                </NavLink>
                <NavLink to={{ pathname: `/proposal/add` }}>
                  <button
                    type="button"
                    className="btn-non-edit-invoice btn btn-secondary"
                  >
                    New Proposal
                  </button>
                </NavLink>
                <NavLink to={{ pathname: `/newLead` }}>
                  <button
                    type="button"
                    className="btn-non-edit-invoice btn btn-secondary"
                  >
                    New Contact
                  </button>
                </NavLink>

                {/* <NavLink to={{ pathname: `/registration` }}>
                  <button
                    type="button"
                    className="btn-non-edit-invoice btn btn-secondary"
                  >
                    New Customer
                  </button>
                </NavLink> */}
                <NavLink to={{ pathname: `/products/add` }}>
                  <button
                    type="button"
                    className="btn-non-edit-invoice btn btn-secondary"
                  >
                    New Product
                  </button>
                </NavLink>
              </form>
            </div>
          </div>
        </div>

        {/*<div className="service-new-area">*/}
        {/*<div className="service-new-close">*/}
        {/*<i className="ti-close"></i>*/}
        {/*</div>*/}
        {/*<div style={{textAlign: "center", marginTop: "15px"}}>*/}
        {/*<h5>Search</h5>*/}
        {/*</div>*/}
        {/*<div className="service-new-content tab-content">*/}
        {/*<div id="invoice-new" className="tab-pane fade in show active">*/}
        {/*<form className="form-group">*/}
        {/*<div className="form-group">*/}
        {/*<textarea*/}
        {/*className="form-control"*/}
        {/*id=""*/}
        {/*rows="1"*/}
        {/*placeholder=""*/}
        {/*></textarea>*/}
        {/*</div>*/}
        {/*</form>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}

        <div className="slider-setup">
          <div className="slider-setup-close">
            <i className="ti-close"></i>
          </div>
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <h5>Account Setup Wizard</h5>
          </div>
          <div
            className="offset-content tab-content"
            style={{ paddingTop: "25px" }}
          >
            <div id="activity" className="tab-pane fade in show active">
              <div
                className="slimScrollDiv"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "auto",
                  height: "calc(100vh - 114px)",
                }}
              >
                <div
                  className="recent-activity"
                  style={{
                    overflow: "hidden",
                    width: "auto",
                    height: "calc(100vh - 114px)",
                  }}
                >
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-square-o"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Organisation Details</h4>
                      <p>Enter all business details.</p>
                      <br />
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ width: "200px" }}
                        >
                          Complete Task
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-square-o"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Customers</h4>
                      <p>
                        Do you have customers you need to import from an
                        existing system?
                      </p>
                      <br />
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "100px", margin: "5px" }}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ width: "100px", margin: "5px" }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-square-o"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Products</h4>
                      <p>
                        Enter products/services you will be offering to your
                        customers.
                      </p>
                      <br />
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ width: "200px" }}
                        >
                          Complete Task
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-square-o"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Flow</h4>
                      <p>
                        Setup the customer flow which will assist with
                        automating onboarding, payment management and debtor
                        management.
                      </p>
                      <br />
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ width: "200px" }}
                        >
                          Complete Task
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="slimScrollBar"
                  style={{
                    background: "rgb(0, 0, 0)",
                    width: "7px",
                    position: "absolute",
                    top: "0px",
                    opacity: "0.4",
                    display: "none",
                    borderRadius: "7px",
                    zIndex: "99",
                    right: "1px",
                    height: "98.8694px",
                  }}
                ></div>
                <div
                  className="slimScrollRail"
                  style={{
                    width: "7px",
                    height: "100%",
                    position: "absolute",
                    top: "0px",
                    display: "none",
                    borderRadius: "7px",
                    background: "rgb(51, 51, 51)",
                    opacity: "0.2",
                    zIndex: "90",
                    right: "1px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="modal-org">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <ChangeOrganisationForm
                onSubmit={this.handleChangeOrganisation}
                {...this.props}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const {
  changeDefaultOrganisation,
  fetchBusinessOrganisations,
} = businessActions;
const {
  fetchNotification,
  readNotifications,
  readSingleNotification,
} = notificationAction;

const mapDispatchToProps = (dispatch) => ({
  changeDefaultOrganisation: (payload, history) =>
    changeDefaultOrganisation({ dispatch, payload, history }),
  fetchBusinessOrganisations: () => fetchBusinessOrganisations({ dispatch }),
  fetchNotification: (payload, history) =>
    fetchNotification({ dispatch, payload, history }),
  readNotifications: (payload, history) =>
    readNotifications({ dispatch, payload, history }),
  readSingleNotification: (payload, history) =>
    readSingleNotification({ dispatch, payload, history }),
});

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
    myBusinessOrganisations: state.BusinessUser.myBusinessOrganisations,
    initialValues: {
      organisation: state.Auth.get("user")
        ? state.Auth.get("user").default_organisation_id
        : "",
    },
    allNotifications: state.Notifications,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Models);
