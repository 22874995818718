import React, { Component } from "react";
import SuperFetch from "../../../helpers/superFetch";

class InvoiceNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      isLoaded: false,
      deleteInfo: "",
    };
  }

  componentDidMount() {
    // let userInfo = JSON.parse(localStorage.getItem("cloud_collect_userInfo"));
    // SuperFetch.get('/organisation/leads/'+userInfo.organisation_id).then(response => {
    //     this.setState({
    //     leads: response.data
    //     });
    // }).catch((error) => {
    //     throw error;
    // });
  }

  render() {
    // const { leads } = this.state;

    // const leadsList = leads.map(item => (
    //     <tr key={item.id}>
    //       <td>{item.first_name}</td>
    //       <td>{item.last_name}</td>
    //       <td>{item.email}</td>
    //       <td>{item.mobile}</td>
    //       <td>{(item.product_name) ? item.product_name :  '-'}</td>
    //     </tr>
    //   ))

    return (
      <div className="main-content">
        {/* <div class="header-area">
            <div class="row align-items-center">
                <div class="col-md-6 col-sm-8 clearfix">
                    <div class="nav-btn pull-left">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div class="breadcrumbs-area clearfix" style={{padding: 5}}>
                        <h4 class="page-title pull-left">New Invoice</h4>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="main-content-inner">
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="invoice-area">
                    <div className="row">
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Contact Search"
                          aria-label="Search"
                        />
                      </div>
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Invoice Reference"
                          aria-label="Search"
                        />
                      </div>
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Invoice Date"
                          aria-label="Search"
                        />
                      </div>
                      <div className="col-md-2">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Invoice Due Date"
                          aria-label="Search"
                        />
                      </div>
                    </div>
                    <br></br>
                    <div id="table" className="table-editable">
                      <table className="table table-bordered table-responsive-md text-center">
                        <thead>
                          <tr>
                            <th className="text-center">Product Name</th>
                            <th className="text-center">Description</th>
                            <th className="text-center">Unit Price</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Tax Rate</th>
                            <th className="text-center">Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="pt-3-half">PT Session</td>
                            <td className="pt-3-half">10 Pack</td>
                            <td className="pt-3-half">$50.00</td>
                            <td className="pt-3-half">10</td>
                            <td className="pt-3-half">GST</td>
                            <td className="pt-3-half">$500.00</td>
                            <td>
                              <span className="table-add">
                                <a href="#" className="text-danger">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="pt-3-half">Discount</td>
                            <td className="pt-3-half">10 Pack</td>
                            <td className="pt-3-half">($5.00)</td>
                            <td className="pt-3-half">10</td>
                            <td className="pt-3-half">GST</td>
                            <td className="pt-3-half">($50.00)</td>
                            <td>
                              <span className="table-add">
                                <a href="#" className="text-danger">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="6">
                              <span className="table-add">
                                <a href="#" className="text-success">
                                  <i
                                    className="fa fa-plus fa-2x"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4"></td>
                            <td>TOTAL :</td>
                            <td>$500.00</td>
                          </tr>
                          <tr>
                            <td colSpan="4"></td>
                            <td>GST :</td>
                            <td>$45.45</td>
                          </tr>
                          <tr>
                            <td colSpan="4"></td>
                            <td>NET :</td>
                            <td>$454.55</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="invoice-buttons text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: 150 }}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceNew;
