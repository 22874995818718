import React from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { apiHost, apiSocketHost } from "../../../config";

class IncomeSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to_date: "",
      from_date: "",
      total_invoice_amount: "",
      total_payment_recieved: "",
      total_charges: "",
      gst: "",
      subtotal: "",
    };
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
  }

  handleFromDate(e) {
    var date = null;
    if (e){
      date = e
    }
    this.setState({ from_date: date }, () => {
      SuperFetch.get(
        "/reports/income-summary?start_date=" +
          this.getDate(this.state.from_date) +
          "&end_date=" +
          this.getDate(this.state.to_date)
      ).then((response) => {
        this.setState({
          total_invoice_amount: response.data.total_invoice_amount,
          total_payment_recieved: response.data.total_payment_recieved,
          total_charges: response.data.total_charges,
          gst: response.data.gst,
          subtotal: response.data.subtotal,
        });
      });
    });
  }

  handleToDate(e) {
    var date = null;
    if (e){
      date = e
    }
    this.setState({ to_date: date }, () => {
      SuperFetch.get(
        "/reports/income-summary?start_date=" +
          this.getDate(this.state.from_date) +
          "&end_date=" +
          this.getDate(this.state.to_date)
      ).then((response) => {
        this.setState({
          total_invoice_amount: response.data.total_invoice_amount,
          total_payment_recieved: response.data.total_payment_recieved,
          total_charges: response.data.total_charges,
          gst: response.data.gst,
          subtotal: response.data.subtotal,
        });
      });
    });
  }

  // handleFilterDate() {
  //     let data = JSON.parse(JSON.stringify(this.state.data));
  //
  //     data = data.filter((item) => {
  //         let filterFromDate = this.state.from_date;
  //         let filterToDate = this.state.to_date;
  //         let DebtorDate = this.getDate(item.invoice_date);
  //         DebtorDate = new Date(DebtorDate);
  //         filterFromDate = new Date(filterFromDate);
  //         filterToDate = new Date(filterToDate);
  //         if(this.state.from_date && !this.state.to_date) return DebtorDate.setHours(0, 0, 0, 0) >= filterFromDate.setHours(0, 0, 0, 0);
  //         if(!this.state.from_date && this.state.to_date) return DebtorDate.setHours(0, 0, 0, 0) <= filterToDate.setHours(0, 0, 0, 0);
  //         if(this.state.from_date && this.state.to_date) return (DebtorDate.setHours(0, 0, 0, 0) >= filterFromDate.setHours(0, 0, 0, 0)) && (DebtorDate.setHours(0, 0, 0, 0) <= filterToDate.setHours(0, 0, 0, 0));
  //     });
  //
  //     this.setState({allData: data});
  // }

  getDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + this.addZero(month) + "-" + this.addZero(date);
  }

  changeDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return this.addZero(date) + "/" + this.addZero(month) + "/" + year;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }
  getSummary() {
    SuperFetch.get("/reports/income-summary").then((response) => {
      this.setState({
        total_invoice_amount: response.data.total_invoice_amount,
        total_payment_recieved: response.data.total_payment_recieved,
        total_charges: response.data.total_charges,
        gst: response.data.gst,
        subtotal: response.data.subtotal,
      });
    });
  }

  downloadReport = () => {
    this.setState({
      downloading: true,
    });
    let query = "?download=true&";
    if (this.state.from_date != "" && this.state.to_date != "") {
      query += `&start_date=${this.getDate(
        this.state.from_date
      )}&end_date=${this.getDate(this.state.to_date)}`;
    }
    SuperFetch.get(`/reports/income-summary${query}`)
      .then((response) => {
        this.setState({
          downloading: false,
        });
        if (response && response.data && response.data.url) {
          window.open(
            apiSocketHost.fetchUrl + `/download-pdf/?url=${response.data.url}&name=income-summary.pdf`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch(() => {
        this.setState({
          downloading: false,
        });
      });
  };

  componentDidMount() {
    document.title = "Cloud Collect - Income Summary";
    this.getSummary();
  }

  render() {
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-8 mt-3">
            <div className="card">
              <div className="card-body">
                <p>
                  This report details total invoices raised between the
                  following dates.
                </p>
                <br />
                <div className="row">
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date From
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date From:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        selected={this.state.from_date}
                        onChange={this.handleFromDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date To
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date To:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        selected={this.state.to_date}
                        onChange={this.handleToDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="invoice-buttons text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ width: "150px" }}
                        onClick={this.downloadReport}
                        disabled={this.state.downloading}
                      >
                        {this.state.downloading ? (
                          <>
                            <i class="fa fa-spinner fa-spin"></i> Downloading...
                          </>
                        ) : (
                          <>Download PDF</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="row">
          <div class="col-8 mt-3">
            <div class="card">
              <div class="card-body">
                <h2 class="text-center">Balanced Book</h2>
                <h4 class="text-center">Income Summary</h4>
                <p class="text-center">1 January 2019 to 31 December 2019</p>
                <br />
                <br />
                <div class="row" id="row-report">
                  <div class="col-6 mt-3">
                    <p>INCOME</p>
                  </div>
                  <div class="col-6 mt-3" id="text-number">
                    <p>$1,000.00</p>
                  </div>
                </div>
                <div class="row" id="row-report-sum">
                  <div class="col-6 mt-3">
                    <p>GST</p>
                  </div>
                  <div class="col-6 mt-3" id="text-number">
                    <p>$100.00</p>
                  </div>
                </div>
                <div class="row" id="row-report">
                  <div class="col-6 mt-3">
                    <p>TOTAL</p>
                  </div>
                  <div class="col-6 mt-3" id="text-number">
                    <p>$1,100.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-8 mt-3">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center">
                  {this.props.currentUser &&
                    this.props.currentUser.organisation &&
                    this.props.currentUser.organisation.business_name}
                </h2>
                <h4 className="text-center">Income Summary</h4>
                <p className="text-center">
                  {this.state.from_date &&
                    this.changeDate(this.state.from_date)}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.from_date || this.state.to_date ? "to" : ""}
                  &nbsp;&nbsp;&nbsp;{" "}
                  {this.state.to_date && this.changeDate(this.state.to_date)}
                </p>
                <br />
                <br />
                {/* <div className="row" id="row-report">
                  <div className="col-6 mt-3">
                    <p>Income</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      {this.state.subtotal ? (
                        <NumberFormat
                          value={parseFloat(this.state.subtotal).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        parseFloat(0).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
                <div className="row" id="row-report-sum">
                  <div className="col-6 mt-3">
                    <p>Charges</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      {this.state.total_charges ? (
                        <NumberFormat
                          value={parseFloat(this.state.total_charges).toFixed(
                            2
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        parseFloat(0).toFixed(2)
                      )}
                    </p>
                  </div>
                </div> */}
                <div className="row" id="row-report">
                  <div className="col-6 mt-3">
                    <p>SUB-TOTAL</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      {this.state.subtotal ? (
                        <NumberFormat
                          value={parseFloat(this.state.subtotal).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        parseFloat(0).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
                <div className="row" id="row-report-sum">
                  <div className="col-6 mt-3">
                    <p>GST</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      {this.state.gst ? (
                        <NumberFormat
                          value={parseFloat(this.state.gst).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        parseFloat(0).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
                <div className="row" id="row-report">
                  <div className="col-6 mt-3">
                    <p>TOTAL</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      {this.state.total_invoice_amount ? (
                        <NumberFormat
                          value={parseFloat(
                            this.state.total_invoice_amount
                          ).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(value) => <div>{value}</div>}
                        />
                      ) : (
                        parseFloat(0).toFixed(2)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
  };
};
export default connect(mapStateToProps)(IncomeSummary);
