import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./action";
import InvoiceHelper from "../../helpers/invoiceHelper";

export function* getSelectedInvoice() {
  yield takeEvery("FETCH_SELECTED_INVOICE", function* ({ payload }) {
    yield put({
      type: actions.SET_CONTACT,
      payload: payload.payload,
    });
  });
}

export function* fetchAllInvoices() {
  yield takeEvery("FETCH_CURRENT_INVOICES", function* () {
    try {
      const result = yield call(InvoiceHelper.fetchAllInvoice);
      if (result) {
        yield put({
          type: actions.SET_LIST,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FLOW,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getSelectedInvoice)]);
  yield all([fork(fetchAllInvoices)]);
}
