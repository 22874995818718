import NotificationHelper from "../../helpers/notificationHelper";

const actions = {
    FETCH_ALL_NOTIFICATIONS: "FETCH_ALL_NOTIFICATIONS",
    FETCH_ALL_NOTIFICATIONS_ERROR: "FETCH_ALL_NOTIFICATIONS_ERROR",
    FETCH_ALL_NOTIFICATIONS_SUCCESS: "FETCH_ALL_NOTIFICATIONS_SUCCESS",

    READ_NOTIFICATION: "READ_NOTIFICATION",
    READ_NOTIFICATION_ERROR: "READ_NOTIFICATION_ERROR",
    READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",

    fetchNotification: ({ dispatch }) =>
        NotificationHelper.fetchNotifications()
            .then(({ data }) => {
                dispatch({
                    type: actions.FETCH_ALL_NOTIFICATIONS_SUCCESS,
                    payload: data
                });
            })
            .catch(() => {}),


    setNotification: (data) => ({
            type: actions.FETCH_ALL_NOTIFICATIONS_SUCCESS,
            payload: data
        }),
       
    readNotifications: ({dispatch}) =>
        NotificationHelper.readNotification()
            .then(({ data }) => {
                dispatch({
                    type: actions.READ_NOTIFICATION_SUCCESS,
                    payload:data,
                })
            }),

    readSingleNotification: ({payload, dispatch, history}) => {
        NotificationHelper.readSingleNotification(payload)
            .then((response) => {
                if(response){
                        actions.fetchNotification({dispatch});
                }

            })
            .catch(error => {
                throw error;
            })
    },

};

export default actions;
