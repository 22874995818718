import InvoiceHelper from "../../helpers/invoiceHelper";

const actions = {
  FETCH_CUSTOMER_INVOICE_LIST: "FETCH_CUSTOMER_INVOICE_LIST",
  FETCH_CURRENT_INVOICES: "FETCH_CURRENT_INVOICES",
  FETCH_CUSTOMER_INVOICE_LIST_ERROR: "FETCH_CUSTOMER_INVOICE_LIST_ERROR",
  FETCH_CUSTOMER_INVOICE_LIST_SUCCESS: "FETCH_CUSTOMER_INVOICE_LIST_SUCCESS",
  FETCH_SELECTED_INVOICE: "FETCH_SELECTED_INVOICE",
  SET_CONTACT: "SET_CONTACT",
  SET_LIST: "SET_LIST",

  fetchCurrentInvoices: () => ({
    type: actions.FETCH_CURRENT_INVOICES,
  }),

  fetchCustomerInvoice: ({ dispatch }) => {
    InvoiceHelper.fetchCustomerInvoice()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_CUSTOMER_INVOICE_LIST_SUCCESS,
          payload: data,
        });
      })
      .catch(() => {});
  },

  selectedContact: (payload) => {
    return {
      type: actions.FETCH_SELECTED_INVOICE,
      payload,
    };
  },
};
export default actions;
