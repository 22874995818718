import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import SuperFetch from "../../../helpers/superFetch";
import { getUser, getToken } from "../../../helpers/utility";
import url from "../../../url";
import $ from "jquery";
import { apiHost } from "../../../config";
import { store } from "react-notifications-component";
import DataTable from "react-data-table-component";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      allContacts: [],
      isLoaded: false,
      showContactType: 0,
      checkedContacts: [],
      deleteType: "single",
      arrayIds: "",
      all_checked: false,
      id: "",
      status: "",
      tags: [],
      file_size: null,
      file_type: "",
      foreData: "",
      file_upload: "",
      errors: [],
      file_url: "",
      import_message: "",
      on_click: true,
      file_name: "",
    };
    this.handleChecked = this.handleChecked.bind(this);
    this.handleRemoveIds = this.handleRemoveIds.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handelRemoveById = this.handelRemoveById.bind(this);
    this.handleEditLead = this.handleEditLead.bind(this);
    this.handleImportContact = this.handleImportContact.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAllChecked = this.handleAllChecked.bind(this);
  }

  handleFileUpload(e) {
    let files = e.target.files[0];
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let format = "";
    if (files.name) {
      this.setState({ file_name: files.name });
      format = files.name.split(".");
      if (format[1]) {
        this.setState({
          file_type: format[1],
          size: files.size,
          file_upload: files,
          formData: formData,
        });
      }
    }
  }

  validate() {
    let is_Error = false;
    const errors = {};
    if (this.state.file_type !== "xlsx") {
      is_Error = true;
      errors.file_type = 'File type should be "xlsx"';
    }
    this.setState({ errors: errors });
    return is_Error;
  }

  handleImportContact() {
    if (this.validate()) {
      return;
    } else {
      this.setState({ on_click: !this.state.on_click });
      if (this.state.on_click) {
        let formData = new FormData();
        formData.append("file", this.state.file_upload);
        let url = apiHost.fetchUrl + "/leads/import/file";
        fetch(url, {
          method: "POST",
          body: formData,
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        })
          .then((response) => response.json())
          .then((success) => {
            $("#modal-org-invoice")
              .find('button[data-dismiss="modal"]')
              .click();
            this.addNotification();
            setTimeout(function () {
              window.location.reload(false);
            }, 5000);
          });
      }
    }

    return;
  }

  addNotification() {
    return store.addNotification({
      message: "Imported successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  handleClose(e) {
    // window.location.reload(false);
  }

  handleEditLead(id, e) {
    this.props.history.push("/lead/edit/" + id);
  }

  handleRowClick = (lead) => {
    this.props.history.push("/lead/edit/" + lead.id);
  };

  componentDidMount() {
    document.title = "Cloud Collect - Contacts";
    this.getContacts();
    this.handleExportContact();
  }

  handleExportContact() {
    SuperFetch.get("/leads/downloadExcel/xlsx").then((response) => {
      if (response) this.setState({ file_url: response.file_url });
    });
  }

  handleRemoveIds() {
    this.setState({ status: "by_ids" });
    let stringIds = this.state.checkedContacts.join(",");
    let dataIds = {
      ids: stringIds,
    };
    return dataIds;
  }

  handleRemove() {
    if (this.state.status == "by_ids") {
      this.actionDeleteIds();
    }
    if (this.state.status == "by_id") {
      this.actionDeleteById();
    }
  }

  actionDeleteIds() {
    SuperFetch.post("/leads/delete-bulk", this.handleRemoveIds()).then(
      (response) => {
        if (response) {
          this.getContacts();
          this.setState({ checkedContacts: [] });
          return store.addNotification({
            message: response.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }
    );
  }

  actionDeleteById() {
    SuperFetch.delete("/leads/" + this.state.id).then((response) => {
      if (response) this.getContacts();
    });
  }

  handelRemoveById(id, e) {
    this.setState({
      id: id,
      status: "by_id",
    });
  }

  handleAllChecked(e) {
    let tmp;
    if (e) {
      this.setState({
        all_checked: !this.state.all_checked,
      });
      if (!this.state.all_checked) {
        $(".custom-control-input").prop("checked", "true");
        this.state.contacts.map((item) => {
          if (!this.state.checkedContacts.includes(item.id)) {
            tmp = this.state.checkedContacts;
            tmp.push(item.id.toString());
          } else {
            tmp = [];
            for (let id of this.state.checkedContacts) {
              if (id !== item.id.toString()) tmp.push(id);
            }
          }
          this.setState({
            checkedContacts: tmp,
          });
        });
      } else {
        tmp = [];
        this.setState({ checkedContacts: tmp });
        $(".custom-control-input").prop("checked", "");
      }
    }
  }

  handleChecked(e) {
    let id = e.target.value;
    let tmp;
    if (!this.state.checkedContacts.includes(id)) {
      tmp = this.state.checkedContacts;
      tmp.push(id);
    } else {
      tmp = [];
      for (let item of this.state.checkedContacts) {
        if (item !== id) tmp.push(item);
      }
    }
    this.setState({
      checkedContacts: tmp,
    });
  }

  getContacts() {
    let userInfo = JSON.parse(localStorage.getItem("cloud_collect_userInfo"));
    let token = this.props.userToken;
    SuperFetch.get("/leads/", token)
      .then((response) => {
        this.setState({
          contacts: response.data,
          allContacts: response.data,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  filterContacts(type) {
    type = type.target.value;
    let contacts;
    if (type === "all") contacts = this.state.allContacts;
    else contacts = this.state.allContacts.filter((v) => v.status == type);
    this.setState({
      contacts: contacts,
      showContactType: type,
    });
  }

  handleShowContactType = (value) => {
    this.setState({ showContactType: value.target.value });
    this.getContacts(value.target.value);
  };
  handleFilter(e) {
    if (e.target.value === "") {
      this.setState({ contacts: this.state.allContacts });
    } else {
      let updatedList = this.state.allContacts;
      updatedList = updatedList.filter(function (item) {
        return (
          item.first_name.toLowerCase().search(e.target.value.toLowerCase()) !==
            -1 ||
          item.last_name.toLowerCase().search(e.target.value.toLowerCase()) !==
            -1 ||
          (item.company_name &&
            item.company_name
              .toLowerCase()
              .search(e.target.value.toLowerCase()) !== -1)
        );
      });
      this.setState({ contacts: updatedList });
    }
  }

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    let ids = state.selectedRows.map((row) => row.id);
    this.setState({ checkedContacts: ids });
  };

  render() {
    const columns = [
      {
        name: "Name",
        selector: "full_name",
        sortable: true,
        cell: (lead) => {
          return (
            <>
              {lead.type !== "Individual" ? (
                <span>{lead.company_name}</span>
              ) : (
                <span>{lead.full_name}</span>
              )}
            </>
          );
        },
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Mobile",
        selector: "mobile",
        sortable: true,
      },
      // {
      //   name: "Type",
      //   selector: "status",
      //   sortable: true,
      // },
      // {
      //   name: "Tags",
      //   cell: (lead) => {
      //     return (
      //       <a
      //         href="javascript:void(0);"
      //         onClick={this.handleEditLead.bind(this, lead.id)}
      //       >
      //         {lead.tags &&
      //           lead.tags.map((tag, key) => (
      //             <span key={key} className="mr-2 badge badge-secondary">
      //               {tag}
      //             </span>
      //           ))}
      //       </a>
      //     );
      //   },
      //   sortable: false,
      // },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
        },
      },
    };
    return (
      <div className="main-content">
        <div className="main-content-inner">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-9 col-sm-12 c-buttons">
                      <div className="input-group">
                        <NavLink to={{ pathname: `/newLead` }}>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            id="button-float-left"
                            style={{ marginRight: 15 }}
                          >
                            New Contact
                          </button>
                        </NavLink>
                        <button
                          className="btn btn-secondary dropdown-toggle "
                          type="button"
                          id="button-float-left"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                      </div>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          href="#modal-org-invoice"
                          data-toggle="modal"
                          data-target="#modal-org-invoice"
                        >
                          Import
                        </a>
                        <a className="dropdown-item" href={this.state.file_url}>
                          Export
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={this.handleRemoveIds.bind(this)}
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          Remove
                        </a>
                      </div>

                      {/*<a className=""><button type="button" className="btn btn-secondary" id="button-float-right">Manage</button></a>*/}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <div class="input-group-text filter-span">Search</div>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => this.handleFilter(e)}
                          placeholder="First name and/or last name"
                          aria-label="Search"
                        />
                      </div>
                    </div>
                    {/* <div class="col-md-2 col-sm-12">
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text filter-span">Status</div>
                        </div>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={this.state.showContactType}
                          onChange={(e) => this.filterContacts(e)}
                          style={{ height: 44 }}
                        >
                          <option value="all">All</option>
                          <option value="Customer"> Customer</option>
                          <option value="Lead">Lead</option>
                          {/* <option value="Archive">Archive</option> */}
                    {/* </select>
                      </div>
                    </div> */}
                  </div>
                  <br />
                  {/* <div className="position-relative">
                    {this.state.checkedContacts.length ? (
                      <div
                        onClick={this.handleRemoveIds.bind(this)}
                        data-toggle="modal"
                        data-target="#myModal"
                        className="row invoice-list-delete"
                      >
                        <i className="fa fa-trash"></i>
                        <span className="trash_name">archive</span>
                      </div>
                    ) : null}
                  </div> */}
                  <div
                    className="single-table single-table_1"
                    id="table-contacts"
                  >
                    <div className="">
                      <DataTable
                        noHeader
                        overflowY
                        highlightOnHover
                        responsive={true}
                        title=""
                        columns={columns}
                        data={this.state.contacts || []}
                        defaultSortField="Name"
                        onRowClicked={this.handleRowClick}
                        selectableRows // add for checkbox selection
                        Clicked
                        onSelectedRowsChange={this.handleChange}
                        customStyles={customStyles}
                        pointerOnHover={true}
                        pagination={true}
                        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete</h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete these contacts?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={this.handleRemove.bind(this)}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Import Modal*/}
        <div className="modal fade" id="modal-org-invoice">
          <div
            className="modal-dialog modal-dialog-centered import_modal"
            role="document"
          >
            <div className="modal-content">
              {/* <div className="modal-header text-center"> */}
              <div className="modal-header">
                <h5 className="modal-title" style={{ margin: "auto" }}>
                  Import Contacts
                </h5>
              </div>

              {/* </div> */}
              <div className="modal-body">
                <div className="container">
                  <div className="">
                    <div className="import-text" style={{ marginBottom: 30 }}>
                      <p>
                        1. Download{" "}
                        <a href="https://cloudcollect.io/contacts.xlsx">
                          Import Template
                        </a>
                        .<br />
                        2. Save data into the Import Template under relevant
                        columns.
                        <br />
                        3. Upload file using the Upload File button.
                        <br />
                      </p>
                    </div>
                    <div className="form-group-modal  import_upload_file">
                      <label
                        style={{ margin: "auto" }}
                        htmlFor="upload_file"
                        className="btn btn_reject_color text-center"
                      >
                        Upload File
                      </label>
                      <p className="text-secondary m-2">
                        {this.state.file_name && this.state.file_name}
                      </p>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        id="upload_file"
                        onChange={this.handleFileUpload.bind(this)}
                        className="form-control form-non-edit-modal"
                        multiple
                      />
                    </div>
                    <div className="form-group-modal import_modal_error">
                      {this.state.errors.file_type !== "" && (
                        <span className="m-form__help w-100  text-danger">
                          {this.state.errors.file_type}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.import_message === "" ? (
                <div className="modal-footer my_modal_footer">
                  <div className="d-flex">
                    <button
                      onClick={this.handleImportContact.bind(this)}
                      type="button"
                      className="btn btn_reject_color btn-secondary-modal btn_modal"
                    >
                      Import
                    </button>
                    <button
                      onClick={this.handleClose.bind(this)}
                      type="button"
                      data-dismiss="modal"
                      className="btn btn_reject_color btn-secondary-modal btn_modal"
                    >
                      Cancel
                    </button>
                  </div>
                  {/* <div className="text-center">
                    {" "}
                    <a href="https://cloudcollect.io/contacts.xlsx">
                      Download import template
                    </a>
                  </div> */}
                </div>
              ) : (
                <div className="modal-footer my_modal_footer">
                  <span className="m-form__help w-100  text-danger text-center">
                    {this.state.import_message}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const { login } = authAction
const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    userToken: getToken(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
