import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import RegisterForm from "./form";
import authAction from "../../../redux/auth/actions";
import $ from "jquery"; // ES6

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverErrors: {},
    };
  }
  componentDidMount() {
    document.title = "Cloud Collect - Register";
  }

  static getDerivedStateFromProps(props, state) {
    if (props.serverError && props.serverError.errors !== state.serverErrors) {
      return {
        serverErrors: props.serverError.errors,
      };
    }

    return {
      serverErrors: {},
    };
  }

  handleShowPassword(e) {
    // this.counter +=1;
    if ($('input[name="password"]').attr("type") === "password") {
      $('input[name="password"]').attr("type", "text");
    } else {
      $('input[name="password"]').attr("type", "password");
    }
  }

  setStripeCardObject = (objectData) => {
    this.setState({ stripeCardObject: objectData });
  };

  render() {
    const { register, history } = this.props;
    return (
      <StripeProvider
        apiKey={
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
          "pk_live_CeEh82TYTyUbPvi5CkQvLOSw00S4b8pC3R"
        }
      >
        <Elements>
          <div
            className="login-area"
            style={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              top: "0",
            }}
          >
            <div className="container">
              <div className="login-box">
                <RegisterForm
                  serverErrors={this.state.serverErrors}
                  showPassword={this.handleShowPassword}
                  register={register}
                  history={history}
                />
              </div>
            </div>
          </div>
        </Elements>
      </StripeProvider>
    );
  }
}

const { register } = authAction;
const mapDispatchToProps = { register };

const mapStateToProps = (state) => {
  return {
    serverError: state.Alert.get("error"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
