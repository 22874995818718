import React, { Component } from "react";
import SuperFetch from '../../helpers/superFetch';
import { replace } from "react-router-redux";
export default class Customers extends Component {
  constructor(props){
    super(props);
    this.state={
      data:[]
    }
  }

  componentDidMount(){
      document.title = "Cloud Collect - Dashboard";
    SuperFetch.get('/business/customers/').then(response => {

      if(response.data != null)
      {
        this.setState({data:response.data})

      }
    }).catch((error) => {
      throw error;
    });
  }
  render() {
    return (
      <div className='main-content-inner'>
        <div className='row'>
          <div className='col-12 mt-5'>
            <div className='card' style={{margin: '0 5% 0 5%'}}>
              <div className='card-body'>
                <div className='single-table'>
                  <div className='table-responsive'>
                    <table
                      id='table-main'
                      className='table table-hover progress-table text-center'
                    >
                      <thead className='text-capitalize'>
                        <tr>
                          <th style={{width: '10px'}} >
                            Select
                          </th>
                          <th >Name</th>
                          <th style={{width: '100px'}} >
                            Outstanding
                          </th>
                          <th style={{width: '100px'}}>
                            Reference
                          </th>
                          <th style={{width: '100px'}}>
                            Due Date
                          </th>
                          <th>Status</th>
                          <th>Tags</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.data.map(item=>{ return(
                        <tr>
                      <td>
                           <input type='checkbox' />
                          </td>
                          <td>
                            <a href='b-profile.html'>{item.first_name} {item.last_name}</a>
                          </td>
                          <td>$50.00</td>
                          <td>
                            <a href='b-invoice.html'>000001</a>
                          </td>
                          <td>2008/11/28</td>
                          <td>Awaiting Payment</td>
                          <td>[Manager - Steve]</td>

                      </tr>
                      )})}
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
