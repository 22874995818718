import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import TermsActions from "../../../redux/terms/actions";
import TermsForm from "./TermsForm/TermsForm";
import SuperFetch from "../../../helpers/superFetch";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term_conditions: undefined,
    };
  }

  componentDidMount = () => {
    const { fetchPrevTerms } = this.props;

    fetchPrevTerms();
  };

  handleTermsSubmit = () => {
    const { term_conditions } = this.state;
    let bodyFormData = new FormData();
    bodyFormData.append("term_conditions", term_conditions);

    SuperFetch.post("/settings", { term_conditions: term_conditions })
      .then((response) => {
        store.addNotification({
          message: "Terms successfully updated.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      })
      .catch((error) => {
       
      });
  };

  editorValue = (value) => {
    this.setState({ term_conditions: value });
  };

  render() {
    const { terms } = this.props;

    return (
      <div>
        <div className="main-content-inner">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="card-1">
                <div className="card-body-1">
                  <div className="card">
                    <div className="card-body">
                      <TermsForm
                        initialValues={
                          terms.term_conditions && {
                            term_conditions: terms.term_conditions,
                          }
                        }
                        onSubmit={this.handleTermsSubmit}
                        editorValue={this.editorValue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { createTerms, fetchTerms } = TermsActions;

const mapDispatchToProps = (dispatch) => {
  return {
    createNewTerms: ({ payload }) => createTerms({ payload }),
    fetchPrevTerms: () => fetchTerms({ dispatch }),
  };
};

const mapStateToProps = (state) => {
  return {
    terms: state.Terms.terms,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
