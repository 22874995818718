import React, { Component } from "react";
import NumberFormat from "react-number-format";
import SuperFetch from "../../../helpers/superFetch";
import InvoiceTable from "./InvoiceTable";
import NotesTable from "./NotesTable";
import ProposalTable from "./ProposalTable";
import Settings from "./Settings";
import { store } from "react-notifications-component";

class NotesSection extends Component {
  constructor(props) {
    super(props);
    this.state = { active: "invoices", showModal: false };
  }

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <div>{value}</div>}
      />
    );
  }

  tabChange = (tabText) => {
    this.setState({ active: tabText });
  };

  componentDidMount = () => {
    const { fetchCurrentInvoices, fetchCurrentProposals } = this.props;

    fetchCurrentInvoices();
    fetchCurrentProposals();
  };

  updateHandler = (values) => {
    values.reminder_enabled = values.reminder_enabled ? 1 : 0;
    const { match } = this.props;
    SuperFetch.post("/leads/" + match.params.id, {
      ...values,
      _method: "PUT",
    }).then((response) => {
      this.addNotification();
    });
  };

  addNotification() {
    return store.addNotification({
      message: "Lead updated successfully.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  }

  render() {
    const { active } = this.state;
    const {
      history,
      invoices,
      proposals,
      fetchSingleContact,
      contact,
    } = this.props;

    return (
      <div className="col-md-6 mt-3">
        <div className="card notes-card">
          <div className="card-body">
            <p className="onboard-heading">History & Notes</p>
            <hr />

            <ul className="contacts-tab nav nav-pills nav-fill">
              <li className="nav-item">
                <a
                  className={
                    active === "invoices" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.tabChange("invoices")}
                  href="#"
                >
                  Invoices
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    active === "proposals" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.tabChange("proposals")}
                  href="#"
                >
                  Proposals
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    active === "notes" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.tabChange("notes")}
                  href="#"
                >
                  Notes
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    active === "settings" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.tabChange("settings")}
                  href="#"
                >
                  Settings
                </a>
              </li>
            </ul>

            {active === "invoices" && (
              <InvoiceTable
                invoices={invoices}
                amountToFixed={this.amountToFixed}
                history={history}
              />
            )}
            {active === "proposals" && (
              <ProposalTable
                proposals={proposals}
                amountToFixed={this.amountToFixed}
                history={history}
              />
            )}
            {active === "notes" && this.props.selectedContact && (
              <NotesTable
                {...this.props}
                fetchSingleContact={fetchSingleContact}
              />
            )}
            {active === "settings" && this.props.selectedContact && (
              <>
                {console.log('contact.reminder_enabled', contact.reminder_enabled)}
                <Settings
                  onSubmit={this.updateHandler}
                  initialValues={
                    contact && {
                      reminder_enabled: contact.reminder_enabled ? true : false,
                    }
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NotesSection;
