import React, { Component } from "react";
import { connect } from 'react-redux';
import alertActions from '../../redux/alert/actions';

class Alert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            errorMessage: null,
            successMessage: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.error && (props.error.message !== state.errorMessage || props.error.error !== state.errorMessage)) {
            return {
                errorMessage: props.error ? (props.error.error ? props.error.error : props.error.message) : props.error,
                errors: props.error ? props.error.errors : {}
            };
        }

        if (props.success !== state.successMessage) {
            return {
                successMessage: props.success,
                errorMessage: null,
                successMessage: null,
            };
        }
        return {
            errorMessage: null,
            successMessage: null,
            errors: {}
        };
    }

    componentDidUpdate() {
        const { removeAlert } = this.props
        setTimeout(() => {
            removeAlert();
        }, 10000);
    }

    render() {
        const { errorMessage, successMessage } = this.state
        return (
            <div className="col-md-12">
                {errorMessage &&
                    <div className="alert alert-danger ">
                        {errorMessage}
                        {this.state.errors &&
                            <div>

                                {
                                    Object.keys(this.state.errors).length && <ul style={{ padding: '9px 3px 3px 15px', listStyleType: 'disc' }}>
                                        {Object.keys(this.state.errors).map((key) => {
                                            return (<li key={key}>{this.state.errors[key]}</li>)
                                        })}
                                    </ul>
                                }
                            </div>
                        }
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success ">
                        {successMessage}
                    </div>
                }
            </div>

        );
    }
}

const { removeAlert } = alertActions;
const mapDispatchToProps = { removeAlert };


const mapStateToProps = (state) => {
    return {
        error: state.Alert.get('error'),
        success: state.Alert.get('success'),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);