const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  FETCH_CURRENT_USER: "FETCH_CURRENT_USER",
  FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",
  SHOW_LOGIN_MODAL: "SHOW_LOGIN_MODAL",
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload,
  }),
  fetchCurrentUser: () => ({
    type: actions.FETCH_CURRENT_USER,
  }),
  register: (payload) => ({
    type: actions.REGISTER_REQUEST,
    payload,
  }),
  forgotPassword: (payload) => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
    payload,
  }),
  resetPassword: (payload) => ({
    type: actions.RESET_PASSWORD_REQUEST,
    payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  refreshToken: () => ({
    type: actions.REFRESH_TOKEN,
  }),
};
export default actions;
