import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
// import Alert from "../../uielements/alert";
// import ColumnLoader from "../../uielements/columnLoader";
import { apiSocketHost } from "../../../config";
import {
  renderField,
  renderSelect,
  FileInput,
} from "../../uielements/FormFields";
import { required, length, max } from "redux-form-validators";

const UploadFile = ({
  name,
  input: { value: omitValue, ...inputProps },
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
  ...props
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <div class="custom-file">
        <input
          disabled={readOnly}
          class="custom-file-input"
          type={type}
          {...inputProps}
          {...props}
        />
        <label class="custom-file-label" for="customFile">
          Attach
        </label>
      </div>
    </div>
    {omitValue && typeof omitValue == "string" && (
      <a target="_blank" href={`${apiSocketHost.fetchUrl}${omitValue}`}>
        <i class="fa fa-eye"></i> &nbsp; View Document
      </a>
    )}
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

const validate = (values) => {
  const errors = {};

  // if (!values.business_name) {
  //     errors.business_name = 'Business name is required'
  // }
  // if (!values.abn) {
  //     errors.abn = 'Business abn is required'
  // }
  // if (!values.address) {
  //     errors.address = 'Address is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'mobile is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'mobile is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'Mobile is required'
  // }
  // if (!values.suburb) {
  //     errors.mobile = 'Suburb is required'
  // }
  // if (!values.post_code) {
  //     errors.mobile = 'Postcode is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'Contact Number is required'
  // }
  // if (!values.contact_email) {
  //     errors.contact_email = 'Contact email is required'
  // } else

  if (values.contact_number && values.contact_number.length !== 10) {
    errors.contact_number = "Contact number should be 10 digits";
  }
  if (values.mobile && values.mobile.length !== 10) {
    errors.mobile = "Mobile should be 10 digits";
  }
  if (values.postcode && values.postcode.length != 4) {
    errors.postcode = "Post code should be 4 digits";
  }

  if (values.abn && values.abn.length > 11) {
    errors.abn = "Business ABN should be 11 digits";
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
    errors.contact_email = "Contact email is invalid";
  }

  return errors;
};

let OrganisationForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    organisationDetailsEdit,
    handleEditClick,
    handleCancelCLicked,
    handleSaveCLicked,
    organisationLoading,
    currentUser,
  } = props;

  const states = [
    {
      label: "Australian Capital Territory",
      value: "Australian Capital Territory",
    },
    {
      label: "New South Wales",
      value: "New South Wales",
    },
    {
      label: "Northern Territory",
      value: "Northern Territory",
    },
    {
      label: "Queensland",
      value: "Queensland",
    },
    {
      label: "South Australia",
      value: "South Australia",
    },
    {
      label: "Tasmania",
      value: "Tasmania",
    },
    {
      label: "Victoria",
      value: "Victoria",
    },
    {
      label: "Western Australia",
      value: "Western Australia",
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <p class="onboard-heading">Details</p>
      <hr />
      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            name="business_name"
            label="Business Name"
            component={renderField}
            type="text"
            id="business_name"
            validate={[required(), length({ min: 5 })]}
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="Business ABN"
            component={renderField}
            id="abn"
            name="abn"
            validate={[required(), length({ max: 11 })]}
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="Email Address"
            component={renderField}
            type="email"
            id="contact_email"
            name="contact_email"
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="Mobile"
            component={renderField}
            type="number"
            id="contact_number"
            name="contact_number"
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="Address"
            component={renderField}
            type="text"
            id="address"
            name="address"
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>
      {/* <div className="form-group">
        <Field
          label="Mobile"
          component={renderField}
          type="number"
          id="mobile"
          name="mobile"
          readOnly={!organisationDetailsEdit}
        />
      </div> */}
      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            component={renderField}
            label="Suburb"
            type="text"
            id="suburb"
            name="suburb"
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            label="State"
            id="state"
            name="state"
            component={renderSelect}
            options={states}
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-7 mt-3">
        <label class="sr-only" for="inlineFormInputGroup"></label>
        <div class="input-group mb-2">
          <Field
            component={renderField}
            label="Postcode"
            type="number"
            id="postcode"
            name="postcode"
            // readOnly={!organisationDetailsEdit}
          />
        </div>
      </div>

      <div class="col-md-12 mt-3">
        <Field
          label="Logo"
          component={FileInput}
          type="file"
          id="logo"
          name="logo"
          accept="image/*"
        />
      </div>

      {/* <div class="col-md-12 mt-3">
        <Field
          label="ATO/ASIC Document detailing business name, address and ABN"
          placeholder="Upload new"
          type={!organisationDetailsEdit ? "text" : "file"}
          id="asic_document"
          name="asic_document"
          readOnly={!organisationDetailsEdit}
          component={UploadFile}
        />
      </div> */}
      {/* <div className="form-group">
        <Field
          label="Industry"
          readOnly={!organisationDetailsEdit}
          name="industry"
          component={renderSelect}
          options={[
            {
              label: "Health & Recreation",
              value: "Health & Recreation"
            }
          ]}
        />
      </div> */}
      {/* {!organisationDetailsEdit ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "5px",
          }}
        >
          {currentUser &&
            currentUser.primary_organisation &&
            currentUser.primary_organisation.id ===
              currentUser.default_organisation_id && (
              <>
                <hr />
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ width: "150px" }}
                  onClick={(event) => {
                    event.preventDefault();
                    handleEditClick("organisation");
                  }}
                >
                  Edit
                </button>
              </>
            )}
        </div>
      ) : ( */}
      {/* <> */}
      <hr />
      {/* <div className="form-buttons-group"> */}
      <div class="invoice-buttons text-center">
        <button type="submit" class="btn btn-success">
          Update
        </button>
      </div>
      {/* <button
              type="button"
              className="btn  btn-secondary"
              style={{ width: "100px" }}
              onClick={(event) => {
                event.preventDefault();
                handleCancelCLicked("organisation");
              }}
            >
              Cancel
            </button> */}
      {/* </div>
        </>
      )} */}
    </form>
  );
};

OrganisationForm = reduxForm({
  // a unique name for the form
  form: "organisation",
  validate,
  enableReinitialize: true,
  // initialsValues: this.props.initialValues
})(OrganisationForm);

export default OrganisationForm;
