import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import actions from "./actions";
import alertActions from "../alert/actions";
import TermsHelper from "../../helpers/termsHelper";

export function* fetchTerms() {
  yield takeEvery("FETCH_TERMS", function* () {
    try {
      const result = yield call(TermsHelper.fetchTerms);

      if (result.data) {
        yield put({
          type: actions.SET_DATA,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export function* createTerms() {
  yield takeEvery("SUBMIT_TERMS", function* ({ payload }) {
    try {
      const result = yield call(TermsHelper.createTerms, payload);
      if (result.data) {
        yield put({
          type: alertActions.ADD_SUCCESS,
          payload: "Terms Created Successfully",
        });
      }
    } catch (error) {
      yield put({
        type: alertActions.ADD_ERROR,
        payload: error,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchTerms), fork(createTerms)]);
}
