import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import ColumnLoader from "../../uielements/columnLoader";
import DateDropdown from "../../DateDropdown/DateDropdown";

const validate = (values) => {
  const errors = {};

  if (values.phone && values.phone.length !== 10) {
    errors.phone = "Phone number should be min 10 digits";
  }
  if (values.post_code && values.post_code.length !== 4) {
    errors.post_code = "Post code should be 4 digits";
  }
  // if (!values.abn) {
  //     errors.abn = 'Business abn is required'
  // }
  // if (!values.address) {
  //     errors.address = 'Address is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'mobile is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'mobile is required'
  // }
  // if (!values.mobile) {
  //     errors.mobile = 'Mobile is required'
  // }
  // if (!values.suburb) {
  //     errors.mobile = 'Suburb is required'
  // }
  // if (!values.post_code) {
  //     errors.mobile = 'Postcode is required'
  // }
  // if (!values.contact_number) {
  //     errors.contact_number = 'Contact Number is required'
  // }
  // if (!values.contact_email) {
  //     errors.contact_email = 'Contact email is required'
  // } else

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)) {
    errors.contact_email = "Contact email is invalid";
  }

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        readOnly={readOnly}
        id="inlineFormInputGroup"
        id={id}
      />
      {touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span className="warning">{warning}</span>))}
    </div>
  </div>
);

const renderSelect = ({
  input,
  label,
  type,
  readOnly,
  id,
  options,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <select
        {...input}
        className="form-control"
        style={{ height: "50px" }}
        disabled={readOnly}
      >
        <option value=""></option>
        {options &&
          options.map((option, index) => (
            <option key={id + index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span className="warning">{warning}</span>))}
    </div>
  </div>
);

let EditCreditCardForm = (props) => {
  const {
    handleSubmit,
    creditFormEdit,
    handleEditClick,
    handleCancelCLicked,
    handleSaveCLicked,
    depositAccountEdit,
    currentUser,
    removeCreditCard,
  } = props;

  const months = [];

  for (let i = 1; i <= 12; i++) {
    months.push({
      label: ("0" + i).slice(-2),
      value: ("0" + i).slice(-2),
    });
  }
  const years = [];

  for (let i = 2020; i <= 2042; i++) {
    years.push({
      label: i,
      value: i,
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="col-md-12 mt-3">
        <Field
          label="Cardholder Name"
          type="text"
          id="card_holder_name"
          name="card_holder_name"
          component={renderField}
          // readOnly={!creditFormEdit}
        />
      </div>
      <div className="col-md-12 mt-3">
        <Field
          label="Expiry Month"
          id="expiry_month"
          name="expiry_month"
          component={renderSelect}
          options={months}
          // readOnly={!creditFormEdit}
        />
      </div>
      <div className="col-md-12 mt-3">
        <Field
          label="Expiry Year"
          id="expiry_year"
          name="expiry_year"
          component={renderSelect}
          options={years}
          // readOnly={!creditFormEdit}
        />
      </div>

      {/* {!creditFormEdit ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "5px",
          }}
        >
          {currentUser &&
            currentUser.primary_organisation &&
            currentUser.primary_organisation.id ===
              currentUser.default_organisation_id && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ width: "100px" }}
                  onClick={(event) => {
                    event.preventDefault();
                    handleEditClick("creditCard");
                  }}
                >
                  Edit
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ width: "180px" }}
                  data-toggle="modal"
                  data-target="#deleteCard"
                >
                  Remove Card & Add New
                </button>
                <div className="modal fade" id="deleteCard">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">
                          Are you sure, you want to remove this card?
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary"
                          onClick={() => removeCreditCard()}
                          data-dismiss="modal"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      ) : ( */}
      <>
        <hr />
        <div class="invoice-buttons text-center">
          <button type="submit" class="btn btn-success">
            Update
          </button>
        </div>
      </>
      {/* )} */}
    </form>
  );
};

EditCreditCardForm = reduxForm({
  form: "EditCreditCardForm",
  validate,
  enableReinitialize: true,
})(EditCreditCardForm);

export default EditCreditCardForm;
