import actions from "./actions";

const initState = { terms: {} };
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_DATA:
      return {
        ...state,
        terms: action.payload.data,
      };
    default:
      return state;
  }
}
