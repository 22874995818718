import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import organisationSagas from "./organisation/saga";
import SubscriptionSagas from "./subscription/saga";
import UserSagas from "./user/saga";
import termsSagas from "./terms/saga";
import contactSaga from "./contacts/saga";
import flowSaga from "./flow/saga";
import invoiceSaga from "./invoice/saga";
import proposalsSaga from "./Proposals/saga";
import productSaga from "./Products/saga";
// import notificationSaga from  './notification/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    organisationSagas(),
    SubscriptionSagas(),
    UserSagas(),
    termsSagas(),
    contactSaga(),
    flowSaga(),
    invoiceSaga(),
    proposalsSaga(),
    productSaga(),
  ]);
}
