import React, { Component } from "react";
import SuperFetch from "../../../helpers/superFetch";
import { replace } from "react-router-redux";
import { store } from "react-notifications-component";
import DraftComponent from "../../DraftComponent";
export default class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signature: "",
      invite_message: "",
      terms_conditions: "",
      welcome_email: "",
      welcome_sms: "",
      payment_reminder: "",
      payment_successful: "",
      payment_unsuccessful: "",
      retry_attempt_unsuccessful: "",
      payment_suspension: "",
      proposal: "",
      invoice: "",
      disabled: false,
      signature: "",
      invoice_notes: null,
      proposal_notes: null,

      proposal_expiry: null,
      late_payment_reminder: null,
      late_payment_reminder_ongoing: null,
      failed_payment_reminder_no_retry: null,
      payment_plan_reminder: null,
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - Communications";
    this.initializeTemplates();
  }

  initializeTemplates = () => {
    SuperFetch.get("/templates")
      .then((response) => {
        const data = response.data.template_data;
        this.setState({
          invite_message: data.invite_message ? data.invite_message : "<p></p>",
          terms_conditions: data.terms_conditions
            ? data.terms_conditions
            : "<p></p>",
          welcome_email: data.welcome_email ? data.welcome_email : "<p></p>",
          welcome_sms: data.welcome_sms ? data.welcome_sms : "<p></p>",
          payment_reminder: data.payment_reminder
            ? data.payment_reminder
            : "<p></p>",
          payment_successful: data.payment_successful
            ? data.payment_successful
            : "<p></p>",
          payment_unsuccessful: data.payment_unsuccessful
            ? data.payment_unsuccessful
            : "<p></p>",
          retry_attempt_unsuccessful: data.retry_attempt_unsuccessful
            ? data.retry_attempt_unsuccessful
            : "<p></p>",
          payment_suspension: data.payment_suspension
            ? data.payment_suspension
            : "<p></p>",
          invoice: data.invoice,
          proposal: data.proposal,
          signature: data.signature,
          invoice_notes: data.invoice_notes ? data.invoice_notes : "<p></p>",
          proposal_notes: data.proposal_notes ? data.proposal_notes : "<p></p>",
          proposal_expiry: data.proposal_expiry
            ? data.proposal_expiry
            : "<p></p>",
          late_payment_reminder: data.late_payment_reminder
            ? data.late_payment_reminder
            : "<p></p>",
          late_payment_reminder_ongoing: data.late_payment_reminder_ongoing
            ? data.late_payment_reminder_ongoing
            : "<p></p>",
          failed_payment_reminder: data.failed_payment_reminder
            ? data.failed_payment_reminder
            : "<p></p>",
          failed_payment_reminder_ongoing: data.failed_payment_reminder_ongoing
            ? data.failed_payment_reminder_ongoing
            : "<p></p>",
          failed_payment_reminder_no_retry: data.failed_payment_reminder_no_retry
            ? data.failed_payment_reminder_no_retry
            : "<p></p>",
            payment_plan_reminder: data.payment_plan_reminder
            ? data.payment_plan_reminder
            : "<p></p>",
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  handleUserInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleEdit() {
    var data = {
      template_data: {
        ...this.state,
      },
    };
    this.setState({ disabled: false });
    if (!this.state.disabled) {
      delete data["template_data"]["disabled"];
      SuperFetch.post("/templates", data)
        .then((response) => {
          this.setState({ disabled: true });
          store.addNotification({
            message: "Template updated successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.initializeTemplates();
        })
        .catch((error) => {
          store.addNotification({
            message: "Something went wrong. Please try after some time.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    }
  }

  render() {
    console.log('state', this.state)
    return (
      <div className="main-content-inner">
        <div className="row mt-3 mb-5">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <p class="onboard-heading">Communications</p>
                <hr />
                <div id="accordion32" className="according accordion-s3">
                  {/* 1. Email Signature */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion1"
                      >
                        Email Signature
                      </a>
                    </div>
                    <div
                      id="accordion1"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <form
                        onClick={(e) => {
                          this.setState({ disabled: false });
                        }}
                      >
                        {this.state.signature && (
                          <DraftComponent
                            initialValue={
                              this.state.signature && this.state.signature
                            }
                            getValue={(value) =>
                              this.setState({ signature: value })
                            }
                          />
                        )}
                        {/* <textarea
                          name="signature"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          onChange={(e) => this.handleUserInput(e)}
                          value={
                            this.state.signature ? this.state.signature : ""
                          }
                        ></textarea> */}
                      </form>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 2. Invoice Message */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion2"
                      >
                        Invoice Message
                      </a>
                    </div>
                    <div
                      id="accordion2"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.invoice && (
                          <DraftComponent
                            initialValue={
                              this.state.invoice && this.state.invoice
                            }
                            getValue={(value) =>
                              this.setState({ invoice: value })
                            }
                          />
                        )}
                        {/* <textarea
                          name="invoice"
                          className="form-control"
                          onChange={(e) => this.handleUserInput(e)}
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={this.state.invoice ? this.state.invoice : ""}
                        ></textarea> */}
                        <div className="templateFooter">
                          <p>
                            [View Invoice Link]
                            <br />
                          </p>
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 3. Invoice Notes */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion3"
                      >
                        Invoice Notes
                      </a>
                    </div>
                    <div
                      id="accordion3"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <form>
                        {this.state.invoice_notes && (
                          <DraftComponent
                            initialValue={
                              this.state.invoice_notes &&
                              this.state.invoice_notes
                            }
                            getValue={(value) =>
                              this.setState({ invoice_notes: value })
                            }
                          />
                        )}
                        {/* <textarea
                          name="invoice_notes"
                          className="form-control"
                          onChange={(e) => this.handleUserInput(e)}
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={
                            this.state.invoice_notes
                              ? this.state.invoice_notes
                              : ""
                          }
                        ></textarea> */}
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 4. Proposal Message */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion4"
                      >
                        Proposal Message
                      </a>
                    </div>
                    <div
                      id="accordion4"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.proposal && (
                          <DraftComponent
                            initialValue={
                              this.state.proposal && this.state.proposal
                            }
                            getValue={(value) =>
                              this.setState({ proposal: value })
                            }
                          />
                        )}
                        {/* <textarea
                          disabled={this.state.disabled}
                          name="proposal"
                          className="form-control"
                          onChange={(e) => this.handleUserInput(e)}
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={this.state.proposal ? this.state.proposal : ""}
                        ></textarea> */}
                      </form>
                      <div className="templateFooter">
                        <p>
                          [View Proposal Link]
                          <br />
                        </p>
                        <p>{`<EMAIL SIGNATURE as per above>`}</p>
                      </div>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 5. Proposal Message */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion5"
                      >
                        Proposal Notes
                      </a>
                    </div>
                    <div
                      id="accordion5"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <form>
                        {this.state.proposal_notes && (
                          <DraftComponent
                            initialValue={
                              this.state.proposal_notes &&
                              this.state.proposal_notes
                            }
                            getValue={(value) =>
                              this.setState({ proposal_notes: value })
                            }
                          />
                        )}
                        {/* <textarea
                          name="proposal_notes"
                          className="form-control"
                          onChange={(e) => this.handleUserInput(e)}
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={
                            this.state.proposal_notes
                              ? this.state.proposal_notes
                              : ""
                          }
                        ></textarea> */}
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 6. Invite Message */}
                  {/* <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion6"
                      >
                        Invite Message
                      </a>
                    </div>
                    <div
                      id="accordion6"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                        <p>
                          Please select the following button to register a
                          product with our business.
                        </p>
                        <p>[View Products Link]</p>
                      </div>

                      <form>
                        {
                          this.state.invite_message && (
                            <DraftComponent
                              initialValue={
                                this.state.invite_message &&
                                this.state.invite_message
                              }
                              getValue={(value) =>
                                this.setState({ invite_message: value })
                              }
                            />
                          )
                          : (
                            <DraftComponent
                              initialValue={
                                this.state.invite_message &&
                                this.state.invite_message
                              }
                              getValue={(value) =>
                                this.setState({ invite_message: value })
                              }
                            />
                          )
                        } */}
                  {/* <textarea
                          name="invite_message"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          onChange={(e) => {
                            this.handleUserInput(e);
                          }}
                          rows="5"
                          style={{ marginTop: "10px" }}
                          defaultValue={
                            this.state.invite_message
                              ? this.state.invite_message
                              : ""
                          }
                        ></textarea> */}

                  {/* <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div> */}

                  {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          name="invite_message"
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button> */}
                  {/* <button
                          type='button'
                          className='btn btn-primary'
                          style={{ width: "150px" }}
                          data-toggle='modal'
                          data-target='#modal-payreminder'
                        >
                          Preview
                        </button> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}

                  {/* <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion002"
                      >
                        Terms and Conditions
                      </a>
                    </div>
                    <div
                      id="accordion002"
                      className="collapse"
                      data-parent="#accordion3"
                    >
                      <form
                        onClick={(e) => {
                          this.setState({ disabled: false });
                        }}
                      >
                        <textarea
                          disabled={this.state.disabled}
                          name="terms_conditions"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          onChange={(e) => this.handleUserInput(e)}
                          value={
                            this.state.terms_conditions
                              ? this.state.terms_conditions
                              : ""
                          }
                        ></textarea>
                      </form>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div>
                    </div>
                  </div> */}

                  {/* 7. Welcome Email */}
                  {/* <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion7"
                      >
                        Welcome Email
                      </a>
                    </div>
                    <div
                      id="accordion7"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                       
                      </div>

                      <form>
                        {this.state.invite_message && (
                          <DraftComponent
                            initialValue={this.state.welcome_email}
                            getValue={(value) =>
                              this.setState({ welcome_email: value })
                            }
                          />
                        )}
                       

                        <div className="templateFooter">
                          <p>
                            An account with Cloud Collect has been created so
                            you can manage your contact and payment details.
                          </p>
                          <p>
                            <u>Login Details:</u>
                            <br />
                            [Login Link]
                            <br />
                            Username: [Email]
                            <br />
                            Password: [Password]
                            <br />
                            <br />
                          </p>

                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                     
                    </div>
                  </div> */}

                  {/* 8. Payment Reminder */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion8"
                      >
                        Payment Reminder (Invoice Due Date)
                      </a>
                    </div>
                    <div
                      id="accordion8"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.payment_reminder && (
                          <DraftComponent
                            initialValue={this.state.payment_reminder}
                            getValue={(value) =>
                              this.setState({ payment_reminder: value })
                            }
                          />
                        )}
                   

                        <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    
                    </div>
                  </div>

                  {/* 8. Payment Reminder */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordionX8"
                      >
                        Payment Reminder (Pay Later & Payment Plan)
                      </a>
                    </div>
                    <div
                      id="accordionX8"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.payment_plan_reminder && (
                          <DraftComponent
                            initialValue={this.state.payment_plan_reminder}
                            getValue={(value) =>
                              this.setState({ payment_plan_reminder: value })
                            }
                          />
                        )}
                   

                        <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    
                    </div>
                  </div>

                  {/* 9. Payment Successful */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion9"
                      >
                        Payment Successful
                      </a>
                    </div>
                    <div
                      id="accordion9"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.payment_successful && (
                          <DraftComponent
                            initialValue={
                              this.state.payment_successful &&
                              this.state.payment_successful
                            }
                            getValue={(value) =>
                              this.setState({ payment_successful: value })
                            }
                          />
                        )}
                        {/* <textarea
                          name="payment_successful"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          onChange={(e) => this.handleUserInput(e)}
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={
                            this.state.payment_successful
                              ? this.state.payment_successful
                              : ""
                          }
                        ></textarea> */}
                        <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>

                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 10. Payment Unsuccessful */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion10"
                      >
                        Failed Payment (Retry Enabled)
                      </a>
                    </div>
                    <div
                      id="accordion10"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.payment_unsuccessful && (
                          <DraftComponent
                            initialValue={
                              this.state.payment_unsuccessful &&
                              this.state.payment_unsuccessful
                            }
                            getValue={(value) =>
                              this.setState({ payment_unsuccessful: value })
                            }
                          />
                        )}

                        {/* <textarea
                          name="payment_unsuccessful"
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          onChange={(e) => this.handleUserInput(e)}
                          value={
                            this.state.payment_unsuccessful
                              ? this.state.payment_unsuccessful
                              : ""
                          }
                        ></textarea> */}

                        <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>

                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 11. Payment Unsuccessful */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion11"
                      >
                        Failed Payment (Retry Enabled & Ongoing)
                      </a>
                    </div>
                    <div
                      id="accordion11"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.retry_attempt_unsuccessful && (
                          <DraftComponent
                            initialValue={
                              this.state.retry_attempt_unsuccessful &&
                              this.state.retry_attempt_unsuccessful
                            }
                            getValue={(value) =>
                              this.setState({
                                retry_attempt_unsuccessful: value,
                              })
                            }
                          />
                        )}

                        {/* <textarea
                          name="retry_attempt_unsuccessful"
                          onChange={(e) => this.handleUserInput(e)}
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={
                            this.state.retry_attempt_unsuccessful
                              ? this.state.retry_attempt_unsuccessful
                              : ""
                          }
                        ></textarea> */}

                        <div className="templateFooter">
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>

                      {/* <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ width: "150px", marginRight: "5px" }}
                          onClick={(e) => this.handleEdit()}
                        >
                          {this.state.disabled ? "Edit" : "Save"}
                        </button>
                      </div> */}
                    </div>
                  </div>

                  {/* 18. FAILED PAYMENT NOTIFICATION (NO RETRY ENABLED) */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion18"
                      >
                        Failed Payment (No Retry Enabled)
                      </a>
                    </div>
                    <div
                      id="accordion18"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <div className="templateFooter">
                        <p>
                          [View Invoice Link]
                          <br />
                        </p>
                      </div>
                      <form>
                        {this.state.failed_payment_reminder_no_retry && (
                          <DraftComponent
                            initialValue={
                              this.state.failed_payment_reminder_no_retry &&
                              this.state.failed_payment_reminder_no_retry
                            }
                            getValue={(value) =>
                              this.setState({
                                failed_payment_reminder_no_retry: value,
                              })
                            }
                          />
                        )}
                      </form>
                      <p>{`<EMAIL SIGNATURE as per above>`}</p>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 12. Payment Suspension */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion12"
                      >
                        Payment Suspension
                      </a>
                    </div>
                    <div
                      id="accordion12"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <form>
                        {this.state.payment_suspension && (
                          <DraftComponent
                            initialValue={
                              this.state.payment_suspension &&
                              this.state.payment_suspension
                            }
                            getValue={(value) =>
                              this.setState({
                                payment_suspension: value,
                              })
                            }
                          />
                        )}
                        <div className="templateFooter">
                          <p>
                            [View Invoice Link]
                            <br />
                          </p>
                          <p>{`<EMAIL SIGNATURE as per above>`}</p>
                        </div>
                      </form>

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 13. Proposal Expiry */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion13"
                      >
                        Proposal Expiry
                      </a>
                    </div>
                    <div
                      id="accordion13"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <div className="templateFooter">
                        <p>
                          [View Proposal & Accept]
                          <br />
                        </p>
                      </div>
                      <form>
                        {this.state.proposal_expiry && (
                          <DraftComponent
                            initialValue={
                              this.state.proposal_expiry &&
                              this.state.proposal_expiry
                            }
                            getValue={(value) =>
                              this.setState({
                                proposal_expiry: value,
                              })
                            }
                          />
                        )}
                      </form>
                      <p>{`<EMAIL SIGNATURE as per above>`}</p>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 14. LATE PAYMENT REMINDER */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion14"
                      >
                        Late Payment Reminder
                      </a>
                    </div>
                    <div
                      id="accordion14"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <div className="templateFooter">
                        <p>
                          [View Invoice Link]
                          <br />
                        </p>
                      </div>
                      <form>
                        {this.state.late_payment_reminder && (
                          <DraftComponent
                            initialValue={
                              this.state.late_payment_reminder &&
                              this.state.late_payment_reminder
                            }
                            getValue={(value) =>
                              this.setState({
                                late_payment_reminder: value,
                              })
                            }
                          />
                        )}
                      </form>
                      <p>{`<EMAIL SIGNATURE as per above>`}</p>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* 15. LATE PAYMENT REMINDER (ONGOING) */}
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href="#accordion15"
                      >
                        Late Payment Reminder (Ongoing)
                      </a>
                    </div>
                    <div
                      id="accordion15"
                      className="collapse"
                      data-parent="#accordion32"
                    >
                      <div className="templateHeader">
                        <p>Hi [Customer Name],</p>
                      </div>
                      <div className="templateFooter">
                        <p>
                          [View Invoice Link]
                          <br />
                        </p>
                      </div>
                      <form>
                        {this.state.late_payment_reminder_ongoing && (
                          <DraftComponent
                            initialValue={
                              this.state.late_payment_reminder_ongoing &&
                              this.state.late_payment_reminder_ongoing
                            }
                            getValue={(value) =>
                              this.setState({
                                late_payment_reminder_ongoing: value,
                              })
                            }
                          />
                        )}
                      </form>
                      <p>{`<EMAIL SIGNATURE as per above>`}</p>
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={(e) => this.handleEdit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>

                
                  
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title">Glossary</h4>
                <p>
                  Insert the following tags into your templates to return
                  specific values
                </p>
                <br />
                <div className="single-table">
                  <div className="table-responsive">
                    <table className="table text-center">
                      <thead className="text-uppercase">
                        <tr>
                          <th scope="col">Word</th>
                          <th scope="col">Tag</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Amount</td>
                          <td>[amt]</td>
                        </tr>
                        <tr>
                          <td>Customer Name</td>
                          <td>[cust]</td>
                        </tr>
                        <tr>
                          <td>Date Due</td>
                          <td>[date-due]</td>
                        </tr>
                        <tr>
                          <td>Days</td>
                          <td>[days]</td>
                        </tr>
                        <tr>
                          <td>Late Fee Amount</td>
                          <td>[late-fee]</td>
                        </tr>
                        <tr>
                          <td>Organisation Name</td>
                          <td>[org]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
