import { Map } from 'immutable';

const initState = new Map({ isLoading:false });

export const loaderReducer= (state = initState, action) => {
    switch (action.type) {
        case "@@wizard/SET_LOADER":
            return state
            .set('isLoading', action.isLoading)
        case "@@wizard/UNSET_LOADER":
            return { ...state, isLoading: action.isLoading }
        default:
            return state
            
    }
}