import React, { Component } from "react";
import ColumnLoader from "../../uielements/columnLoader";
import SuperFetch from "../../../helpers/superFetch";
import Alert from "../../uielements/alert";
import { getUser } from "../../../helpers/utility";
// import "react-datepicker/dist/react-datepicker.css";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import ProductActions from "../../../redux/Products/action";
import ConfirmComponent from "../../ConfirmComponent/ConfirmComponent";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      name: "",
      amount: "",
      description: "",
      type: "Single",
      checkbox: true,
      frequency: "",
      name_err: "",
      amount_err: "",
      hidden: null,
      gst_free: false,
      showModal: false,
    };
    this.checkBoxUpdate = this.checkBoxUpdate.bind(this);
    this.checkHidden = this.checkHidden.bind(this);
  }

  checkBoxUpdate() {
    this.setState({
      checkbox: !this.state.checkbox,
    });
  }

  checkHidden(e) {
    this.setState({ hidden: !this.state.hidden });
  }

  handleUserInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    const { selectedProduct } = this.props;
    document.title = "Cloud Collect - Edit Product";
    let hidden = null;
    let gst_free = null;
    SuperFetch.get("/products/" + this.props.match.params.id)
      .then((response) => {
        selectedProduct({ payload: response.data });
        if (response.data.hidden === 1) hidden = true;
        if (response.data.hidden === 0) hidden = false;
        this.setState({
          name: response.data.name,
          amount: response.data.amount,
          description: response.data.description,
          type: response.data.type,
          frequency: response.data.frequency,
          isLoading: false,
          hidden: hidden,
          gst_free: response.data.gst_free === 1 ? true : false,
        });
      })
      .catch((error) => {
        throw error;
      });
  }

  validate = async () => {
    let isError = false;

    const errors = {
      name_err: "",
      amount_err: "",
      description_err: "",
      frequency_err: "",
    };

    if (this.state.name === "") {
      isError = true;
      errors.name_err = "Please enter Product ID.";
    } else {
      const res = await SuperFetch.get(
        `/products/validate?name=${this.state.name}&id=${this.props.match.params.id}`
      );
      if (res && !res.valid) {
        isError = true;
        errors.name_err = "Product ID already taken.";
      } else {
        errors.name_err = "";
      }
    }

    if (this.state.amount === "") {
      isError = true;
      errors.amount_err = "Please enter Unit Price.";
    }

    if (this.state.description === "") {
      isError = true;
      errors.description_err = "Please enter description.";
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  upfrontPaymentValue() {
    let upfront_payment = null;
    if (this.state.checkbox === true) upfront_payment = 1;
    if (this.state.checkbox === false) upfront_payment = 0;
    return upfront_payment;
  }
  hiddenValue() {
    let hidden = null;
    if (this.state.hidden === true) hidden = 1;
    if (this.state.hidden === false) hidden = 0;
    return hidden;
  }

  gstFreeValue() {
    let gstFree = null;
    if (this.state.gst_free === true) gstFree = 1;
    if (this.state.gst_free === false) gstFree = 0;
    return gstFree;
  }

  singleOrRecuring() {
    const userinfo = getUser();
    const data = {
      _method: "PUT",
      name: this.state.name,
      amount: this.state.amount,
      type: this.state.type,
      description: this.state.description,
      // upfront_payment: this.upfrontPaymentValue(),
      duration: this.state.duration,
      payment_in: this.state.payment_in,
      userId: userinfo.id,
      organisation_id: userinfo.organisation.id,
      hidden: this.hiddenValue(),
      gst_free: this.gstFreeValue(this.state.gst_free),
    };
    if (this.state.type === "Recurring") data.frequency = this.state.frequency;
    return data;
  }

  handleSubmit = async (e) => {
    if (this.state.type === "Single") {
      this.setState({ frequency: "" });
    }
    e.preventDefault();
    let valid = await this.validate();

    if (!valid) {
      this.setState({ isLoading: true });
      SuperFetch.post(
        `/products/${this.props.match.params.id}`,
        this.singleOrRecuring()
      )
        .then((response) => {
          this.setState({ isLoading: false });
          store.addNotification({
            message: "Product updated successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.props.history.push("/products");
          SuperFetch.get("/products").then((response) => {

            this.props.history.push({
              state: { detail: response.data },
            });
          });
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      // due_date: '',
      // start_date: '',
      // end_date: '',
    });
  };

  checkGstFree = (e) => {
    this.setState({ gst_free: e.target.value === "true" ? true : false });
  };

  removeHandler = () => {
    const { product, history } = this.props;

    SuperFetch.delete("/products/" + product.id, {
      _method: "DELETE",
    }).then((response) => {
      this.setState({ showModal: false });
      history.push("/products");
      store.addNotification({
        message: "Product deleted successfully.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    });
  };

  render() {
    const { showModal } = this.state;
    return (
      <>
        {/* <div className="row">
          <Alert />
        </div> */}
        <div class="main-content-inner">
          <ConfirmComponent
            title="Delete"
            body="Are you sure you want to delete this product?"
            buttonText="Delete"
            show={showModal}
            handleClose={() => this.setState({ showModal: false })}
            confirmHandler={this.removeHandler}
          />
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card" id="card-inner">
                <div className="card-body">
                  {this.state.isLoading === true ? (
                    <ColumnLoader />
                  ) : (
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <p class="onboard-heading">Product Details</p>
                      <hr />

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Product ID</div>
                          </div>
                          <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            className="form-control"
                            onChange={(e) => this.handleUserInput(e)}
                            id="inlineFormInputGroup"
                            placeholder=""
                          />
                          {this.state.name_err !== "" ? (
                            <span className="m-form__help w-100 d-inline-block text-danger">
                              {this.state.name_err}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Unit Price</div>
                          </div>
                          <input
                            type="number"
                            step="0.01"
                            name="amount"
                            value={this.state.amount}
                            className="form-control"
                            onChange={(e) => this.handleUserInput(e)}
                            id="inlineFormInputGroup"
                            placeholder=""
                          />
                          {this.state.amount_err !== "" ? (
                            <span className="m-form__help w-100 d-inline-block text-danger">
                              {this.state.amount_err}
                            </span>
                          ) : null}
                        </div>
                        <span>Input GST-Exclusive Unit Price</span>
                      </div>

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">GST Code</div>
                          </div>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            onChange={this.checkGstFree}
                            name="gst_free"
                            style={{ height: "45px" }}
                          >
                            <option
                              value={false}
                              selected={this.state.gst_free === false && true}
                            >
                              GST
                            </option>
                            <option
                              value={true}
                              selected={this.state.gst_free === true && true}
                            >
                              GST-Free
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="gst_free"
                            value={this.state.gst_free}
                            checked={this.state.gst_free}
                            onChange={this.checkGstFree}
                            className="custom-control-input"
                            id="customCheck4"
                          />
                          <label
                            className="custom-control-label custom-control-invoice"
                            htmlFor="customCheck4"
                          >
                            GST Free
                          </label>
                        </div>
                      </div> */}

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">Description</div>
                            </div>
                            <textarea
                              onChange={(e) => this.handleUserInput(e)}
                              value={this.state.description}
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="8"
                              name="description"
                            ></textarea>
                            {this.state.description_err !== "" ? (
                              <span className="m-form__help w-100 d-inline-block text-danger">
                                {this.state.description_err}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Type</div>
                          </div>
                          <select
                            style={{ height: "44.44px" }}
                            className="form-control"
                            name="type"
                            onChange={(e) => this.handleOptionChange(e)}
                          >
                            {this.state.type === "Single" ? (
                              <>
                                <option value="Single" selected>
                                  Single
                                </option>
                              </>
                            ) : (
                              <>
                                <option value="Single">Single</option>
                              </>
                            )}
                            {this.state.type === "Upfront" ? (
                              <>
                                <option value="Upfront" selected>
                                  Upfront
                                </option>
                              </>
                            ) : (
                              <>
                                <option value="Upfront">Upfront</option>
                              </>
                            )}
                            {this.state.type === "Recurring" ? (
                              <option value="Recurring" selected>
                                Recurring
                              </option>
                            ) : (
                              <option value="Recurring">Recurring</option>
                            )}
                          </select>
                        </div>
                      </div> */}
                      {/* {this.state.type === "Single" ||
                      this.state.type === "Upfront" ? null : (
                        <div class="col-md-8 mt-3">
                          <label
                            class="sr-only"
                            for="inlineFormInputGroup"
                          ></label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">Frequency</div>
                            </div>
                            <select
                              style={{ height: "45.44px" }}
                              className="form-control"
                              name="frequency"
                              id="exampleFormControlSelect1"
                              onChange={(e) => this.handleOptionChange(e)}
                            >
                              {this.state.frequency === "" ? (
                                <option value="" selected></option>
                              ) : (
                                <option value=""></option>
                              )}
                              {this.state.frequency === "Weekly" ? (
                                <option value="weekly" selected>
                                  Weekly 1-52
                                </option>
                              ) : (
                                <option value="weekly">Weekly 1-52</option>
                              )}
                              {this.state.frequency === "Forthrightly" ? (
                                <option value="Forthrightly" selected>
                                  Forthrightly 1-26
                                </option>
                              ) : (
                                <option value="Forthrightly">
                                  Forthrightly 1-26
                                </option>
                              )}
                              {this.state.frequency === "Monthly" ? (
                                <option value="Monthly" selected>
                                  Monthly 1-12
                                </option>
                              ) : (
                                <option value="Monthly">Monthly 1-12</option>
                              )} */}
                      {/*{*/}
                      {/*this.state.frequency == 'Quarterly'*/}
                      {/*?*/}
                      {/*<option value='Quarterly' selected>Quarterly</option>*/}
                      {/*:*/}
                      {/*<option value='Quarterly'>Quarterly</option>*/}
                      {/*}*/}
                      {/*{*/}
                      {/*this.state.frequency == 'Annually'*/}
                      {/*?*/}
                      {/*<option value='Annually' selected>Annually</option>*/}
                      {/*:*/}
                      {/*<option value='Annually'>Annually</option>*/}
                      {/*}*/}
                      {/* </select>
                          </div>
                        </div>
                      )} */}
                      {/* <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="hidden"
                            onChange={this.checkHidden.bind(this)}
                            checked={this.state.hidden}
                            value={this.state.hidden}
                            className="custom-control-input"
                            id="customCheck2"
                          />
                          <label
                            className="custom-control-label custom-control-invoice"
                            htmlFor="customCheck2"
                          >
                            Public Product
                          </label>
                        </div>
                      </div> */}
                      <hr />
                      <div class="invoice-buttons text-center">
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={() => this.setState({ showModal: true })}
                        >
                          Remove
                        </button>
                        <button type="submit" class="btn btn-success ml-2">
                          Save
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const { selectedProduct } = ProductActions;

const mapDispatchToProps = { selectedProduct };

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
    product: state.Product.product,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
