import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SuperFetch from "../../../helpers/superFetch";
import { Autosuggestion } from "../../../tools/Autosuggestion";
import { formatTwoDecimalsWithComma } from "../../../_methods/commonMethods";

const AddInvoiceItemModal = ({
  title,
  selectedProduct,
  onQuntityChangeHandler,
  unitPriceHandler,
  gstChangeHandler,
  onAddItemHandler,
  showAddInvoice,
  closeAddInvoiceHandler,
  onNameChangeHandler,
  saveProductHandler,
  onDescriptionChangeHandler,
  products,
  productKeys,
  selectValue,
  keyupValue,
  addProduct,
  addNewProduct,
  selectedIndex,
  handleRemoveRow,
}) => {
  const [name_err, setNameError] = useState(null);
  const [description_err, setDescriptionError] = useState(null);
  const [unit_err, setUnitError] = useState(null);
  const [unit_price_err, setUnitPriceError] = useState(null);

  const validate = async () => {
    let isError = false;
    if (selectedProduct.name != "") {
      if (selectedProduct.save) {
        const res = await SuperFetch.get(
          `/products/validate?name=${selectedProduct.name}`
        );
        if (res && !res.valid) {
          isError = true;
          setNameError("Product ID already taken");
        } else {
          setNameError(null);
        }
      } else {
        setNameError(null);
      }
    }

    if (selectedProduct.unit_amount === "") {
      isError = true;
      setUnitPriceError("Please enter amount");
    } else {
      setUnitPriceError(null);
    }
    if (selectedProduct.quantity === "") {
      isError = true;
      setUnitError("Please enter unit");
    } else {
      setUnitError(null);
    }
    if (selectedProduct.description === "") {
      isError = true;
      setDescriptionError("Please enter description");
    } else {
      setDescriptionError(null);
    }
    return isError;
  };

  const validateonAddItemHandler = async (e) => {
    e.preventDefault();
    let valid = await validate();
    if (!valid) {
      onAddItemHandler();
    }
  };

  return (
    <Modal
      show={showAddInvoice}
      onHide={closeAddInvoiceHandler}
      className="modal_add_item"
      aria-labelledby="modal_add_item"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal_add_item">
          <h3>{title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card" style={{ boxShadow: "none" }}>
          <div className="card-body">
            <div class="row" id="hide-desktop">
              <div class="col-md-auto">
                <label class="sr-only" for="inlineFormInputGroup">
                  Product ID
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Product ID</div>
                  </div>
                  <Autosuggestion
                    suggestionData={[
                      // { name: "+Add New", data_id: "add-new" },
                      ...products,
                    ]}
                    keys={productKeys}
                    nameValue={selectedProduct && selectedProduct.name}
                    // addProduct={addProduct}
                    keyupValue={keyupValue}
                    placeholder={""}
                    selectValue={selectValue}
                    addNewProduct={addNewProduct}
                    id="productId"
                  />

                  {name_err ? (
                    <span className="m-form__help w-100 d-inline-block text-danger">
                      {name_err}
                    </span>
                  ) : null}
                </div>
              </div>
              <div class="col-md-auto" style={{ paddingRight: 5 }}>
                <label class="sr-only" for="inlineFormInputGroup">
                  Description
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Description </div>
                  </div>
                  <textarea
                    class="form-control"
                    id="productDescription"
                    rows="6"
                    // onChange={this.handleInput.bind(this, index)}
                    defaultValue={
                      selectedProduct &&
                      selectedProduct.description &&
                      selectedProduct.description
                    }
                    onChange={onDescriptionChangeHandler}
                  ></textarea>
                  {description_err ? (
                    <span className="m-form__help w-100 d-inline-block text-danger">
                      {description_err}
                    </span>
                  ) : null}
                  {/* <!-- <input type="text" class="form-control" id="inlineFormInputGroup" placeholder=""> --> */}
                </div>
              </div>
              <div class="col-md-8">
                <label class="sr-only" for="inlineFormInputGroup">
                  Units
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Units</div>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="inlineFormInputGroup"
                    name="quantity"
                    value={selectedProduct && selectedProduct.quantity}
                    onChange={onQuntityChangeHandler}
                  />
                  {unit_err ? (
                    <span className="m-form__help w-100 d-inline-block text-danger">
                      {unit_err}
                    </span>
                  ) : null}
                </div>
              </div>
              <div class="col-md-8" style={{ paddingRight: 5 }}>
                <label class="sr-only" for="inlineFormInputGroup">
                  Unit Price
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Unit Price</div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="unitAmount"
                    placeholder=""
                    defaultValue={
                      selectedProduct && selectedProduct.unit_amount
                    }
                    onChange={unitPriceHandler}
                  />
                  {unit_price_err ? (
                    <span className="m-form__help w-100 d-inline-block text-danger">
                      {unit_price_err}
                    </span>
                  ) : null}
                </div>
                <span>Input GST-Exclusive Unit Price</span>
              </div>
              <div class="col-md-8">
                <label class="sr-only" for="inlineFormInputGroup">
                  GST Rate
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">GST Rate</div>
                  </div>
                  <select
                    style={{ height: "44.44px" }}
                    className="form-control"
                    name="gst_free"
                    id="inlineFormInputGroup"
                    onChange={gstChangeHandler}
                  >
                    <option
                      value="0"
                      selected={
                        selectedProduct && selectedProduct.tax_rate === 0
                          ? true
                          : false
                      }
                    >
                      GST
                    </option>
                    <option
                      value="1"
                      selected={
                        selectedProduct && selectedProduct.tax_rate === 1
                          ? true
                          : false
                      }
                    >
                      GST Free
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-8">
                <label class="sr-only" for="inlineFormInputGroup">
                  Amount
                </label>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Amount</div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    id="inlineFormInputGroup"
                    placeholder=""
                    disabled
                    value={
                      !isNaN(selectedProduct && selectedProduct.amount)
                        ? selectedProduct &&
                          formatTwoDecimalsWithComma(selectedProduct.amount)
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
                onChange={saveProductHandler}
                checked={selectedProduct && selectedProduct.save}
              />
              <label class="form-check-label" for="exampleCheck1">
                Save 'Product' for future use.
              </label>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12" style={{ padding: "0px 23px" }}>
              <button
                type="button"
                class="btn btn-success"
                style={{ width: "100%" }}
                onClick={validateonAddItemHandler}
              >
                Save Item
              </button>
              {selectedIndex !== null ? (
                <button
                  type="button"
                  class="btn btn-danger"
                  style={{ width: "100%" }}
                  onClick={() => handleRemoveRow(selectedIndex)}
                >
                  Remove Item
                </button>
              ) : (
                <button
                  type="button"
                  class="btn btn-danger"
                  style={{ width: "100%" }}
                  onClick={closeAddInvoiceHandler}
                >
                  Remove Item
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoiceItemModal;
