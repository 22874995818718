import React from 'react';
import SuperFetch from "../../../../helpers/superFetch";

class PaymentPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_data: [],
        }
    }

    componentDidMount() {
        this.PaymentPlan();
    }

    PaymentPlan() {
        SuperFetch.get('/invoices/payment-plans/list')
            .then((response) => {
                this.setState({payment_data: response.data});
            })
            .catch(error => {
            })
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <h5>Payment Plan</h5>
                            <br/>
                            <div className="invoice-table">
                                <table className="table table-bordered table-responsive-md text-center">
                                    <thead>
                                    <tr>
                                        <th className="text-center">Number</th>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Balance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/*{(this.state.payment_data && this.state.payment_data.length !== 0)*/}
                                    {/*&& this.state.payment_data.map((item) => {*/}
                                        {/*return (*/}
                                            {/*<tr key={item.id}>*/}
                                                {/*<td>-</td>*/}
                                                {/*<td>Invoice</td>*/}
                                                {/*<td>31/01/2020</td>*/}
                                                {/*<td>{item.status}</td>*/}
                                                {/*<td>${item.amount}</td>*/}
                                                {/*<td>${item.balance}</td>*/}
                                            {/*</tr>*/}
                                        {/*)*/}
                                    {/*})*/}

                                    {/*}*/}
                                    <tr>
                                        <td>-</td>
                                        <td>Invoice</td>
                                        <td>31/01/2020</td>
                                        <td>-</td>
                                        <td>$1,100.00</td>
                                        <td>$1,100.00</td>
                                    </tr>
                                    <tr>
                                        <td>-</td>
                                        <td>Setup Fee</td>
                                        <td>31/01/2020</td>
                                        <td>-</td>
                                        <td>$100.00</td>
                                        <td>$1,200.00</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Payment</td>
                                        <td>14/02/2020</td>
                                        <td>Pending</td>
                                        <td>-$400.00</td>
                                        <td>$800.00</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Payment</td>
                                        <td>14/03/2020</td>
                                        <td>Pending</td>
                                        <td>-$400.00</td>
                                        <td>$400.00</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Final Payment</td>
                                        <td>14/03/2020</td>
                                        <td>Pending</td>
                                        <td>-$400.00</td>
                                        <td>$0.00</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentPlan;
