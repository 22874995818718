import React, { Component } from "react";
import { connect } from "react-redux";
import AccountDetailForm from "./AccountDetailForm";
import UserHelper from "../../../../helpers/userHelper";
import { store } from "react-notifications-component";
import AuthAction from "../../../../redux/auth/actions";

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - Account Details";
  }

  handleSubmit = async (values) => {
    this.setState({ loading: true });
    const data = await UserHelper.updateUser(values);
    console.log("data", data);
    this.setState({ loading: false });

    if (data.success && data.success != "") {
      store.addNotification({
        message: data.success,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    if (data.error) {
      store.addNotification({
        message:
          data.error || "Something went wrong. Please try after some time.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    this.props.fetchCurrentUser();
  };

  sendRecoveryEmail = async () => {
    const { forgotPassword, history, currentUser } = this.props;
    let values = { email: currentUser.email };
    await forgotPassword({ history, userInfo: values, donotRedirect: true });
  };

  render() {
    const { currentUser, loading } = this.props;

    return (
      <div class="main-content-inner">
        <div class="row">
          <div class="col-lg-4 mt-3">
            <AccountDetailForm
              initialValues={
                currentUser && {
                  first_name: currentUser.first_name,
                  last_name: currentUser.last_name,
                  email: currentUser.email,
                  mobile: currentUser.mobile,
                }
              }
              onSubmit={this.handleSubmit}
              loading={loading}
            />
            <br />

            <div class="card">
              <div class="card-body">
                <p class="onboard-heading">Password</p>
                <hr />
                <p>
                  Click the button below to receive an email to change your
                  password.
                </p>
                <br />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => this.sendRecoveryEmail()}
                  >
                    Send Email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const { fetchCurrentUser, forgotPassword } = AuthAction;
const mapDispatchToProps = { fetchCurrentUser, forgotPassword };

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
    loading: state.Organisation.get("loading"),
    errors: state.Alert.error ? state.Alert.error.errors : {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
