import React from "react";
import $ from "jquery";
import SuperFetch from "../../../helpers/superFetch";
import {
  formatTwoDecimals,
  renderTaxType,
} from "../../../_methods/commonMethods";
import Logo from "../../../assets/images/logo.jpg";
import "./preview.scss";
import ConfirmPopup from "../../Common/ConfirmPopup";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser } from "../../../helpers/utility";
import { store } from "react-notifications-component";

class PreviewProposal extends React.Component {
  messages = {
    accepted: "This proposal was accepted.",
    declined: "This proposal was declined.",
  };

  constructor(props) {
    super(props);
    this.state = {
      lead: null,
      accept_thank: false,
      reject_thank: false,
      message: "",
      notes: "",
      title: "",
      accept_click: null,
      reject_click: null,
      hide_accept_button: false,
      accepted: "",
      declined: "",
      termData: "",
      show: false,
      showReject: false,
      showAcceptAndPayNow: false,
      organisation: null,
      stripe_account_id: null,
    };
    this.handleAccept = this.handleAccept.bind(this);
    this.handleReject = this.handleReject.bind(this);
  }

  handleCloseConfirm = () => {
    this.setState({ show: false, showAcceptAndPayNow: false });
  };

  handleAccept = () => {
    this.setState({ show: true });
    $("#modal-proposal-options").find('button[data-dismiss="modal"]').click();
  };

  handleAcceptAndPayNow = () => {
    this.setState({ showAcceptAndPayNow: true });
    $("#modal-proposal-options").find('button[data-dismiss="modal"]').click();
  };

  handleReject = () => {
    this.setState({ showReject: true });
    $("#modal-proposal-options").find('button[data-dismiss="modal"]').click();
  };

  handleCloseRejectDilogue = () => {
    this.setState({ showReject: false });
  };

  componentDidMount() {
    document.title = "Cloud Collect - Proposal";

    this.getAllData();
  }

  getStripeAccountDetails = () => {
    const { organisation } = this.state;

    if (organisation && organisation.stripe_account_id) {
      if (organisation.stripe_account_id !== null) {
        this.setState({
          stripe_account_id: organisation.stripe_account_id,
        });
      }
    }
  };

  getAllData = () => {
    // this.getTermsConditions();
    let uuid = this.props.match.params.id;

    SuperFetch.get("/proposals/uuid/" + uuid).then((response) => {
      if (response.data)
        response.data.lead &&
          this.setState(
            {
              lead: response.data.lead,
              notes: response.data.notes,
              allData: response.data,
              number: response.data.proposal_reference,
              first_name: response.data.lead.first_name,
              last_name: response.data.lead.last_name,
              address: response.data.lead.organisation.address,
              suburb: response.data.lead.organisation.suburb,
              state: response.data.lead.organisation.state,
              organisation_id: response.data.lead.organisation.id,
              organisation: response.data.lead.organisation,
              postcode: response.data.lead.organisation.postcode,
              date: response.data.proposal_date,
              expiry_date: response.data.proposal_expiry_date,
              items: response.data.items,
              net: response.data.net,
              gst: response.data.gst,
              total: response.data.total,
              id: response.data.id,
              business_name: response.data.lead.organisation.business_name,
              phone: response.data.lead.organisation.contact_number,
              email: response.data.lead.organisation.contact_email,
              status: response.data.status,
              abn: response.data.lead.organisation.abn,
              abn: response.data.lead.organisation.abn,
              logo: response.data.lead.organisation.logo_thumb_url,
              company_name: response.data.lead.company_name,
              // terms_conditions:
              //   response.data.lead.organisation.flow.terms_conditions,
            },
            () => {
              this.getTermsConditions(this.state.organisation_id);
              this.getStripeAccountDetails();
            }
          );
    });
  };

  getTermsConditions(organisation_id) {
    SuperFetch.get(`/organisation/${organisation_id}/terms`)
      .then((response) => {
        this.setState({ termData: response.data });
      })
      .catch((error) => {
        throw error;
      });
  }

  isExpired() {
    if (this.state.expiry_date) {
      if (new Date(this.rechangeDate(this.state.expiry_date)) < new Date()) {
        // this.setState({hide_accept_button: true})
        return true;
      }
    }
    return false;
  }

  rechangeDate(data) {
    let day = data.split("-");
    return this.addZero(day[1]) + "-" + this.addZero(day[0]) + "-" + day[2];
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  handleAcceptProposal(type) {
    if (this.state.accept_click > 1 || this.state.accept_click === 1) return;
    this.setState({
      accept_click: 1,
    });
    let data = {
      status: "Accepted",
    };
    SuperFetch.post("/proposals/accept-reject/" + this.state.id, data)
      .then((response) => {
        $("#modal-proposal-options")
          .find('button[data-dismiss="modal"]')
          .click();
        this.setState({ show: false, showAcceptAndPayNow: false });
        this.getAllData();
        if (type === "Accept and Pay Now") {
          this.props.history.push(`/invoice/${response.data.invoice.uuid}`);
        }

        // modal-proposal-options
        if (response)
          this.setState({
            accept_thank: true,
            message:
              "Thank you for accepting our proposal. We will be in touch soon.",
            title: "Accept Message",
            accepted: "This proposal was accepted.",
          });
      })
      .catch((error) => {
        if (error.error) {
          store.addNotification({
            message: error.error,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }

        $("#modal-proposal-options")
          .find('button[data-dismiss="modal"]')
          .click();
      });
  }

  handleRejectProposal() {
    if (this.state.reject_click > 1 || this.state.reject_click === 1) return;
    this.setState({
      reject_click: 1,
    });
    let data = {
      status: "Declined",
    };
    SuperFetch.post("/proposals/accept-reject/" + this.state.id, data).then(
      (response) => {
        $("#modal-proposal-options")
          .find('button[data-dismiss="modal"]')
          .click();
        this.setState({ showReject: false });
        this.getAllData();

        if (response)
          this.setState({
            reject_thank: true,
            message: "Thank you for your response.",
            title: "Reject Message",
            declined: "This proposal was declined.",
          });
      }
    );
  }

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <span>{value}</span>}
      />
    );
  }

  render() {
    this.isExpired();
    const { stripe_account_id } = this.state;

    return (
      <div class="proposal-view">
        <ConfirmPopup
          handleOk={this.handleAcceptProposal.bind(this)}
          handleClose={this.handleCloseConfirm}
          show={this.state.show}
          acceptButtonClass="success"
          buttonText="Accept"
          confirmText="Are you sure want to accept this proposal?"
        />

        <ConfirmPopup
          handleOk={() => this.handleAcceptProposal("Accept and Pay Now")}
          handleClose={this.handleCloseConfirm}
          show={this.state.showAcceptAndPayNow}
          acceptButtonClass="success"
          buttonText="Accept"
          confirmText="Are you sure want to accept this proposal?"
        />

        <ConfirmPopup
          handleOk={this.handleRejectProposal.bind(this)}
          handleClose={this.handleCloseRejectDilogue}
          show={this.state.showReject}
          acceptButtonClass="danger"
          buttonText="Reject"
          confirmText="Are you sure want to reject this proposal?"
        />
        {/* <!-- MAIN CONTAINER START--> */}
        <div class="main-content">
          {/* <!-- HEADER START --> */}

          {/* <!-- HEADER END --> */}

          {/* <!-- MAIN CONTENT INNER START --> */}
          <div class="main-content-inner-preview">
            {this.state.status === "Declined" && (
              <div class="row">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status-declined">
                      <div class="row">
                        <h5>REJECTED</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.isExpired() && this.state.status === "Accepted" && (
              <div class="row">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status-accepted">
                      <div class="row">
                        <div id="status-text">
                          <div class="col-12">
                            <h5>PROPOSAL ACCEPTED</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.isExpired() &&
            this.state.status !== "Accepted" &&
            this.state.status !== "Declined" ? (
              <div class="row">
                <div class="col-lg-6 mt-3" id="card-customer-invoice">
                  <div class="card">
                    <div class="card-body" id="form-status">
                      <div class="row">
                        <div class="col-12" id="status-text">
                          <h5>PROPOSAL EXPIRED</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              this.state.status === "Accepted" && (
                <div class="row">
                  <div class="col-lg-6 mt-3" id="card-customer-invoice">
                    <div class="card">
                      <div class="card-body" id="form-status-accepted">
                        <div class="row">
                          <div id="status-text">
                            <div class="col-12">
                              <h5>PROPOSAL ACCEPTED</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

            {!this.isExpired() ? (
              this.state.status === "Approved" ||
              this.state.status === "Awaiting Payment" ||
              this.state.status === "Pending Acceptance" ? (
                <div class="row">
                  <div class="col-lg-6 mt-3" id="card-customer-invoice">
                    <div class="card">
                      <div class="card-body" id="form-status-pending">
                        <div class="row">
                          <div class="col-9" id="status-text">
                            <h5>PROPOSAL PENDING</h5>
                          </div>
                          <div class="col-3">
                            {/* {stripe_account_id && ( */}
                            <button
                              type="button"
                              class="btn btn-success"
                              data-toggle="modal"
                              data-target="#modal-proposal-options"
                              id="button-action"
                            >
                              Options
                            </button>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}

            <div class="row">
              <div class="col-lg-6 mt-3" id="card-customer-invoice">
                <div class="card">
                  <div class="card-body">
                    <div class="invoice-area">
                      <div class="proposal-head">
                        <div class="row">
                          <div class="col-6">
                            {this.state.status === "Draft" ? (
                              <h1>DRAFT PROPOSAL</h1>
                            ) : this.state.status === "Archived" ? (
                              <h1>ARCHIVED PROPOSAL</h1>
                            ) : (
                              <h1>PROPOSAL</h1>
                            )}
                            <h5>{this.state.business_name}</h5>
                            <p>
                              <small>ABN {this.state.abn}</small>
                            </p>
                            <br />
                          </div>
                          <div class="iv-right col-6 text-md-right">
                            {this.state.logo && (
                              <img
                                src={this.state.logo}
                                alt=""
                                style={{ width: "175px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4" id="form-content">
                          <h5>Proposal</h5>
                          <p>Proposal Ref: #{this.state.number}</p>
                          <p>Proposal Date : {this.state.date}</p>
                          <p>Expiry Date : {this.state.expiry_date}</p>
                        </div>
                        {this.state.lead && (
                          <div className="col-md-4 " id="form-content">
                            <h5>To</h5>
                            {/* <p class="text-capitalize">
                              {this.state.lead.company_name}
                            </p> */}
                            {this.state.lead.type === "Individual" ? (
                              <>
                                <p class="text-capitalize">
                                  {this.state.lead.full_name}
                                </p>
                              </>
                            ) : (
                              <>
                                <p class="text-capitalize">
                                  {this.state.lead.company_name}
                                </p>
                              </>
                            )}
                            {this.state.lead.address && (
                              <p class="text-capitalize">
                                {this.state.lead.address}
                              </p>
                            )}
                            <p class="text-capitalize">
                              {this.state.lead.suburb} {this.state.lead.state}{" "}
                              {this.state.lead.postcode}
                            </p>
                          </div>
                        )}
                        {this.state.organisation && (
                          <div className="col-md-4 " id="form-content">
                            <h5>From</h5>
                            <p>
                              {this.state.organisation.business_name &&
                                this.state.organisation.business_name}
                            </p>
                            {this.state.organisation.flow &&
                              parseInt(
                                this.state.organisation.flow.address_detail
                              ) === 1 && (
                                <>
                                  {this.state.organisation.address && (
                                    <p>{this.state.organisation.address}</p>
                                  )}

                                  <p>
                                    {this.state.organisation.suburb &&
                                      this.state.organisation.suburb}{" "}
                                    {this.state.organisation.state &&
                                      this.state.organisation.state}{" "}
                                    {this.state.organisation.postcode &&
                                      this.state.organisation.postcode}
                                  </p>
                                </>
                              )}
                            {this.state.organisation.flow &&
                              parseInt(
                                this.state.organisation.flow.mobile_detail
                              ) === 1 && (
                                <>
                                  {this.state.organisation.contact_number && (
                                    <p>
                                      {this.state.organisation.contact_number}
                                    </p>
                                  )}
                                </>
                              )}
                            {this.state.organisation.flow &&
                              parseInt(
                                this.state.organisation.flow.email_detail
                              ) === 1 && (
                                <>
                                  {this.state.organisation.contact_email && (
                                    <p>
                                      {" "}
                                      {this.state.organisation.contact_email}
                                      {/* <a
                                  href={`mailto:${this.state.organisation.contact_email}`}
                                >
                                  {this.state.organisation.contact_email}
                                </a> */}
                                    </p>
                                  )}
                                </>
                              )}
                          </div>
                        )}
                      </div>
                      <div class="invoice-table table-responsive">
                        <table class="table table-bordered table-hover text-right">
                          <thead>
                            <tr>
                              <th class="table-text-left" id="column-1">
                                Description
                              </th>
                              <th
                                class="table-text-right"
                                width="125"
                                id="column-2"
                              >
                                Units
                              </th>
                              <th
                                class="table-text-right"
                                width="125"
                                id="column-3"
                              >
                                Unit Price
                              </th>
                              <th
                                class="table-text-right"
                                width="125"
                                id="column-4"
                              >
                                Tax
                              </th>
                              <th
                                class="table-text-right"
                                width="125"
                                id="column-5"
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.allData &&
                              this.state.items &&
                              this.state.items.map((item, index) => {
                                let total = item.quantity * item.amount;
                                return (
                                  <tr>
                                    <td class="table-text-left" id="column-1">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                    </td>
                                    <td class="table-text-right" id="column-2">
                                      {item.quantity}
                                    </td>
                                    <td class="table-text-right" id="column-3">
                                      {item.amount < 0 && "("}
                                      {this.amountToFixed(
                                        Math.abs(item.amount)
                                      )}
                                      {item.amount < 0 && ")"}
                                    </td>
                                    <td class="table-text-right" id="column-4">
                                      {renderTaxType(item.gst_free)}
                                    </td>
                                    <td class="table-text-right" id="column-5">
                                      {total < 0 && "("}
                                      {this.amountToFixed(Math.abs(total))}
                                      {total < 0 && ")"}
                                    </td>
                                  </tr>
                                );
                              })}

                            <tr className="mobile-bold">
                              <td colspan="5">
                                <span>Sub-Total</span>&nbsp;
                                <div className="total_position">
                                  {this.state.net
                                    ? this.amountToFixed(this.state.net)
                                    : "0.00"}
                                </div>
                              </td>
                            </tr>
                            <tr className="mobile-bold">
                              <td colspan="5">
                                <span>Total GST</span>&nbsp;
                                <div className="total_position">
                                  {this.state.gst
                                    ? this.amountToFixed(this.state.gst)
                                    : "0.00"}
                                </div>
                              </td>
                            </tr>
                            <tr className="mobile-bold">
                              <td colspan="5">
                                <h5>
                                  Total&nbsp;
                                  <div className="total_position">
                                    {this.state.total
                                      ? this.amountToFixed(this.state.total)
                                      : "0.00"}
                                  </div>
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.notes &&
                              this.state.notes,
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <p id="cc-signature">
                        <strong>Powered by CLOUD COLLECT of Australia</strong>
                        <br />
                        Simplify Invoicing, Automate Debt Collection & Get Paid
                        <br />
                        <a href="https://www.cloudcollect.io">
                          www.cloudcollect.io
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- MAIN CONTENT INNER END --> */}

          {/* <!-- FOOTER START--> */}
        </div>
        {/* <!-- FOOTER END --> */}
        {/* <!-- MAIN CONTAINER END --> */}

        {/* <!-- MODALS START --> */}

        <div class="modal fade" id="modal-proposal-options">
          <div class="modal-dialog modal-dialog-centered" id="modal-pay">
            <div class="modal-content">
              <div class="modal-body">
                <h5 class="modal-title">Options</h5>
                <hr />
                <br />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={this.handleAccept}
                    // data-toggle="modal"
                    // data-target="#modal-paynow"
                  >
                    Accept
                  </button>
                  {stripe_account_id && (
                    <>
                      <br />
                      <button
                        type="button"
                        class="btn btn-success"
                        onClick={this.handleAcceptAndPayNow}
                        // data-toggle="modal"
                        // data-target="#modal-paylater"
                      >
                        Accept & Pay Now
                      </button>
                    </>
                  )}
                  <br />
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={this.handleReject}
                    // data-toggle="modal"
                    // data-target="#modal-payplan"
                  >
                    Reject
                  </button>
                  <hr />
                  <button
                    type="button"
                    class="btn btn-secondary"
                    // class="close"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modal-paynow">
          <div class="modal-dialog modal-dialog-centered" id="modal-pay">
            <div class="modal-content">
              <div class="modal-body">
                <h5 class="modal-title">Pay Now</h5>
                <hr />
                <div>
                  <p>
                    Existing Cloud Collect user?{" "}
                    <a
                      href="#modal-login"
                      data-toggle="modal"
                      data-target="#modal-login"
                    >
                      {" "}
                      Login
                    </a>
                    .
                  </p>
                </div>
                <hr />
                <form>
                  <div class="form-group">
                    <label for="">Card Number</label>
                    <input
                      type=""
                      class="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                    />
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label for="">CVV</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="XXX"
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="">Expiry</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="MMYY"
                      />
                    </div>
                  </div>
                </form>
                <br />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
                <p class="text-tcs">
                  By clicking 'Submit' you agree to our{" "}
                  <Link target="blank" to="/terms-and-conditions">
                    terms and conditions
                  </Link>
                  .
                </p>
                <hr />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modal-paylater">
          <div class="modal-dialog modal-dialog-centered" id="modal-pay">
            <div class="modal-content">
              <div class="modal-body">
                <h5 class="modal-title">Pay Later</h5>
                <hr />
                <div>
                  <p>
                    Existing Cloud Collect user?{" "}
                    <a
                      href="#modal-login"
                      data-toggle="modal"
                      data-target="#modal-login"
                    >
                      {" "}
                      Login
                    </a>
                    .
                  </p>
                </div>
                <hr />
                <form>
                  <div class="form-group">
                    <label for="">Pay Date</label>
                    <select class="form-control" id="select">
                      <option>Dates...</option>
                    </select>
                    <small id="" class="form-text text-muted">
                      Select a date prior to due date of [Due Date]
                    </small>
                  </div>
                  <div class="form-group">
                    <label for="">Card Number</label>
                    <input
                      type=""
                      class="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                    />
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label for="">CVV</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="XXX"
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="">Expiry</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="MMYY"
                      />
                    </div>
                  </div>
                </form>
                <br />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
                <p class="text-tcs">
                  By clicking 'Submit' you agree to our{" "}
                  <Link target="blank" to="/terms-and-conditions">
                    terms and conditions
                  </Link>
                  .
                </p>
                <hr />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modal-payplan">
          <div class="modal-dialog modal-dialog-centered" id="modal-pay">
            <div class="modal-content">
              <div class="modal-body">
                <h5 class="modal-title">Payment Plan</h5>
                <hr />
                <div>
                  <p>
                    Existing Cloud Collect user?{" "}
                    <a
                      href="#modal-login"
                      data-toggle="modal"
                      data-target="#modal-login"
                    >
                      {" "}
                      Login
                    </a>
                    .
                  </p>
                </div>
                <hr />
                <form>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label for="">Payment Amount</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="$XXX.XX"
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="">Upfront Payment</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label for="">Frequency</label>
                      <select class="form-control" id="select">
                        <option></option>
                        <option>Weekly</option>
                        <option>Fortnighly</option>
                        <option>Monthly</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <label for="">First Payment</label>
                      <select class="form-control" id="select">
                        <option></option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Card Number</label>
                    <input
                      type=""
                      class="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                    />
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label for="">CVV</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="XXX"
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="">Expiry</label>
                      <input
                        type=""
                        class="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="MMYY"
                      />
                    </div>
                  </div>
                </form>
                <br />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
                <p class="text-tcs">
                  By clicking 'Submit' you agree to our{" "}
                  <Link target="blank" to="/terms-and-conditions">
                    terms and conditions
                  </Link>
                  .
                </p>
                <hr />
                <div class="invoice-buttons text-center">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    class="close"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Terms and Conditions Modal --> */}
        <div class="modal fade" id="modal-tcs">
          <div class="modal-dialog modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <p class="onboard-heading">Terms & Conditions</p>
                <button type="button" class="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p></p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps)(PreviewProposal);
