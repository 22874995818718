const actions = {
  FETCH_CURRENT_PROPOSALS: "FETCH_CURRENT_PROPOSALS",
  SET_SELECTED_PROPOSAL: "SET_SELECTED_PROPOSAL",
  SET_PROPOSALS: "SET_PROPOSALS",
  SET_PROPOSAL: "SET_PROPOSAL",

  fetchCurrentProposals: () => ({
    type: actions.FETCH_CURRENT_PROPOSALS,
  }),

  selectedProposal: (payload) => {
    return {
      type: actions.SET_SELECTED_PROPOSAL,
      payload,
    };
  },
};

export default actions;
