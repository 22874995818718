import actionTypes from "./action";

const initialState = {
  product: {},
};

const Products = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.SET_PRODUCT:
      return {
        ...state,
        product: payload,
      };

    default:
      return state;
  }
};
export default Products;
