export function setLoader(isLoading ) {
    return {
        type: '@@wizard/SET_LOADER',
        isLoading
    }
}


export function unSetLoader(isLoading ) {
    return {
        type: '@@wizard/UNSET_LOADER',
        isLoading
    }
}
