import axios from "axios";
import SuperFetch from "./superFetch";
import { store } from "react-notifications-component";
import { apiHost } from "../config";

class ProposalsHelper {
  static fetchAllProposals = async () => {
    return await SuperFetch.get("/proposals")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  static markAsPaidProposal = async (data) => {
    const response = await axios.post(
      `${apiHost.fetchUrl}/proposal-status-update`,
      data,
      {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      }
    );

    return response;
  };

  static markAsVoidProposal = async (data) => {
    const response = await axios.post(
      `${apiHost.fetchUrl}/proposal-status-update`,
      data,
      {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      }
    );

    return response;
  };
}

export default ProposalsHelper;
