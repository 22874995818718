import actionTypes from "./actions";

const initialState = {
    allNotifications: [],

};

const Notifications = (state = initialState, action) => {
    const {payload} = action;
    switch (action.type) {
        case actionTypes.FETCH_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                allNotifications: payload
            };
        case actionTypes.READ_NOTIFICATION_SUCCESS:
            return {
                ...state,
                allNotifications: []

            };
        default:
            return state;
    }
};

export default Notifications;
