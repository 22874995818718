import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import { store } from "react-notifications-component";
import SuperFetch from "../../../helpers/superFetch";
import TextareaAutosize from "react-textarea-autosize";
import { Autosuggestion } from "../../../tools/Autosuggestion";
import NumberFormat from "react-number-format";
import Modal from "react-bootstrap/Modal";
import AddProductModal from "../Invoice/AddProductModal";
import {
  calculateGstHelper,
  calcTwoDecimalPlaces,
  formatTwoDecimals,
  formatTwoDecimalsWithComma,
} from "../../../_methods/commonMethods";
import { apiHost } from "../../../config";
import AddInvoiceItemModal from "../Invoice/AddInvoiceItemModal";
import axios from "axios";
import DraftComponent from "../../DraftComponent";
import { getUser } from "../../../helpers/utility";
import AddInvoiceContact from "../Modals/addInvoiceContact";

const date = new Date().setDate(new Date().getDate() + 7);

class EditProposal extends React.Component {
  newRow = {
    name: "",
    description: "",
    unit_amount: "",
    quantity: "",
    amount: 0,
    tax_rate: "",

    errors: {
      // name: false,
      description: false,
      unit_amount: false,
      quantity: false,
    },
  };
  errors = {
    // name: "Incorrect product name",
    unit_amount: "Incorrect product amount",
    quantity: "Incorrect product quantity",
    customer: "Please select customer",
    proposal_length: "At least one element needed",
  };
  cloneNewRow = Object.assign({}, this.newRow);
  tmpSelection = "";
  constructor(props) {
    super(props);
    this.state = {
      due_date_error: false,
      showAddProduct: false,
      proposal_date: new Date(),
      due_date: new Date(),
      proposal_due_date: date,
      proposal_rows: [this.cloneNewRow],
      total: 0,
      notes: null,
      attachments: [],
      notes_files: [],
      gst: 0,
      net: 0,
      proposal_number: "",
      leads: [],
      lead: 0,
      first_name: "",
      last_name: "",
      email: "",
      currentLead: {
        name: "",
      },
      organisation_id: "",
      products: [],
      suggestionKeys: ["name"],
      productKeys: ["name"],
      errors: {
        customer: false,
        proposal_length: false,
      },
      disabled: false,
      selectedProduct: {
        description: "",
        tax_rate: 0,
        name: "",
        quantity: 1,
        unit_amount: "",
        save: false,
      },
      selectedIndex: null,
      editing: false,
      status: false,
      invoicesProducts: [],
    };
    this.handleDate = this.handleDate.bind(this);
    this.handleDuoDate = this.handleDuoDate.bind(this);
    this.handleAddInvoice = this.handleAddInvoice.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.selectLead = this.selectLead.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.getNewLeads = this.getNewLeads.bind(this);
  }

  componentDidMount() {
    document.title = "Cloud Collect - Edit Proposal";
    this.getInvoice();
  }

  onFileChangeHandler = (e) => {
    var files = Array.from(e.target.files);

    this.setState((prevState) => ({
      notes_files:
        prevState.notes_files.length < 10 - prevState.attachments.length
          ? prevState.notes_files.concat(files)
          : prevState.notes_files,
    }));
  };

  fileDelteHandler = (file1) => {
    if (file1.id) {
      this.setState({
        attachments: this.state.attachments.filter(
          (file2) => file2.id !== file1.id
        ),
      });
    }
  };

  onNotesChangeHandler = (e) => {
    if (e.target.value != "") {
      this.setState({ notes: e.target.value });
    } else {
      this.setState({ notes: null });
    }
  };

  getInvoice() {
    let id = this.props.match.params.id;
    SuperFetch.get("/proposals/" + id).then((response) => {
      this.initInvoice(response.data);
    });
  }

  initInvoice(data) {
    // lead_id: 74
    let rows = [];

    for (let item of data.items) {
      let obj = {
        name: item.name ? item.name : "",
        description: item.description,
        unit_amount: parseFloat(item.amount).toFixed(2),
        quantity: item.quantity,
        tax_rate: item.gst_free,
        amount: 0,
        errors: {
          name: false,
          description: false,
          unit_amount: false,
          quantity: false,
        },
      };
      rows.push(obj);
    }
    let tmpDate = data.proposal_date.split("-");
    let date = new Date(tmpDate[2] + "-" + tmpDate[1] + "-" + tmpDate[0]);
    tmpDate = data.due_date?.split("-");
    let due_date = data.due_date.split("-");
    let dueDate = new Date(tmpDate[2] + "-" + tmpDate[1] + "-" + tmpDate[0]);
    // let dueDate = new Date(tmpDate[2] + "-" + tmpDate[1] + "-" + tmpDate[0]);
    let newDewDate = new Date(
      due_date[2] + "-" + due_date[1] + "-" + due_date[0]
    );

    let proposal_expiry_date = data.proposal_expiry_date;
    let expiryDate;
    if (proposal_expiry_date) {
      proposal_expiry_date = data.proposal_expiry_date.split("-");
      expiryDate = new Date(
        proposal_expiry_date[2] +
          "-" +
          proposal_expiry_date[1] +
          "-" +
          proposal_expiry_date[0]
      );
    }

    this.setState(
      {
        proposal_date: date,
        proposal_due_date: dueDate,
        proposal_expiry_date: expiryDate,
        due_date: newDewDate,
        proposal_number: data.proposal_reference,
        lead: data.lead_id,
        attachments: data.attachments,
        notes: data.notes,
        status: data.status,
      },
      () => {
        this.getLeads(true);
      }
    );
    this.calculatePrices(rows);
  }

  getLeads(existingLead = false, leadId = null) {
    SuperFetch.get("/leads").then((response) => {
      let tmp = [{ name: "+ Add New", data_id: "add-new" }];
      for (let item of response.data) tmp.push(item);
      this.setState({
        leads: tmp,
      });
      if (existingLead) {
        let lead;
        if (!leadId) leadId = this.state.lead;
        for (let item of response.data) {
          if (item.id === parseInt(this.state.lead)) lead = item;
        }
        if (lead) {
          this.setState({
            currentLead: lead,
            lead: lead.id,
          });
        }
      }
    });
  }

  getNewLeads(lead) {
    if (lead.id !== "") {
      this.setState({
        currentLead: lead,
        lead: lead.id,
        first_name: lead.first_name,
        last_name: lead.last_name,
        email: lead.email,
      });
      this.getLeads(true, lead.id);
    }
  }
  handleDate(e) {
    this.setState({
      proposal_date: new Date(e),
    });
  }

  handleDuoDate(e) {
    if (e !== "") {
      this.setState({
        proposal_expiry_date: new Date(e),
      });
    } else {
      this.setState({
        proposal_expiry_date: null,
      });
    }
    // const expiryDate = moment(
    //   this.changeDate(new Date(e)),
    //   "DD-MM-YYYY"
    // ).format("YYYY-MM-DD");
    // const dueDate = moment(new Date(this.state.due_date), "DD-MM-YYYY").format(
    //   "YYYY-MM-DD"
    // );

    // if (moment(dueDate).isBefore(expiryDate)) {
    //   this.setState({
    //     proposal_expiry_date: new Date(e),
    //     due_date_error: true,
    //   });
    // } else {
    //   this.setState({
    //     proposal_expiry_date: new Date(e),
    //     due_date_error: false,
    //   });
    // }
  }

  handleDueDate = (e) => {
    // const expiryDate = moment(
    //   this.changeDate(new Date(this.state.proposal_expiry_date)),
    //   "DD-MM-YYYY"
    // ).format("YYYY-MM-DD");
    // const dueDate = moment(this.changeDate(new Date(e)), "DD-MM-YYYY").format(
    //   "YYYY-MM-DD"
    // );
    // if (moment(dueDate).isBefore(expiryDate)) {
    //   this.setState({ due_date: new Date(e), due_date_error: true });
    // } else {
    //   this.setState({
    //     due_date: new Date(e),
    //     due_date_error: false,
    //   });
    // }
  };

  handleAddInvoice() {
    let cloneNewRow = Object.assign({}, this.newRow);
    cloneNewRow.quantity = 1;
    this.setState({
      proposal_rows: this.state.proposal_rows.concat(cloneNewRow),
    });
  }

  handleRemoveRow(key, e) {
    key = parseInt(key);
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;
    let tmp = [];

    for (let i = 0; i < this.state.proposal_rows.length; i++) {
      if (i !== key) tmp.push(this.state.proposal_rows[i]);
    }

    for (let element of this.state.proposal_rows) {
      net += element.amount;
      if (parseInt(element.tax_rate) === 0) {
        netWithGST += element.amount;
      }
      gst += calculateGstHelper(netWithGST, element.tax_rate);
      total = net + gst;
    }
    this.setState({
      proposal_rows: tmp,
      invoicesProducts: tmp,
      net: net,
      gst: gst,
      total: total,
    });
    this.closeAddInvoiceHandler();
  }

  handleInput(index, e) {
    if (
      e.target.name === "name" &&
      e.target.value === this.tmpSelection &&
      e.target.value
    )
      return;
    let clone_proposal_rows = this.state.proposal_rows;
    clone_proposal_rows.map((element, i) => {
      if (i === index) {
        if (e.target.name === "name") {
          element.name = e.target.value;
          // element.errors.name = false;
        }
        if (e.target.name === "description") {
          element.description = e.target.value;
        }
        if (e.target.name === "unit_amount") {
          element.unit_amount = e.target.value;
          element.errors.unit_amount = false;
        }
        if (e.target.name === "quantity") {
          element.quantity = e.target.value;
        }
        if (e.target.name === "gst_free") {
          element.tax_rate = e.target.value;
        }
      }
    });
    this.calculatePrices(clone_proposal_rows);
  }

  handleDescription(value, index) {
    let clone_proposal_rows = JSON.parse(
      JSON.stringify(this.state.proposal_rows)
    );
    clone_proposal_rows.map((element, i) => {
      if (i === index) {
        element.description = value;
        element.errors.description = false;
      }
    });
    this.calculatePrices(clone_proposal_rows);
  }

  changeDate(data) {
    let date = data.getDate();
    let month = data.getMonth() + 1;
    let year = data.getFullYear();
    return this.addZero(date) + "-" + this.addZero(month) + "-" + year;
    // let newDate = data.split('.');
    // newDate = newDate[0] + "-" + newDate[1] + "-" + newDate[2];
    // if(newDate.indexOf('/') !== -1) {
    //     newDate = newDate.split('-')[0];
    //     newDate = newDate.split('/').join('-');
    // }
    // let tmp = newDate.split('-');
    // newDate = this.addZero(tmp[0]) + '-' + this.addZero(tmp[1]) + '-' + tmp[2];
    // return newDate;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  selectLead(e) {
    if (e.data_id === "add-new") {
      $(".contact_modal").click();
      this.setState({
        lead: 0,
        organisation_id: 0,
      });
      return;
    }
    let tmp = this.state.errors;
    tmp.customer = false;
    this.setState({
      lead: e.id,
      name: e.name,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      organisation_id: e.organisation_id,
      errors: tmp,
    });
  }

  getProducts = () => {
    const { userInfo } = this.props;

    SuperFetch.get(
      "/organisation/" + userInfo.organisation_id + "/products"
    ).then((response) => {
      this.setState({ products: response.data });
    });
  };

  setProductName(index, e) {
    let data = {
      target: {
        value: e,
        name: "name",
      },
    };
    this.handleInput(index, data);
  }

  selectProduct(index, e) {
    this.tmpSelection = e.name;
    let tmp = Object.assign({}, this.state.proposal_rows[index]);
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    tmp.id = e.id;
    tmp.name = e.name;
    tmp.description = e.description;
    tmp.unit_amount = formatTwoDecimals(e.price);
    tmp.gst_free = e.gst_free;
    tmp.tax_rate = e.gst_free;
    baseTmp[index] = tmp;
    this.calculatePrices(baseTmp);
    this.setState({
      proposal_rows: baseTmp,
    });
  }

  calculatePrices(baseTmp) {
    let net = null;
    let gst = null;
    let total = null;
    let netWithGST = null;

    for (let i = 0; i < baseTmp.length; i++) {
      if (baseTmp[i] && baseTmp[i].save) {
        baseTmp[i].save = baseTmp[i].save;
      }
      if (baseTmp[i] && baseTmp[i].unit_amount) {
        baseTmp[i].amount =
          baseTmp[i].unit_amount * parseFloat(baseTmp[i].quantity);
        net += baseTmp[i].amount;
        if (parseInt(baseTmp[i].tax_rate) === 0) {
          netWithGST = baseTmp[i].amount;
        }
        // gst += baseTmp[i].gst_free === 1 ? 0 : (net * 10) / 100;
        gst += calculateGstHelper(netWithGST, baseTmp[i].tax_rate);
        total = net + gst;
      }
    }
    this.setState({
      proposal_rows: baseTmp,
      net: net,
      gst: gst,
      total: total,
    });
  }

  doNothing() {
    return;
  }

  modifyDataForStoring() {
    let arr = [];
    for (let element of this.state.proposal_rows) {
      arr.push({
        id: element.id,
        name: element.name,
        description: element.description,
        quantity: element.quantity,
        amount: element.unit_amount,
        gst_free: element.tax_rate,
        save: element.save ? element.save : false,
      });
    }
    return arr;
  }

  validation() {
    let valid = true;
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    let customerError =
      this.state.lead === 0 || !this.state.lead ? true : false;
    let proposalLengthError =
      this.state.proposal_rows.length === 0 ? true : false;
    let totalError = this.state.total < 0 ? true : false;
    if (totalError) valid = false;
    for (let item of baseTmp) {
      // item.errors.name = false;
      if (item.errors) {
        item.errors.unit_amount = false;
        item.errors.quantity = false;
        if (item.errors.name || item.errors.unit_amount || item.errors.quantity)
          valid = false;
      }
      // if (!item.name || item.name.length > 255) item.errors.name = true;
      if (!item.unit_amount) item.errors.unit_amount = true;
      if (!item.quantity) item.errors.quantity = true;
    }

    this.setState({
      proposal_rows: baseTmp,
      errors: {
        customer: customerError,
        proposal_length: proposalLengthError,
        totalError: totalError,
        totalDescription: "Total cannot be negative",
      },
    });
    return valid;
  }

  handleSave(e) {
    console.log('this.validation()', this.validation())
    console.log('this.validation()', this.state.proposal_rows.length)
    if (!this.validation()) return;
    // if (this.state.due_date_error) return;

    if (this.state.proposal_rows.length === 0) return;
    let data = {
      _method: "PUT",
      lead_id: this.state.lead,
      proposal_date: this.changeDate(this.state.proposal_date),
     
      proposal_reference: this.state.proposal_number,
      status: e.target.name,
      products: this.modifyDataForStoring(),
      attachments: this.state.notes_files,
      notes: this.state.notes,
      prevUploaded: this.state.attachments,
      due_date: this.changeDate(this.state.due_date),
    };

    if (this.state.proposal_expiry_date){
      data.proposal_expiry_date = this.changeDate(
        new Date(this.state.proposal_expiry_date)
      );
    }
    let id = this.props.match.params.id;

    let formdata = new FormData();

    for (let key in data) {
      if (data[key] && key !== "products" && key !== "prevUploaded") {
        formdata.append(key, data[key]);
      }
    }
    if (data.products) {
      for (let index in data.products) {
        for (let key in data.products[index]) {
          if (data.products[index][key] || data.products[index][key] == 0) {
            if (key !== "id") {
              formdata.append(
                `products[${index}][${key}]`,
                data.products[index][key]
              );
            }
          }
        }
      }
    }
    if (data.attachments) {
      for (let key in data.attachments) {
        formdata.append(`attachments[${key}]`, data.attachments[key]);
      }
    }

    if (data.prevUploaded) {
      for (let key in data.prevUploaded) {
        formdata.append(
          `otherAttachments[${key}][id]`,
          data.prevUploaded[key].id
        );
      }
    }

    if (!this.state.disabled)
      axios
        .post(`${apiHost.fetchUrl}/proposals/` + id, formdata, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("id_token") || undefined,
          },
        })
        .then((response) => {
          store.addNotification({
            message: "Proposal updated successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          if (data.status === "Draft") {
            this.props.history.push("/proposal");
          } else {
            this.props.history.push(
              "/proposal/non-edit/" + response.data.data.id
            );
          }
          // this.props.history.push("/proposal");
        })
        .catch((error) => {
          if (error.response.data && error.response.data.error) {
            return store.addNotification({
              message: error.response.data.error,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });
    this.setState({ disabled: true });
  }

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;
    const characterNumber = Number(characterCode);
    if (
      (characterNumber >= 0 && characterNumber <= 9) ||
      characterCode === "." ||
      characterCode === "-"
    ) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  hideModal = () => {
    this.setState({ showAddProduct: false, showButton: false });
  };

  addedProduct = (product) => {
    this.selectProductInvoice(product);

    let net = parseFloat(this.state.net)
      ? parseFloat(this.state.net) + parseFloat(product.amount)
      : parseFloat(product.amount);
    let gst = (net * 10) / 100;
    let total = parseFloat(net) + parseFloat(gst);

    let proposals = this.state.proposal_rows.map((proposal) => {
      if (proposal.name === "+Add New") {
        return {
          id: product.id,
          name: product.name,
          unit_amount: product.price,
          tax_rate: product.gst_free,
          amount: product.price,
          quantity: 1,
          description: product.description,
          errors: {
            // name: false,
            unit_amount: false,
            quantity: false,
          },
        };
      } else {
        return proposal;
      }
    });

    this.setState({
      net,
      gst,
      total,
      proposal_rows: proposals,
    });
  };

  addNewProduct = () => {
    this.setState({ ...this.state, showAddProduct: true, showButton: false });
  };

  onChangeProposalNumber = (e) => {
    this.setState({ invoice_number: e.target.value });
    this.setState({
      errors: {
        ...this.state.errors,
        proposal_reference: null,
      },
    });
  };

  onQuntityChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["quantity"] = e.target.value;
    this.setState({ selectProduct: product });
    this.amountCalculateHandler();
  };

  gstChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["tax_rate"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  amountCalculateHandler = () => {
    const amount =
      parseFloat(this.state.selectedProduct.quantity) *
      parseFloat(this.state.selectedProduct.unit_amount);

    let product = this.state.selectedProduct;
    product["amount"] = amount;
    this.setState({ selectProduct: product });
  };

  unitPriceHandler = (e) => {
    let product = this.state.selectedProduct;
    product["unit_amount"] = e.target.value;
    this.setState({ selectProduct: product });
    this.amountCalculateHandler();
  };

  closeAddInvoiceHandler = () => {
    this.setState({
      showAddInvoice: false,
      selectedProduct: {
        description: "",
        gst_free: 0,
        name: "",
        quantity: 1,
        unit_amount: "",
      },
    });
  };

  selectProductInvoice = (e) => {
    let tmp = {};
    let baseTmp = JSON.parse(JSON.stringify(this.state.proposal_rows));
    tmp.name = e.name;
    this.setState({ name: e.name });
    tmp.description = e.description;
    tmp.unit_amount = formatTwoDecimals(e.price);
    tmp.id = e.id;
    tmp.quantity = this.state.selectedProduct.quantity;
    tmp.tax_rate = e.gst_free;
    tmp.amount = parseFloat(e.price) * this.state.selectedProduct.quantity;

    baseTmp.push(tmp);
    this.setState({ selectedProduct: tmp });
  };

  onAddItemHandler = () => {
    const { selectedIndex, editing, selectedProduct } = this.state;

    let products = this.state.proposal_rows;
    if (editing) {
      products = Object.assign([], products, {
        [selectedIndex]: selectedProduct,
      });
    } else {
      if (selectedProduct.description) {
        products.push(selectedProduct);
        if (selectedProduct.save) {
          this.createProduct(selectedProduct);
        }
      }
    }

    this.setState({ invoicesProducts: products, selectedIndex: null });
    this.calculatePrices(products);
    this.closeAddInvoiceHandler();
  };

  createProduct = (selectedProduct) => {
    selectedProduct.type = "Single";
    SuperFetch.post("/products", selectedProduct)
      .then((response) => {})
      .catch((error) => {
        throw error;
      });
  };

  setProductInvoiceName = (e) => {
    let product = this.state.selectedProduct;
    product["name"] = e;
    this.setState({ selectProduct: product });
  };

  onNameChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["name"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  saveProductHandler = (e) => {
    let product = this.state.selectedProduct;
    product["save"] = e.target.checked;
    this.setState({ selectProduct: product });
  };

  onDescriptionChangeHandler = (e) => {
    let product = this.state.selectedProduct;
    product["description"] = e.target.value;
    this.setState({ selectProduct: product });
  };

  changeStatusToArchived = () => {
    const { match, history } = this.props;

    let data = { status: "Archived", proposal_id: match.params.id };
    axios
      .post(`${apiHost.fetchUrl}/proposal-status-update`, data, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("id_token") || undefined,
        },
      })
      .then((response) => {
        if (this.state.status == "Draft") {
          store.addNotification({
            message: "Draft proposal removed successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          history.push("/proposal");
        } else {
          store.addNotification({
            message: "Proposal archived successfully",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });

          this.getInvoice();
        }
      })
      .catch((error) => {
        console.log(Error);
      });
  };

  removeContact = () => {
    this.setState({
      currentLead: {
        name: " ",
      },
      lead: null,
    });
  };

  render() {
    const { due_date_error } = this.state;

    return (
      <div className="main-content-inner">
        <Modal
          show={this.state.showAddProduct}
          onHide={() => this.setState({ showAddProduct: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              New Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <div className="card-body">
                <AddProductModal
                  hideModal={this.hideModal}
                  addedProduct={this.addedProduct}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <AddInvoiceItemModal
          title={
            this.state.editing ? "Edit Proposal Item" : "Add Proposal Item"
          }
          selectedIndex={this.state.selectedIndex}
          showAddInvoice={this.state.showAddInvoice}
          selectedProduct={this.state.selectedProduct}
          onQuntityChangeHandler={this.onQuntityChangeHandler}
          unitPriceHandler={this.unitPriceHandler}
          gstChangeHandler={this.gstChangeHandler}
          onAddItemHandler={this.onAddItemHandler}
          closeAddInvoiceHandler={this.closeAddInvoiceHandler}
          onNameChangeHandler={this.onNameChangeHandler}
          saveProductHandler={this.saveProductHandler}
          onDescriptionChangeHandler={this.onDescriptionChangeHandler}
          products={this.state.products}
          productKeys={this.state.productKeys}
          addProduct
          addNewProduct={this.addNewProduct}
          selectValue={this.selectProductInvoice}
          keyupValue={this.setProductInvoiceName}
          handleRemoveRow={this.handleRemoveRow}
        />
        <div className="row">
          <div className="col-lg-10 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="invoice-area">
                  <div className="row">
                    {/*<div className="col-md-2">*/}
                    {/*<input className="form-control" type="text" placeholder="Contact"*/}
                    {/*aria-label="Search"/>*/}
                    {/*</div>*/}
                    <div className="col-md-3 position-relative  c-contact">
                      <label class="sr-only" for="inlineFormInputGroup">
                        Contact
                      </label>
                      <div class="input-group mb-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Contact</div>
                        </div>
                        <button
                          className="contact_modal d-none"
                          href="#modal-org-invoice"
                          data-toggle="modal"
                          data-target="#modal-org-invoice"
                        ></button>
                        <Autosuggestion
                          addContact
                          suggestionData={this.state.leads}
                          keys={this.state.suggestionKeys}
                          selectValue={this.selectLead}
                          keyupValue={this.doNothing}
                          nameValue={this.state.currentLead?.name}
                          placeholder={"Contacts"}
                        />
                        {this.state.lead ? (
                          <a
                            href="javascript:void(0);"
                            onClick={this.removeContact}
                          >
                            <span className="remove-value">x</span>
                          </a>
                        ) : (
                          ""
                        )}
                        {this.state.errors.customer ? (
                          <span className="invoice-error text-danger">
                            {this.errors.customer}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label className="sr-only" for="inlineFormInputGroup">
                        Reference
                      </label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">Reference</div>
                        </div>
                        <input
                          className="form-control"
                          id="inlineFormInputGroup"
                          type="text"
                          onChange={(e) =>
                            this.setState({ proposal_number: e.target.value })
                          }
                          value={this.state.proposal_number}
                          aria-label="Search"
                        />
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-12">
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div className="input-group-text filter-span date-span">
                            Date
                          </div>
                        </div>
                        <DatePicker
                          className="form-control date-picker-input"
                          selected={this.state.proposal_date}
                          onChange={this.handleDate}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-2 col-sm-12 margin_top_date"
                      // id="hide-content"
                    >
                      <div class="input-group mb-2 date-e">
                        <div class="input-group-prepend">
                          <div className="input-group-text filter-span date-span">
                            Expires
                          </div>
                        </div>
                        <DatePicker
                          className="form-control date-picker-input"
                          name="duo_date"
                          selected={this.state.proposal_expiry_date}
                          onChange={this.handleDuoDate}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                    {/* <div
                      class="col-md-2 col-sm-12 margin_top_date"
                      // id="hide-content"
                    >
                      <div class="input-group mb-2 date">
                        <div class="input-group-prepend">
                          <div className="input-group-text filter-span date-span">
                            Due
                          </div>
                        </div>
                        <DatePicker
                          name="due_date"
                          className="form-control date-picker-input"
                          selected={this.state.due_date}
                          onChange={this.handleDueDate}
                          dateFormat={"dd/MM/yyyy"}
                        />
                        {due_date_error && (
                          <div style={{ color: "red", marginTop: "-35px" }}>
                            Due date must be after Expiry date.
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>

                  <div class="row" id="hide-mobile">
                    <div class="col-12">
                      <div id="table" className="table-editable">
                        <table className="table table-bordered table-responsive-md text-center">
                          <thead>
                            <tr>
                              <th className="text-center">Product ID</th>
                              <th className="text-center">Description</th>
                              <th className="text-right">Units</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-center">Tax Rate</th>
                              <th className="text-right">Total Amount</th>
                              <th className="text-center" width="50"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.proposal_rows.map((element, index) => {
                              return (
                                <tr key={index} className="invoice-body">
                                  <td
                                    className="pt-3-half table-add invoice-col"
                                    width="125"
                                  >
                                    <Autosuggestion
                                      suggestionData={this.state.products}
                                      keys={this.state.productKeys}
                                      selectValue={this.selectProduct.bind(
                                        this,
                                        index
                                      )}
                                      nameValue={
                                        this.state.proposal_rows[index].name
                                      }
                                      keyupValue={this.setProductName.bind(
                                        this,
                                        index
                                      )}
                                      placeholder=""
                                      addProduct
                                      addNewProduct={this.addNewProduct}
                                    />
                                    {element.errors && element.errors.name ? (
                                      <span className="invoice-error text-danger">
                                        {this.errors.name}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td className="pt-3-half table-add invoice-col">
                                    {/* <TextareaAutosize
                                      minRow={1}
                                      name="description"
                                      onChange={this.handleInput.bind(
                                        this,
                                        index
                                      )}
                                      className="form-control input_border_none"
                                      value={element.description}
                                    /> */}

                                    <DraftComponent
                                      background={"#fff"}
                                      toolbarHidden={true}
                                      editorClass={"description-editor"}
                                      width={"100%"}
                                      initialValue={element && element.description
                                        ? element.description
                                        : ""}
                                      getValue={(value) =>
                                        this.handleDescription(
                                          value,
                                          index
                                        )
                                      }
                                    />
                                  </td>
                                  <td
                                    className="pt-3-half table-add invoice-col text-left"
                                    width="125"
                                  >
                                    <input
                                      name="quantity"
                                      type="text"
                                      onChange={this.handleInput.bind(
                                        this,
                                        index
                                      )}
                                      className="form-control input_border_none text-right"
                                      value={element.quantity}
                                      onKeyPress={this.handleKeypress.bind(
                                        this
                                      )}
                                    />
                                    {element.errors &&
                                    element.errors.quantity ? (
                                      <span className="invoice-error text-danger">
                                        {this.errors.quantity}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td
                                    className="pt-3-half table-add invoice-col text-left"
                                    width="125"
                                  >
                                    <input
                                      type="text"
                                      name="unit_amount"
                                      min="0"
                                      step="0.01"
                                      onChange={this.handleInput.bind(
                                        this,
                                        index
                                      )}
                                      className="form-control input_border_none text-right"
                                      value={
                                        element.unit_amount &&
                                        calcTwoDecimalPlaces(
                                          element.unit_amount
                                        )
                                      }
                                      onKeyPress={this.handleKeypress.bind(
                                        this
                                      )}
                                    />
                                    {element.errors &&
                                    element.errors.unit_amount ? (
                                      <span className="invoice-error text-danger">
                                        {this.errors.unit_amount}
                                      </span>
                                    ) : null}
                                  </td>
                                  <td
                                    className="pt-3-half table-add invoice-col"
                                    width="125"
                                    style={{ padding: 0 }}
                                  >
                                    <select
                                      style={{ height: "44.44px", border: 0 }}
                                      className="form-control"
                                      name="gst_free"
                                      onChange={this.handleInput.bind(
                                        this,
                                        index
                                      )}
                                    >
                                      <option
                                        value="0"
                                        selected={
                                          element.tax_rate === 0 ? true : false
                                        }
                                      >
                                        GST
                                      </option>
                                      <option
                                        value="1"
                                        selected={
                                          element.tax_rate === 1 ? true : false
                                        }
                                      >
                                        GST Free
                                      </option>
                                    </select>
                                  </td>
                                  <td
                                    className="pt-3-half table-add invoice-col text-right p-10"
                                    width="125"
                                  >
                                    <NumberFormat
                                      value={parseFloat(element.amount).toFixed(
                                        2
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      renderText={(value) => <div>{value}</div>}
                                    />
                                  </td>
                                  {this.state.proposal_rows.length > 1 && (
                                    <td>
                                      <span className="table-add invoice-col">
                                        <a
                                          onClick={this.handleRemoveRow.bind(
                                            this,
                                            index
                                          )}
                                          className="text-danger"
                                        >
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="6">
                                <span className="table-add">
                                  <a
                                    onClick={this.handleAddInvoice.bind(this)}
                                    className="text-success"
                                  >
                                    <i
                                      className="fa fa-plus fa-2x"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <td>Sub-Total:</td>
                              <td className="text-right">
                                <NumberFormat
                                  value={parseFloat(this.state.net).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <td> Total GST:</td>
                              <td className="text-right">
                                <NumberFormat
                                  value={parseFloat(this.state.gst).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <td>Total:</td>
                              <td className="text-right invoice-col">
                                <NumberFormat
                                  value={parseFloat(this.state.total).toFixed(
                                    2
                                  )}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  renderText={(value) => <div>{value}</div>}
                                />
                                <span className="invoice-error text-danger text-right">
                                  {this.state.errors.totalError
                                    ? this.state.errors.totalDescription
                                    : ""}
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row" id="hide-mobile">
                    <div className="col-md-6 mt-1">
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">Notes</div>
                          </div>
                          {/* <textarea
                            className="form-control"
                            onKeyUp={this.onNotesChangeHandler}
                            id="exampleFormControlTextarea1"
                            rows="8"
                            defaultValue={this.state.notes}
                          ></textarea> */}
                          {/* {this.state.notes && ( */}
                            <DraftComponent
                            editorClass={"notes-editor"}
                              width={"80%"}
                                initialValue={
                                this.state.notes && this.state.notes
                              }
                              toolbarHidden={true}
                              getValue={(value) =>
                                this.setState({ notes: value })
                              }
                            />
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 mt-1">
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        <div className="input-group mb-2">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              onChange={this.onFileChangeHandler}
                              id="customFile"
                            />
                            <label
                              className="custom-file-label"
                              for="customFile"
                            >
                              Attach file
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.attachments.map((file2) => (
                        <div className="row">
                          <div title={file2.file_name} className="col-md-10">
                            {file2.name.length > 30
                              ? file2.name.substr(0, 30) + "..."
                              : file2.name}{" "}
                          </div>

                          <i
                            style={{ cursor: "pointer" }}
                            onClick={() => this.fileDelteHandler(file2)}
                            class="fas fa-trash"
                          ></i>
                        </div>
                      ))}
                      {this.state.notes_files.map((file2) => (
                        <div className="row">
                          <div className="col-md-10">
                            {file2.name.length > 30
                              ? file2.name.substr(0, 30) + "..."
                              : file2.name}{" "}
                          </div>

                          <i
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                notes_files: this.state.notes_files.filter(
                                  (file1) => file1.name !== file2.name
                                ),
                              })
                            }
                            class="fas fa-trash"
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row" id="hide-desktop">
                    {this.state.proposal_rows.length &&
                    this.state.proposal_rows[0].name ? (
                      <p style={{ fontSize: 16 }}>Invoice Items</p>
                    ) : (
                      ""
                    )}

                    <table className="table">
                      <tbody>
                        {this.state.proposal_rows.map((element, index) => {
                          return element.description ? (
                            <tr key={index}>
                              <td
                                style={{ whiteSpace: "pre-wrap", width: "95%" }}
                                id="myBtn-1"
                                onClick={() =>
                                  this.setState({
                                    selectedProduct: element,
                                    selectedIndex: index,
                                    showAddInvoice: true,
                                    editing: true,
                                  })
                                }
                              >
                                {element.description} <br />
                                {element.quantity}X{" "}
                                {element.unit_amount &&
                                  "$" +
                                    formatTwoDecimalsWithComma(
                                      parseFloat(element.unit_amount)
                                    )}{" "}
                                (
                                {parseInt(element.tax_rate) === 1
                                  ? "GST Free"
                                  : "GST"}
                                )
                              </td>
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div class="row" id="hide-desktop">
                    <div class="col-12">
                      <button
                        type="button"
                        class="btn btn-success"
                        style={{ width: "100%" }}
                        onClick={() => this.setState({ showAddInvoice: true })}
                      >
                        + Add Item
                      </button>
                    </div>
                  </div>

                  <div class="row" id="hide-desktop">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td>Sub-Total</td>
                          <td style={{ textAlign: "right" }}>
                            <NumberFormat
                              value={formatTwoDecimals(this.state.net)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total GST</td>
                          <td style={{ textAlign: "right" }}>
                            <NumberFormat
                              value={formatTwoDecimals(this.state.gst)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td
                            style={{ textAlign: "right" }}
                            className="invoice-col"
                          >
                            <NumberFormat
                              value={formatTwoDecimals(this.state.total)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <span>{value}</span>}
                            />
                            <span className="invoice-error text-danger text-right">
                              {this.state.errors.totalError
                                ? this.state.errors.totalDescription
                                : ""}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" id="hide-desktop">
                    <div className="col-md-3 mt-1">
                      <div className="form-group">
                        <label for="exampleFormControlTextarea1"></label>
                        <div className="input-group mb-2">
                          <div className="custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              onChange={this.onFileChangeHandler}
                              id="customFile"
                            />
                            <label
                              className="custom-file-label"
                              for="customFile"
                            >
                              Attach file
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.attachments.map((file2) => (
                        <div className="row">
                          <div title={file2.file_name} className="col-md-10">
                            {file2.name.length > 30
                              ? file2.name.substr(0, 30) + "..."
                              : file2.name}{" "}
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() => this.fileDelteHandler(file2)}
                              class="fas fa-trash"
                            ></i>
                          </div>
                        </div>
                      ))}
                      {this.state.notes_files.map((file2) => (
                        <div className="row">
                          <div className="col-md-10">
                            {file2.name.length > 30
                              ? file2.name.substr(0, 30) + "..."
                              : file2.name}{" "}
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  notes_files: this.state.notes_files.filter(
                                    (file1) => file1.name !== file2.name
                                  ),
                                })
                              }
                              class="fas fa-trash"
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-buttons text-center pt-2 pb-2">
                {this.state.errors.proposal_length ? (
                  <span className="invoice-error text-danger">
                    {this.errors.proposal_length}
                  </span>
                ) : null}
              </div>
              {/* <div className="invoice-buttons text-center"> */}
              {/* <button
                  name="Preview"
                  type="button"
                  className="btn btn-secondary btn-non-edit-invoice"
                  style={{ width: "150px", marginRight: "4px" }}
                >
                  Preview
                </button> */}
              {/* <button
                  name="Draft"
                  onClick={this.handleSave.bind(this)}
                  type="button"
                  className="btn btn-secondary btn-non-edit-invoice"
                  style={{ width: "150px", marginRight: "4px" }}
                >
                  Save As Draft
                </button>
                <button
                  name="Approved"
                  onClick={this.handleSave.bind(this)}
                  type="button"
                  className="btn btn-secondary btn-non-edit-invoice"
                  style={{ width: "150px" }}
                >
                  Approve
                </button>
              </div> */}
            </div>
          </div>

          <div className="col-lg-2 mt-3">
            <div className="card">
              <div className="card-body">
                <div className="invoice-buttons text-center">
                  <button
                    type="button"
                    name="Approved"
                    className="btn btn-success"
                    style={{ width: "150px" }}
                    onClick={this.handleSave.bind(this)}
                  >
                    Approve
                  </button>
                  {this.state.status == "Draft" && (
                    <button
                      type="button"
                      name="Draft"
                      className="btn btn-secondary new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.handleSave.bind(this)}
                    >
                      Save As Draft
                    </button>
                  )}
                  {this.state.status === "Overdue" ||
                  this.state.status === "Approved" ||
                  this.state.status === "Draft" ? (
                    <button
                      type="button"
                      className="btn btn-danger new-proposal-button"
                      style={{ width: "150px" }}
                      onClick={this.changeStatusToArchived}
                    >
                      Void
                    </button>
                  ) : null}
                  {/* <!-- <button type="button" class="btn btn-secondary" style="width: 150px">Edit</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">Copy</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">View</button> -->
										<!-- <button type="button" class="btn btn-secondary" style="width: 150px">Print PDF</button>
										<button type="button" class="btn btn-secondary" style="width: 150px">Mark As Paid</button> --> */}
                </div>
              </div>
            </div>
          </div>
          <AddInvoiceContact getLeads={this.getNewLeads} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProposal);
