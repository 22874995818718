import React from "react";
import SuperFetch from "../../../helpers/superFetch";
import $ from "jquery";

export default class AddInvoiceContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      type: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      company_name: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {
    let tmp = this.state.errors;
    if (e.target.value !== "") {
      tmp[e.target.name] = "";
      this.setState({ errors: tmp });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  validate() {
    let isError = false;
    let errors = {};
    if (this.state.type === "") {
      isError = true;
      errors.type = "Required field";
    }
    if (
      this.state.type === "Business/Organisation" &&
      this.state.company_name === ""
    ) {
      isError = true;
      errors.company_name = "Required field";
    }
    if (this.state.first_name === "") {
      isError = true;
      errors.first_name = "Required field";
    }
    if (this.state.last_name === "") {
      isError = true;
      errors.last_name = "Required field";
    }
    if (this.state.email === "") {
      isError = true;
      errors.email = "Required field";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      errors.email = "Invalid email address";
    }
    // if(this.state.mobile === ''){
    //     isError = true;
    //     errors.mobile = 'Required field';
    // }
    this.setState({ errors: errors });
    return isError;
  }

  createData() {
    let data = {
      type: this.state.type,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      mobile: this.state.mobile,
      company_name: this.state.company_name,
    };
    return data;
  }

  clearInput() {
    this.setState({
      type: "",
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      company_name: ""
    });
  }

  handleSubmit(e) {
    let newLeads = "";
    if (this.validate()) return;
    SuperFetch.post("/leads", this.createData()).then((response) => {
      if (response) {
        this.props.getLeads(response.data);
        $("#modal-org-invoice").find('button[data-dismiss="modal"]').click();
        this.clearInput();
      }
    });
  }

  render() {
    return (
      <div className="modal fade" id="modal-org-invoice">
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title contact_modal_title">New Contact</h5>
              <button type="button" className="close" data-dismiss="modal">
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="col-md-12 mt-3">
                  <label className="sr-only" for="inlineFormInputGroup"></label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="input-label-large">
                        Type
                      </div>
                    </div>
                    <select
                      onChange={this.handleInput.bind(this)}
                      className="form-control"
                      name="type"
                      value={this.state.type}
                      style={{ padding: "8px" }}
                    >
                      <option value=""></option>
                      <option value="Business/Organisation">
                        Business/Organisation
                      </option>
                      <option value="Individual">Individual</option>
                    </select>
                    {this.state.errors.type !== "" ? (
                      <span className="m-form__help w-100  text-danger">
                        {this.state.errors.type}
                      </span>
                    ) : null}
                  </div>
                </div>

                {this.state.type === "Business/Organisation" && (
                  <div className="col-md-12 mt-3">
                    <label
                      className="sr-only"
                      for="inlineFormInputGroup"
                    ></label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text"
                          id="input-label-large"
                        >
                          Organisation
                        </div>
                      </div>
                      <input
                        onChange={this.handleInput.bind(this)}
                        className="form-control"
                        name="company_name"
                        value={this.state.company_name}
                      />
                      {this.state.errors.company_name !== "" ? (
                        <span className="m-form__help w-100  text-danger">
                          {this.state.errors.company_name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="col-md-12 mt-3">
                  <label className="sr-only" for="inlineFormInputGroup"></label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="input-label-large">
                        First Name
                      </div>
                    </div>
                    <input
                      onChange={this.handleInput.bind(this)}
                      className="form-control"
                      name="first_name"
                      value={this.state.first_name}
                    />
                    {this.state.errors.first_name !== "" ? (
                      <span className="m-form__help w-100  text-danger">
                        {this.state.errors.first_name}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="sr-only" for="inlineFormInputGroup"></label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="input-label-large">
                        Last Name
                      </div>
                    </div>
                    <input
                      onChange={this.handleInput.bind(this)}
                      className="form-control"
                      name="last_name"
                      value={this.state.last_name}
                    />
                    {this.state.errors.last_name !== "" ? (
                      <span className="m-form__help w-100  text-danger">
                        {this.state.errors.last_name}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="sr-only" for="inlineFormInputGroup"></label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="input-label-large">
                        Email
                      </div>
                    </div>
                    <input
                      onChange={this.handleInput.bind(this)}
                      className="form-control"
                      name="email"
                      value={this.state.email}
                    />
                    {this.state.errors.email !== "" ? (
                      <span className="m-form__help w-100  text-danger">
                        {this.state.errors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label className="sr-only" for="inlineFormInputGroup"></label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="input-label-large">
                        Mobile
                      </div>
                    </div>
                    <input
                      onChange={this.handleInput.bind(this)}
                      className="form-control"
                      name="mobile"
                      type="number"
                      value={this.state.mobile}
                    />
                    {/*{(this.state.errors.mobile !== '') ? <span*/}
                    {/*className="m-form__help w-100  text-danger">{this.state.errors.mobile}</span> : null}*/}
                  </div>
                </div>
                <hr />
                <div class="invoice-buttons text-center">
                  <button
                    onClick={this.handleSubmit.bind(this)}
                    type="button"
                    className="btn btn-success"
                  >
                    Create
                  </button>
                  <button
                    onClick={() =>
                      $("#modal-org-invoice")
                        .find('button[data-dismiss="modal"]')
                        .click()
                    }
                    type="button"
                    className="btn btn-secondary ml-2"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
