import SuperFetch from "./superFetch";
import { apiHost } from "../config";
import axios from "axios";
import { configMultipart } from "./utility";

class ContactHelper {
  
  fetchSingleContact = async (id) => {
    return await SuperFetch.get("/leads/" + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createNote = async (values) => {
    return await SuperFetch.post("/lead_notes", values)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export default new ContactHelper();
