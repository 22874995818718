
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from "react-router-dom";
import url from "../../../url";
import Alert from "../../uielements/alert"

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!values.password) {
        errors.password = 'Password is required'
    } else if (values.password.length < 6) {
        errors.password = 'Password should be min 6 character'
    }

    if (!values.password_confirmation) {
        errors.password_confirmation = 'Confirm password is required'
    } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Password and confirm password should be same'
    }

    return errors
}



const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
        <div>

            <input {...input} placeholder={label} type={type} />

            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    )

let ResetPasswordForm = props => {
    const { handleSubmit, pristine, reset, submitting } = props
    return (<form onSubmit={handleSubmit}>
        <div
            className='login-form-head'
            style={{ backgroundColor: "#2C3539" }}
        >
            <h2 className='logo' style={{ color: "white" }}>
                Cloud Collect
          </h2>
            <h2
                className='logo'
                style={{ color: "white", fontSize: "20px" }}
            >
                Reset Password
          </h2>
        </div>
        <div className='login-form-body'>
            <Alert />
            <div className='form-gp'>
                {/* <label for='exampleInputEmail1'>Email</label> */}
                <Field label="Email" component={renderField} type='email' name="email" id='exampleInputEmail1' />
            </div>
            <div className='form-gp'>
                {/* <label for='exampleInputEmail1'>Email</label> */}
                <Field label="Password" component={renderField} type='password' name="password" id='password' />
            </div>
            <div className='form-gp'>
                {/* <label for='exampleInputEmail1'>Email</label> */}
                <Field label="Confirm Password" component={renderField} type='password' name="password_confirmation" id='password_confirmation' />
            </div>
            <div className='submit-btn-area'>
                <button id='form_submit' type='submit'>
                    Reset Password
              <i className='ti-arrow-right'></i>
                </button>
            </div>
            <br />
            <div className='submit-btn-area'>
                <Link to={url.account.login}>
                    <button type='button'>
                        Go back to login<i className='ti-arrow-left'></i>
                    </button>
                </Link>
            </div>
        </div>
    </form>)
}

ResetPasswordForm = reduxForm({
    // a unique name for the form
    form: 'resetPassword',
    validate
})(ResetPasswordForm)

export default ResetPasswordForm
