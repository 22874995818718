import React, { Component } from "react";

class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        {/* <div id="preloader">
          <div className="loader"></div>
        </div> */}
        <div className="">
          <div class="">
            <div class="sales-report-area mt-2 mb-5">
              <div class="row">
                <div class="col-md-12" id="onboard-card">
                  <div class="card w-75" style={{ margin: "200px auto" }}>
                    <div class="card-body" style={{ textAlign: "center" }}>
                      <div id="text-center">
                        <h3 style={{ textAlign: "center" }}>Cloud Collect</h3>
                        <h3 style={{ textAlign: "center" }}>
                          Customer Sign-Up
                        </h3>
                        <p>Powered by Cloud Collect</p>
                      </div>
                      <hr />
                      <p>
                        Sign-up complete, thank you! A confirmation email with
                        account details has been sent to you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Thankyou;
