import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import ProposalHelper from "../../helpers/proposalHelper";

export function* getSelectedProposal() {
  yield takeEvery("SET_SELECTED_PROPOSAL", function* ({ payload }) {
    yield put({
      type: actions.SET_PROPOSAL,
      payload: payload.payload,
    });
  });
}

export function* fetchAllProposals() {
  yield takeEvery("FETCH_CURRENT_PROPOSALS", function* () {
    try {
      const result = yield call(ProposalHelper.fetchAllProposals);
      if (result) {
        yield put({
          type: actions.SET_PROPOSALS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FLOW,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchAllProposals)]);
  yield all([fork(getSelectedProposal)]);
}
