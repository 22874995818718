import React, { Component } from "react";
import ColumnLoader from "../../uielements/columnLoader";
import SuperFetch from "../../../helpers/superFetch";
import Alert from "../../uielements/alert";
import { getUser } from "../../../helpers/utility";
import { store } from "react-notifications-component";
// import "react-datepicker/dist/react-datepicker.css";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      amount: "",
      description: "",
      type: "Single",
      checkbox: true,
      frequency: "Weekly",
      name_err: "",
      amount_err: "",
      duration: "",
      payment_in: "",
      upfront_payment: false,
      hidden: false,
      gst_free: false,
    };
    this.checkBox = this.checkBox.bind(this);
    this.checkHidden = this.checkHidden.bind(this);
  }
  componentDidMount() {
    document.title = "Cloud Collect - Create Product";
  }

  checkBox(e) {
    this.setState({
      checkbox: !this.state.checkbox,
    });
  }

  checkHidden() {
    this.setState({ hidden: !this.state.hidden });
  }

  checkGstFree(e) {
    this.setState({ gst_free: e.target.value });
  }

  handleUserInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = async () => {
    let isError = false;
    const errors = {
      name_err: "",
      amount_err: "",
      description_err: "",
    };

    if (this.state.name === "") {
      isError = true;
      errors.name_err = "Please enter Product ID.";
    } else {
      const res = await SuperFetch.get(
        `/products/validate?name=${this.state.name}`
      );
        console.log(res.valid)
      if (res && !res.valid) {
        console.log('sadasdsad');
        isError = true;
        errors.name_err = "Product ID already taken.";
      } else {
        errors.name_err = "";
      }
    }

    if (this.state.amount === "") {
      isError = true;
      errors.amount_err = "Please enter Unit Price.";
    }
    if (this.state.description === "") {
      isError = true;
      errors.description_err = "Please enter description.";
    }

    this.setState({
      ...this.state,
      ...errors,
    });
    return isError;
  };

  hiddenValue() {
    let hidden = null;
    if (this.state.hidden === true) hidden = 1;
    if (this.state.hidden === false) hidden = 0;
    return hidden;
  }

  upfrontPaymentValue() {
    let upfront_payment = null;
    if (this.state.checkbox === true) upfront_payment = 1;
    if (this.state.checkbox === false) upfront_payment = 0;
    return upfront_payment;
  }

  gstFreeValue() {
    let gstFree = null;
    if (this.state.gst_free === true) gstFree = 1;
    if (this.state.gst_free === false) gstFree = 0;
    return gstFree;
  }

  singleOrRecuring() {
    const userinfo = getUser();
    const data = {
      name: this.state.name,
      amount: this.state.amount,
      type: this.state.type,
      description: this.state.description,
      // upfront_payment: this.upfrontPaymentValue(),
      duration: this.state.duration,
      payment_in: this.state.payment_in,
      userId: userinfo.id,
      organisation_id: userinfo.organisation.id,
      hidden: this.hiddenValue(),
      gst_free: this.gstFreeValue(this.state.gst_free),
    };
    if (this.state.type !== "Single") data.frequency = this.state.frequency;
    return data;
  }

  handleSubmit = async (e) => {
    if (this.state.type === "Single") {
      this.setState({ frequency: "" });
    }
    e.preventDefault();
    let valid = await this.validate();
    console.log(valid);

    if (!valid) {
      this.setState({ isLoading: true });
      SuperFetch.post("/products", this.singleOrRecuring())
        .then((response) => {
          this.setState({ isLoading: false });
          store.addNotification({
            message: "Product added successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          this.props.history.push("/products");
          SuperFetch.get("/products").then((response) => {
            this.props.history.push({
              state: { detail: response.data },
            });
          });
        })
        .catch((error) => {
          throw error;
        });
    }
  };
  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <>
        {/* <div className="row">
          <Alert />
        </div> */}
        <div class="main-content-inner">
          <div class="row">
            <div class="col-md-4 mt-3">
              <div className="card" id="card-inner">
                <div className="card-body">
                  {this.state.isLoading === true ? (
                    <ColumnLoader />
                  ) : (
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <p class="onboard-heading">Product Details</p>
                      <hr />

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Product ID</div>
                          </div>
                          <input
                            type="text"
                            name="name"
                            id="inlineFormInputGroup"
                            placeholder=""
                            className="form-control"
                            onChange={(e) => this.handleUserInput(e)}
                          />
                          {this.state.name_err ? (
                            <span className="m-form__help w-100 d-inline-block text-danger">
                              {this.state.name_err}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Unit Price</div>
                          </div>
                          <input
                            type="number"
                            step="0.01"
                            name="amount"
                            className="form-control"
                            onChange={(e) => this.handleUserInput(e)}
                            id="inlineFormInputGroup"
                            placeholder=""
                          />
                          {this.state.amount_err ? (
                            <span className="m-form__help w-100 d-inline-block text-danger">
                              {this.state.amount_err}
                            </span>
                          ) : null}
                        </div>
                        <span>Input GST-Exclusive Unit Price</span>
                      </div>

                      <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">GST Code</div>
                          </div>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            onChange={this.checkGstFree.bind(this)}
                            name="gst_free"
                            style={{ height: "45px" }}
                          >
                            <option value={false}>GST</option>
                            <option value={true}>GST-Free</option>
                          </select>
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="gst_free"
                            value={this.state.gst_free}
                            onChange={this.checkGstFree.bind(this)}
                            className="custom-control-input"
                            id="customCheck4"
                          />
                          <label
                            className="custom-control-label custom-control-invoice"
                            htmlFor="customCheck4"
                          >
                            GST Free
                          </label>
                        </div>
                      </div> */}

                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1"></label>
                          <div class="input-group mb-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">Description</div>
                            </div>
                            <textarea
                              onChange={(e) => this.handleUserInput(e)}
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="8"
                              name="description"
                            ></textarea>
                            {this.state.description_err ? (
                              <span className="m-form__help w-100 d-inline-block text-danger">
                                {this.state.description_err}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-md-8 mt-3">
                        <label
                          class="sr-only"
                          for="inlineFormInputGroup"
                        ></label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Type</div>
                          </div>
                          <select
                            style={{ height: "44.44px" }}
                            className="form-control"
                            name="type"
                            id="exampleFormControlSelect1"
                            onChange={(e) => this.handleOptionChange(e)}
                          >
                            <option value="Single">Regular</option>
                            <option value="Recurring">Recurring</option>
                            <option value="Upfront">Upfront</option>
                          </select>
                        </div>
                      </div> */}
                      {/* {this.state.type === "Single" ||
                      this.state.type === "Upfront" ? null : (
                        <>
                          <div class="col-md-8 mt-3">
                            <label
                              class="sr-only"
                              for="inlineFormInputGroup"
                            ></label>
                            <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                <div class="input-group-text">Frequency</div>
                              </div>
                              <select
                                style={{ height: "45.44px" }}
                                className="form-control"
                                name="frequency"
                                id="exampleFormControlSelect1"
                                onChange={(e) => this.handleOptionChange(e)}
                              >
                                <option value="Weekly">Weekly 1-52</option>
                                <option value="Forthrightly">
                                  Forthrightly 1-26
                                </option>
                                <option value="Monthly">Monthly 1-12</option> */}
                      {/*<option value="Quarterly">Quarterly</option>*/}
                      {/*<option value="Annually">Annually</option>*/}
                      {/* </select>
                            </div>
                          </div>
                          <div class="col-md-8 mt-3">
                            <label
                              class="sr-only"
                              for="inlineFormInputGroup"
                            ></label>
                            <div class="input-group mb-2">
                              <div class="input-group-prepend">
                                <div class="input-group-text">Duration</div>
                              </div>
                              <input
                                type="number"
                                name="duration"
                                onChange={(e) => this.handleOptionChange(e)}
                                className="form-control"
                                // id="inlineFormInputGroup"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </>
                      )} */}
                      {/* <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="hidden"
                            value={this.state.hidden}
                            onChange={this.checkHidden.bind(this)}
                            className="custom-control-input"
                            id="customCheck2"
                          />
                          <label
                            className="custom-control-label custom-control-invoice"
                            htmlFor="customCheck2"
                          >
                            Public Product
                          </label>
                        </div>
                      </div> */}

                      <hr />
                      <div class="invoice-buttons text-center">
                        <button type="submit" class="btn btn-success">
                          Save
                        </button>
                      </div>
                      {/* <div className="form-group">
                        <button className="btn btn-secondary">Submit</button>
                      </div> */}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Products;
