import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import createHistory from 'history/createBrowserHistory';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from '../redux/reducers';
import rootSaga from '../redux/sagas';
import { reducer as formReducer } from 'redux-form'
import {loaderReducer} from "../redux/Loader/Reducer"
const history = require("history").createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent;

const logger = createLogger({
  predicate: (getState, action) => isDebuggingInChrome,
  collapsed: true,
  duration: true,
  diff: true,
});

const middlewares = [thunk, sagaMiddleware, routeMiddleware, logger];


const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;


const store = createStore(
  
  combineReducers({
    ...reducers,
    router: routerReducer,
    form: formReducer,
    Loader:loaderReducer
  }),
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
export { store, history };
