import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderSelect } from "../../uielements/FormFields";
import { required } from "redux-form-validators";

const ContactForm = (props) => {
  const { handleSubmit, typeOptions, states, contactForm } = props;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <p class="onboard-heading">Details</p>
        <hr />
        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              name="type"
              label="Type"
              type="text"
              id="type"
              component={renderSelect}
              options={typeOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              // validate={[required()]}
            />
          </div>
        </div>
        {contactForm?.values?.type === "Business/Organisation" && (
          <div class="col-md-12 mt-3">
            <label class="sr-only" for="inlineFormInputGroup"></label>
            <div class="input-group mb-2">
              <Field
                name="company_name"
                label="Organisation"
                component={renderField}
                type="text"
                id="company_name"
                validate={[required()]}
              />
            </div>
          </div>
        )}

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="First Name"
              component={renderField}
              id="first_name"
              name="first_name"
              validate={[required()]}
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="Last Name"
              component={renderField}
              id="last_name"
              name="last_name"
              validate={[required()]}
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="Email "
              component={renderField}
              type="email"
              id="email"
              name="email"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="Mobile"
              component={renderField}
              type="number"
              id="mobile"
              name="mobile"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="Address"
              component={renderField}
              type="text"
              id="address"
              name="address"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              component={renderField}
              label="Suburb"
              type="text"
              id="suburb"
              name="suburb"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              label="State"
              id="state"
              name="state"
              component={renderSelect}
              options={states.map((state) => ({ label: state, value: state }))}
            />
          </div>
        </div>

        <div class="col-md-7 mt-3">
          <label class="sr-only" for="inlineFormInputGroup"></label>
          <div class="input-group mb-2">
            <Field
              component={renderField}
              label="Postcode"
              type="number"
              id="postcode"
              name="postcode"
            />
          </div>
        </div>

        <hr />
        <div class="invoice-buttons text-center">
          <button type="submit" class="btn btn-success">
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default reduxForm({
  form: "ContactForm",
  // validate,
  enableReinitialize: true,
})(ContactForm);
