import React from "react";
import url from "../../url";
import businessActions from "../../redux/businessUser/actions";
import { connect } from "react-redux";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      entity_id: null,
      entity_status: "",
    };
  }
  componentDidMount() {
    document.title = "Cloud Collect - Dashboard";
    let data = JSON.parse(localStorage.getItem("cloud_collect_userInfo"));
    this.setState({ data: data });
    this.props.fetchMyBusinessInvites();
  }
  handleLogout = () => {
    localStorage.removeItem("cloud_collect_userInfo");
    window.location.href = url.index;
  };

  updateInvite = () => {
    const { history } = this.props;

    this.props.acceptRejectBusinessInvite(history, {
      id: this.state.entity_id,
      status: this.state.entity_status,
    });
  };

  handleEntity = (id, status) => {
    this.setState({
      entity_id: id,
      entity_status: status,
    });
  };

  render() {
    return (
      <div>
        <div className="">
          <div className="row">
            <div className="col-md-12 mt-3">
              <div className="card-1">
                <div className="card-body-1">
                  {this.props.myBusinessInvites.map((invite, index) => (
                    <div className="alert alert-info m-2 p-4" key="index">
                      {index + 1}. You have been invited to join{" "}
                      {invite.organisation.business_name.toUpperCase()}
                      <div className="ml-4 btn-group float-right">
                        <button
                          className="btn btn-outline-primary btn-xs mb-0"
                          onClick={() =>
                            this.handleEntity(invite.id, "Accepted")
                          }
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          Accept
                        </button>
                        <button
                          className="btn btn-outline-danger btn-xs mb-0"
                          onClick={() =>
                            this.handleEntity(invite.id, "Declined")
                          }
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="modal fade" id="myModal">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">Are you sure?</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => this.updateInvite()}
                          data-dismiss="modal"
                          data-target="#myModal"
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          data-dismiss="modal"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<footer>*/}
          {/*<div className="footer-area">*/}
          {/*<p>© Copyright 2019 Cloud Collect Pty Ltd (ABN 74 630 831 775). All rights reserved.</p>*/}
          {/*</div>*/}
          {/*</footer>*/}

          <div className="offset-area">
            <div className="offset-close">
              <i className="ti-close"></i>
            </div>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <h5>Notifications</h5>
            </div>
            <div
              className="offset-content tab-content"
              style={{ paddingTop: "25px" }}
            >
              <div id="activity" className="tab-pane fade in show active">
                <div className="recent-activity">
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-plus"></i>
                    </div>
                    <div className="tm-title">
                      <h4>New Customer: Rashan Gary</h4>
                      <span className="time">
                        <i className="ti-time"></i>7 Minutes Ago
                      </span>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg2">
                      <i className="fa fa-exclamation-triangle"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Missed Payment: Kevin King</h4>
                      <span className="time">
                        <i className="ti-time"></i>09:20 Am
                      </span>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg3">
                      <i className="fa fa-bomb"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Cancellation: Darnell Savage Jr.</h4>
                      <span className="time">
                        <i className="ti-time"></i>09:35
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="messages-area">
            <div className="messages-close">
              <i className="ti-close"></i>
            </div>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <h5>Messages</h5>
            </div>
            <ul
              className="nav offset-menu-tab"
              style={{ marginBottom: "-25px" }}
            >
              <li>
                <a className="active" data-toggle="tab" href="">
                  Inbox
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="">
                  New Message
                </a>
              </li>
            </ul>
            <div className="messages-content tab-content">
              <div id="inbox" className="tab-pane fade in show active">
                <div id="message-search">
                  <form className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Search"
                    />
                    <br />
                  </form>
                </div>
                <div className="recent-activity">
                  <div className="timeline-task">
                    <div className="icon bg1">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Nick Bosa</h4>
                      <span className="time">
                        <i className="ti-time"></i>09:35am 24/08/2019
                      </span>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg1">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Shaun Wade</h4>
                      <span className="time">
                        <i className="ti-time"></i>08:42am 24/08/2019
                      </span>
                    </div>
                  </div>
                  <div className="timeline-task">
                    <div className="icon bg1">
                      <i className="fa fa-envelope"></i>
                    </div>
                    <div className="tm-title">
                      <h4>Victor Benjamin</h4>
                      <span className="time">
                        <i className="ti-time"></i>05:22pm 23/08/2019
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div id="messages-new" className="tab-pane fade">
                <div className="messages-settings">
                  <form className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Recepeint"
                    />
                    <br />
                    <textarea
                      className="form-control"
                      rows="10"
                      placeholder="Message"
                    ></textarea>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="customer-new-area">
            <div className="customer-new-close">
              <i className="ti-close"></i>
            </div>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <h5>New Customer</h5>
            </div>
            <ul
              className="nav offset-menu-tab"
              style={{ marginBottom: "-25px" }}
            >
              <li>
                <a className="active" data-toggle="tab" href="#customer-create">
                  Create Customer
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#customer-invite">
                  Invite Customer
                </a>
              </li>
            </ul>
            <div className="customer-new-content tab-content">
              <div
                id="customer-create"
                className="tab-pane fade in show active"
              >
                <form className="form-group">
                  <div className="form-group">
                    <label>First Name</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>E-mail Address</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Mobile Phone</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Residential Address</label>
                    <textarea
                      className="form-control"
                      rows="2"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Product/Service</label>
                    <br />
                    <select className="custom-select">
                      <option defaultValue></option>
                      <option value></option>
                    </select>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: " 100%" }}
                  >
                    Create
                  </button>
                </form>
              </div>
              <div id="customer-invite" className="tab-pane fade">
                <div className="messages-settings">
                  <form className="form-group">
                    <div className="form-group">
                      <label>E-mail Address</label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <p>or</p>
                    <br />
                    <div className="form-group">
                      <label>Mobile Phone</label>
                      <input
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder=""
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                    >
                      Send Form
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="service-new-area">
            <div className="service-new-close">
              <i className="ti-close"></i>
            </div>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <h5>New Engagement</h5>
            </div>
            <div className="service-new-content tab-content">
              <div id="invoice-new" className="tab-pane fade in show active">
                <form className="form-group">
                  <div className="form-group">
                    <label className="col-form-label">Select</label>
                    <select className="form-control" style={{ height: "50px" }}>
                      <option style={{ padding: "20px" }}>Invoice</option>
                      <option>Proposal</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Customer</label>
                    <textarea
                      className="form-control"
                      rows="1"
                      placeholder=""
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Current Service(s)</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group">
                    <label>Product/Service</label>
                    <br />
                    <select className="custom-select">
                      <option defaultValue></option>
                      <option></option>
                    </select>
                  </div>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" />
                    <label className="form-check-label">
                      Replace Current Service
                    </label>
                  </div>
                  <br />
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const {
  fetchMyBusinessInvites,
  acceptRejectBusinessInvite,
  fetchBusinessOrganisations,
} = businessActions;

const mapDispatchToProps = (dispatch) => ({
  fetchMyBusinessInvites: () => fetchMyBusinessInvites({ dispatch }),
  fetchBusinessOrganisations: () => fetchBusinessOrganisations({ dispatch }),
  acceptRejectBusinessInvite: (history, payload) =>
    acceptRejectBusinessInvite({ dispatch, history, payload }),
});

const mapStateToProps = (state) => {
  return {
    loading: state.BusinessUser.loading,
    myBusinessInvites: state.BusinessUser.myBusinessInvites,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
