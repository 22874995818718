import React from "react";
import { Field, reduxForm } from "redux-form";
import Alert from "../uielements/alert";
import { Link } from "react-router-dom";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password should be min 6 character";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  ...rest
}) => (
  <div>
    <input {...input} {...rest} placeholder={label} type={type} />
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

let LoginForm = (props) => {
  const { handleSubmit } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <Alert />
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <Field
              label="Email Address"
              name="email"
              className="form-control"
              component={renderField}
              type="email"
              id="input-useremail"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <Field
              label="Password"
              name="password"
              className="form-control"
              component={renderField}
              type="password"
              id="input-password"
            />
          </div>
        </div>
        <div className="submit-btn-area">
          <button type="submit">
            Login<i className="ti-arrow-right"></i>
          </button>
        </div>
      </form>

      <div className="submit-btn-area">
        <Link
          className="btn btn-outline-default"
          target="_blank"
          to={"/forgot-password"}
          style={{ width: "100%", padding: 0 }}
        >
          <button type="button">Forget Password <i className="ti-arrow-right"></i></button>
        </Link>
      </div>
    </div>
  );
};

LoginForm = reduxForm({
  // a unique name for the form
  form: "login",
  validate,
})(LoginForm);

export default LoginForm;
