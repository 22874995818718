import { Map } from "immutable";
import actions from "./actions";

const initState = { flow: null };
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_FLOW:
      return { ...state, flow: action.payload };

    default:
      return state;
  }
}
