import SuperFetch from "./superFetch";

class UserHelper {
  fetchUsers = async ({ payload }) => {
    const { role } = payload;
    return await SuperFetch.get("/users?role=" + role)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  deleteUser = async ({ payload }) => {
    const { id } = payload;
    return await SuperFetch.delete("/users/" + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  deleteConnectAccount = async ({ payload }) => {
    const { id } = payload;
    return await SuperFetch.post("/users/delete-connect/" + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
  updateUser = async (payload) => {
    return await SuperFetch.post("/update-current-user", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.errors && error.errors.email) {
          return { error: error.errors.email };
        }
        return { error: "Something went wrong" };
      });
  };
}
export default new UserHelper();
