import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({ selectedContact: null, contact: null });
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, selectedContact: action.payload };
    case actions.FETCH_SINGLE_CONTACT_SUCCESS:
      return { ...state, selectedContact: action.payload.full_name, contact: action.payload };

    default:
      return state;
  }
}
