import React, { Component } from "react";
import authAction from "../../redux/auth/actions";
import { connect } from "react-redux";
import { getUser } from "../../helpers/utility";
import LoginModalForm from "./LoginModalForm";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class Login extends Component {
  couter = 0;
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  submit = async (values) => {
    const { login } = this.props;

    await login({ userInfo: values });
  };

  handleShowPassword(e) {
    // this.counter +=1;
    if ($('input[name="password"]').attr("type") === "password") {
      $('input[name="password"]').attr("type", "text");
    } else {
      $('input[name="password"]').attr("type", "password");
    }
  }

  render() {
    const { show, hideModal, history } = this.props;
    return (
      <Modal
        show={show}
        onHide={() => hideModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Login to Cloud Collect
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginModalForm
            onSubmit={this.submit}
            showPassword={this.handleShowPassword}
            history={history}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const { login } = authAction;
const mapDispatchToProps = { login };

const mapStateToProps = (state) => {
  return {
    userInfo: getUser(),
    user: state.Auth.get("user"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
