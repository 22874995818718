const actions = {
  FETCH_FLOW: "FETCH_FLOW",
  UPDATE_NOTIFICATION_SETTINGS: "UPDATE_NOTIFICATION_SETTINGS",
  SET_FLOW: "SET_FLOW",

  fetchFlow: () => ({
    type: actions.FETCH_FLOW,
  }),

  updateNotificationSettings: (payload) => {
    return {
      type: actions.UPDATE_NOTIFICATION_SETTINGS,
      payload,
    };
  },
};
export default actions;
