import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import url from "../../../url";
import Alert from "../../uielements/alert";
import Loader from "../../uielements/loader";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password should be min 6 character";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <div>
    <input {...input} className="form-control" placeholder={label} type={type} />
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

let LoginForm = (props) => {
  const { handleSubmit, pristine, reset, submitting, showPassword } = props;

  return (
    <form onSubmit={handleSubmit} className="login-form">
      <div className="login-form-head" style={{ backgroundColor: "#292b2c" }}>
        <h2 className="logo" style={{ color: "white" }}>
          Cloud Collect
        </h2>
        <h2 className="logo" style={{ color: "white", fontSize: "20px" }}>
          Login
        </h2>
      </div>

      <div className="login-form-body">
        <div className="row">
          <Alert />
        </div>
        <div className="form-group">
          <Field
            label="Email Address"
            name="email"
            component={renderField}
            type="email"
            id="input-useremail"
          />
        </div>
        <div className="form-group" style={{position: 'relative'}}>
          <Field
            label="Password"
            name="password"
            component={renderField}
            type="password"
            id="input-password"
          />
          <i
            onClick={showPassword}
            className="fa fa-eye password-show"
            // onClick='showPassword()'
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <br />
        <div className="submit-btn-area">
          <button type="submit">
            Login
          </button>
        </div>
        <br />
        <div className="submit-btn-area">
          <Link
            onClick={(e) => {
              e.preventDefault();
            }}
            to={url.account.forgotPassword}
          >
            <button
              onClick="location.href='u-forgot-password.html'"
              type="button"
            >
              Forgot Passsword?
            </button>
          </Link>
        </div>
        <br />
        <div className="submit-btn-area ">
          <Link
            onClick={(e) => {
              e.preventDefault();
            }}
            to={url.account.register}
          >
            <button
              onClick="location.href='u-forgot-password.html'"
              type="button"
            >
              Register
            </button>
          </Link>
        </div>
        <br />
        <p class="text-center"><a href="https://www.cloudcollect.io">Return to Cloud Collect Home</a></p>            
     
        <br />
        {/*<div className='submit-btn-area'>*/}
        {/*<Link onClick={(e) => {*/}
        {/*e.preventDefault();*/}
        {/*}} to={url.account.register}>*/}
        {/*<button id='table-invoice'*/}
        {/*onClick="location.href='u-register.html'"*/}
        {/*type='button'*/}
        {/*>*/}
        {/*Register Account<i className='ti-arrow-right'></i>*/}
        {/*</button>*/}
        {/*</Link>*/}

        {/*</div>*/}
      </div>
    </form>
  );
};

LoginForm = reduxForm({
  // a unique name for the form
  form: "login",
  validate,
})(LoginForm);

export default LoginForm;
