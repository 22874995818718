import React from "react";
import userActions from "../../../../redux/user/actions";
import { connect } from "react-redux";
import moment from "moment";

class BusinessesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      delete_id: null,
      deleteType: "single",
      connect_id: null,
    };
  }

  componentDidMount() {
    document.title = "Cloud Collect - Businesses";
    this.props.fetchUsers({ role: "Business" });

    
  }
  componentWillMount() {
  }

  handleDeleteId = (id) => {
    this.setState({
      delete_id: id,
      deleteType: "single",
    });
  };
  handleDeleteConnectId = (id) => {
    this.setState({
      connect_id: id,
      role: "Business",
    });
  };

  handleRemove = () => {
    if (this.state.deleteType === "single") {
      this.props.deleteUser({ id: this.state.delete_id, role: "Business" });
    }
  };

  handleRemoveStripe = () => {
    this.props.deleteConnectAccount({
      id: this.state.connect_id,
    });
  };

  handleUserInput(e) {}

  handleBulkDelete(e) {}

  handleChecked(e) {}

  render() {
    return (
      <div className="main-content-inner">
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="single-table">
                  <div className="table-responsive">
                    <table
                      id="table-main"
                      className="table table-hover progress-table text-left"
                    >
                      <thead>
                        <tr>
                          <th>Business Name</th>
                          <th>Business ABN</th>
                          <th>Administrator</th>
                          <th>Contact Email</th>
                          <th>Contact Mobile</th>
                          <th>Business Address</th>
                          <th>Last Login At</th>
                          <th>Activated</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.users && this.props.users.length ? (
                          this.props.users.map((element) => {
                            
                            return (
                              <tr key={element.id}>
                                <td>
                                  {element.primary_organisation && element.primary_organisation.business_name}
                                </td>
                                <td>{element.primary_organisation && element.primary_organisation.abn}</td>
                                <td>
                                  {element.first_name} {element.last_name}
                                </td>
                                <td>
                                  {element.primary_organisation && element.primary_organisation.contact_email}
                                </td>
                                <td>
                                  {element.primary_organisation && element.primary_organisation.contact_number}
                                </td>
                                <td>
                                  {element.primary_organisation && element.primary_organisation.address}{" "}
                                  {element.primary_organisation && element.primary_organisation.suburb}{" "}
                                  {element.primary_organisation && element.primary_organisation.state}
                                </td>
                                <td>{element.last_login_at? moment(element.last_login_at).format('MMMM DD YYYY') : ''}</td>
                                <td>{element.created_at? moment(element.created_at).format('MMMM DD YYYY') : ''}</td>
                                <td>
                                  <div className="btn-group">
                                    <button
                                      onClick={() =>
                                        this.handleDeleteId(element.id)
                                      }
                                      className="btn btn-danger"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                    >
                                      Delete Business
                                    </button>
                                    {element &&
                                      element.primary_organisation &&
                                      element.primary_organisation
                                        .stripe_account_id && (
                                        <button
                                          className="btn btn-success"
                                          data-toggle="modal"
                                          data-target="#removeConnect"
                                          onClick={() =>
                                            this.handleDeleteConnectId(
                                              element.id
                                            )
                                          }
                                        >
                                          Remove Connect
                                        </button>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No record found!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <div className="modal fade" id="myModal">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Are you sure want delete?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => this.handleRemove()}
                              data-dismiss="modal"
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              data-dismiss="modal"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal fade" id="removeConnect">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              Are you sure want remove connect account from
                              stripe?
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </div>

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => this.handleRemoveStripe()}
                              data-dismiss="modal"
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              data-dismiss="modal"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { fetchUsers, deleteUser, deleteConnectAccount } = userActions;

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (payload) => fetchUsers({ dispatch, payload }),
  deleteUser: (payload) => deleteUser({ dispatch, payload }),
  deleteConnectAccount: (payload) =>
    deleteConnectAccount({ dispatch, payload }),
});

const mapStateToProps = (state) => {
  return {
    loading: state.Users.loading,
    users: state.Users.users,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessesList);
