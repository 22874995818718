import { store } from 'react-notifications-component';
import './notification.scss'

const createNotification = (type, title = 'Server error', message = '') => {
    store.addNotification({
        title: title,
        message: message || '   ',
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

export { createNotification };