import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

class ProposalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { proposals, amountToFixed } = this.props;

    return (
      <>
        <br />
        <PerfectScrollbar style={{ height: "500px" }}>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" width="25%">Reference</th>
                <th scope="col" width="25%">Amount</th>
                <th scope="col" width="25%">Due</th>
                <th scope="col" width="25%">Status</th>
              </tr>
            </thead>
            <tbody>
              {proposals.map((proposal) => (
                <tr
                  key={proposal.id}
                  onClick={() =>
                    this.props.history.push(`/proposal/non-edit/${proposal.id}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <td>{proposal.proposal_reference}</td>
                  <td>{amountToFixed(proposal.total)}</td>
                  <td>{proposal.proposal_date}</td>
                  <td>{proposal.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </PerfectScrollbar>
      </>
    );
  }
}

export default ProposalTable;
