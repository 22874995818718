import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmComponent = ({
  show,
  handleClose,
  title,
  body,
  buttonText,
  confirmHandler,
}) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmHandler}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmComponent;
