import BusinessHelper from "../../helpers/businessHelper";
import { createNotification } from "../../components/uielements/notification";
import { initialize } from "redux-form";
import authActions from "../auth/actions";

const actions = {
  FETCH_ALL_BUSINESS_USERS: "FETCH_ALL_BUSINESS_USERS",
  FETCH_ALL_BUSINESS_USERS_ERROR: "FETCH_ALL_BUSINESS_USERS_ERROR",
  FETCH_ALL_BUSINESS_USERS_SUCCESS: "FETCH_ALL_BUSINESS_USERS_SUCCESS",

  FETCH_MY_BUSINESS_INVITES: "FETCH_MY_BUSINESS_INVITES",
  FETCH_MY_BUSINESS_INVITES_ERROR: "FETCH_MY_BUSINESS_INVITES_ERROR",
  FETCH_MY_BUSINESS_INVITES_SUCCESS: "FETCH_MY_BUSINESS_INVITES_SUCCESS",

  FETCH_SINGLE_BUSINESS_USER: "FETCH_SINGLE_BUSINESS_USER",
  FETCH_SINGLE_BUSINESS_USER_ERROR: "FETCH_SINGLE_BUSINESS_USER",
  FETCH_SINGLE_BUSINESS_USER_SUCCESS: "FETCH_SINGLE_BUSINESS_USER_SUCCESS",

  CREATE_BUSINESS_USER: "CREATE_BUSINESS_USER",
  CREATE_BUSINESS_USER_ERROR: "CREATE_BUSINESS_USER_ERROR",
  CREATE_BUSINESS_USER_SUCCESS: "CREATE_BUSINESS_USER_SUCCESS",

  DELETE_BUSINESS_USER: "DELETE_BUSINESS_USER",
  DELETE_BUSINESS_USER_ERROR: "DELETE_BUSINESS_USER_ERROR",
  DELETE_BUSINESS_USER_SUCCESS: "DELETE_BUSINESS_USER_SUCCESS",

  FETCH_ALL_BUSINESS_ORGANISATIONS: "FETCH_ALL_BUSINESS_ORGANISATIONS",
  FETCH_ALL_BUSINESS_ORGANISATIONS_SUCCESS: "FETCH_ALL_BUSINESS_ORGANISATIONS_SUCCESS",



  businessUserSuccess(payload) {
    return { type: actions.FETCH_ALL_BUSINESS_USERS_SUCCESS, payload };
  },

  fetchBusinessUsers: ({ dispatch }) =>
    BusinessHelper.fetchBusinessUsers()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_BUSINESS_USERS_SUCCESS,
          payload: data
        });
      })
      .catch(() => {}),

  fetchBusinessOrganisations: ({ dispatch }) =>
    BusinessHelper.fetchBusinessOrganisations()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_ALL_BUSINESS_ORGANISATIONS_SUCCESS,
          payload: data
        });
      })
      .catch(() => {}),

  fetchSingleBusinessUser: ({ dispatch, payload }) => {
    dispatch({
      type: actions.FETCH_SINGLE_BUSINESS_USER_SUCCESS,
      payload
    });
  },

  inviteBusinessUser: ({ payload, dispatch, history }) => {
    BusinessHelper.inviteBusinessUser(payload)
      .then(() => {
        dispatch(initialize("UserForm", {}));
        createNotification("success", "User invited successfully");
        actions.fetchBusinessUsers({ dispatch });
      })
      .catch(error => {
        let errorMessage = "Server Error";
        let errorDescription = "";
        if (error.message) {
          errorMessage = error.message;
        }
        if (error.errors)
          if (error.errors.email) {
            errorDescription = error.errors.email || "";
          }
        createNotification("danger", errorMessage, errorDescription);
      });
  },

  changeDefaultOrganisation: ({ payload, dispatch, history }) => {
    BusinessHelper.changeDefaultOrganisation(payload)
      .then((result) => {
        createNotification("success", "Organisation Changed Successfully");
        dispatch({
          type: authActions.FETCH_CURRENT_USER_SUCCESS,
          payload: result.data
        });

        setTimeout(()=> {
          window.location.reload()
        }, 1000)
       
      })
      .catch(error => {
        let errorMessage = "Server Error";
        let errorDescription = "";
        if (error.message) {
          errorMessage = error.message;
        }
        if (error.errors)
          if (error.errors.email) {
            errorDescription = error.errors.email || "";
          }
        createNotification("danger", errorMessage, errorDescription);
      });
  },

  fetchMyBusinessInvites: ({ dispatch }) =>
    BusinessHelper.myInvites()
      .then(({ data }) => {
        dispatch({
          type: actions.FETCH_MY_BUSINESS_INVITES_SUCCESS,
          payload: data
        });
      })
      .catch(() => {}),

  acceptRejectBusinessInvite: ({ payload, dispatch }) =>
    BusinessHelper.acceptRejectBusinessInvite(payload)
      .then(() => {
        createNotification("success", `Invite ${payload.status} Successfully`);
        actions.fetchMyBusinessInvites({ dispatch });
      })
      .catch(() => {}),

  updateBusinessUser: ({ payload, dispatch }) =>
    BusinessHelper.updateBusinessUser(payload)
      .then(() => {
        actions.fetchBusinessUsers({ dispatch });
      })
      .catch(() => {}),

  deleteBusinessUser: ({ id, dispatch }) =>
    BusinessHelper.deleteBusinessUser(id)
      .then(() => {
        createNotification("success", "User removed successfully");
        actions.fetchBusinessUsers({ dispatch });
      })
      .catch(() => {
        createNotification("danger");
      })
};
export default actions;
