import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderCheckBox } from "../../../uielements/FormFields";

class InvoiceDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleSubmit, initialValues } = this.props;

    return (
      <div className="col-lg-3 mt-3">
        <div className="card">
          <div className="card-body">
            <p className="onboard-heading">Invoice Details</p>
            <hr />
            <p>
              Select which details appear on your invoices, proposals and
              receipts.
            </p>

            <div className="col-md-12 mt-3">
              <form onSubmit={handleSubmit}>
                <fieldset className="form-group">
                  <div className="form-check">
                    <Field
                      name="email_detail"
                      label="Email"
                      component={renderCheckBox}
                      id="email_detail"
                      checked={initialValues && initialValues.email_detail}
                    />
                  </div>
                  <br />
                  <div className="form-check">
                    <Field
                      name="mobile_detail"
                      label="Mobile"
                      component={renderCheckBox}
                      id="mobile_detail"
                      checked={initialValues && initialValues.mobile_detail}
                    />
                  </div>
                  <br />
                  <div className="form-check">
                    <Field
                      name="address_detail"
                      label="Address"
                      component={renderCheckBox}
                      id="address_detail"
                      checked={initialValues && initialValues.address_detail}
                    />
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "InvoiceDetailsForm",
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    props.submit();
  },
})(InvoiceDetailsForm);
