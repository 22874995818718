import SuperFetch from "./superFetch";

class TermsHelper {
  fetchTerms = async () => {
    return await SuperFetch.get("/settings")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  createTerms = async ({ payload }) => {
    let bodyFormData = new FormData();
    for (let key in payload) {
      bodyFormData.append(key, payload[key]);
    }

    return await SuperFetch.post("/settings", payload)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
export default new TermsHelper();
