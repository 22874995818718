import React from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { apiHost, apiSocketHost } from "../../../config";

class PaymentSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      from_date: "",
      to_date: "",
      total_invoice_amount: "",
      total_balance_amount: "",
      total_payment_recieved: "",
      total_charges: "",
      gst: "",
      subtotal: "",
    };
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
  }

  handleFromDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({ from_date: date }, () => {
      this.getPaymentByDate();
    });
  }

  handleToDate(e) {
    var date = null;
    if (e) {
      date = e;
    }
    this.setState({ to_date: date }, () => {
      this.getPaymentByDate();
    });
  }
  componentDidMount() {
    document.title = "Cloud Collect - Payment Summary";
    this.getPayment();
  }
  createState(response) {
    this.setState({
      total_invoice_amount: response.total_invoice_amount,
      total_balance_amount: response.total_balance_amount,
      total_payment_recieved: response.total_payment_recieved,
      total_charges: response.total_charges,
      netpayment: response.netpayment,
      gst: response.gst,
      subtotal: response.netpayment + response.gst,
    });
  }
  getPaymentByDate() {
    SuperFetch.get(
      "/reports/payment-summary?start_date=" +
        this.getDate(this.state.from_date) +
        "&end_date=" +
        this.getDate(this.state.to_date)
    ).then((response) => {
      if (response && response.data) this.setState({ allData: response.data });
    });
  }
  getPayment() {
    SuperFetch.get("/reports/payment-summary").then((response) => {
      if (response && response.data) this.setState({ allData: response.data });
    });
  }

  downloadReport = () => {
    this.setState({
      downloading: true,
    });
    let query = "?download=true&";
    if (this.state.from_date != "" && this.state.to_date != "") {
      query += `&start_date=${this.getDate(
        this.state.from_date
      )}&end_date=${this.getDate(this.state.to_date)}`;
    }
    SuperFetch.get(`/reports/payment-summary${query}`)
      .then((response) => {
        this.setState({
          downloading: false,
        });
        if (response && response.data && response.data.url) {
          window.open(
            apiSocketHost.fetchUrl +
              `/download-pdf/?url=${response.data.url}&name=payment-summary.pdf`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch(() => {
        this.setState({
          downloading: false,
        });
      });
  };

  getDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + this.addZero(month) + "-" + this.addZero(date);
  }

  changeDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return this.addZero(date) + "/" + this.addZero(month) + "/" + year;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  render() {
    const totalData = this.state.allData;

    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-8 mt-3">
            <div className="card">
              <div className="card-body">
                <p>
                  This report details total payments per customer between the
                  following dates.
                </p>
                <br />
                <div className="row">
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date From
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date From:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        id="exampleFormControlSelect1"
                        selected={this.state.from_date}
                        onChange={this.handleFromDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date To
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date To:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        selected={this.state.to_date}
                        onChange={this.handleToDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="invoice-buttons  text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ width: "150px" }}
                        onClick={this.downloadReport}
                        disabled={this.state.downloading}
                      >
                        {this.state.downloading ? (
                          <>
                            <i class="fa fa-spinner fa-spin"></i> Downloading...
                          </>
                        ) : (
                          <>Download PDF</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8 mt-3">
            <div class="card">
              <div class="card-body">
                <h2 class="text-center">
                  {this.props.currentUser &&
                    this.props.currentUser.organisation &&
                    this.props.currentUser.organisation.business_name}
                </h2>
                <h4 class="text-center">Payments Summary</h4>
                <p class="text-center">
                  {this.state.from_date &&
                    this.changeDate(this.state.from_date)}{" "}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.from_date || this.state.to_date ? "to" : ""}
                  &nbsp;&nbsp;&nbsp;{" "}
                  {this.state.to_date && this.changeDate(this.state.to_date)}
                </p>
                <br />
                <br />

                <div class="row" id="row-report">
                  <div class="col-6 mt-3">
                    <p></p>
                  </div>
                  <div class="col-2 mt-3" id="text-report-header">
                    <p>Payment</p>
                  </div>
                  <div class="col-2 mt-3" id="text-report-header">
                    <p>GST</p>
                  </div>
                </div>

                <div class="row" id="row-report">
                  <div class="col-6 mt-3">
                    <p>TOTAL PAYMENTS</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    <p>${totalData.total_payment_recieved}</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    <p>${totalData.payment_gst}</p>
                  </div>
                </div>
                <div class="row" id="row-report-sum">
                  <div class="col-6 mt-3">
                    <p>CHARGES</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    <p>${totalData.stripe_fees}</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    <p>${totalData.stripe_fees_charges}</p>
                  </div>
                </div>
                <div class="row" id="row-report">
                  <div class="col-6 mt-3">
                    <p>NET PAYMENTS</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    <p>${totalData.net_payment}</p>
                  </div>
                  <div class="col-2 mt-3" id="text-number">
                    ${totalData.net_fees}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
  };
};
export default connect(mapStateToProps)(PaymentSummary);
