const actions = {
  FETCH_SELECTED_PRODUCT: "FETCH_SELECTED_PRODUCT",
  SET_PRODUCT: "SET_PRODUCT",

  selectedProduct: (payload) => {
    return {
      type: actions.FETCH_SELECTED_PRODUCT,
      payload,
    };
  },
};

export default actions;
