import React from "react";
import DatePicker from "react-datepicker";
import SuperFetch from "../../../helpers/superFetch";
import { connect } from "react-redux";
import { formattedAmount } from "../../../_methods/commonMethods";
import NumberFormat from "react-number-format";
import { apiHost, apiSocketHost } from "../../../config";

class ReportIncome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: "",
      to_date: "",
      data: [],
      allData: [],
    };
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
  }

  handleFromDate(e) {
    var date = null;
    if (e){
      date = e
    }
    this.setState(
      {
        from_date: date,
      },
      () => {
        this.getIncomeWithDate();
      }
    );
  }

  handleToDate(e) {
    var date = null;
    if (e){
      date = e
    }
    this.setState(
      {
        to_date: date,
      },
      () => {
        this.getIncomeWithDate();
      }
    );
  }

  getImcome() {
    SuperFetch.get("/reports/income-detailed").then((response) => {
      if (response.data) {
        this.setState({ data: response.data, allData: response.data });
      }
    });
  }

  getIncomeWithDate() {
    SuperFetch.get(
      "/reports/income-detailed?start_date=" +
        this.getDate(this.state.from_date) +
        "&end_date=" +
        this.getDate(this.state.to_date)
    ).then((response) => {
      if (response.data) {
        this.setState({ data: response.data, allData: response.data });
      }
    });
  }

  downloadReport = () => {
    this.setState({
      downloading: true,
    });
    let query = "?download=true&";
    if (this.state.from_date != "" && this.state.to_date != "") {
      query += `&start_date=${this.getDate(
        this.state.from_date
      )}&end_date=${this.getDate(this.state.to_date)}`;
    }
    SuperFetch.get(`/reports/income-detailed${query}`)
      .then((response) => {
        this.setState({
          downloading: false,
        });
        if (response && response.data && response.data.url) {
          window.open(
            apiSocketHost.fetchUrl + `/download-pdf/?url=${response.data.url}&name=income-report.pdf`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch(() => {
        this.setState({
          downloading: false,
        });
      });
  };

  componentDidMount() {
    document.title = "Cloud Collect - Income Detail";
    this.getImcome();
  }

  getTotals() {
    let sub_total_charger = 0;
    let sub_total_income = 0;
    let sub_total_netIncome = 0;
    let gst_total = 0;
    let gst_charges = 0;
    if (this.state.data) {
      let incomeData = this.state.allData;
      incomeData.map((item) => {
        sub_total_charger += item.reports_data.total_charges;
        sub_total_income += item.reports_data.subtotal;
        gst_total += item.reports_data.gst;
        gst_charges += item.reports_data.gst_charges;
        sub_total_netIncome +=
          item.reports_data.subtotal - item.reports_data.total_charges;
      });
    }

    let totalData = {
      sub_total_charger: sub_total_charger,
      sub_total_income: sub_total_income,
      sub_total_netIncome: sub_total_netIncome,
      gst_charger: gst_charges,
      gst_income: gst_total - gst_charges,
      gst_netIncome: gst_total - gst_charges,
      total_charger: sub_total_charger + gst_charges,
      total_income: sub_total_income + gst_total,
      total_netIncome:
        sub_total_income + gst_total - (sub_total_charger + gst_charges),
      gst_total,
    };
    return totalData;
  }

  getDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return year + "-" + this.addZero(month) + "-" + this.addZero(date);
  }

  changeDate(data) {
    let newDate = new Date(data);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return this.addZero(date) + "/" + this.addZero(month) + "/" + year;
  }
  getChangeDate(data) {
    if (data) {
      let date = data.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    }
    return;
  }

  addZero(day) {
    day = parseInt(day).toString();
    if (day.length === 1) day = "0" + day;
    return day;
  }

  render() {
    const totalData = this.getTotals();
    return (
      <div className="main-content-inner">
        <div className="row">
          <div className="col-8 mt-3">
            <div className="card">
              <div className="card-body">
                <p>
                  This report details all invoices raised between the following dates.
                </p>
                <br />
                <div className="row">
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date From
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date From:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        selected={this.state.from_date}
                        onChange={this.handleFromDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <label class="sr-only" for="inlineFormInputGroup">
                      Date To
                    </label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text" id="input-label-large">
                          Date To:
                        </div>
                      </div>
                      <DatePicker
                        className="form-control date-picker-input-reports"
                        selected={this.state.to_date}
                        onChange={this.handleToDate.bind(this)}
                        dateFormat={"dd/MM/yyyy"}
                      />
                    </div>
                  </div>
                  <div className="col-4 mt-3">
                    <div className="invoice-buttons text-right">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ width: "150px" }}
                        onClick={this.downloadReport}
                        disabled={this.state.downloading}
                      >
                        {this.state.downloading ? (
                          <>
                            <i class="fa fa-spinner fa-spin"></i> Downloading...
                          </>
                        ) : (
                          <>Download PDF</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8 mt-3">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center">
                  {this.props.currentUser &&
                    this.props.currentUser.organisation &&
                    this.props.currentUser.organisation.business_name}
                </h2>
                <h4 className="text-center">Income Detailed</h4>
                <p className="text-center">
                  {this.state.from_date &&
                    this.changeDate(this.state.from_date)}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.from_date || this.state.to_date ? "to" : ""}
                  &nbsp;&nbsp;&nbsp;
                  {this.state.to_date && this.changeDate(this.state.to_date)}
                </p>
                <br />
                <br />

                {this.state.allData &&
                  this.state.allData.map((item, key) => {
                    return (
                      <div key={key} className="row" id="row-report">
                        <div className="col-2 mt-3">
                          <p>{this.getChangeDate(item.invoice_date)}</p>
                        </div>
                        <div className="col-4 mt-3">
                          <p>
                            Invoice #
                            {item.invoice_number && item.invoice_number} -{" "}
                            {item.lead && item.lead.first_name}{" "}
                            {item.lead && item.lead.last_name}
                          </p>
                        </div>
                        <div className="col-6 mt-3" id="text-number">
                          <p>
                            {
                              <NumberFormat
                                value={parseFloat(
                                  item.reports_data.subtotal
                                ).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                renderText={(value) => <div>{value}</div>}
                              />
                            }
                          </p>
                        </div>
                        {/* <div className="col-2 mt-3" id="text-number">
                          <p>
                            <NumberFormat
                              value={parseFloat(
                                item.reports_data.total_charges
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <div>{value}</div>}
                            />
                          </p>
                        </div>
                        <div className="col-2 mt-3" id="text-number">
                          <p>
                            <NumberFormat
                              value={parseFloat(
                                item.reports_data.total_invoice_amount -
                                  item.reports_data.total_charges
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={(value) => <div>{value}</div>}
                            />
                          </p>
                        </div> */}
                      </div>
                    );
                  })}

                <div className="row  mt-3" id="row-report-sum-t">
                  <div className="col-6">
                    <p>SUB-TOTAL</p>
                  </div>
                  <div className="col-6" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.sub_total_income).toFixed(
                          2
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  {/* <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.sub_total_charger).toFixed(
                          2
                        )}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(
                          totalData.sub_total_netIncome
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div> */}
                </div>
                <div className="row" id="row-report-sum">
                  <div className="col-6 mt-3">
                    <p>GST</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.gst_total).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  {/* <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.gst_charger).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.gst_netIncome).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div> */}
                </div>
                <div className="row" id="row-report">
                  <div className="col-6 mt-3">
                    <p>TOTAL</p>
                  </div>
                  <div className="col-6 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.total_income).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  {/* <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.total_charger).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div>
                  <div className="col-2 mt-3" id="text-number">
                    <p>
                      <NumberFormat
                        value={parseFloat(totalData.total_netIncome).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => <div>{value}</div>}
                      />
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.Auth.get("user"),
  };
};
export default connect(mapStateToProps)(ReportIncome);
