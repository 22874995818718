import React, { useState } from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import Alert from "../../uielements/alert";
import { CardElement, injectStripe } from "react-stripe-elements";

const validate = (values) => {
  const errors = {};

  return errors;
};

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        readOnly={readOnly}
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  type,
  readOnly,
  id,
  options,
  meta: { touched, error, warning },
}) => (
  <div>
    <label htmlFor={id} className="col-form-label">
      {label}
    </label>
    <select
      {...input}
      className="form-control"
      style={{ height: "50px" }}
      disabled={readOnly}
    >
      <option value=""></option>
      {options &&
        options.map((option, index) => (
          <option key={id + index} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

let CreditCardForm = (props) => {
  const {
    handleSubmit,
    pristine,
    reset,
    submitting,
    handleEditClick,
    handleCancelCLicked,
    handleSaveCLicked,
    creditFormEdit,
    currentUser,
    createCreditCard,
  } = props;

  const [stripeCardObject, setStripeCardObject] = useState({});

  const onSubmit = async (values) => {
    if (stripeCardObject && stripeCardObject.complete) {
      const { token } = await props.stripe.createToken({
        name: values.card_holder_name,
      });
      if (token){
        values.stripe_token = token.id;
        values.organisation_id = props.organisationData.id;
        createCreditCard(values);
        handleCancelCLicked("creditCard");
      }
      
    }
    return false;
  };
  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))}>
      <div class="col-md-12 mt-3">
        <Field
          label="Cardholder Name"
          type="text"
          id="card_holder_name"
          name="card_holder_name"
          component={renderField}
        />
      </div>
      <div class="col-md-12 mt-3">
        <label for="example-text-input" class="col-form-label">
          Cardholder Number, Expiry Date and CVV
        </label>
        <CardElement
          hidePostalCode={true}
          onChange={(obj) => {
            setStripeCardObject(obj);
          }}
        />
      </div>

      <hr />
      <div class="invoice-buttons text-center">
        <button
          type="submit"
          class="btn btn-success"
          // onClick={(event) => {
          //   event.preventDefault();
          //   handleEditClick("creditCard");
          // }}
        >
          Add Card
        </button>
      </div>
      {/* {!creditFormEdit ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "5px",
          }}
        >
          
          {currentUser &&
            currentUser.primary_organisation &&
            currentUser.primary_organisation.id ===
              currentUser.default_organisation_id && (
              <button
                type="button"
                className="btn btn-secondary"
                style={{ width: "150px" }}
                onClick={(event) => {
                  event.preventDefault();
                  handleEditClick("creditCard");
                }}
              >
                Add Card
              </button>
            )}
        </div>
      ) : (
        <div className="form-buttons-group">
          <button
            type="submit"
            className="btn btn-secondary"
            style={{ width: "100px" }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            style={{ width: "100px" }}
            onClick={(event) => {
              event.preventDefault();
              handleCancelCLicked("creditCard");
            }}
          >
            Cancel
          </button>
        </div>
      )} */}
    </form>
  );
};

CreditCardForm = reduxForm({
  // a unique name for the form
  form: "creditCardForm",
  validate,
  enableReinitialize: true,
})(injectStripe(CreditCardForm));

export default CreditCardForm;
