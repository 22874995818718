import { Map } from "immutable";
import actions from "./actions";

const initState = new Map({
  organisationData: null,
  loading: false,
  organisationLoading: false,
  directorLoading: false,
  depositLoading: false,
  billingLoading: false,
  creditCardLoading: false,
});
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ORGANISATION:
      return state
        .set("organisationLoading", true)
        .set("directorLoading", true)
        .set("billingLoading", true)
        .set("depositLoading", true);
    case actions.UPDATE_DEPOSIT:
      return state.set("depositLoading", true);
    case actions.UPDATE_BILLING:
      return state.set("billingLoading", true);
    case actions.UPDATE_ORGANISATION:
      return state.set("organisationLoading", true);
    case actions.UPDATE_DIRECTOR:
      return state.set("loading", true).set("directorLoading", true);
    case actions.CREATE_CREDIT_CARD:
      return state.set("loading", true).set("creditCardLoading", true);
    case actions.UPDATE_CREDIT_CARD:
      return state.set("loading", true).set("creditCardLoading", true);
    case actions.DELETE_CREDIT_CARD:
      return state.set("loading", true).set("creditCardLoading", true);
    case actions.FETCH_ORGANISATION_SUCCESS:
      return state
        .set("organisationData", action.payload.data)
        .set("organisationLoading", false)
        .set("directorLoading", false)
        .set("depositLoading", false)
        .set("billingLoading", false)
        .set("creditCardLoading", false);
    case actions.REMOVE_LOADER:
      return state
        .set("loading", false)
        .set("depositLoading", false)
        .set("billingLoading", false)
        .set("directorLoading", false)
        .set("organisationLoading", false);
    default:
      return state;
  }
}
