export default {
  apiUrl: process.env.REACT_APP_API_HOST,
  apiSocket: process.env.REACT_APP_HOST,
};

const apiHost = {
    // fetchUrl: process.env.REACT_APP_API_HOST || 'https://admin.cloudcollect.io/api/v1',
    fetchUrl: process.env.REACT_APP_API_HOST || "https://cc-admin.instaging.net/api/v1",
};
const apiSocketHost = {
  //fetchUrl: process.env.REACT_APP_HOST || "https://admin.cloudcollect.io",
    fetchUrl: process.env.REACT_APP_API_HOST || "https://cc-admin.instaging.net/api/v1",
};
export { apiHost, apiSocketHost };
