import React from "react";
import OutstandingInvoice from "./outstandingInvoice";
import PendingProposals from "./pendingProposals";
import SuperFetch from "../../helpers/superFetch";
import { NavLink } from "react-router-dom";
import NumberFormat from "react-number-format";

export default class Dashboard1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proposals: {},
      invoices: {},
    };
  }
  componentDidMount() {
    SuperFetch.get("/business/dashboard").then((response) => {
      if (response && response.data) {
        this.setState({
          proposals: response.data.proposals,
          invoices: response.data.invoices,
        });
      }
    });
  }

  amountToFixed(e) {
    return (
      <NumberFormat
        value={parseFloat(e).toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => <div>{value}</div>}
      />
    );
  }

  render() {
    return (
      <div className="main-content-inner dashboard_tables">
        <div className="row" id="dashboard-actions">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h3 style={{ textAlign: "center" }}>
                  What would you like to do?
                </h3>
                <br />
                <div className="button-center row">
                  <NavLink
                    to={{ pathname: `/invoice/add` }}
                    className="btn btn-secondary"
                  >
                    New Invoice
                  </NavLink>
                  <NavLink
                    to={{ pathname: `/proposal/add` }}
                    className="btn btn-secondary"
                  >
                    New Proposal
                  </NavLink>
                  <NavLink
                    to={{ pathname: `/newLead` }}
                    className="btn btn-secondary"
                  >
                    New Contact
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="dashboard-performance">
          <div className="col-md-3 mt-3">
            <div className="card">
              <div className="card-body">
                <h5>Invoices Sent</h5>
                <br />
                <table className="table-dash">
                  <tbody>
                    <tr>
                      <td>This Month</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.sent_this_month
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>This Year</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.sent_this_month
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <h5>Proposals Accepted</h5>
                <br />
                <table className="table-dash">
                  <tbody>
                    <tr>
                      <td>This Month</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.proposals.accepted_this_month
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>This Year</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.proposals.accepted_this_year
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <h5>Invoiced Amount</h5>
                <br />
                <table className="table-dash">
                  <tbody>
                    <tr>
                      <td>This Month</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.total_invoice_amount_this_month
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>This Year</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.total_invoice_amount_this_year
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-3">
            <div className="card">
              <div className="card-body">
                <h5>Payments Received</h5>
                <br />
                <table className="table-dash">
                  <tbody>
                    <tr>
                      <td>This Month</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.total_payment_recieved_this_month
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>This Year</td>
                      <td className="table-number">
                        {this.amountToFixed(
                          this.state.invoices.total_payment_recieved_this_year
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row" id="dashboard-attention">
          <OutstandingInvoice
            daysOverdue={this.state.invoices.top_based_on_days_overdue}
            totalAmount={this.state.invoices.top_based_on_total_amount}
            amountToFixed={this.amountToFixed}
          />
          <PendingProposals
            totalAmount={this.state.proposals.top_based_on_total_amount}
            soonestExpire={this.state.proposals.soonest_to_expire}
            amountToFixed={this.amountToFixed}
          />
        </div>
      </div>
    );
  }
}
