import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";

const renderField = ({
  input,
  label,
  type,
  readOnly,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <label class="sr-only" for="inlineFormInputGroup"></label>
    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <div class="input-group-text" id="input-label-large">
          {label}
        </div>
      </div>
      <input
        {...input}
        type={type}
        className="form-control"
        readOnly={readOnly}
        id={id}
      />
    </div>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
  </div>
);

const validate = (values, props) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Please enter first name.";
  }
  if (!values.last_name) {
    errors.last_name = "Please enter last name.";
  }
  if (!values.email) {
    errors.email = "Please enter email.";
  }
  if (!values.mobile) {
    errors.mobile = "Please enter mobile.";
  }

  if (values.mobile && values.mobile.length !== 10) {
    errors.mobile = "Mobile should be 10 digits";
  }

  return errors;
};

class SubscriptionSection extends Component {
  render() {
    const { handleSubmit, loading, subscription } = this.props;
    return (
        <div class="card">
          <div class="card-body">
            <p class="onboard-heading">Details</p>
            <hr />
            <form onSubmit={handleSubmit}>
              <div class="col-md-12 mt-3">
                <Field
                  label="First Name"
                  type="text"
                  id="first_name"
                  name="first_name"
                  component={renderField}
                />
              </div>
              <div class="col-md-12 mt-3">
                <Field
                  label="Last Name"
                  type="text"
                  id="last_name"
                  name="last_name"
                  component={renderField}
                />
              </div>
              <div class="col-md-12 mt-3">
                <Field
                  label="Email Address"
                  type="text"
                  id="email"
                  name="email"
                  readOnly={true}
                  component={renderField}
                />
              </div>
              <div class="col-md-12 mt-3">
                <Field
                  label="Mobile"
                  type="text"
                  id="mobile"
                  name="mobile"
                  component={renderField}
                />
              </div>
              <hr />
              <div className="invoice-buttons text-center">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={loading}
                >
                  {loading && <i class="fa fa-spinner fa-spin" />} Update
                </button>
              </div>
            </form>
          </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "AccountForm",
  validate,
  enableReinitialize: true,
})(SubscriptionSection);
